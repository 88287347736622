<template>
    <!-- Vertical Navbar -->
    <div>
        <div v-if="apiLoading" class="loading">
            Loading&#8230;
        </div>
        <div class="d-flex flex-column flex-lg-row h-lg-full navOutDiv">
            <!-- Vertical Navbar -->
            <Sidebar></Sidebar>
            <!-- Main content -->
            <div class="h-screen flex-grow-1 overflow-y-lg-auto m-4 dashScroll">
                <main class="py-6">
                    <div class="row align-items-center">
                        <div class="col-sm-3 col-12 mb-4 mb-sm-0">
                            <div class="card-header" style="background: none; border: none">
                                <h2 class="mb-0 fontWeightBolder">
                                    wizard
                                </h2>
                            </div>
                        </div>
                    </div>

                    <div class="card border-0 mb-7 table-align" id="userForDiv">
                        <div class="table-outer-wrap">
                            <div class="inner-white-wrap">
                                <div class="table-responsive">
                                    <table class="table table-hover table-nowrap formtable">
                                        <thead class="thead-light">
                                            <tr class="header">
                                                <th class="heading" scope="col">ID</th>
                                                <th class="heading" scope="col">Title</th>
                                                <th class="heading" scope="col">Tutorial Link</th>
                                                <th class="heading" scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="wizardList.length > 0">
                                            <tr v-for="wizard in wizardList" v-bind:key="wizard.id">
                                                <td>
                                                    <a class="text-heading" style="text-decoration: none" href="#">
                                                        {{wizard.id}}
                                                    </a>
                                                </td>
                                                <td>
                                                    <a class="text-heading" style="text-decoration: none" href="#">
                                                        {{ ` ${wizard.subheading}` }}
                                                    </a>
                                                </td>
                                                <td>
                                                    <a class="text-heading" style="text-decoration: none" href="#">
                                                        {{ wizard.tutorialLink }}
                                                    </a>
                                                </td>
                                                <td>
                                                    <button class="platFormCardButton" @click="editwizard(wizard.id)"
                                                        style="width: auto;background: #00c0ef;">Edit
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr>
                                                <td colspan="4" class="text-heading">No Data Found.</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div v-if="wizardCount > wizardPerPage"
                                    class="admin-new-pagination role-table-pagination">
                                    <b-pagination v-model="wizardCurrentPage" :total-rows="wizardCount"
                                        :per-page="wizardPerPage" @change="showwizardPage" first-number last-number
                                        align="center" pills size="lg">
                                    </b-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    </div>
</template>

<script>
import Sidebar from './Sidebar'
export default {
    name: "WizardSetting",
    components: {
        Sidebar
    },
    data() {
        return {
            wizardList: [],
            wizardCount: 0,
            wizardCurrentPage: 1,
            wizardPerPage: 20,
            apiLoading: false,
        };
    },

    mounted() {
        this.wizardData();
    },
    created() { },
    computed: {},
    methods: {
        wizardData() {
            this.apiLoading = true;

            let data = {
                page: this.wizardCurrentPage ? this.wizardCurrentPage : 1,
            };
            this.$store
                .dispatch("wizardList", data)
                .then((response) => {
                    if (response.success) {
                        this.wizardList = response.data.data;
                        this.wizardCount = response.data.total;
                    }
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => (this.apiLoading = false));
        },
        editwizard(id) {
            this.$router.push({ path: `/admin/wizard/edit/${id}` });
        },
        /**
    * @desc: Show Page
    * @param: page
    * @return: None
    * */
        showwizardPage(page) {
            this.wizardCurrentPage = page;
            this.wizardData();
        },
    },
};
</script>

<style scoped>
.text-heading {
    font-weight: 500 !important;
    text-transform: capitalize;
    color: #00053c;
    font-family: "Inter";
    font-style: normal;
    font-size: 14px;
    align-items: center;
    white-space: initial;
}

.formtable {
    text-align: center;
    border-collapse: inherit;
}

.fontWeightBolder {
    font-weight: bolder;
}


.heading {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-size: 12px;
    line-height: 24px;
    align-items: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #00053c !important;
    font-weight: 600;
    align-items: center;
}

::placeholder {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 29px;
    /* or 181% */
    display: flex;
    align-items: center;

    color: #9194b3;
}


.table>tbody>tr>td {
    padding: 30px 15px;
}

/* Review Css End */
.platFormCardButton {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    /* or 181% */
    color: #ffffff;
    width: 100px;
    height: 40px;
    border-radius: 10px;
}

textarea {
    resize: none;
}

form i {
    margin-left: -30px;
    cursor: pointer;
}
.navOutDiv {
    position: fixed;
    width: 100%;
}

.dashScroll {
    margin-right: 0 !important;
    padding-right: 15px;
}

.role-table-pagination .pagination {
    justify-content: center;
}

.admin-new-pagination ul.pagination {
    background: none;
}

.admin-new-pagination ul li {
    flex-grow: initial !important;
    box-shadow: 0px 2px 6px #0000000a;
}

.admin-new-pagination {
    padding: 35px 0;
    background: #fff;
    overflow: auto;
}

.admin-new-pagination .pagination {
    /* justify-content: flex-end; */
    height: auto;
    box-shadow: none;
}

.admin-new-pagination .pagination li.active a.page-link {
    background: #623cea;
    border-radius: 0px;
    border-color: #623cea;
    color: #fff;
}

.admin-new-pagination .pagination li.active a.page-link:focus {
    box-shadow: none;
}

.admin-new-pagination .pagination a.page-link {
    color: #a4afb7;
    background: #fff;
}

.admin-new-pagination ul li span,
.admin-new-pagination ul li a,
.admin-new-pagination ul li button {
    padding: 10px 15px !important;
    font-size: 13px !important;
}

.admin-new-pagination .pagination a.page-link:focus {
    box-shadow: none;
}

.dark-theme .admin-new-pagination .pagination li.active a.page-link {
    background: #1a73e8;
    border-color: #1a73e8;
}

.table-align {
    margin-top: 15px;
    border-radius: 36px;
    margin-left: 10px;
}

table th:first-child {
    border-radius: 15px 0 0 15px;
}

table th:last-child {
    border-radius: 0 15px 15px 0;
}

.table>tbody>tr>td,
.table>thead>tr>th {
    border-top: none;
}

.header th {
    height: 10px;
    max-width: 600px;
    margin: 100px auto;
}

table th {
    background-color: #f4f5fa !important;
    height: 51px !important;
    padding: 15px !important;
    text-align: center !important;
}

/* style start */
.inner-white-wrap {
    border-radius: 36px;
    background: #fff;
    /* overflow: hidden; */
    height: 100%;
    padding: 15px;
}

.table-outer-wrap {
    padding: 30px;
    background: rgb(244, 245, 250);
    border-radius: 36px;
}

.card-header h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600 !important;
    font-size: 32px;
    color: #00053c;
}

.table-outer-wrap .pagination button,
.table-outer-wrap .pagination span {
    margin: 0 3px;
}

table td h1 {
    font-size: 16px;
}


@media (min-width: 1400px) {}

@media (max-width: 1599.98px) {
    .table>tbody>tr>td {
        padding: 10px;
    }

    table .btn {
        padding: 0 5px !important;
    }
}

@media (max-width: 1299.98px) {

    .text-width {
        width: auto !important;
    }

    .platFormCardGroup {
        margin: 0;
        -moz-column-gap: 15px;
        column-gap: 15px;
    }

    .platFormCard {
        padding: 50px 0 5px;
        height: auto;
    }
}

@media (max-width: 1199.98px) {
    .platFormCardGroup {
        margin: 0;
        -moz-column-gap: 15px;
        column-gap: 15px;
    }

    .platFormCard {
        padding: 30px 0 5px;
        height: auto;
    }

    .profileSettingHeading {
        margin: 0;
    }

    .com-set-wrap {
        margin: 0 !important;
    }
}

@media (max-width: 991.98px) {

    .navOutDiv {
        position: unset;
    }

}

@media (max-width: 767.98px) {

    .table-align>.card {
        margin: 12px !important;
    }

    .card.border-0.mb-7.inner-feedback-action {
        margin: 0 10px;
    }

    .card.border-0.feeback-action.col-9 {
        width: 100% !important;
    }


    table td h1 {
        font-size: 15px;
    }

    .card-header h2 {
        font-size: 26px;
    }

    .table-responsive {
        border: 0;
    }

    .table-responsive .table th {
        border: 0;
    }

    .table-outer-wrap {
        padding: 12px;
    }

    div#userForDiv {
        margin: 0;
        margin-top: 10px;
    }

}

/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {}

.createButton {
    font-family: "Inter", sans-serif;
    background: #00053c;
    color: white;
    font-size: 18px;
    border-radius: 15px;
    width: 170px;
    height: 51px;
}
</style>
