import Vue from "vue";
import VueRouter from "vue-router";
import App from "../components/App";
import SignUp from "../components/SignUp";
import Dashboard from "../components/Dashboard";
import ForgotPassword from "../components/ForgotPassword";
import MailFeedback from "../components/MailFeedback";
import Payment from "../components/Payment";
import FrontrunnerRequestReview from "../components/FrontrunnerRequestReview";
import LandingPage from "../components/LandingPage";
import AdminLogin from "../components/admin/AdminLogin";
import AdminDashboard from "../components/admin/Dashboard";
import Homepage from "../components/admin/Homepage";
import Clients from "../components/admin/Clients";
import Faq from "../components/admin/Faq";
import faqCreate from "../components/admin/faqCreate";
import Support from "../components/admin/Support";
import SupportCreate from "../components/admin/SupportCreate";
import AddClient from "../components/admin/AddClient";
import WizardSetting from "../components/admin/WizardSetting";
import WizardEdit from "../components/admin/WizardEdit";
import ChangePassword from "../components/admin/ChangePassword";
import CancelSubscriptionRequest from "../components/admin/CancelSubscriptionRequest";
import PrivacyPolicy from "../components/PrivacyPolicy";
import PageNotFound from "../components/PageNotFound";

// # OLD
import Reviews from "../components/Reviews";
import RequestReview from "../components/RequestReview";
import Feedback from "../components/Feedback";
import Feedback3 from "../components/Feedback3";
import Feedback5 from "../components/Feedback5";
import FeedbackThree from "../components/FeedbackThree";
import FeedbackFive from "../components/FeedbackFive";
import Users from "../components/Users";
import Reports from "../components/Reports";
import Settings from "../components/Settings";
import Help from "../components/Help";

/* const App = () => import('../components/App.vue'); */

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "landing-page",
        component: LandingPage,
    },
    {
        path: "/login",
        name: "App",
        component: App,
    },
    {
        path: "/signup",
        name: "SignUp",
        component: SignUp,
    },
    {
        path: "/signup/payment",
        name: "Payment",
        component: Payment,
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
    },
    {
        path: "/reviews",
        name: "Reviews",
        component: Reviews,
    },
    {
        path: "/request-reviews",
        name: "request-reviews",
        component: RequestReview,
    },
    {
        path: "/feedback",
        name: "Feedback",
        component: Feedback,
    },
    {
        path: "/individual-less-star",
        name: "FeedbackThree",
        component: FeedbackThree,
    },
    {
        path: "/individual-more-star",
        name: "Feedback5",
        component: Feedback5,
    },
    {
        path: "/users",
        name: "Users",
        component: Users,
    },
    {
        path: "/reports",
        name: "Reports",
        component: Reports,
    },
    {
        path: "/settings",
        name: "Settings",
        component: Settings,
    },
    {
        path: "/help",
        name: "Help",
        component: Help,
    },
    {
        path: "/password/reset",
        name: "ForgotPassword",
        component: ForgotPassword,
    },
    {
        path: "/request-review-old/:id",
        name: "MailFeedback",
        component: MailFeedback,
    },
    {
        path: "/admin/login",
        name: "AdminLogin",
        component: AdminLogin,
    },
    {
        path: "/admin/dashboard",
        name: "AdminDashboard",
        component: AdminDashboard,
    },
    {
        path: "/admin/homepage",
        name: "Homepage",
        component: Homepage,
    },
    {
        path: "/admin/clients",
        name: "Clients",
        component: Clients,
    },
    {
        path: "/admin/clients/create",
        name: "AddClient",
        component: AddClient,
    },
    {
        path: "/admin/clients/edit/:id",
        name: "AddClient",
        component: AddClient,
    },
    {
        path: "/admin/faq",
        name: "Faq",
        component: Faq,
    },
    {
        path: "/admin/faq/create",
        name: "faqCreate",
        component: faqCreate,
    },
    {
        path: "/admin/faq/edit/:id",
        name: "faqCreate",
        component: faqCreate,
    },
    {
        path: "/admin/support",
        name: "Support",
        component: Support,
    },
    {
        path: "/admin/support/create",
        name: "SupportCreate",
        component: SupportCreate,
    },
    {
        path: "/admin/support/edit/:id",
        name: "SupportCreate",
        component: SupportCreate,
    },
    {
        path: "/admin/wizard",
        name: "WizardSetting",
        component: WizardSetting,
    },
    {
        path: "/admin/wizard/edit/:id",
        name: "WizardEdit",
        component: WizardEdit,
    },
    {
        path: "/admin/change-password",
        name: "ChangePassword",
        component: ChangePassword,
    },
    {
        path: "/admin/cancel-subscription-request",
        name: "CancelSubscriptionRequest",
        component: CancelSubscriptionRequest,
    },
    {
        path: "/frontrunner_reviews/:id/:sellId",
        name: "FrontrunnerRequestReview",
        component: FrontrunnerRequestReview,
    },
    {
        path: "/privacy_policy",
        name: "PrivacyPolicy",
        component: PrivacyPolicy,
    },
    { path: "*", component: PageNotFound }
];
const router = new VueRouter({
    mode: "history",
    routes,
});

export default router;
