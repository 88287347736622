import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
// import common from "../Common/index";
import common from "../Common/config";
import VueSession from "vue-session";

Vue.use(Vuex);

Vue.use(VueSession);
const store = new Vuex.Store({
    state: {
        loginUser: {},
        UserData: {},
    },
    getters: {
        loginUserGetter: (state) => state.loginUser,
        parentBucketFormGetter: (state) => state.parentBucketForm,
    },
    //  mutations: {
    //   loginUserReceived(state, loginUser) {
    //     state.loginUser = loginUser
    //   },
    //   registerUserReceived(state, registerUser) {
    //     state.registerUser = registerUser
    //   }
    // },
    actions: {
        login: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "login",
                        { email: data.userName, password: data.password },
                        {
                            headers: {
                                Accept: "application/json",
                            },
                        }
                    )
                    .then(function (response) {
                        localStorage.setItem("token", response.data.data.token);
                        localStorage.setItem("frontRunner", response.data.data.frontRunner);
                        localStorage.setItem("adminId", response.data.data.parentId);
                        localStorage.setItem("isAdmin", response.data.data.isAdmin);
                        localStorage.setItem("email", response.data.data.email);
                        localStorage.setItem("id", response.data.data.id);
                        localStorage.setItem("loginFirstTime", response.data.data.loginFirstTime);
                        localStorage.setItem("wizard", response.data.data.loginFirstTime);
                        localStorage.setItem("isSetupWizardPlay", 'false');

                        //   this.$session.start();
                        //   this.$session.set("token", response.data.data.token);
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response);
                    });
            });
        },
        register: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "validate-register",
                        {
                            first_name: data.first_name,
                            last_name: data.last_name,
                            organisation_name: data.organisation_name,
                            email: data.email,
                            phone: data.phone,
                            billing_address: data.billing_address,
                            city: data.city,
                            state: data.state,
                            zip_code: data.zip_code,
                            password: data.password,
                            c_password: data.password,
                        },
                        {
                            headers: {
                                Accept: "application/json",
                            },
                        }
                    )
                    .then(function (response) {
                        localStorage.setItem("token", response.data.data.token);
                        resolve(response.data);
                    })
                    .catch((err) => {
                        console.log(err);
                        resolve(err.response);
                    });
            });
        },

        // register: (context, data) => {
        //     return new Promise((resolve) => {
        //         axios
        //             .post(
        //                 common.api + "register",
        //                 {
        //                     first_name: data.first_name,
        //                     last_name: data.last_name,
        //                     organisation_name: data.organisation_name,
        //                     email: data.email,
        //                     phone: data.phone,
        //                     billing_address: data.billing_address,
        //                     city: data.city,
        //                     state: data.state,
        //                     zip_code: data.zip_code,
        //                     password: data.password,
        //                     c_password: data.password,
        //                     file: data.fileSelected,
        //                 },
        //                 {
        //                     headers: {
        //                         Accept: "application/json",
        //                     },
        //                 }
        //             )
        //             .then(function (response) {
        //                 localStorage.setItem("token", response.data.data.token);
        //                 console.log(response);
        //                 resolve(response.data);
        //             })
        //             .catch((err) => {
        //                 console.log(err);
        //                 resolve(err.response);
        //             });
        //     });
        // },

        subscribe: (context, data) => {
            let registerData = data.registerForm
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "user/subscribe",
                        {
                            first_name: registerData.first_name,
                            last_name: registerData.last_name,
                            organisation_name: registerData.organisation_name,
                            email: registerData.email,
                            phone: registerData.phone,
                            billing_address: registerData.billing_address,
                            city: registerData.city,
                            state: registerData.state,
                            zip_code: registerData.zip_code,
                            password: registerData.password,
                            c_password: registerData.password,
                            b_firstName: registerData.b_firstName,
                            b_lastNAme: registerData.b_lastNAme,
                            b_billingAddress: registerData.b_billingAddress,
                            b_city: registerData.b_city,
                            b_state: registerData.b_state,
                            b_zip: registerData.b_zip,
                            b_cardNumber: registerData.b_cardNumber,
                            b_expDate: registerData.b_expDate,
                            b_cvv: registerData.b_cvv,
                            stripe_coupon: registerData.stripe_coupon,
                            token: data.token,
                        },
                        {
                            headers: {
                                Accept: "application/json",
                            },
                        }
                    )
                    .then(function (response) {
                        localStorage.setItem("token", response.data.data.token);
                        localStorage.setItem(
                            "first_name",
                            response.data.data.first_name
                        );
                        localStorage.setItem(
                            "lastName",
                            response.data.data.last_name
                        );
                        localStorage.setItem("phone", response.data.data.phone);
                        localStorage.setItem("state", response.data.data.state);
                        localStorage.setItem(
                            "pincode",
                            response.data.data.zip_code
                        );
                        localStorage.setItem("city", response.data.data.city);
                        localStorage.setItem(
                            "billing_address",
                            response.data.data.billing_address
                        );
                        localStorage.setItem("email", response.data.data.email);
                        localStorage.setItem("adminId", response.data.data.parentId);
                        localStorage.setItem("isAdmin", response.data.data.isAdmin);
                        localStorage.setItem(
                            "organisation_name",
                            response.data.data.organisation_name
                        );
                        localStorage.setItem(
                            "b_firstName",
                            response.data.data.b_first_name
                        );
                        localStorage.setItem(
                            "b_lastNAme",
                            response.data.data.b_last_name
                        );
                        localStorage.setItem(
                            "b_billingAddress",
                            response.data.data.b_billing_address
                        );
                        localStorage.setItem(
                            "b_city",
                            response.data.data.b_city
                        );
                        localStorage.setItem(
                            "b_state",
                            response.data.data.b_state
                        );
                        localStorage.setItem(
                            "b_zip",
                            response.data.data.b_zip_code
                        );
                        localStorage.setItem("id", response.data.data.id);
                        localStorage.setItem("loginFirstTime", response.data.data.loginFirstTime);
                        localStorage.setItem("wizard", response.data.data.loginFirstTime);
                        localStorage.setItem("isSetupWizardPlay", 'false');
                        resolve(response.data);
                    })
                    .catch((err) => {
                        console.log(err);
                        resolve(err.response);
                    });
            });
        },
        updateCardDetails: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "user/update_card_details",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        console.log(err);
                        resolve(err.response);
                    });
            });
        },
        forgotPassword: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "password/forgot-password",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response);
                    });
            });
        },
        resetPassword: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "password/reset",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },
        billingInfo: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "admin/billing",
                        {
                            id: data.id,
                            b_first_name: data.b_firstName,
                            b_last_name: data.b_lastNAme,
                            b_billing_address: data.b_billingAddress,
                            b_city: data.b_city,
                            b_state: data.b_state,
                            b_zip_code: data.b_zip,
                        },
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response);
                    });
            });
        },
        userList: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "admin/user/list",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        console.log(err);
                        resolve(err.response);
                    });
            });
        },
        allUser: (context) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "user/alllist",
                        {},
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        console.log(err);
                        resolve(err.response);
                    });
            });
        },

        feedbackList: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "feedback/list",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        console.log(err);
                        resolve(err.response);
                    });
            });
        },

        feedbackUpdate: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "feedback/update/records",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        console.log(err);
                        resolve(err.response);
                    });
            });
        },

        deleteFeedback: (context, userId) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "feedback/delete",
                        {
                            id: userId,
                        },
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },
        deleteMultipleFeedback: (context, userId) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "feedback/deleteAll",
                        {
                            ids: userId,
                        },
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },
        viewFeedback: (context, userId) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "feedback/single",
                        {
                            id: userId,
                        },
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },
        viewReview: (context, userId) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "reviews/single",
                        {
                            id: userId,
                        },
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },

        reviewUpdate: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "reviews/update/records",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },
        statusFeedback: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "feedback/status",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err);
                    });
            });
        },

        defaultFeedbackData: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "communication/feedback/details",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err);
                    });
            });
        },

        individualFeedbackResponse: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "individualfeedback/create",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },

        individualFeedbackFiveResponse: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "individual-feedback/create/five",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },

        individualFeedbackList: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "individualfeedback/list",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },
        individualFeedbackDelete: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "individualfeedback/delete",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },
        reviewsList: (context, data) => {
            let page = data.page ? data.page : 1;
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "review/list?page=" + page,
                        // common.api + "ReviewList?page=" + data,
                        // {
                        //     userId: localStorage.getItem("id"),
                        // },
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        console.log(err);
                        resolve(err.response);
                    });
            });
        },
        deleteReview: (context, userId) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "admin/review/delete",
                        {
                            id: userId,
                        },
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },
        deleteMultipleReview: (context, userId) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "admin/review/deleteall",
                        {
                            ids: userId,
                        },
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },
        viewMultipleReview: (context, userId) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "admin/review/readall",
                        {
                            ids: userId,
                        },
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },
        createUser: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "admin/register",
                        {
                            first_name: data.firstName,
                            last_name: data.lastName,
                            email: data.email,
                            password: data.password,
                            c_password: data.confirmPassword,
                        },
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        localStorage.setItem(
                            "registUserToken",
                            response.data.data.token
                        );
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },
        updateUser: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "admin/user/update",
                        {
                            id: data.userId,
                            first_name: data.firstName,
                            last_name: data.lastName,
                            email: data.email,
                            password: data.password,
                            c_password: data.confirmPassword,
                        },
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        localStorage.setItem(
                            "registUserToken",
                            response.data.data.token
                        );
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },
        deleteUser: (context, userId) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "admin/user/delete",
                        {
                            id: userId,
                        },
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },
        deleteMultipleUser: (context, userId) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "admin/user/deleteAll",
                        {
                            ids: userId,
                        },
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },
        updateProfile: (context, data) => {
            let formData = new FormData();
            // formData.append('id', data.adminId);
            formData.append('id', data.id);
            formData.append('first_name', data.firstName);
            formData.append('last_name', data.lastName);
            formData.append('organisation_name', data.organisation_name);
            formData.append('phone', data.phone);
            formData.append('billing_address', data.billing_address);
            formData.append('city', data.city);
            formData.append('state', data.state);
            formData.append('zip_code', data.pincode);
            if (data.fileSelected !== null) {
                formData.append("file", data.fileSelected);
            }
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "update/profile",
                        formData,
                        {
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "multipart/form-data",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        console.log(err);
                        resolve(err.response);
                    });
            });
        },
        updateBillingInfo: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "update/profile",
                        {
                            id: data.adminId,
                            first_name: data.b_firstName,
                            last_name: data.b_lastNAme,
                            organisation_name: data.b_billingAddress,
                            phone: data.b_city,
                            billing_address: data.b_state,
                            city: data.b_zip,
                            state: data.b_cardNumber,
                            zip_code: data.b_expDate,
                            file: data.b_cvv,
                        },
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },
        userProfileSettings: async (context) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "admin/user/id",
                        {},
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        console.log(err);
                        resolve(err.response);
                    });
            });
        },
        googleConnect: (context, data, provider) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.app + "social/google",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        console.log(err);
                        resolve(err.response);
                    });
            });
        },

        platformDisconnect: (context, data) => {
            let userId = data.data;
            let platform = data.platform;
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + `${platform}/review/disconnect`,
                        userId,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        localStorage.setItem(
                            "registUserToken",
                            response.data.data.token
                        );
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },

        businessAdd: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "admin/reviews/add",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        localStorage.setItem(
                            "registUserToken",
                            response.data.data.token
                        );
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },

        createFeedback: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "feedback/create",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },

        timezoneList: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "admin/showTimeZone",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },

        saveCommunicationSetting: (context, data) => {
            let formData = new FormData();
            formData.append('user_id', data.user_id);
            formData.append('timezone', data.timezone);
            formData.append('emailSendFromName', data.emailSendFromName);
            formData.append('textSendFromName', data.textSendFromName);
            formData.append('replyEmail', data.replyEmail);
            // formData.append('textNumberAreaCode', data.textNumberAreaCode);
            formData.append('reviewRequestTextDefaultMessage', data.reviewRequestTextDefaultMessage);
            formData.append('defaultThreeStarResponseEmail', data.defaultThreeStarResponseEmail);
            formData.append('defaultThreeStarResponseText', data.defaultThreeStarResponseText);
            formData.append('reviewRequestEmailDefaultMessage', data.reviewRequestEmailDefaultMessage);
            formData.append('threeStarThankyouMessage', data.threeStarThankyouMessage);
            formData.append('FourFiveReviewMessage', data.FourFiveReviewMessage);
            formData.append('thankyouMessage', data.thankyouMessage);
            formData.append('thankyouEmail', data.thankyouEmail);
            formData.append('reviewRequestMainPageMessage', data.reviewRequestMainPageMessage);
            formData.append('reviewRequestThreeStarMessage', data.reviewRequestThreeStarMessage);
            formData.append('reviewRequestFourFiveStarMessage', data.reviewRequestFourFiveStarMessage);

            if (data.logo !== null) {
                formData.append("logo", data.logo);
            }
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "communication/store",
                        formData,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },
        updateCommunicationSetting: (context, data) => {
            let formData = new FormData();
            // formData.append('id', data.id);
            formData.append('user_id', data.user_id);
            formData.append('timezone', data.timezone);
            formData.append('emailSendFromName', data.emailSendFromName);
            formData.append('textSendFromName', data.textSendFromName);
            formData.append('replyEmail', data.replyEmail);
            // formData.append('textNumberAreaCode', data.textNumberAreaCode);
            formData.append('reviewRequestTextDefaultMessage', data.reviewRequestTextDefaultMessage);
            formData.append('defaultThreeStarResponseEmail', data.defaultThreeStarResponseEmail);
            formData.append('defaultThreeStarResponseText', data.defaultThreeStarResponseText);
            formData.append('reviewRequestEmailDefaultMessage', data.reviewRequestEmailDefaultMessage);
            formData.append('threeStarThankyouMessage', data.threeStarThankyouMessage);
            formData.append('FourFiveReviewMessage', data.FourFiveReviewMessage);
            formData.append('thankyouMessage', data.thankyouMessage);
            formData.append('thankyouEmail', data.thankyouEmail);
            formData.append('reviewRequestMainPageMessage', data.reviewRequestMainPageMessage);
            formData.append('reviewRequestThreeStarMessage', data.reviewRequestThreeStarMessage);
            formData.append('reviewRequestFourFiveStarMessage', data.reviewRequestFourFiveStarMessage);

            if (data.logo !== null) {
                formData.append("logo", data.logo);
            }

            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "communication/update",
                        formData,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },

        fetchCommunicationSetting: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "communication/show",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },

        removeCommunicationSettingLogo: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "communication/remove-logo",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },

        platformCheck: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "platform/check",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },
        singleRequest: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "requestreview/create",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },

        requestCountryCode: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "countries/list",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },
        requestReviewListing: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "requestreview/list",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },
        requestUpdate: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "requestreview/update/records",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        console.log(err);
                        resolve(err.response);
                    });
            });
        },
        deleteSingleRequst: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "requestreview/delete",
                        {
                            id: data,
                        },
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },
        deleteMultipleRequest: (context, Ids) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "requestreview/deleteall",
                        {
                            ids: Ids,
                        },
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },

        requestReviewValidate: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "request-review/validate",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "multipart/form-data",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },

        sendBuldRequst: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "requestreview/uploadFile",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "multipart/form-data",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },

        facebookConnect: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "platform/facebook",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },

        yelpReview: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "review/yelp",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err);
                    });
            });
        },
        yelpCountry: (context) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "yelp/countries   ",
                        {},
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },

        yelpCategories: (context) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "yelp/categories",
                        {},
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },

        zillowReview: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "review/zillow",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },

        reviewGraph: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "reports/graph/reviews",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },
        reviewGraphCard: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "reports/graph/reviews/yearly",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },

        reviewConversionGraph: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "reports/conversion/graph",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },

        zillowSearch: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "review/zillow/search/name",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },

        googleSync: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "google/review/fetch ",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },
        fbSync: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "facebook/review/fetch ",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },
        yelpSync: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "yelp/review/fetch",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },
        zillowSync: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "zillow/review/fetch",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },

        textList: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "individualfeedback/text/listing",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },

        mailFeebackData: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "feedback/form-logo",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },


        mailClick: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "feedback/review-click",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },

        mailFeebackAdd: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "feedback/form-create",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },

        frontrunnerConnect: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "user/frontrunner/sync",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },

        frontrunneruserId: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "request-review/UserID",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },
        frontrunnerRequestReview: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "request-review/front-runner/create",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },

        redirectUrl: (context, data) => {
            return new Promise((resolve) => {
                axios.post(
                    common.api + "platform/url",
                    data, {
                    header: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token")
                    },
                }
                ).then(function (response) {
                    resolve(response.data);
                }).catch(err => {
                    resolve(err.response.data);
                });
            })
        },

        dashboardApi: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "dashboard/recent-reviews",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },

        reviewsNewCount: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "reviews/count",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },
        feedbackNewCount: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "feedback/count",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },
        feedbackReadCount: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "feedback/count/update",
                        { id: data },
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },
        reviewReadCount: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "reviews/count/update",
                        { id: data },
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },
        connectedHistory: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "platform/connected/history",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },
        platformHistoryDelete: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "platform/delete/history",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },

        wizardFlag: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "update/login/flag",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },

        csvFile: (context) => {
            return common.csvFile;
        },

        landingHome: (context) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "home",
                        {},
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },
        landingHomeUpdate: (context, data) => {
            let formData = new FormData();
            formData.append('sectonID', data.sectonID);
            formData.append('heading', data.heading);
            formData.append('description', data.description);
            formData.append('video_url', data.video_url);
            if (data.images !== null) {
                formData.append('images', data.images);
            }
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "update/home",
                        formData,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("adminToken"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },

        adminLogin: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "admin/login",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },

        adminPassword: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "admin/change-password",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("adminToken"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },

        clientList: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "admin/user-list",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("adminToken"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },
        clientDelete: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "admin/delete/user",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("adminToken"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },
        clientEdit: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "admin/id",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("adminToken"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },
        clientAdd: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "admin/user/create",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("adminToken"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },
        clientUpdate: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "admin/update",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("adminToken"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },
        clientLogin: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "user/switch/start",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("adminToken"),
                            },
                        }
                    )
                    .then(function (response) {
                        if (response.data.success) {
                            localStorage.clear();
                        }
                        if (response.data.data.switchAdmin) {
                            localStorage.setItem("token", response.data.data.token);
                            localStorage.setItem("frontRunner", response.data.data.frontRunner);
                            localStorage.setItem("adminId", response.data.data.parentId);
                            localStorage.setItem("isAdmin", response.data.data.isAdmin);
                            localStorage.setItem("id", response.data.data.id);
                            localStorage.setItem("change", response.data.data.switchAdmin);
                            localStorage.setItem("email", response.data.data.email);
                        }
                        localStorage.setItem("adminID", response.data.data.id);
                        localStorage.setItem("adminToken", response.data.data.token);
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },

        faqList: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "faq/list",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("adminToken"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },
        faqDelete: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "faq/delete",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("adminToken"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },
        faqCreate: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "faq/create",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("adminToken"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },
        faqEdit: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "faq/detail/list",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("adminToken"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },
        faqUpdate: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "faq/update",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("adminToken"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },

        supportList: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "support/list",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("adminToken"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },
        supportDelete: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "support/delete",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("adminToken"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },
        supportCreate: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "support/create",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("adminToken"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },
        supportEdit: (context, data) => {
            let token = localStorage.getItem("token") ? localStorage.getItem("token") : localStorage.getItem("adminToken") ? localStorage.getItem("adminToken") : ""
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "support/detail/list",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    `Bearer ${token}`,
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },
        supportUpdate: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "support/update",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("adminToken"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },

        wizardList: (context) => {
            let token = localStorage.getItem("token") ? localStorage.getItem("token") : localStorage.getItem("adminToken") ? localStorage.getItem("adminToken") : ""
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "setup-wizard/list",
                        {},
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    `Bearer ${token}`,
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },


        wizardEdit: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "setup-wizard/detail/list",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("adminToken"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },


        wizardUpdate: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "setup-wizard/update",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("adminToken"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },

        requestCancelSubscription: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "subscription/request_cancel",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("token"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },
        requestCancelSubscriptionList: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "admin/request_subscription_cancel/list",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("adminToken"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },
        requestCancelSubscriptionDelete: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "admin/request_subscription_cancel/delete",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer " + localStorage.getItem("adminToken"),
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },

        sendDemoVideoRequest: (context, data) => {
            return new Promise((resolve) => {
                axios
                    .post(
                        common.api + "demo-video",
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                            },
                        }
                    )
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        resolve(err.response.data);
                    });
            });
        },
    },
});
export { store };
