<template>
    <!-- Vertical Navbar -->
    <div>
        <div v-if="apiLoading" class="loading">
            Loading&#8230;
        </div>
        <div class="d-flex flex-column flex-lg-row h-lg-full navOutDiv">
            <!-- Vertical Navbar -->

            <Sidebar></Sidebar>
            <!-- Main content -->
            <div class="h-screen flex-grow-1 overflow-y-lg-auto m-4 dashScroll">
                <main class="py-6">
                    <div class="row align-items-center">
                        <div class="col-sm-6 col-12 mb-4 mb-sm-0">
                            <div class="card-header" style="background: none; border: none">
                                <h2 class="mb-0">Homepage</h2>
                            </div>
                        </div>
                    </div>
                    <header class="bg-surface-primary border-bottom pt-6 tabs-outer" style="margin-left: 50px">
                        <div>
                            <div class="mb-npx">
                                <div class="row align-items-center tab-wrap">

                                    <div class="col-md-1 col-4 mb-4 mb-sm-0">
                                        <a href="#" @click="showProfiles('Section1')"
                                            :class="{ active: showProfile == 'Section1' }" class="profileSettingSwitch">
                                            <span :class="{'account-active': showProfile == 'Section1',}">
                                                Section 1
                                            </span>
                                        </a>
                                    </div>
                                    <div class="col-md-1 col-4 mb-4 mb-sm-0">
                                        <a href="#" @click="showProfiles('Section2')"
                                            :class="{ active: showProfile == 'Section2' }" class="profileSettingSwitch">
                                            <span :class="{ 'account-active': showProfile == 'Section2' }">
                                                Section 2
                                            </span></a>
                                    </div>
                                    <div class="col-md-1 col-4 mb-4 mb-sm-0">
                                        <a href="#" @click="showProfiles('Section3')"
                                            :class="{ active: showProfile == 'Section3' }" class="profileSettingSwitch">
                                            <span :class="{ 'account-active': showProfile == 'Section3' }">
                                                Section 3
                                            </span></a>
                                    </div>
                                    <div class="col-md-1 col-4 mb-4 mb-sm-0">
                                        <a href="#" @click="showProfiles('Section4')"
                                            :class="{ active: showProfile == 'Section4' }" class="profileSettingSwitch">
                                            <span :class="{ 'account-active': showProfile == 'Section4' }">
                                                Section 4
                                            </span></a>
                                    </div>
                                    <div class="col-md-1 col-4 mb-4 mb-sm-0">
                                        <a href="#" @click="showProfiles('Section5')"
                                            :class="{ active: showProfile == 'Section5' }" class="profileSettingSwitch">
                                            <span :class="{ 'account-active': showProfile == 'Section5' }">
                                                Section 5
                                            </span></a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </header>
                    <div class="col-sm-11 col-12 mb-4 mb-sm-0 basic-info">
                        <div style="margin-top: 20px">
                            <!-- Section 1 Start -->

                            <div class="com-set-wrap"
                                style=" background-color: white; margin: 0px 27px; border-radius: 15px;"
                                v-if="showProfile == 'Section1'">

                                <header class="bg-surface-primary border-bottom pt-6 profile-text-wrap"
                                    style="margin: 15px; padding-bottom: 50px">
                                    <div class="row">
                                        <div class="col-md-12 mb-4 pb-2">
                                            <div class="form-outline form-white">
                                                <p class="communicationFormLabel">
                                                    Section 1 Heading
                                                </p>
                                                <vue-editor v-model="section1.heading" :editorToolbar="customToolbar"
                                                    class="ql-editor">
                                                </vue-editor>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12 mb-4 pb-2">
                                            <div class="form-outline form-white">
                                                <p class="communicationFormLabel">
                                                    Section 1 Text
                                                </p>
                                                <vue-editor v-model="section1.text" :editorToolbar="customToolbar"
                                                    class="ql-editor">
                                                </vue-editor>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12 mb-4 pb-2">
                                            <div class="form-outline form-white">
                                                <p class="communicationFormLabel">
                                                    Section 1 Video URL
                                                </p>
                                                <input type="text" v-model="section1.video_url"
                                                    class="form-control userForm form-control-lg inputOrange"
                                                    placeholder="Ex: https://www.youtube.com/embed/RWIKc7BKjcs" />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12 mb-4 pb-2">
                                            <div class="form-outline form-white">
                                                <p class="communicationFormLabel">
                                                    Section 1 Video Image
                                                </p>
                                                <input type="file" id="fileElem1" accept="image/*"
                                                    @change="onSectionChange" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" v-if="section1.image">
                                        <div class="col-md-12 mb-4 pb-2">
                                            <div class="form-outline form-white">
                                                <p class="communicationFormLabel">
                                                    <img :src="section1.image" alt="">
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" v-if="section1.uploadedImg">
                                        <div class="col-md-12 mb-4 pb-2">
                                            <div class="form-outline form-white">
                                                <p class="communicationFormLabel">
                                                    <img :src="section1.uploadedImg" alt="" srcset="" width="500"
                                                        height="100">
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 mb-4 pb-2">
                                            <div class="form-outline form-white d-flex flex-row-reverse">
                                                <button class="platFormCardButton" style="width: 150px"
                                                    @click="saveSection(section1.sectionId,section1.heading,section1.text,section1.image,section1.video_url)">
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </header>
                            </div>
                            <!-- Section 1 End -->

                            <!-- Section 2 Start -->
                            <div class="com-set-wrap"
                                style=" background-color: white; margin: 0px 27px; border-radius: 15px;"
                                v-if="showProfile == 'Section2'">

                                <header class="bg-surface-primary border-bottom pt-6 profile-text-wrap"
                                    style="margin: 15px; padding-bottom: 50px">
                                    <div class="row">
                                        <div class="col-md-12 mb-4 pb-2">
                                            <div class="form-outline form-white">
                                                <p class="communicationFormLabel">
                                                    Section 2 Heading
                                                </p>
                                                <vue-editor v-model="section2.heading" :editorToolbar="customToolbar"
                                                    class="ql-editor">
                                                </vue-editor>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 mb-4 pb-2">
                                            <div class="form-outline form-white">
                                                <p class="communicationFormLabel">
                                                    Section 2 Text
                                                </p>
                                                <vue-editor v-model="section2.text" :editorToolbar="customToolbar"
                                                    class="ql-editor">
                                                </vue-editor>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 mb-4 pb-2">
                                            <div class="form-outline form-white">
                                                <p class="communicationFormLabel">
                                                    Section 1 Video URL
                                                </p>
                                                <input type="text" v-model="section2.video_url"
                                                    class="form-control userForm form-control-lg inputOrange"
                                                    placeholder="Ex: https://www.youtube.com/embed/RWIKc7BKjcs" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 mb-4 pb-2">
                                            <div class="form-outline form-white">
                                                <p class="communicationFormLabel">
                                                    Section 2 Video Image
                                                </p>
                                                <input type="file" id="fileElem2" accept="image/*"
                                                    @change="onSectionChange" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" v-if="section2.image">
                                        <div class="col-md-12 mb-4 pb-2">
                                            <div class="form-outline form-white">
                                                <p class="communicationFormLabel">
                                                    <img :src="section2.image" alt="">
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" v-if="section2.uploadedImg">
                                        <div class="col-md-12 mb-4 pb-2">
                                            <div class="form-outline form-white">
                                                <p class="communicationFormLabel">
                                                    <img :src="section2.uploadedImg" alt="" srcset="" width="500"
                                                        height="100">
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 mb-4 pb-2">
                                            <div class="form-outline form-white d-flex flex-row-reverse">
                                                <button class="platFormCardButton" style="width: 150px"
                                                    @click="saveSection(section2.sectionId,section2.heading,section2.text,section2.image,section2.video_url)">
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </header>
                            </div>
                            <!-- Section 2 End -->

                            <!-- Section 3 Start -->
                            <div class="com-set-wrap"
                                style=" background-color: white; margin: 0px 27px; border-radius: 15px;"
                                v-if="showProfile == 'Section3'">

                                <header class="bg-surface-primary border-bottom pt-6 profile-text-wrap"
                                    style="margin: 15px; padding-bottom: 50px">
                                    <div class="row">
                                        <div class="col-md-12 mb-4 pb-2">
                                            <div class="form-outline form-white">
                                                <p class="communicationFormLabel">
                                                    Section 3 Heading
                                                </p>
                                                <vue-editor v-model="section3.heading" :editorToolbar="customToolbar"
                                                    class="ql-editor">
                                                </vue-editor>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 mb-4 pb-2">
                                            <div class="form-outline form-white">
                                                <p class="communicationFormLabel">
                                                    Section 3 Text
                                                </p>
                                                <vue-editor v-model="section3.text" :editorToolbar="customToolbar"
                                                    class="ql-editor">
                                                </vue-editor>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12 mb-4 pb-2">
                                            <div class="form-outline form-white">
                                                <p class="communicationFormLabel">
                                                    Section 1 Video URL
                                                </p>
                                                <input type="text" v-model="section3.video_url"
                                                    class="form-control userForm form-control-lg inputOrange"
                                                    placeholder="Ex: https://www.youtube.com/embed/RWIKc7BKjcs" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 mb-4 pb-2">
                                            <div class="form-outline form-white">
                                                <p class="communicationFormLabel">
                                                    Section 3 Video Image
                                                </p>
                                                <input type="file" id="fileElem3" accept="image/*"
                                                    @change="onSectionChange" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" v-if="section3.image">
                                        <div class="col-md-12 mb-4 pb-2">
                                            <div class="form-outline form-white">
                                                <p class="communicationFormLabel">
                                                    <img :src="section3.image" alt="">
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" v-if="section3.uploadedImg">
                                        <div class="col-md-12 mb-4 pb-2">
                                            <div class="form-outline form-white">
                                                <p class="communicationFormLabel">
                                                    <img :src="section3.uploadedImg" alt="" srcset="" width="500"
                                                        height="100">
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 mb-4 pb-2">
                                            <div class="form-outline form-white d-flex flex-row-reverse">
                                                <button class="platFormCardButton" style="width: 150px"
                                                    @click="saveSection(section3.sectionId,section3.heading,section3.text,section3.image,section3.video_url)">
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </header>
                            </div>
                            <!-- Section 3 End -->

                            <!-- Section 4 Start -->
                            <div class="com-set-wrap"
                                style=" background-color: white; margin: 0px 27px; border-radius: 15px;"
                                v-if="showProfile == 'Section4'">

                                <header class="bg-surface-primary border-bottom pt-6 profile-text-wrap"
                                    style="margin: 15px; padding-bottom: 50px">
                                    <div class="row">
                                        <div class="col-md-12 mb-4 pb-2">
                                            <div class="form-outline form-white">
                                                <p class="communicationFormLabel">
                                                    Section 4 Heading
                                                </p>
                                                <vue-editor v-model="section4.heading" :editorToolbar="customToolbar"
                                                    class="ql-editor">
                                                </vue-editor>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 mb-4 pb-2">
                                            <div class="form-outline form-white">
                                                <p class="communicationFormLabel">
                                                    Section 4 Text
                                                </p>
                                                <vue-editor v-model="section4.text" :editorToolbar="customToolbar"
                                                    class="ql-editor">
                                                </vue-editor>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12 mb-4 pb-2">
                                            <div class="form-outline form-white">
                                                <p class="communicationFormLabel">
                                                    Section 1 Video URL
                                                </p>
                                                <input type="text" v-model="section4.video_url"
                                                    class="form-control userForm form-control-lg inputOrange"
                                                    placeholder="Ex: https://www.youtube.com/embed/RWIKc7BKjcs" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 mb-4 pb-2">
                                            <div class="form-outline form-white">
                                                <p class="communicationFormLabel">
                                                    Section 4 Video Image
                                                </p>
                                                <input type="file" id="fileElem4" accept="image/*"
                                                    @change="onSectionChange" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" v-if="section4.image">
                                        <div class="col-md-12 mb-4 pb-2">
                                            <div class="form-outline form-white">
                                                <p class="communicationFormLabel">
                                                    <img :src="section4.image" alt="">
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" v-if="section4.uploadedImg">
                                        <div class="col-md-12 mb-4 pb-2">
                                            <div class="form-outline form-white">
                                                <p class="communicationFormLabel">
                                                    <img :src="section4.uploadedImg" alt="" srcset="" width="500"
                                                        height="100">
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 mb-4 pb-2">
                                            <div class="form-outline form-white d-flex flex-row-reverse">
                                                <button class="platFormCardButton" style="width: 150px"
                                                    @click="saveSection(section4.sectionId,section4.heading,section4.text,section4.image,section4.video_url)">
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </header>
                            </div>
                            <!-- Section 4 End -->

                            <!-- Section 5 Start -->
                            <div class="com-set-wrap"
                                style=" background-color: white; margin: 0px 27px; border-radius: 15px;"
                                v-if="showProfile == 'Section5'">

                                <header class="bg-surface-primary border-bottom pt-6 profile-text-wrap"
                                    style="margin: 15px; padding-bottom: 50px">
                                    <div class="row">
                                        <div class="col-md-12 mb-4 pb-2">
                                            <div class="form-outline form-white">
                                                <p class="communicationFormLabel">
                                                    Signup Form Heading
                                                </p>
                                                <input type="text" v-model="section5.heading" class="form-control userForm form-control-lg inputOrange" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 mb-4 pb-2">
                                            <div class="form-outline form-white">
                                                <p class="communicationFormLabel">
                                                    Signup Form Text
                                                </p>
                                                <vue-editor v-model="section5.text" :editorToolbar="customToolbar"
                                                    class="ql-editor">
                                                </vue-editor>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12 mb-4 pb-2">
                                            <div class="form-outline form-white d-flex flex-row-reverse">
                                                <button class="platFormCardButton" style="width: 150px"
                                                    @click="saveSection(section5.sectionId,section5.heading,section5.text,section5.image,section5.video_url)">
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </header>
                            </div>
                            <!-- Section 5 End -->
                        </div>
                    </div>

                </main>
            </div>
        </div>
    </div>
</template>

<script>
import Sidebar from './Sidebar'
export default {
    name: "Homepage",
    components: {
        Sidebar
    },
    data() {
        return {
            show: "homepage",
            showProfile: "Section1",
            section1: {
                sectionId: 1,
                heading: "",
                text: "",
                image: "",
                uploadedImg: "",
                video_url: ""
            },
            section2: {
                sectionId: 2,
                heading: "",
                text: "",
                image: "",
                uploadedImg: "",
                video_url: ""
            },
            section3: {
                sectionId: 3,
                heading: "",
                text: "",
                image: "",
                uploadedImg: "",
                video_url: ""
            },
            section4: {
                sectionId: 4,
                heading: "",
                text: "",
                image: "",
                uploadedImg: "",
                video_url: ""
            },
            section5: {
                sectionId: 5,
                heading: "",
                text: "",
                image: ""
            },
            apiLoading: false,
            customToolbar: [
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ["bold", "italic", "underline"],
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" },
                ],
                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
            ],
        };
    },

    mounted() {
        this.landingData();
        console.clear();
    },
    created() { },
    computed: {},
    methods: {
        showProfiles(data) {
            this.landingData();
            this.showProfile = data;
        },

        onSectionChange(e) {
            this.createFile(e.target.files[0]);
        },
        createFile(file) {
            this.showProfile == 'Section1' ? (this.section1.image = file) : ""
            this.showProfile == 'Section2' ? (this.section2.image = file) : ""
            this.showProfile == 'Section3' ? (this.section3.image = file) : ""
            this.showProfile == 'Section4' ? (this.section4.image = file) : ""
            this.showProfile == 'Section5' ? (this.section5.image = file) : ""

            var reader = new FileReader();
            var vm = this;

            reader.onload = function (e) {
                vm.showProfile == 'Section1' ? (vm.section1.uploadedImg = e.target.result) : ""
                vm.showProfile == 'Section2' ? (vm.section2.uploadedImg = e.target.result) : ""
                vm.showProfile == 'Section3' ? (vm.section3.uploadedImg = e.target.result) : ""
                vm.showProfile == 'Section4' ? (vm.section4.uploadedImg = e.target.result) : ""
                vm.showProfile == 'Section5' ? (vm.section5.uploadedImg = e.target.result) : ""
            }
            reader.readAsDataURL(file);
        },

        landingData() {
            this.$store.dispatch("landingHome").then((response) => {
                if (response.success) {
                    let sec1 = response.data[0];
                    let sec2 = response.data[1];
                    let sec3 = response.data[2];
                    let sec4 = response.data[3];
                    let sec5 = response.data[4];

                    this.section1.sectionId = sec1.sectonID;
                    this.section1.heading = sec1.heading;
                    this.section1.text = sec1.description;
                    this.section1.uploadedImg = sec1.image_url;
                    this.section1.video_url = sec1.video_url;

                    this.section2.sectionId = sec2.sectonID;
                    this.section2.heading = sec2.heading;
                    this.section2.text = sec2.description;
                    this.section2.uploadedImg = sec2.image_url;
                    this.section2.video_url = sec2.video_url;

                    this.section3.sectionId = sec3.sectonID;
                    this.section3.heading = sec3.heading;
                    this.section3.text = sec3.description;
                    this.section3.uploadedImg = sec3.image_url;
                    this.section3.video_url = sec3.video_url;

                    this.section4.sectionId = sec4.sectonID;
                    this.section4.heading = sec4.heading;
                    this.section4.text = sec4.description;
                    this.section4.uploadedImg = sec4.image_url;
                    this.section4.video_url = sec4.video_url;

                    this.section5.sectionId = sec5.sectonID;
                    this.section5.heading = sec5.heading;
                    this.section5.text = sec5.description;
                }
            });
        },
        saveSection(sectionId, heading, description, image,video_url) {
            this.apiLoading = true;
            let data = {
                sectonID: sectionId,
                heading: heading,
                description: description,
                images: image,
                video_url: video_url
            }
            this.$store
                .dispatch("landingHomeUpdate", data)
                .then((response) => {
                    if (response.success) {
                        this.$toastr.s(`${response.message}`);
                    } else {
                        this.$toastr.e(`${response.message}`);
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => (this.apiLoading = false));
        },
    },
};
</script>

<style scoped>
.row {
    margin-right: 0px;
}

/* vue2-editior style start */
.form-white>div {
    width: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    background: #f4f5fa;
    border-radius: 15px;
    font-weight: 500;
    font-size: 20px;
    color: #9194b3;
}

::v-deep .ql-toolbar.ql-snow {
    border: 0;
    background: rgba(145, 148, 179, 0.1);
    border-top: 1px solid rgba(145, 148, 179, 0.2);
}

::v-deep .ql-container.ql-snow {
    border: 0;
    height: 150px;
    overflow: scroll;
    color: black !important;
}

::v-deep .quillWrapper.ql-editor {
    display: flex;
    display: -ms-flexbox;
    flex-direction: column;
    /* flex-direction: column-reverse; */
}

::v-deep .ql-toolbar.ql-snow .ql-formats {
    margin-right: 0;
    color: #9194b3 !important;
}

::v-deep .ql-toolbar.ql-snow span.ql-formats svg {
    width: 16px;
    height: 16px;
}

::v-deep .ql-toolbar.ql-snow span.ql-formats svg line,
.ql-toolbar.ql-snow span.ql-formats svg path {
    stroke: #9194b3;
}

::v-deep .ql-snow .ql-picker {
    color: #9194b3;
}

/* vue2-editior style ends */
.profileSettingSwitch {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 1.3;
    display: block;
    text-align: center;
    color: #9194b3;
    text-decoration-line: none;
    padding-bottom: 15px;
}

.profileSettingSwitch.active {
    color: #ffab00;
    border-bottom: 2px solid #ffab00;
    display: block;
}

.platFormCardButton {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    /* or 181% */
    background: #00053c;
    color: #ffffff;
    width: 180px;
    height: 51px;
    border-radius: 15px;
}

.communicationFormLabel {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 29px;
    /* or 207% */

    display: flex;
    align-items: center;
    letter-spacing: 1px;

    color: #9194b3;
}

.btn {
    border: none;
    padding: 10px;
    /* padding: 10px 20px; */
    border-radius: 50px;
    background-color: #f4f5fa;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 200ms ease-in;
}


.navOutDiv {
    position: fixed;
    width: 100%;
}

.dashScroll {
    margin-right: 0 !important;
    padding-right: 15px;
}

.basic-info {
    margin: 20px;
    border-radius: 20px;
    background: rgb(244, 245, 250);
    padding: 20px;
}

.card-header h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600 !important;
    font-size: 32px;
    color: #00053c;
}

@media (min-width: 1400px) {}

@media (max-width: 1599.98px) {}

@media (max-width: 1299.98px) {}

@media (max-width: 1199.98px) {
    .com-set-wrap {
        margin: 0 !important;
    }
}

@media (max-width: 991.98px) {

    .navOutDiv {
        position: unset;
    }

}

@media (max-width: 767.98px) {
    .card-header h2 {
        font-size: 26px;
    }

    .tab-wrap {
        flex-wrap: wrap;
        width: 100% !important;
    }

    .tab-wrap>.col-12 {
        width: 50%;
    }

    .tabs-outer {
        margin: 0 !important;
    }

    .basic-info {
        margin: 10px 0 0 0;
    }

    .profile-text-wrap {
        padding: 0 !important;
        margin-top: 60px;
    }

    .com-set-wrap {
        margin: 0 !important;
    }

}

/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    .form-white.inner-white {
        margin-left: 0 !important;
    }

    .form-outline.form-white.inner-white {
        margin-left: 10px !important;
    }
}

#editorid .ql-editor {
    width: 100%;
    height: 150px;
    background: #f4f5fa;
    border-radius: 15px;
}
</style>
