<template>
    <div>
        <div class="d-flex flex-column flex-lg-row h-lg-full navOutDiv">
            <!-- Vertical Navbar -->

            <Menu></Menu>
            <!-- Main content -->
            <div class="h-screen flex-grow-1 overflow-y-lg-auto m-4 dashScroll">
                <!-- Header -->
                <Header></Header>

                <!-- Main -->
                <div>
                    <main class="py-6">
                        <div>
                        <div class="row align-items-center heading-wrap mb-5">
                            <div class="col-6 mb-4 mb-sm-0 pl-4">
                            <div class="card-header" style="background: none; border: none">
                                <h2 class="mb-0">Reports</h2>
                            </div>
                            </div>
                            <div class="
                                col-6
                                mb-4 mb-sm-0
                                d-flex
                                justify-content-end
                                action-wrap
                                align-items-center
                                pr-4
                            ">
                            <div v-if="report_type != 'conversion'">
                                <div class="action-btns">
                                <div class="dropdown">
                                    <select class="
                                        report-type
                                        inputOrange
                                        userForm
                                        form-control-lg
                                        report-change
                                    " @change="onPlatformChange($event)" v-model="platform">
                                    <option class="report-type" value="">All</option>
                                    <option class="report-type" value="facebook">Facebook</option>
                                    <option class="report-type" value="google">Google</option>
                                    <option class="report-type" value="zillow">Zillow</option>
                                    <option class="report-type" value="yelp">Yelp</option>
                                    </select>
                                </div>
                                </div>
                                <div class="filter"></div>
                            </div>
                            <div>
                                <select class="
                                    report-type
                                    inputOrange
                                    userForm
                                    form-control-lg
                                    report-change
                                " v-model="report_type" @change="onChange($event)">
                                <option class="report-type" value="">Report type</option>
                                <option class="report-type" value="reviews">Reviews</option>
                                <option class="report-type" value="conversion">Conversion</option>
                                </select>
                            </div>
                            <div v-if="report_type != 'conversion'">
                                <div class="action-btns">
                                <div class="dropdown">
                                    <button class="btn drop-btn dropdown-toggle" type="button" id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    Date Range
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li class="drop-btn2">
                                        <a class="dropdown-item" href="#" @click="showPicker('picker')">Range</a>
                                    </li>
                                    </ul>
                                </div>
                                </div>
                                <div class="filter"></div>
                            </div>
                            <div>
                                <date-range-picker v-model="cust_range" :options="cust_range_options" :autoApply="autoApply" class="ml-2"
                                @click="selectFilter(cust_range)" :ranges="false" opens="left" v-if="datePicker" />
                            </div>
                            </div>
                        </div>
                        <div v-if="report_type == 'reviews'">
                            <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="true" :preview-modal="false"
                            :paginate-elements-by-height="1400" filename="review-report" :pdf-quality="2" :manual-pagination="false"
                            pdf-format="a4" pdf-orientation="portrait" pdf-content-width="800px" @progress="onProgress($event)"
                            ref="html2Pdf">
                            <section slot="pdf-content">
                                <div class="row">
                                <div class="col-md-2 mb-4 reportStatistics">
                                    <h2 class="cardTitle">Reviews</h2>
                                    <div class="inner-head-wrap">
                                    <h1 class="data-h1">
                                        {{ cardReview }}
                                    </h1>

                                    <!-- <p v-if="percentageGrowth > 0" class="data-per data-plus">
                                        {{ `+ ${percentageGrowth}%` }}&nbsp;
                                        <img src="/resources/assets/arrow-up.svg" alt="" />
                                    </p>
                                    <p v-else class="data-per data-minus">
                                        {{ `${percentageGrowth}%` }}&nbsp;
                                        <img src="/resources/assets/arrow-down.svg" alt="" />
                                    </p> -->
                                    </div>
                                    <p class="date-color">{{ `Started: ${cardDate}` }}</p>
                                </div>
                                <div class="col-md-2 mb-4 reportStatistics">
                                    <h2 class="cardTitle">Average Ratings</h2>
                                    <div class="inner-head-wrap">
                                    <h1 class="data-h1">
                                        {{ cardAverageRating }}
                                    </h1>

                                    <!-- <p v-if="averageRatingGrowth > 0" class="data-per data-plus">
                                        {{ `+ ${averageRatingGrowth}%` }}&nbsp;
                                        <img src="/resources/assets/arrow-up.svg" alt="" />
                                    </p>
                                    <p v-else class="data-per data-minus">
                                        {{ `${averageRatingGrowth}%` }}&nbsp;
                                        <img src="/resources/assets/arrow-down.svg" alt="" />
                                    </p> -->
                                    </div>
                                </div>
                                </div>
                                <div class="row report-chart-wrap">
                                <div class="col-12 mb-4 pb-2 reportGraph">
                                    <div class="action-btns d-flex justify-content-end">
                                    <div class="dropdown dropdownMargin">
                                        <select class="
                                            yearSelectDropdown
                                            inputOrange
                                            yearSelect
                                            form-select
                                        " v-model="yearFilter" @change="onChangeYear($event)">
                                        <option class="yearSelectDropdown" :value="year" v-for="year in years" :key="year">
                                            {{ year }}
                                        </option>
                                        </select>
                                    </div>
                                    </div>
                                    <div id="chart">
                                    <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
                                    </div>
                                </div>
                                </div>
                            </section>
                            </vue-html2pdf>
                            <div class="table-align" style="background: #f4f5fa; border-radius: 15px; padding: 15px">
                            <div class="row">
                                <div class="col-md-2 mb-4 reportStatistics">
                                <h2 class="cardTitle">Reviews</h2>
                                <div class="inner-head-wrap">
                                    <h1 class="data-h1">
                                    {{ cardReview }}
                                    </h1>

                                    <!-- <p v-if="percentageGrowth > 0" class="data-per data-plus">
                                    {{ `+ ${percentageGrowth}%` }}&nbsp;
                                    <img src="/resources/assets/arrow-up.svg" alt="" />
                                    </p>
                                    <p v-else class="data-per data-minus">
                                    {{ `${percentageGrowth}%` }}&nbsp;
                                    <img src="/resources/assets/arrow-down.svg" alt="" />
                                    </p> -->
                                </div>
                                <p class="date-color">{{ `Started: ${cardDate}` }}</p>
                                </div>
                                <div class="col-md-2 mb-4 reportStatistics">
                                <h2 class="cardTitle">Average Ratings</h2>
                                <div class="inner-head-wrap">
                                    <h1 class="data-h1">
                                    {{ cardAverageRating }}
                                    </h1>

                                    <!-- <p v-if="averageRatingGrowth > 0" class="data-per data-plus">
                                    {{ `+ ${averageRatingGrowth}%` }}&nbsp;
                                    <img src="/resources/assets/arrow-up.svg" alt="" />
                                    </p>
                                    <p v-else class="data-per data-minus">
                                    {{ `${averageRatingGrowth}%` }}&nbsp;
                                    <img src="/resources/assets/arrow-down.svg" alt="" />
                                    </p> -->
                                </div>
                                </div>
                            </div>
                            <div class="row report-chart-wrap">
                                <div class="col-12 mb-4 pb-2 reportGraph">
                                <div class="action-btns d-flex justify-content-end">
                                    <div class="dropdown dropdownMargin">
                                    <select class="
                                        yearSelectDropdown
                                        inputOrange
                                        yearSelect
                                        form-select
                                        " v-model="yearFilter" @change="onChangeYear($event)">
                                        <option class="yearSelectDropdown" :value="year" v-for="year in years" :key="year">
                                        {{ year }}
                                        </option>
                                    </select>
                                    </div>
                                </div>
                                <div id="chart">
                                    <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
                                </div>
                                </div>
                            </div>
                            </div>

                            <div class="col-12 mt-4 mb-4 mb-sm-0 d-flex center-item file-wrap">
                            <div>
                                <div class="action-btns">
                                <div class="dropdown">
                                    <button class="btn export-ui" type="button" @click="generatePDF()">
                                    <img src="resources/assets/export-pdf.svg" />&nbsp; Export to
                                    PDF &nbsp;
                                    </button>
                                </div>
                                </div>
                            </div>
                            <div>
                                <div class="action-btns">
                                <div class="dropdown">
                                    <button class="btn export-ui" type="button" @click="downloadFile()">
                                    <img src="resources/assets/export-excel.svg" />&nbsp; Export
                                    to Excel &nbsp;
                                    </button>
                                </div>
                                </div>
                            </div>
                            <div>
                                <div class="action-btns">
                                <div class="dropdown">
                                    <a href="mailto:" target="_blank">
                                    <button class="btn export-ui" type="button">
                                        <img src="resources/assets/email-report.svg" />&nbsp; Email
                                        &nbsp;
                                    </button>
                                    </a>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div v-if="report_type == 'conversion'">
                        <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="true" :preview-modal="false"
                            :paginate-elements-by-height="1400" filename="review-report" :pdf-quality="2" :manual-pagination="false"
                            pdf-format="a4" pdf-orientation="portrait" pdf-content-width="800px" @progress="onProgress($event)"
                            ref="html2Pdf">
                            <section slot="pdf-content">
                            <div class="custom-card-wrapper">
                                <div class="custom-card">
                                <div class="custom-inner">
                                    <h2 class="cardTitle">Delivery</h2>
                                    <div class="inner-card pt-0">
                                    <div id="main" style="width: 350px; height: 200px">
                                        <apacheChart :option="chartOptions_1" autoresize />
                                    </div>
                                    <ul class="status-list">
                                        <li>
                                        <p class="delivery-header">
                                            <span class="fcircle"></span>&nbsp; Failed
                                        </p>
                                        <p class="delivery-data">{{ failed }}</p>
                                        </li>
                                        <li>
                                        <p class="delivery-header">
                                            <span class="dcircle"></span>&nbsp; Delivered
                                        </p>
                                        <p class="delivery-data">{{ delivered }}</p>
                                        </li>
                                        <li>
                                        <p class="delivery-header">
                                            <span class="scircle"></span>&nbsp;Scheduled
                                        </p>
                                        <p class="delivery-data">{{ scheduled }}</p>
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                </div>
                                <div class="custom-card">
                                <div class="custom-inner">
                                    <h2 class="cardTitle">Perfomance</h2>
                                    <div class="inner-card">
                                    <div class="row">
                                        <div class="col-md-4">
                                        <p class="delivery-header">Delivered</p>
                                        <p class="delivery-data">{{ `${deliveredPer}%` }}</p>
                                        </div>
                                        <div class="progress col-md-4">
                                        <!-- style="width: 100%" -->
                                        <div class="progress-bar deliver-bar" role="progressbar" aria-valuenow="25"
                                            aria-valuemin="`${parseInt(deliveredPer)}`" aria-valuemax="100"
                                            :style="{ width: deliveredPer + '%' }">
                                            {{ delivered }}
                                        </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row">
                                        <div class="col-md-4">
                                        <p class="delivery-header">Opened</p>
                                        <p class="delivery-data">{{ `${openedPer}%` }}</p>
                                        </div>
                                        <div class="progress col-md-4">
                                        <div class="progress-bar open-bar" role="progressbar" aria-valuenow="25" aria-valuemin="0"
                                            aria-valuemax="100" :style="{ width: openedPer + '%' }">
                                            {{ opened }}
                                        </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row">
                                        <div class="col-md-4">
                                        <p class="delivery-header">Review Clicks</p>
                                        <p class="delivery-data">{{ `${reviewClicksPer}%` }}</p>
                                        </div>
                                        <div class="progress col-md-4">
                                        <div class="progress-bar click-bar" role="progressbar" aria-valuenow="25" aria-valuemin="0"
                                            aria-valuemax="100" :style="{ width: reviewClicksPer + '%' }">
                                            {{ reviewClicks }}
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </section>
                        </vue-html2pdf>
                        <div class="custom-card-wrapper">
                            <div class="custom-card">
                            <div class="custom-inner">
                                <h2 class="cardTitle">Delivery</h2>
                                <div class="inner-card pt-0">
                                <div id="main" style="width: 350px; height: 200px">
                                    <apacheChart :option="chartOptions_1" autoresize />
                                </div>
                                <ul class="status-list">
                                    <li>
                                    <p class="delivery-header">
                                        <span class="fcircle"></span>&nbsp; Failed
                                    </p>
                                    <p class="delivery-data">{{ failed }}</p>
                                    </li>
                                    <li>
                                    <p class="delivery-header">
                                        <span class="dcircle"></span>&nbsp; Delivered
                                    </p>
                                    <p class="delivery-data">{{ delivered }}</p>
                                    </li>
                                    <li>
                                    <p class="delivery-header">
                                        <span class="scircle"></span>&nbsp;Scheduled
                                    </p>
                                    <p class="delivery-data">{{ scheduled }}</p>
                                    </li>
                                </ul>
                                </div>
                            </div>
                            </div>
                            <div class="custom-card">
                            <div class="custom-inner">
                                <h2 class="cardTitle">Perfomance</h2>
                                <div class="inner-card">
                                <div class="row">
                                    <div class="col-md-4">
                                    <p class="delivery-header">Delivered</p>
                                    <p class="delivery-data">{{ `${deliveredPer}%` }}</p>
                                    </div>
                                    <div class="progress col-md-4">
                                    <!-- style="width: 100%" -->
                                    <div class="progress-bar deliver-bar" role="progressbar" aria-valuenow="25"
                                        aria-valuemin="`${parseInt(deliveredPer)}`" aria-valuemax="100"
                                        :style="{ width: deliveredPer + '%' }">
                                        {{ delivered }}
                                    </div>
                                    </div>
                                </div>
                                <hr />
                                <div class="row">
                                    <div class="col-md-4">
                                    <p class="delivery-header">Opened</p>
                                    <p class="delivery-data">{{ `${openedPer}%` }}</p>
                                    </div>
                                    <div class="progress col-md-4">
                                    <div class="progress-bar open-bar" role="progressbar" aria-valuenow="25" aria-valuemin="0"
                                        aria-valuemax="100" :style="{ width: openedPer + '%' }">
                                        {{ opened }}
                                    </div>
                                    </div>
                                </div>
                                <hr />
                                <div class="row">
                                    <div class="col-md-4">
                                    <p class="delivery-header">Review Clicks</p>
                                    <p class="delivery-data">{{ `${reviewClicksPer}%` }}</p>
                                    </div>
                                    <div class="progress col-md-4">
                                    <div class="progress-bar click-bar" role="progressbar" aria-valuenow="25" aria-valuemin="0"
                                        aria-valuemax="100" :style="{ width: reviewClicksPer + '%' }">
                                        {{ reviewClicks }}
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="col-12 mt-4 mb-4 mb-sm-0 d-flex center-item">
                            <div>
                            <!-- @click="generateConversionPDF" -->
                            <div class="action-btns">
                                <div class="dropdown">
                                <button class="btn export-ui" type="button" @click="downloadConversionFile">
                                    <img src="resources/assets/export-excel.svg" />&nbsp; Export to
                                    Excel &nbsp;
                                </button>
                                </div>
                            </div>
                            </div>
                            <div>
                            <div class="action-btns">
                                <div class="dropdown">
                                <a href="mailto:" target="_blank">
                                    <button class="btn export-ui" type="button">
                                    <img src="resources/assets/export-email.svg" />&nbsp; Email
                                    &nbsp;
                                    </button>
                                </a>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ActionBar from "./ActionBar";
import VueApexCharts from "vue-apexcharts";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import ECharts from "vue-echarts";
import * as echarts from "echarts";
import VueHtml2pdf from "vue-html2pdf";
import exportFromJSON from "export-from-json";
import Header from "./Header";
import Menu from "./Menu";
export default {
    name: "Reports",
    props: {
        filterPlatform: {
            type: String,
        },
    },
    components: {
        ActionBar,
        apexchart: VueApexCharts,
        DateRangePicker,
        apacheChart: ECharts,
        VueHtml2pdf, Header,
        Menu,
    },
    watch: {
        cust_range() {
            this.selectFilter(this.cust_range);
        },
    },
    data() {
        return {
            moment: moment,
            cust_range: ["01/09/2018", "01/10/2018", false],
            cust_range_options: {
                timePicker: false,
                autoUpdateInput: true,
                startDate: moment(),
                endDate: moment(),
                locale: {
                    format: "DD/MM/YYYY",
                },
            },

            dateRange: {},
            autoApply: false,
            report_type: "reviews",
            // report_type: "conversion",
            series: [
                {
                    name: "Reviews",
                    type: "column",
                    data: [],
                },
                {
                    name: "Average Rating",
                    type: "line",
                    data: [],
                },
            ],
            chartOptions: {
                plotOptions: {
                    bar: {
                        columnWidth: "12%",
                        startingShape: "round",
                        endingShape: "round",
                        borderRadius: 8,
                        dataLabels: {
                            position: "top", // top, center, bottom
                        },
                    },
                },
                colors: ["#FA5244", "#6A8FCD"],
                chart: {
                    height: 350,
                    type: "line",
                    toolbar: {
                        show: false,
                        offsetX: 0,
                        offsetY: 0,
                        tools: {
                            download: true,
                            selection: false,
                            zoom: false,
                            zoomin: false,
                            zoomout: false,
                            pan: false,
                            reset: false,
                        },
                        csv: {
                            filename: undefined,
                            columnDelimiter: ",",
                            headerCategory: "category",
                            headerValue: "value",
                            dateFormatter(timestamp) {
                                return new Date(timestamp).toDateString();
                            },
                        },
                        svg: {
                            filename: undefined,
                        },
                        png: {
                            filename: undefined,
                        },
                    },
                },
                stroke: {
                    show: true,
                    width: [0, 4],
                    // curve: "smooth",
                },
                title: {
                    text: "",
                },
                dataLabels: {
                    enabled: true,
                    enabledOnSeries: [1],
                    distributed: false,
                    background: {
                        enabled: true,
                        foreColor: "#fff",
                        padding: 4,
                        borderRadius: 2,
                        borderWidth: 1,
                        borderColor: "#6A8FCD",
                        opacity: 0.9,
                    },
                    formatter: (value, { seriesIndex, dataPointIndex, w }) => {
                        return this.lineGraphData[dataPointIndex];
                    },
                },
                fill: {
                    colors: ["#FA5144"],
                    type: "gradient",
                    gradient: {
                        shade: "dark",
                        type: "vertical",
                        shadeIntensity: 0.5,
                        gradientToColors: ["#FDD640"],
                        inverseColors: true,
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 100],
                    },
                },
                xaxis: {
                    categories: [
                        "Jan",
                        "Feb",
                        "Mar",
                        "Apr",
                        "May",
                        "Jun",
                        "Jul",
                        "Aug",
                        "Sep",
                        "Oct",
                        "Nov",
                        "Dec",
                    ],
                },
                yaxis: [
                    {
                        title: {
                            text: "Reviews",
                        },
                        min: 0,
                    },
                ],
                markers: {
                    size: 10,
                    colors: "#6A8FCD",
                    strokeColors: "#6A8FCD",
                    strokeWidth: 2,
                    strokeOpacity: 0.9,
                    strokeDashArray: 0,
                    fillOpacity: 1,
                    shape: "circle",
                    radius: 4,
                    offsetX: 0,
                    offsetY: 0,
                    onClick: function (e) {
                        // do something on marker click
                        console.log("e", e);
                    },
                },
                legend: {
                    show: true,
                },
                tooltip: {
                    shared: false,
                    intersect: true,
                    enabled: true,
                    marker: {
                        show: true,
                    },
                    custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                        if (seriesIndex) {
                            return (
                                '<div class="col-md-2 mb-4 pb-2 reportStatistics" style="border-radius:25px">' +
                                '<h2 class="cardTitle">Average Rating</h2>' +
                                '<div style="padding: 15px">' +
                                ' <div class="row center-item">' +
                                '  <div class="col-6 col-md-6 mb-2 pb-1 form-outline form-white">' +
                                '<h1 class="data-h1">' +
                                `${this.lineGraphData[dataPointIndex]}` +
                                "</h1>" +
                                "</div>" +
                                " </div>" +
                                "</div>" +
                                "</div>"
                            );
                        } else {
                            return (
                                '<div class="col-md-2 mb-4 pb-2 reportStatistics">' +
                                '<h2 class="cardTitle">Reviews</h2>' +
                                '<div style="padding: 15px">' +
                                ' <div class="row center-item">' +
                                '  <div class="col-6 col-md-6 mb-2 pb-1 form-outline form-white">' +
                                '<h1 class="data-h1">' +
                                series[seriesIndex][dataPointIndex] +
                                "</h1>" +
                                "</div>" +
                                " </div>" +
                                "</div>" +
                                "</div>"
                            );
                        }
                    },
                },
            },
            chartOptions_1: {
                tooltip: {
                    trigger: "item",
                },

                series: [
                    {
                        name: "",
                        type: "pie",
                        radius: ["60%", "50%"],
                        avoidLabelOverlap: false,
                        itemStyle: {
                            borderRadius: 10,
                            borderColor: "#fff",
                            borderWidth: 2,
                        },
                        label: {
                            show: false,
                            position: "center",
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: "30",
                                fontWeight: "bold",
                            },
                        },
                        labelLine: {
                            show: false,
                        },
                        data: [
                            { value: 25, name: "Failed" },
                            { value: 75, name: "Delivered" },
                            { value: 75, name: "Scheduled" },
                        ],
                    },
                ],
                color: ["#FA4444", "#38CB89", "#f9c70a"],
            },
            startDate: "",
            endDate: "",
            reviewGraphData: [],
            platform: "",
            userLoginId: "",
            datePicker: false,
            cardReview: "",
            cardAverageRating: "",
            cardDate: "",
            yearFilter: "All Time",
            failed: 0,
            scheduled: "",
            delivered: "",
            opened: "",
            totalCount: "",
            deliveredPer: "",
            openedPer: "",
            reviewClicks: "",
            reviewClicksPer: "",
            reviewList: [],
            lineGraphData: [],
            percentageGrowth: "",
            averageRatingGrowth: "",
            adminId: ""
        };
    },
    mounted() {
        this.userLoginId = localStorage.getItem("id");
        this.adminId = localStorage.getItem("adminId");
        this.platform = this.filterPlatform ? this.filterPlatform : "";
        this.reviewGraph();
        this.reviewCard();
        this.conversionGraph();
    },
    created() { },
    computed: {
        years() {
            const year = new Date().getFullYear();
            let years = Array.from(
                { length: year - 1970 },
                (value, index) => 1971 + index
            ).reverse();
            years.unshift("All Time");
            return years;
        },
    },
    methods: {
        onChange(event) {
            this.report_type = event.target.value ? event.target.value : "reviews";
            if (this.report_type == "conversion") {
                this.conversionGraph();
            }
        },
        onPlatformChange(event) {
            this.platform = event.target.value;
            this.reviewGraph();
            this.reviewCard();
        },
        selectFilter(data) {
            this.startDate = moment(data.startDate).format("YYYY-MM-DD");
            this.endDate = moment(data.endDate).format("YYYY-MM-DD");
            this.reviewGraph();
            this.reviewCard();
        },
        onChangeYear(e) {
            this.yearFilter = e.target.options[e.target.options.selectedIndex].text;
            if (this.yearFilter) {
                this.reviewGraph();
                this.reviewCard();
            }
        },
        reviewGraph() {
            let year;
            if (this.yearFilter) {
                year = this.yearFilter + "-01-01";
            } else {
                year = moment(this.startDate).format("YYYY");
            }
            const selectedStartOfYear = moment([year])
                .startOf("year")
                .format("YYYY-MM-DD");

            const startOfYear = moment([year]).startOf("year").format("YYYY-MM-DD");
            const endOfYear = moment([year]).endOf("year").format("YYYY-MM-DD");

            let data = {
                date: this.startDate
                    ? ""
                    : selectedStartOfYear != "Invalid date"
                        ? selectedStartOfYear
                        : "",
                platformName: this.platform ? this.platform : "",
                fromDate: this.startDate ? this.startDate : "",
                toDate: this.endDate ? this.endDate : "",
                userId: this.adminId != 'null' ? this.adminId : this.userLoginId,
            };
            this.$store.dispatch("reviewGraph", data).then((response) => {
                this.reviewList = response.data;
                let categoriesData = this.reviewList.map((categories) => {
                    return categories.Month;
                });
                this.chartOptions = {
                    ...this.chartOptions,
                    ...{
                        xaxis: {
                            categories: [...categoriesData],
                        },
                    },
                };
                this.series[0].data = this.reviewList.map((data) => {
                    return data.count ? data.count : 0;
                });

                this.series[1].data = this.reviewList.map((data) => {
                    return data.count ? data.count : 0;
                });

                this.lineGraphData = this.reviewList.map((data) => {
                    return data.reviewRating
                        ? (data.reviewRating / data.count).toFixed(2)
                        : 0;
                });

                window.dispatchEvent(new Event("resize"));
            });
        },
        reviewCard() {
            let year;
            if (this.yearFilter) {
                year = this.yearFilter + "-01-01";
            } else {
                year = moment(this.startDate).format("YYYY");
            }

            const selectedStartOfYear = moment([year])
                .startOf("year")
                .format("YYYY-MM-DD");

            let data = {
                date: this.startDate
                    ? ""
                    : selectedStartOfYear != "Invalid date"
                        ? selectedStartOfYear
                        : "",
                platformName: this.platform ? this.platform : "",
                fromDate: this.startDate ? this.startDate : "",
                toDate: this.endDate ? this.endDate : "",
                userId: this.adminId != 'null' ? this.adminId : this.userLoginId,
            };
            this.$store.dispatch("reviewGraphCard", data).then((response) => {
                let res = response.data["total"];
                let percentage = response.reportCalculation;
                this.cardReview = res && res["count"] ? res["count"] : "0";
                this.cardAverageRating =
                    res && res["reviewRating"]
                        ? (res["reviewRating"] / res["count"]).toFixed(2)
                        : "0";
                this.cardDate = res && res["date"] ? res["date"] : "";

                this.percentageGrowth = percentage["percentageGrowth"]
                    ? percentage["percentageGrowth"]
                    : 0;
                this.averageRatingGrowth = percentage["averageRatingGrowth"]
                    ? percentage["averageRatingGrowth"]
                    : 0;
            });
        },
        showPicker(title) {
            title == "picker" ? (this.datePicker = true) : (this.datePicker = false);
        },
        conversionGraph() {
            let data = {
                userId: this.adminId != 'null' ? this.adminId : this.userLoginId,
            };
            this.$store.dispatch("reviewConversionGraph", data).then((response) => {
                let res = response.data;

                this.chartOptions_1.series[0].data[0].value = res.failed
                    ? res.failed
                    : 0;
                this.chartOptions_1.series[0].data[1].value = res.delivered
                    ? res.delivered
                    : 0;
                this.chartOptions_1.series[0].data[2].value = res.scheduled
                    ? res.scheduled
                    : 0;
                this.failed = res.failed ? res.failed : 0;
                this.scheduled = res.scheduled;
                this.delivered = res.delivered;
                this.opened = res.opened;
                this.totalCount = res.totalCount;
                this.reviewClicks = res.ReviewClicks;
                this.deliveredPer = Math.round((res.delivered / res.totalCount) * 100);
                this.openedPer = Math.round((res.opened / res.totalCount) * 100);
                this.reviewClicksPer = Math.round(
                    (res.ReviewClicks / res.totalCount) * 100
                );
            });
        },
        onProgress(event) {
            console.log(`Processed: ${event} / 100`);
        },
        hasGenerated() {
            // alert("PDF generated successfully!");
        },
        generatePDF() {
            this.$refs.html2Pdf.generatePdf();
        },
        generateConversionPDF() {
            this.$refs.html2Pdf.generatePdf();
        },
        downloadFile() {
            const data = [];
            const fileName = "review-excel";
            const exportType = exportFromJSON.types.csv;

            this.reviewList.forEach((element) => {
                data.push({
                    Month: element.Month,
                    Reviews: element.count,
                    "Average Ratings": (element.reviewRating / element.count).toFixed(2),
                });
            });
            data.push({
                Month: "Total",
                Reviews: this.cardReview,
                "Average Ratings": this.cardAverageRating,
            });
            if (data) exportFromJSON({ data, fileName, exportType });
        },

        downloadConversionFile() {
            const data = [];
            const fileName = "conversion-excel";
            const exportType = exportFromJSON.types.csv;

            data.push(
                {
                    Delivery: "Failed",
                    value: this.failed,
                },
                {
                    Delivery: "Delivered",
                    value: this.delivered,
                },
                {
                    Delivery: "Scheduled",
                    value: this.scheduled,
                },
                {
                    Delivery: "",
                    value: "",
                },
                {
                    Delivery: "",
                    value: "",
                },
                {
                    Delivery: "Perfomance",
                    value: "Percentage",
                },
                {
                    Delivery: "Delivered",
                    value: `${this.deliveredPer}%`,
                },
                {
                    Delivery: "Opened",
                    value: `${this.openedPer}%`,
                },
                {
                    Delivery: "Review Clicks",
                    value: `${this.reviewClicksPer}%`,
                }
            );
            if (data) exportFromJSON({ data, fileName, exportType });
        },
    },
};
</script>
<style scoped>
.leftPanel {
    background: #27293b;
    position: static;
}

.sideBar {
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    height: 100vh !important;
    margin-bottom: 0px;
    border-bottom-width: 0px !important;
}

.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    display: block;
    transition: opacity 0.3s ease;
    overflow: auto;
}

.modal-wrapper {
    display: grid;
}

.modal-container {
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    margin-bottom: 100px;
    padding: 30px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
}

.youtube-video {
    width: 600px;
    height: 300px;
}

.feedback-modal-container {
    padding: 30px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    width: 600px;
}

.business-modal-container {
    padding: 20px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    width: 500px;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.business-modal-history-container {
    padding: 20px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    width: 800px;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.business-input {
    height: 45px;
    border-radius: 15px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px !important;
    margin: 10px 0;
}

.yelp-form-control1 {
    width: 90%;
    display: inline-block !important;
}

.business-btn {
    margin-top: 4px;
    right: 53.47%;
    top: 703px;
    bottom: 25.68%;
    width: 100%;
    height: 45px;
    background: #00053c;
    border-radius: 15px;
    color: white
}

.modal-header h3 {
    margin-top: 0;
    color: #42b983;
}

.modal-body {
    margin: 20px 0;
}

.modal-default-button {
    float: right;
}

.loginFormHeading {
    margin-bottom: 50px;
    top: 294px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 7px;
    color: #323b4b;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container .modal-container-youtube {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.nav-item active {
    background: #00b0ac;
    background-color: #00b0ac;
}

.navbar-light .navbar-nav .nav-link {
    margin-left: 1vw;
    margin-top: 2vh;
    font-size: 14px;
    color: #9194b3;
}

.navbar-vertical.navbar-light .navbar-nav>.nav-item>.nav-link.active {
    color: #fdd640;
    font-size: 14px;
    background-color: #27293b;
}

.navbar>.container .navbar-brand,
.navbar>.container-fluid .navbar-brand {
    margin-left: 1vw;
    margin-top: 2vh;
}

.searchBox {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 16px;
    align-items: center;
    color: #9194b3;
    padding: 0px 20px;
    height: 46px;
    background: #f4f5fa;
    border-radius: 15px;
    min-width: 350px;
    max-width: 350px;
    border: none;
    box-shadow: none;
}

.text-heading {
    font-weight: 500 !important;
    text-transform: capitalize;
    color: #00053c;
    font-family: "Inter";
    font-style: normal;
    font-size: 14px;
    align-items: center;
    white-space: initial;
}

.formtable {
    text-align: center;
    border-collapse: inherit;
}

.fontWeightBolder {
    font-weight: bolder;
}

.createButton {
    font-family: "Inter", sans-serif;
    background: #00053c;
    color: white;
    font-size: 18px;
    border-radius: 15px;
    width: 170px;
    height: 51px;
}

.heading {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-size: 12px;
    line-height: 24px;
    align-items: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #00053c !important;
    font-weight: 600;
    align-items: center;
}

.loginInputsEmail {
    width: 100%;
    width: 100%;
    height: 45px;
    padding: 10px;
    background: #f4f5fa;
    /* BG 2 */

    border: 1px solid #f3f3f3;
    box-sizing: border-box;
    border-radius: 15px;
}

::placeholder {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 29px;
    /* or 181% */
    display: flex;
    align-items: center;

    color: #9194b3;
}

.loginInputsPassword {
    margin-top: 50px;
    width: 100%;
    padding: 20px;
    height: 58px;

    top: 555px;
    /* BG 4 */

    background: #ffffff;
    /* BG 2 */

    border: 1px solid #f3f3f3;
    box-sizing: border-box;
    border-radius: 15px;
}

.userForm {
    height: 50px;
    /* height: 58px; */
    border-radius: 15px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px !important;
}

.loginButtonText {
    justify-content: center;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    /* or 181% */
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
    /* height: 50px; */
}

.callButton {
    justify-content: center;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
    margin: 0px
}

.loginButton {
    margin-top: 20px;
    width: 100%;
    height: 58px;
    background: #00053c;
    border-radius: 15px;
    transition: 0.2s ease;
}

.yellowButton {
    right: 53.47%;
    top: 703px;
    bottom: 25.68%;
    width: 25%;
    height: 50px;
    border-radius: 15px;
    padding: 10px;
    background: #fdd640;
    margin: 0px 12px 12px;
    transition: 0.2s ease;
}

.yellowButton:hover {
    background: #00053c;
}

.row {
    margin-right: 0px;
}

.reviewModalName {
    margin: 0px 20px;
    /* margin: 0px 7px; */
    text-decoration: none;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    /* or 181% */
    align-items: center;

    color: #1778f2 !important;
}

.searchBoxDiv {
    max-width: 281px;
    min-width: 281px;
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.table>tbody>tr>td {
    padding: 30px 15px;
}

/* Review Css  Start*/
.ratingSpan {
    color: white;
    /* padding: 10px 10px; */
    padding: 8px 10px 5px;
    /* background: #1778f2; */
    border-radius: 26px;
    font-weight: 500;
    font-size: 16px;
    margin-left: 15px;
}

.yelpStarColor {
    background: #bf2519;
}

.googleStarColor {
    background: #fdd640;
}

.fbStarColor {
    background: #39559a;
}

.zillowStarColor {
    background: #38cb89;
}

.fbRatingStar {
    color: white;
    padding: 10px 10px;
    padding: 8px 10px 5px;
    background: #1778f2;
    border-radius: 26px;
    background: #1778f2;
    font-weight: 500;
    font-size: 16px;
    margin-left: 15px;
    white-space: nowrap;
}

.ratingSpan>img {
    padding: 0 5px;
    width: 30px;
    margin-top: -4px;
}

/* vue2-editior style start */
.form-white>div {
    width: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    background: #f4f5fa;
    border-radius: 15px;
    font-weight: 500;
    font-size: 20px;
    color: #9194b3;
}

::v-deep .ql-toolbar.ql-snow {
    border: 0;
    background: rgba(145, 148, 179, 0.1);
    border-top: 1px solid rgba(145, 148, 179, 0.2);
}

::v-deep .ql-container.ql-snow {
    border: 0;
    height: 150px;
    overflow: scroll;
    color: black !important;
}

::v-deep .quillWrapper.ql-editor {
    display: flex;
    display: -ms-flexbox;
    flex-direction: column;
    /* flex-direction: column-reverse; */
}

::v-deep .ql-toolbar.ql-snow .ql-formats {
    margin-right: 0;
    color: #9194b3 !important;
}

::v-deep .ql-toolbar.ql-snow span.ql-formats svg {
    width: 16px;
    height: 16px;
}

::v-deep .ql-toolbar.ql-snow span.ql-formats svg line,
.ql-toolbar.ql-snow span.ql-formats svg path {
    stroke: #9194b3;
}

::v-deep .ql-snow .ql-picker {
    color: #9194b3;
}

/* vue2-editior style ends */
.loginName {
    margin-left: 10px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 29px;
    color: #00053c;
}

.loginPic {
    width: 47px;
    height: 47px;
}

.viewReviewInnerBox {
    background-color: white;
    margin-bottom: 20px;
}

.viewReviewInnerBoxImg {
    margin: 20px 20px;
}

.viewReviewInnerBoxPara {
    word-wrap: break-word;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #9194b3;
}

.viewReviewInnerBoxAnchor {
    text-decoration: none;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #4285f4;
}

.viewReviewSecondPartTopText {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #9194b3;
}

.viewReviewSecondPartTopName {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: #00053c;
}

.viewReviewSecondPartTextArea {
    margin: 15px 0px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    /* or 181% */

    display: flex;
    align-items: center;

    color: #9194b3;

    width: 451px;
    height: 150px;
    left: 889px;
    top: 491px;
    background: #ffffff;
    border: 1px solid #f3f3f3;
    box-sizing: border-box;
    border-radius: 15px;
}

.loginButtonText {
    justify-content: center;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    /* or 181% */
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
}

.viewReviewSecondPartdropdown {
    margin: 15px 0px;
    background: #ffffff;
    border: 1px solid #f3f3f3;
    box-sizing: border-box;
    border-radius: 15px;
}

.viewReviewSecondPartdropdownText {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px !important;
    /* font-size: 16px !important; */
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #9194b3;
}

.filterText {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    display: flex;
    color: #9194b3;
}

.profilePic {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 5px solid #ffffff;
    position: absolute;
    margin: -80px 50px;
}

/* Review Css End */
.profileFormTitle {
    text-decoration-line: none;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    align-items: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #9194b3 !important;
}

.profileFormTitleValue {
    text-decoration-line: none;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    /* font-size: 12px; */
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #00053c !important;
}

.profileName {
    margin-left: 226px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #00053c;
}

.profileSettingSwitch {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 1.3;
    display: block;
    text-align: center;
    color: #9194b3;
    text-decoration-line: none;
    padding-bottom: 15px;
}

.profileSettingSwitch.active {
    color: #ffab00;
}

.profileSettingIcons {
    padding-right: 15px;
    margin-top: 5px;
    height: 33px;
}

.platFormCard {
    padding: 60px 0px 10px 0;
    border-radius: 15px !important;
}

.platFormCardGroup {
    column-gap: 20px;
    margin: 30px 20px;
}

.tourButton {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    background: #00053c;
    color: #ffffff;
    width: 125px;
    height: 40px;
    border-radius: 15px;
}

.platFormCardButton {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    /* or 181% */
    background: #00053c;
    color: #ffffff;
    width: 180px;
    height: 51px;
    border-radius: 15px;
}

.platFormCardButtonColor {
    background: #38cb89;
}

.profileSettingHeading {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #00053c;
    margin-top: 24px;
    margin-left: 24px;
}

.communicationFormLabel {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 29px;
    /* or 207% */

    display: flex;
    align-items: center;
    letter-spacing: 1px;

    color: #9194b3;
}

.choose-files {
    align-self: flex-end;
    z-index: 10;
}

.button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;

    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.button-wrapper.btn:hover {
    background-color: #4e5056;
}

.label {
    margin-bottom: 20px;
    color: #4e5056;
    font-size: 18px;
}

.button-wrapper input[type="file"] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

.btn {
    border: none;
    padding: 10px;
    /* padding: 10px 20px; */
    border-radius: 50px;
    background-color: #f4f5fa;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 200ms ease-in;
}

.drop-it-hot {
    margin: 34px 0px;
    border-radius: 15px;
    /* height: 485px; */
    height: 375px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 8px 15px 0 rgba(black, 0.1);
    overflow: hidden;
    color: #f4f5fa;
    border: 1px dashed rgba(145, 148, 179, 0.5);
    box-sizing: border-box;
}

.communicationImg {
    margin: 34px 0px;
    border-radius: 15px;
    /* height: 485px; */
    height: 375px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 8px 15px 0 rgba(black, 0.1);
    overflow: hidden;
    color: #f4f5fa;
}

.drop-it-hot:after {
    content: "";
    position: absolute;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    border: 2px dashed #e6e7f0;
    border-radius: 5px;
    z-index: 0;
}

.circle {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    color: #f4f5fa;
    background-color: white;
    /* border: 3px solid black; */
    width: 100px;
    height: 100px;
}

.circle {
    transition: transform 150ms ease-in;
    z-index: 10;
}

svg {
    width: 40px;
    height: 40px;
}

.circle:before {
    content: "";
    background-color: #f4f5fa;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    position: absolute;
    opacity: 0;
    transition: transform 250ms ease-in, opacity 200ms ease-in;
    z-index: 0;
}

.circle:after {
    content: "";
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.circle:hover {
    transform: scale(1.2);
    opacity: 0.9;
}

.circle:before {
    transform: scale(8);
    opacity: 1;
}

.circle:after {
    border: 3px solid white;
}

svg {
    color: white;
    z-index: 1;
}

.loginInputsTextArea {
    width: 100%;
    height: 150px;

    background: #f4f5fa;
    border-radius: 15px;
    border-style: none;
}

.assignFollowUp {
    height: 50px;
    left: 889px;
    top: 413px;
    /* background: #ffffff; */
    border: 1px solid #f3f3f3;
    box-sizing: border-box;
    border-radius: 15px;
    width: 100%;
    padding: 10px;
}

.respondButton {
    margin-top: 20px;
    right: 53.47%;
    top: 703px;
    bottom: 25.68%;
    width: 100%;
    height: 50px;
    background: #00053c;
    border-radius: 15px;
}

textarea {
    resize: none;
}

.inputOrange:focus {
    outline: none !important;
    border: 1.5px solid #ff8c00;
    box-shadow: 0 0 2px #ff8c00;
}

form i {
    margin-left: -30px;
    cursor: pointer;
}

.form-control1 {
    display: inline-block !important;
}

.navOutDiv {
    position: fixed;
    width: 100%;
}

.dashScroll {
    margin-right: 0 !important;
    padding-right: 15px;
}

.reviewDropDown {
    width: 96%;
    border-radius: 6px;
    position: absolute;
    inset: 0px auto auto 0px;
    margin: 0px;
    transform: translate(9px, 233px);
}

.outer-circle-new {
    background: rgba(137, 176, 240, 0.1);
    border-radius: 15px;
    padding: 10px;
    margin: 0;
}

.outer-circle-pending {
    background: rgba(255, 171, 0, 0.12);
    border-radius: 15px;
    padding: 10px;
}

.outer-circle-resolved {
    background: #e5f7ef;
    border-radius: 15px;
    padding: 10px;
}

.inner-new {
    color: #89b0f0;
}

.inner-pending {
    color: #ffab00;
}

.inner-resolved {
    color: #38cb89;
}

.center-item {
    display: flex;
    justify-content: center;
    align-items: center;
}

.view-button {
    margin-top: 20px;
    right: 53.47%;
    top: 703px;
    bottom: 25.68%;
    width: 25%;
    height: 50px;
    background: #00053c;
    border-radius: 15px;
    padding: 10px;
    transition: 0.2s ease;
}

.view-button:hover {
    background: #00053c;
}

.filter-bg-color {
    background-color: #f4f5fa;
    border-radius: 15px;
    padding: 5px 10px;
    margin: 0 5px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    display: flex;
    align-items: center;
}

.assignFilter {
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 15px;
    width: 100%;
    justify-content: flex-end;
    margin: 0 0 10px;
}

.basic-info {
    margin: 20px;
    border-radius: 20px;
    background: rgb(244, 245, 250);
    padding: 20px;
}

.edit-text {
    width: 33px;
    height: 16px;
    left: 1302px;
    top: 703px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #9194b3;
    padding: 10px 10px 0px;
}

.basic-card {
    background: rgb(255, 255, 255);
    margin: 20px;
    padding: 20px;
    border-radius: 15px;
}

.communication-setting {
    padding: 36px;
    border-radius: 15px;
    margin-bottom: 20px;
}

.role-table-pagination .pagination {
    justify-content: center;
}

.admin-new-pagination ul.pagination {
    background: none;
}

.admin-new-pagination ul li {
    flex-grow: initial !important;
    box-shadow: 0px 2px 6px #0000000a;
}

.admin-new-pagination {
    padding: 35px 0;
    background: #fff;
    overflow: auto;
}

.admin-new-pagination .pagination {
    /* justify-content: flex-end; */
    height: auto;
    box-shadow: none;
}

.admin-new-pagination .pagination li.active a.page-link {
    background: #623cea;
    border-radius: 0px;
    border-color: #623cea;
    color: #fff;
}

.admin-new-pagination .pagination li.active a.page-link:focus {
    box-shadow: none;
}

.admin-new-pagination .pagination a.page-link {
    color: #a4afb7;
    background: #fff;
}

.admin-new-pagination ul li span,
.admin-new-pagination ul li a,
.admin-new-pagination ul li button {
    padding: 10px 15px !important;
    font-size: 13px !important;
}

.admin-new-pagination .pagination a.page-link:focus {
    box-shadow: none;
}

.dark-theme .admin-new-pagination .pagination li.active a.page-link {
    background: #1a73e8;
    border-color: #1a73e8;
}

.spinner-center {
    justify-content: center;
    align-items: center;
    display: flex;
}

.isDisabled {
    pointer-events: none;
    color: red;
}

.is-invalid {
    background-image: none !important;
    border-color: #dc3545 !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    border-color: #dc3545 !important;
}

.logo-center {
    justify-content: center;
    margin-left: -15px !important;
}

.profile-dropdown {
    margin-left: 10px;
    /* margin-bottom: 10px; */
}

.table-align {
    margin-top: 15px;
    border-radius: 36px;
    margin-left: 10px;
}

table th:first-child {
    border-radius: 15px 0 0 15px;
}

table th:last-child {
    border-radius: 0 15px 15px 0;
}

.table>tbody>tr>td,
.table>thead>tr>th {
    /* border: 0 !important; */
    border-top: none;
}

.header th {
    height: 10px;
    /* line-height: 2px;
}
.user-modal {
 /*  margin-left: 600px !important;
  margin-right: 600px !important; */
    max-width: 600px;
    margin: 100px auto;
}

.user-modal-heading {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #323b4b;
}

.profileSettingSwitch.active {
    border-bottom: 2px solid #ffab00;
    display: block;
}

.checkbox-lg .custom-control-label::before,
.checkbox-lg .custom-control-label::after {
    top: 0.8rem;
    width: 1.55rem;
    height: 1.55rem;
}

.checkbox-lg .custom-control-label {
    padding-top: 8px;
    padding-left: 6px;
}

.checkbox-xl .custom-control-label::before,
.checkbox-xl .custom-control-label::after {
    top: 1.2rem;
    width: 1.85rem;
    height: 1.85rem;
}

.checkbox-xl .custom-control-label {
    padding-top: 23px;
    padding-left: 10px;
}

table th {
    background-color: #f4f5fa !important;
    height: 51px !important;
    padding: 15px !important;
    text-align: center !important;
}

.file-upload-text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #9194b3;
}

.account-outline {
    outline: currentcolor none medium !important;
    border: none;
    box-shadow: none;
}

.initialletter {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    background: #41c7db;
    font-size: 35px;
    color: #fff;
    text-align: center;
    line-height: 110px;
    margin: 20px 0;
}

/* style start */
.inner-white-wrap {
    border-radius: 36px;
    background: #fff;
    /* overflow: hidden; */
    height: 100%;
    padding: 15px;
}

.table-outer-wrap {
    padding: 30px;
    background: rgb(244, 245, 250);
    border-radius: 36px;
}

a.navbar-brand {
    padding: 30px 0 0 50px !important;
}

#sidebarCollapse {
    padding: 0;
    margin-top: 40px;
}

#sidebarCollapse li.nav-item a {
    position: relative;
}

#sidebarCollapse li.nav-item a::before {
    position: absolute;
    content: "";
    width: 46px;
    height: 46px;
    left: -36px;
    top: -6px;
    background: #fdd640;
    box-shadow: 0px 0px 17px 5px rgba(253, 214, 64, 0.12);
    border-radius: 15px;
    transform: rotate(45deg);
    opacity: 0;
    transition: 0.3s ease all;
}

#sidebarCollapse li.nav-item a {
    margin: 0;
    padding-left: 30px;
    margin-top: 10px;
}

#sidebarCollapse li.nav-item a.active::before {
    opacity: 1;
}

#sidebarCollapse li.nav-item a:hover::before {
    opacity: 1;
}

#sidebarCollapse li.nav-item a:hover {
    background: transparent;
}

.card-header h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600 !important;
    font-size: 32px;
    color: #00053c;
}

.loginButton:hover {
    background: #fdd640 !important;
}

.loginButton:hover p.loginButtonText {
    color: #fff !important;
}

.action_btn-platform .loginButtonText {
    height: auto !important;
}

.table-outer-wrap .pagination button,
.table-outer-wrap .pagination span {
    margin: 0 3px;
}

table td h1 {
    font-size: 16px;
}

.modal-container-view {
    width: 800px;
    margin: 0 !important;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    padding: 30px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
}

/* file upload style */
input#file-upload {
    padding-left: 76px;
    font-size: 13px;
}

input#file-upload::after {
    content: "Upload Profile Image";
    position: absolute;
    top: 7px;
    left: 20px;
    background: white;
    width: 150px;
    height: 30px;
    line-height: 30px;
    padding: 0 5px;
    font-size: 14px;
    font-weight: 600;
    color: #68696a;
    border-radius: 10px 0 0 10px;
}

.rating-wrap {
    display: inline-block;
}

.star-wrapper {
    display: inline-flex;
    align-items: center;
    margin: 10px !important;
    padding: 0 !important;
}

.star-wrapper+p {
    display: flex;
    align-items: center;
}

button p {
    /* margin: 5px 0; */
}

.profile-img {
    display: inline-flex;
    height: 47px;
    width: 47px;
    border-radius: 50%;
    background: #f7f7f7;
    border: 1px solid #f7f7f7;
    object-fit: cover;
    align-items: center;
    justify-content: center;
}

.profile-img img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #f7f7f7;
    object-fit: cover;
}

.profile-inner {
    display: inline-flex !important;
    align-items: center;
    padding: 5px;
    background: #f7f7f7;
    border-radius: 30px;
    white-space: nowrap;
}

.profile-inner .loginName {
    margin-bottom: 0;
}

.new-color {
    text-decoration: none;
    color: #ff8c00 !important;
}

.modal-container.modal-sm {
    width: 100% !important;
    max-width: 500px;
}

.modal-container.modal-video {
    width: 100% !important;
    max-width: 600px;
}

@media (min-width: 1400px) {
    .modal-container {
        max-width: 1000px;
        width: 100% !important;
        margin: 0 auto !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        max-height: calc(100vh - 0px);
        overflow-y: auto;
    }

    .modal-container-youtube {
        max-width: 1000px;
        width: 100% !important;
        margin: 0 auto !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        max-height: calc(100vh - 0px);
        overflow-y: auto;
    }

    .formInput {
        padding-top: 20px;
    }
}

@media (max-width: 1599.98px) {
    .table>tbody>tr>td {
        padding: 10px;
    }

    table .btn {
        padding: 0 5px !important;
    }
}

@media (max-width: 1299.98px) {
    .modal-container {
        max-width: 85% !important;
    }

    .modal-container-youtube {
        max-width: 85% !important;

    }

    .text-width {
        width: auto !important;
    }

    .platFormCardGroup {
        margin: 0;
        -moz-column-gap: 15px;
        column-gap: 15px;
    }

    .platFormCard {
        padding: 50px 0 5px;
        height: auto;
    }
}

@media (max-width: 1199.98px) {
    .platFormCardGroup {
        margin: 0;
        -moz-column-gap: 15px;
        column-gap: 15px;
    }

    .platFormCard {
        padding: 30px 0 5px;
        height: auto;
    }

    .profileSettingHeading {
        margin: 0;
    }

    .com-set-wrap {
        margin: 0 !important;
    }

    .youtube-video {
        width: 300px;
        height: 150px;
        margin: 150px auto;
    }
}

@media (max-width: 991.98px) {

    .login-wrap .formInput {
        margin-top: 0 !important;
    }

    .fbStarWidth {
        width: 100%;
    }

    .viewReviewInnerBoxImg {
        display: block;
    }

    .text-width {
        margin: 10px !important;
    }

    .feedback-modal-container {
        top: 0;
        transform: translate(-50%, 50%);
    }

    /* responsive sidebar */
    .navbar-collapse.collapse {
        display: none !important;
        background: #27293b;
        width: 100%;
        height: auto;
        padding: 10px 20px !important;
        margin: 0;
    }

    .sideBar {
        height: auto !important;
    }

    a.navbar-brand {
        padding: 0 !important;
    }

    .profile-wrap {
        display: none !important;
    }

    #sidebarCollapse {
        margin-top: 0;
    }

    .btn-logo-wrap button.navbar-toggler {
        position: absolute;
        right: 30px;
        background: #fff !important;
    }

    .container-fluid.leftPanel {
        position: relative;
    }

    .btn-logo-wrap {
        display: flex;
        display: -ms-flexbox;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        padding: 0 30px;
    }

    .navbar-collapse.collapse.show {
        display: block !important;
    }

    .navOutDiv {
        position: unset;
    }

    #sidebarCollapse ul.navbar-nav {
        width: 100%;
    }

    .dashboard-view-card {
        width: 100%;
    }

    .relative-pos {
        width: 100%;
    }

    .dashboard-graph-card {
        width: 100%;
        height: 350px;
        height: auto;
    }

    .dashboard-review-card {
        width: 100%;
        height: 350px;
        height: 100%;
    }

    .modal-container-view {
        top: 0;
        transform: translate(-50%, 50%);
        width: 90%;
    }

    .youtube-video {
        width: 300px;
        height: 150px;
        margin: 150px auto;
    }
}

@media (max-width: 767.98px) {
    .youtube-video {
        width: 300px;
        height: 150px;
        margin: 150px auto;
    }

    .fbStarWidth {
        width: 100%;
    }

    .table-align>.card {
        margin: 12px !important;
    }

    .card.border-0.mb-7.inner-feedback-action {
        margin: 0 10px;
    }

    .card.border-0.feeback-action.col-9 {
        width: 100% !important;
    }

    .detail-wrap {
        margin-left: 0 !important;
    }

    .modal-container {
        /* margin-left: 20px;
    margin-right: 20px; */
    }

    .feedback-modal-container {
        width: 90%;
    }

    .navbar-collapse.collapse {
        padding: 10px 0px !important;
    }

    main .container {
        max-width: 100%;
    }

    table td h1 {
        font-size: 15px;
    }

    .card-header h2 {
        font-size: 26px;
    }

    .requestFrom {
        margin: 20px;
    }

    ul.navbar-nav {
        margin: 0 !important;
    }

    #sidebarCollapse li.nav-item a::before {
        left: -26px;
        width: 36px;
        height: 36px;
        top: 0;
    }

    .searchBox {
        min-width: auto;
        max-width: unset;
        width: 100%;
    }

    .table-responsive {
        border: 0;
    }

    .table-responsive .table th {
        border: 0;
    }

    .modal-wrapper a {
        cursor: pointer;
    }

    .table-outer-wrap {
        padding: 12px;
    }

    div#userForDiv {
        margin: 0;
        margin-top: 10px;
    }

    .profileName {
        margin-left: 0;
        margin-top: 0;
        font-size: 16px;
    }

    .tab-wrap {
        flex-wrap: wrap;
        width: 100% !important;
    }

    .tab-wrap>.col-12 {
        width: 50%;
    }

    .tabs-outer {
        margin: 0 !important;
    }

    .basic-info {
        margin: 10px 0 0 0;
    }

    .basic-card {
        margin: 0;
    }

    .profilePic {
        margin: -80px 30px;
    }

    .profile-text-wrap {
        padding: 0 !important;
        margin-top: 60px;
    }

    .com-set-wrap {
        margin: 0 !important;
    }

    .communication-setting {
        padding: 10px 5px 0px 20px;
    }

    .dashboard-view-card {
        width: 100%;
    }

    .relative-pos {
        width: 100%;
    }

    .dashboard-graph-card {
        width: 100%;
        height: 350px;
        height: auto;
    }

    .dashboard-review-card {
        width: 100%;
        height: 350px;
        height: 100%;
    }

    .business-modal-container {
        width: 90%;
    }

    .business-modal-history-container {
        width: 90%;
    }

    div#drop-area {
        margin-top: 0;
    }

    .connectButton {
        width: 50%;
        height: 75px;
    }

    .frontrunnerText {
        font-size: 12px;
        margin: 0 auto;
    }
}

.connectButton {
    border-radius: 15px;
    border: 1px solid #00053c;
    background: white;

    font-family: "Inter", sans-serif;
    font-size: 18px;
    width: 75%;
    height: 50px;
    font-weight: 600;
}

.frontrunnerText {
    font-size: 14px;
    background: white;
    color: #00053c;
    margin: 0 auto;
}

.sync-svg {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 40px;
    top: 10px;
    cursor: pointer;
}

/* Dashboard CSS */
.dashboard-card {
    position: static;
    width: auto;
    height: auto;
    background: #f4f5fa;
    border-radius: 20px;
}

.relative-pos {
    position: relative;
}

.review-journey {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #00053c;
}

.mt-35 {
    margin-top: 35px;
}

.dashboard-view-card {
    width: 239px;
    height: 137px;

    background: #ffffff;
    border-radius: 15px;
    float: left;
    padding: 10px;
    margin: 10px;
}

.dashboard-per-card {
    height: 116px;
}

.dashboard-graph-card {
    width: 500px;
    height: auto;
    background: #ffffff;
    border-radius: 15px;
    padding: 10px;
    float: left;
    margin: 10px;
}

.dashboard-review-count {
    color: #00053c;
}

.dashboard-review-text {
    width: 89.97px;
    height: 12px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #9194b3;
    margin: 15px;
}

.date-color {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.3;
    display: flex;
    align-items: center;
    color: #9194b3;
    margin-left: 10px;
}

.dashboard-review-star {
    width: 94px;
    height: 20px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #00053c;
    margin: 10px;
}

.dashboard-all-perc {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #38cb89;
    margin: 10px;
}

.dashboard-month-perc {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #4285f4;
    margin: 10px;
}

.dashboard-recent-reviews {
    width: 193px;
    height: 14px;
    left: 883px;
    top: 362px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #00053c;
    margin: 10px;
}

.customer-name {
    margin: 10px;
}

.review-star {
    position: absolute;
    right: 10px;
}

.dashboard-customer-review-text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
    display: flex;
    align-items: center;
    color: #9194b3;
}

.review-time {
    position: absolute;
    top: 25px;
    right: 10px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #9194b3;
}

.dashboard-owner-response {
    position: absolute;
    left: 18%;
    right: 12%;
    top: 41.71%;
    bottom: 30.17%;
    background: #f4f5fa;
    border-radius: 15px;
}

/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    .fbStarWidth {
        width: 100%;
    }

    .dashboard-view-card {
        width: 100%;
    }

    .dashboard-graph-card {
        width: 100%;
        height: 350px;
        height: auto;
    }

    .dashboard-review-card {
        width: 100%;
        height: 350px;
        height: 100%;
    }

    .relative-pos {
        width: 100%;
    }

    .form-white.inner-white {
        margin-left: 0 !important;
    }

    .form-outline.form-white.inner-white {
        margin-left: 10px !important;
    }

    .inner-white {
        padding: 10px 0;
    }

    .action_btn-platform-wrap {
        justify-content: center;
    }

    .action_btn-platform {
        flex: 0 0 100%;
    }
}

.inner-head-wrap {
    display: flex;
    display: -ms-flexbox;
    align-items: baseline;
}

.inner-head-wrap .data-h1 {
    padding-right: 10px;
}

.data-h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #00053c;
}

.buttons-platform {
    width: 200px;
    margin: 0 auto;
    display: inline;
}

.action_btn-platform-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.action_btn-platform {
    /* width: 200px; */
    /* margin: 20px auto 0; */
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    color: #ffffff;
    height: 40px;
    border-radius: 10px;
    margin: 10px 0;
}

.action_btn_color__connected {
    background: #38cb89;
}

.action_btn_color__disconnected {
    background: #ff8c00;
}

.platform__connected {
    color: #38cb89;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 29px;
    display: flex;
    align-items: center;
    letter-spacing: 1px;
}

#editorid .ql-editor {
    width: 100%;
    height: 150px;
    background: #f4f5fa;
    border-radius: 15px;
}

.feeback-action {
    border-radius: 20px;
    background: #f4f5fa;
}

.dashboard-review-card {
    width: 100%;
    background: #ffffff;
    border-radius: 15px;
    padding: 10px;
    margin: 10px;
}

.view-more-btn {
    border-radius: 15px;
    border: 1px solid #00053c;
    background: white;

    font-family: "Inter", sans-serif;
    font-size: 18px;
    width: 50%;
    /* height: 50px; */
    font-weight: 600;
    margin: 5px auto;
}

.business-modal-container img {
    word-break: break-all;
    word-wrap: break-word;
}

.f-start {
    align-self: flex-start;
}

.fbStarWidth {
    width: 100px;
    margin: 0;
}

.imgBtnCenter {
    margin: 0 auto;
}

:deep(.v-step) {
    background: #D7E9F7 !important;
    color: #000 !important;
    box-shadow: none !important;
    /* max-width: none !important; */
    width: 450px !important;
    max-width: 500px !important;
    border-radius: 15px !important;
    z-index: 1 !important;
}

:deep(.v-step__button) {
    font-family: "Inter", sans-serif !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 29px !important;
    background: #00053c !important;
    color: #ffffff !important;
    width: 125px !important;
    height: 40px !important;
    border-radius: 15px !important;
}

.squareWizard {
    cursor: pointer;
    border: 4px solid red;
}

:deep(.vue-para) {
    font-size: 16px
}

.hiddenimg {
    display: none;
}

.hiddentxt {
    font-weight: bold;
    color: #F00;
    z-index: 99;
}

.hiddentxt a {
    color: #F00;
    text-decoration: none;
    z-index: 99;
}

.hiddenclick {
    font-weight: bold;
    color: #F00;
    text-decoration: none;
    cursor: pointer;
}

.hiddenclick a {
    color: #F00;
    text-decoration: none;
}

.hiddenclick a:visited {
    color: #F00;
    text-decoration: none;
}

.hiddentxt:hover~.hiddenimg {
    display: block;
    position: absolute;
    z-index: 2
}

.hiddenclickimg {
    display: none;

}

.formInput {
    margin-right: 10px;
    position: absolute;
    padding: 15px;
    min-width: 40px;
    text-align: center;
}

.reportStatistics {
    background-color: white;
    margin: 10px;
    border-radius: 20px;
    width: 280px;
    padding: 20px;
}

.reportGraph {
    background-color: white;
    border-radius: 20px;
}

.drop-btn {
    font-family: "Roboto", sans-serif;
    width: 114px;
    height: 43px;
    background-color: #f4f5fa;
    margin: 10px 31px;
    border-radius: 15px;
    color: #00053c;
    font-size: 14px;
    font-weight: 500;
}

.drop-btn2 {
    font-family: "Roboto", sans-serif;
    /* background-color: #F4F5FA;    */
    color: #00053c;
    font-size: 14px;
    font-weight: 500;
}

.cardTitle {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.3;
    color: #9194b3;
    margin-bottom: 25px;
}

.inner-head-wrap {
    display: flex;
    display: -ms-flexbox;
    align-items: baseline;
}

.inner-head-wrap .data-h1 {
    padding-right: 10px;
}

.center-item {
    display: flex;
    justify-content: center;
    align-items: center;
}

.space-around-item {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.data-per {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.3;
    margin: 0;
    margin-right: 5px;
}

.data-plus {
    color: #38cb89;
}

.data-minus {
    color: #fa4444;
}

.data-h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 1.3;
    color: #00053c;
}

.date-color {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.3;
    color: #b0b7c3;
    margin: 0;
}

.export-ui {
    background: #f4f5fa;
    border-radius: 15px;
    margin: 15px;
    font-family: "Inter";
    font-weight: 600;
    font-size: 16px;
    line-height: 1.3;
    color: #00053c;
}

.report-type {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 14px !important;
    line-height: 1.3;
    color: #00053c;
}

.inputOrange:focus {
    outline: none !important;
    border: 1.5px solid #ff8c00;
    box-shadow: none;
}

.report-change {
    height: 43px;
    top: 500px;
    background: #f4f5fa;
    border: 1px solid #f3f3f3;
    border-radius: 15px;
    margin: 10px;
}

.table-align {
    margin-top: 15px;
    border-radius: 36px;
    margin-left: 10px;
}

.delivery-header {
    left: 370px;
    top: 500px;
    font-family: "Inter";
    font-weight: 500;
    font-size: 14px;
    line-height: 1.3;
    display: flex;
    align-items: center;
    color: #9194b3;
}

.delivery-data {
    width: 65px;
    height: 24px;
    left: 350px;
    top: 521px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #00053c;
}

.fcircle {
    background: #fa4444;
    clip-path: circle(50%);
    height: 14px;
    width: 14px;
}

.dcircle {
    background: #38cb89;
    clip-path: circle(50%);
    height: 14px;
    width: 14px;
}

.scircle {
    background: #f9c70a;
    clip-path: circle(50%);
    height: 14px;
    width: 14px;
}

.progress {
    height: 20px;
    width: 66%;
    border-radius: 10px;
    display: flex;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: #f4f5fa;
    padding: 0px;
    margin-top: 20px;
    box-shadow: none;
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    transition: width 0.6s ease;
}

.deliver-bar {
    background: linear-gradient(270deg,
            #4285f4 0%,
            rgba(66, 133, 244, 0.35) 100%);
}

.click-bar {
    background: linear-gradient(270deg,
            #6789c1 0%,
            rgba(137, 176, 240, 0.35) 100%);
}

.open-bar {
    background: linear-gradient(270deg,
            #fdd640 0%,
            rgba(253, 214, 64, 0.35) 100%);
}

.p25 {
    padding: 25px;
}

.cards {
    background: #f4f5fa;
    border-radius: 15px;
    padding: 0px 15px;
    height: 400px;
}

/* style start */
.card-header h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600 !important;
    font-size: 32px;
    color: #00053c;
}

.report-chart-wrap {
    margin: 10px 5px;
}

.action-wrap .report-change,
.action-wrap button.drop-btn {
    margin: 0 !important;
    width: 180px !important;
    margin-left: 15px !important;
}

/* cards-style */
.custom-card-wrapper {
    display: flex;
    display: -ms-flexbox;
}

.custom-card {
    width: 50%;
    padding: 0 12px;
}

.custom-inner {
    background: rgb(244, 245, 250);
    border-radius: 15px;
    padding: 20px;
}

ul.status-list {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    display: -ms-flexbox;
    align-items: center;
}

ul.status-list li {
    width: 33.333%;
}

.inner-card {
    background-color: #fff;
    border-radius: 20px;
    padding: 20px 25px;
}

.inner-card hr {
    background-color: #f4f5fa;
    margin: 13px 0;
}

/* responsive start */
@media (max-width: 1198.98px) {
    .custom-card-wrapper div#main {
        width: auto !important;
    }
}

@media (max-width: 767.98px) {
    .heading-wrap>.col-6 {
        width: 100%;
        flex-wrap: wrap;
        justify-content: flex-start !important;
    }

    button#dropdownMenuButton1 {
        margin: 10px;
    }

    .file-wrap {
        flex-wrap: wrap;
    }

    .file-wrap .action-btns button {
        margin: 0;
        margin-bottom: 10px;
        width: 200px;
        display: block !important;
    }

    .reportStatistics {
        width: 90%;
    }

    .custom-card-wrapper {
        display: block;
    }

    .custom-card {
        width: 100%;
        margin-bottom: 20px;
        padding: 0;
    }

    .progress {
        width: 100%;
    }

    .card-header h2 {
        margin-bottom: 10px !important;
    }

    .card-header h2 {
        margin-bottom: 10px !important;
    }

    .action-wrap .report-change,
    .action-wrap button.drop-btn {
        margin: 0 !important;
        margin-bottom: 10px !important;
        width: 150px !important;
        margin-right: 10px !important;
    }

    .heading-wrap.mb-5 {
        margin-bottom: 0px !important;
    }

    .table-align {
        margin: 0 !important;
    }
}

.yearSelectDropdown {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px !important;
    /* font-size: 16px !important; */
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #9194b3;
}

.yearSelect {
    height: 50px;
    left: 889px;
    top: 413px;
    /* background: #ffffff; */
    border: 1px solid #f3f3f3;
    box-sizing: border-box;
    border-radius: 15px;
    width: 100%;
    padding: 10px;
    margin: auto 10px;
}

.dropdownMargin {
    margin: 10px;
}
</style>
