<template>
    <div>
        <div v-if="apiLoading" class="loading">
            Loading&#8230;
        </div>

        <div class="d-flex flex-column flex-lg-row h-lg-full navOutDiv">
            <!-- Vertical Navbar -->

            <Menu></Menu>
            <!-- Main content -->
            <div class="h-screen flex-grow-1 overflow-y-lg-auto m-4 dashScroll">
                <!-- Header -->
                <Header></Header>
                <transition name="modal" v-if="tour">
                    <v-tour name="myTour" :steps="steps" :callbacks="myCallbacks">
                        <template slot-scope="tour" style="background: #fff; color: #000">
                            <transition name="fade">
                                <v-step class="v-step" v-if="tour.steps[tour.currentStep]" :key="tour.currentStep"
                                    :step="tour.steps[tour.currentStep]" :previous-step="tour.previousStep"
                                    :next-step="tour.nextStep" :stop="tour.stop" :skip="tour.skip"
                                    :is-first="tour.isFirst" :is-last="tour.isLast" :labels="tour.labels"
                                >
                                    <div slot="content">
                                        <h1>Welcome to Reviewzy! <img @click="tour.stop"
                                                src="resources/assets/crossicon.png" /></h1>
                                        <p class="vue-para"> You are just a few steps away from improving your
                                            online reputation and
                                            gaining more positive reviews. Follow the steps to easily set up your
                                            account and begin sending
                                            reviews!.</p>
                                    </div>
                                    <div slot="actions">
                                        <button @click="tour.stop" class="tourButton">Maybe Later</button>
                                        <button @click="tour.nextStep" class="tourButton">Get Started</button>
                                    </div>
                                </v-step>
                            </transition>
                        </template>
                    </v-tour>
                </transition>
                <div>
                    <main class="py-6">
                        <div class="container">
                            <div id="v-step-0"></div>
                            <div class="v-step-0"></div>
                            <form class="d-block d-flex connectMessage" v-if="googleRecentReview.length == 0 && fbRecentReview.length == 0 && yelpRecentReview.length == 0 && zillowRecentReview.length == 0" >
                                <div class="card border-0 mb-7 table-align" id="userForDiv" style="width: 100vw;">
                                    <div class="table-outer-wrap">
                                        <div class="inner-white-wrap">
                                            <div class="table-responsive">
                                                <h1 v-if="isAdmin == true">
                                                    No Platform Connected, Please first connect platform to see dashboard data.
                                                </h1>
                                                <h1 v-else>You are logged in!</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <!-- Card stats -->
                            <div class="row g-6 mb-6" v-if="googleRecentReview.length > 0">
                                <div class="col-xl-3 col-sm-6 col-12 dashboard-card">
                                    <div>
                                        <div class="col-md-5 pt-4">
                                            <p class="review-journey">Review Journey</p>
                                        </div>
                                        <div class="col-md-7 pt-4">
                                            <div class="card-body text-center rating-wrap">
                                                <img src="resources/assets/googleLogo.png" />
                                                <p>
                                                    <img v-for="index in 5" :key="index + 'yellow'" src="resources/assets/yellowStar.png" />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-12">
                                                    <div class="dashboard-view-card dv-card">
                                                        <h2 class="dashboard-review-text"><span class="dashboard-review-count">{{ googleAllReview ? googleAllReview : "" }}</span>&nbsp;Reviews</h2>
                                                        <div class="inner-head-wrap">
                                                            <p class="dashboard-review-star">
                                                                <img src="resources/assets/yellowStar.png" />
                                                                &nbsp;{{ googleAllReviewRating ? googleAllReviewRating : "" }}
                                                            </p>
                                                        </div>
                                                        <p class="date-color">
                                                            Started: {{ googleStarted }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-12">
                                                    <div class="dashboard-view-card dv-card">
                                                        <h2 class="dashboard-review-text"><span class="dashboard-review-count">{{ googleCurrentReview ? googleCurrentReview : "" }}</span>&nbsp;Reviews This Period</h2>
                                                        <div class="inner-head-wrap">
                                                            <p class="dashboard-review-star">
                                                                <img src="resources/assets/yellowStar.png" />
                                                                &nbsp;{{ googleCurrentReviewRating ? googleCurrentReviewRating : "" }}
                                                            </p>
                                                        </div>
                                                        <p class="date-color">Current</p>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-12">
                                                    <div class="dashboard-view-card dashboard-per-card dv-card">
                                                        <div class="inner-head-wrap">
                                                            <p class="dashboard-all-perc">
                                                                {{ googleAllTime }}
                                                            </p>
                                                        </div>
                                                        <p class="date-color">All Time Growth</p>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-12">
                                                    <div class="dashboard-view-card dashboard-per-card dv-card">
                                                        <div class="inner-head-wrap">
                                                            <p class="dashboard-month-perc">
                                                                {{ googleThisMonth }}
                                                            </p>
                                                        </div>
                                                        <p class="date-color">Growth This Period</p>
                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="dashboard-graph-card dv-card">
                                                        <div class="col-12 mb-4 mb-sm-0">
                                                            <p class="dashboard-recent-reviews" style="margin: 10px;">
                                                                New Reviews
                                                            </p>
                                                        </div>
                                                        <div id="chart">
                                                            <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
                                                            <button type="button" style="margin: 15px auto;" class="d-flex view-more-btn" @click="showReport('google')">
                                                                <a style="margin: 0 auto; text-decoration: none;">
                                                                    <p style="background: white; color: #00053c; margin: 10px auto;">
                                                                        View More
                                                                    </p>
                                                                </a>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-5">
                                            <div class="dashboard-review-card dv-card">
                                                <div class="col-12 mb-4 mb-sm-0">
                                                    <p class="dashboard-recent-reviews" style="margin: 10px;">
                                                        Recent Reviews
                                                    </p>
                                                </div>

                                                <div class="row" v-for="googleReview in googleRecentReview" :key="googleReview.id">
                                                    <div class="col-12 d-flex">
                                                        <div v-if="googleReview.reviewAuthorImage">
                                                            <img style="margin: 10px;" alt="..." :src="googleReview.reviewAuthorImage" class="avatar avatar-xl rounded-circle me-2 loginPic" />
                                                        </div>

                                                        <div>
                                                            <div class="customer-name form-outline form-white inner-white">
                                                                <h3 class="heading">
                                                                    {{ googleReview.reviewAuthor ? googleReview.reviewAuthor : "" }}
                                                                </h3>
                                                            </div>
                                                        </div>
                                                        <div class="col-6" style="position: relative;">
                                                            <h3 class="heading review-star">
                                                                <img
                                                                    v-for="index in parseInt(
                                    googleReview.reviewRating
                                  )"
                                                                    :key="index + 'yello'"
                                                                    src="resources/assets/yellowStar.png"
                                                                />
                                                                <img
                                                                    v-for="index in 5 -
                                  parseInt(googleReview.reviewRating)"
                                                                    :key="index + 'blank'"
                                                                    src="resources/assets/blankStar.png"
                                                                />
                                                            </h3>
                                                            <p class="review-time">{{ googleReview.reviewDate }}</p>
                                                        </div>
                                                    </div>

                                                    <div class="col-12 mb-4 mb-sm-0 d-flex">
                                                        <div class="form-outline form-white inner-white" style="margin-left: 20%;"></div>
                                                        <div class="form-outline form-white">
                                                            <p class="communicationFormLabel">
                                                                {{ googleReview.reviewText ? googleReview.reviewText : "" }}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <!-- <div class="card border-0 feeback-action col-9" style="margin-left: auto; margin-right: 20px">
                              <div class="mb-4 mb-sm-0 d-flex">
                                <div class="form-outline form-white" style="width: 49%; padding: 10px">
                                  <p class="communicationFormLabel">2 Reviews</p>
                                </div>
                                <div class="form-outline form-white" style="width: 51%; padding: 10px">
                                  <p class="communicationFormLabel" style="justify-content: end">
                                    a week ago
                                  </p>
                                </div>
                              </div>
                              <div class="mb-4 mb-sm-0 d-flex">
                                <div class="form-outline form-white">
                                  <p class="heading">Response from the owner</p>
                                </div>
                              </div>
                              <div class="mb-4 mb-sm-0 d-flex">
                                <div class="form-outline form-white">
                                  <p class="communicationFormLabel">
                                    Lorem ipsum dolor sit, amet consectetur
                                    adipisicing elit. Quam in reprehenderit neque
                                    odit quas fuga itaque, nihil aliquid quasi
                                    modi architecto, vitae eveniet ratione
                                    officia.
                                  </p>
                                </div>
                              </div>
                            </div> -->
                                                </div>

                                                <button type="button" style="margin: 15px auto;" class="d-flex view-more-btn">
                                                    <a style="margin: 0 auto; text-decoration: none;" :href="googleView" target="_blank">
                                                        <p style="background: white; color: #00053c; margin: 10px auto;">
                                                            View on Google
                                                        </p>
                                                    </a>
                                                </button>
                                                <hr size="2" />
                                            </div>
                                        </div>
                                    </div>
                                    <button @click="showReport()" type="button" style="margin: 15px auto;" class="d-flex platFormCardButton">
                                        <p class="buttonText" style="margin: 10px auto;">
                                            View Full Report
                                        </p>
                                    </button>
                                </div>
                            </div>

                            <div class="row g-6 mb-6" v-if="fbRecentReview.length > 0">
                                <div class="col-xl-3 col-sm-6 col-12 dashboard-card">
                                    <div>
                                        <div class="col-md-5 pt-4">
                                            <p class="review-journey">Review Journey</p>
                                        </div>
                                        <div class="col-md-7 pt-4">
                                            <div class="card-body text-center rating-wrap">
                                                <img src="resources/assets/facebookLogo.png" />
                                                <p>
                                                    <img v-for="index in 5" :key="index + 'fb'" src="resources/assets/fb-star.svg" />
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-12">
                                                    <div class="dashboard-view-card dv-card">
                                                        <h2 class="dashboard-review-text"><span class="dashboard-review-count">{{ fbAllReview }}</span>&nbsp;Reviews</h2>
                                                        <div class="inner-head-wrap">
                                                            <p>
                                                                <span class="fbRatingStar"> <img src="resources/assets/rate_star_icon.svg" />&nbsp; {{ fbAllReviewRating ? fbAllReviewRating : 0 }} &nbsp;</span>
                                                            </p>
                                                            <p class="dashboard-review-text">out of &nbsp;<span class="dashboard-review-count">5</span></p>
                                                        </div>
                                                        <p class="date-color">
                                                            Started: {{ fbStarted ? fbStarted : "" }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-12">
                                                    <div class="dashboard-view-card dv-card">
                                                        <h2 class="dashboard-review-text"><span class="dashboard-review-count">{{ fbCurrentReview }}</span>&nbsp;Reviews This Period</h2>
                                                        <div class="inner-head-wrap">
                                                            <p>
                                                                <span class="fbRatingStar"> <img src="resources/assets/rate_star_icon.svg" />&nbsp; {{ fbCurrentReviewRating ? fbCurrentReviewRating : 0 }} &nbsp;</span>
                                                            </p>
                                                            <p class="dashboard-review-text">out of &nbsp;<span class="dashboard-review-count">5</span></p>
                                                        </div>
                                                        <p class="date-color">Current</p>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-12">
                                                    <div class="dashboard-view-card dashboard-per-card dv-card">
                                                        <div class="inner-head-wrap">
                                                            <p class="dashboard-all-perc">{{ fbAllTime }}</p>
                                                        </div>
                                                        <p class="date-color">All Time Growth</p>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-12">
                                                    <div class="dashboard-view-card dashboard-per-card dv-card">
                                                        <div class="inner-head-wrap">
                                                            <p class="dashboard-month-perc">
                                                                {{ fbThisMonth }}
                                                            </p>
                                                        </div>
                                                        <p class="date-color">Growth This Period</p>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="dashboard-graph-card dv-card">
                                                        <div class="col-12 mb-4 mb-sm-0">
                                                            <p class="dashboard-recent-reviews" style="margin: 10px;">
                                                                New Reviews
                                                            </p>
                                                        </div>
                                                        <div id="chart">
                                                            <apexchart type="line" height="350" :options="chartOptions_fb" :series="series_fb"> </apexchart>

                                                            <button type="button" style="margin: 15px auto;" class="d-flex view-more-btn" @click="showReport('facebook')">
                                                                <a style="margin: 0 auto; text-decoration: none;">
                                                                    <p style="background: white; color: #00053c; margin: 10px auto;">
                                                                        View More
                                                                    </p>
                                                                </a>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-5">
                                            <div class="dashboard-review-card dv-card">
                                                <div class="col-12 mb-4 mb-sm-0">
                                                    <p class="dashboard-recent-reviews" style="margin: 10px;">
                                                        Recent Reviews
                                                    </p>
                                                </div>

                                                <!-- <div class="row d-flex">
                            <div class="inner-head-wrap">
                              <p class="dashboard-review-star">
                                <span class="fbRatingStar" style="width: 100px">
                                  <img src="resources/assets/rate_star_icon.svg" />&nbsp; 4.8 &nbsp;</span>
                              </p>
                              <p class="dashboard-review-text" style="width: 100%">
                                out of &nbsp;<span class="dashboard-review-count">5 &nbsp;</span>•&nbsp;
                                <span class="dashboard-review-count">136 Reviews</span>
                              </p>
                            </div>
                          </div>
                          <hr /> -->
                                                <div class="row" v-for="fbReview in fbRecentReview" :key="fbReview.id">
                                                    <div class="col-md-6 d-flex">
                                                        <div v-if="fbReview.reviewAuthorImage">
                                                            <img style="margin: 10px;" alt="..." :src="fbReview.reviewAuthorImage" class="avatar avatar-xl rounded-circle me-2 loginPic" />
                                                        </div>

                                                        <div class="customer-name form-outline form-white inner-white star-wrapper">
                                                            <h3 class="heading">
                                                                {{ fbReview.reviewAuthor ? fbReview.reviewAuthor : "" }}
                                                            </h3>
                                                        </div>
                                                        <p>
                                                            <span class="fbRatingStar"> <img src="resources/assets/rate_star_icon.svg" />&nbsp; {{ fbReview.reviewRating ? fbReview.reviewRating : 0 }} &nbsp;</span>
                                                        </p>
                                                    </div>
                                                    <div class="col-12 mb-4 mb-sm-0 d-flex">
                                                        <div class="form-outline form-white inner-white" style="margin-left: 5%;"></div>
                                                        <div class="form-outline form-white">
                                                            <p class="communicationFormLabel">
                                                                {{ fbReview.reviewText ? fbReview.reviewText : "" }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <button type="button" style="margin: 15px auto;" class="d-flex view-more-btn">
                                                    <a style="margin: 0 auto; text-decoration: none;" :href="fbView" target="_blank">
                                                        <p style="background: white; color: #00053c; margin: 10px auto;">
                                                            View on Facebook
                                                        </p>
                                                    </a>
                                                </button>
                                                <hr size="2" />
                                            </div>
                                        </div>
                                        <button @click="showReport()" type="button" style="margin: 15px auto;" class="d-flex platFormCardButton">
                                            <p class="buttonText" style="margin: 10px auto;">
                                                View Full Report
                                            </p>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="row g-6 mb-6" v-if="yelpRecentReview.length > 0">
                                <div class="col-xl-3 col-sm-6 col-12 dashboard-card">
                                    <div>
                                        <div class="col-md-5 pt-4">
                                            <p class="review-journey">Review Journey</p>
                                        </div>
                                        <div class="col-md-7 pt-4">
                                            <div class="card-body text-center rating-wrap">
                                                <img src="resources/assets/yellpLogo.png" />
                                                <p>
                                                    <img v-for="index in 5" :key="index + 'yelp'" src="resources/assets/yelp-star.svg" />
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-12">
                                                    <div class="dashboard-view-card dv-card">
                                                        <h2 class="dashboard-review-text"><span class="dashboard-review-count">{{ yelpAllReview }}</span>&nbsp;Reviews</h2>
                                                        <div class="inner-head-wrap">
                                                            <p class="dashboard-review-star"><img src="resources/assets/yelp-star.svg" />&nbsp; {{ yelpAllReviewRating ? yelpAllReviewRating : "" }}</p>
                                                        </div>
                                                        <p class="date-color">Started: {{ yelpStarted }}</p>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-12">
                                                    <div class="dashboard-view-card dv-card">
                                                        <h2 class="dashboard-review-text"><span class="dashboard-review-count">{{ yelpCurrentReview ? yelpCurrentReview : "" }}</span>&nbsp;Reviews This Period</h2>
                                                        <div class="inner-head-wrap">
                                                            <p class="dashboard-review-star"><img src="resources/assets/yelp-star.svg" />&nbsp; {{ yelpCurrentReviewRating ? yelpCurrentReviewRating : "" }}</p>
                                                        </div>
                                                        <p class="date-color">Current</p>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-12">
                                                    <div class="dashboard-view-card dashboard-per-card dv-card">
                                                        <div class="inner-head-wrap">
                                                            <p class="dashboard-all-perc">
                                                                {{ yelpAllTime }}
                                                            </p>
                                                        </div>
                                                        <p class="date-color">All Time Growth</p>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-12">
                                                    <div class="dashboard-view-card dashboard-per-card dv-card">
                                                        <div class="inner-head-wrap">
                                                            <p class="dashboard-month-perc">
                                                                {{ yelpThisMonth }}
                                                            </p>
                                                        </div>
                                                        <p class="date-color">Growth This Period</p>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="dashboard-graph-card dv-card">
                                                        <div class="col-12 mb-4 mb-sm-0">
                                                            <p class="dashboard-recent-reviews" style="margin: 10px;">
                                                                New Reviews
                                                            </p>
                                                        </div>
                                                        <div id="chart">
                                                            <apexchart type="line" height="350" :options="chartOptions_yelp" :series="series_yelp"> </apexchart>
                                                            <button type="button" style="margin: 15px auto;" class="d-flex view-more-btn" @click="showReport('yelp')">
                                                                <a style="margin: 0 auto; text-decoration: none;">
                                                                    <p style="background: white; color: #00053c; margin: 10px auto;">
                                                                        View More
                                                                    </p>
                                                                </a>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-5 dv-card">
                                            <div class="dashboard-review-card">
                                                <div class="col-12 mb-4 mb-sm-0">
                                                    <p class="dashboard-recent-reviews" style="margin: 10px;">
                                                        Recent Reviews
                                                    </p>
                                                </div>

                                                <div class="row" v-for="yelpReview in yelpRecentReview" :key="yelpReview.id">
                                                    <div class="col-md-6 d-flex">
                                                        <div v-if="yelpReview.reviewAuthorImage">
                                                            <img style="margin: 10px;" alt="..." src="" class="avatar avatar-xl rounded-circle me-2 loginPic" />
                                                        </div>

                                                        <div class="customer-name form-outline form-white inner-white star-wrapper">
                                                            <h3 class="heading">
                                                                {{ yelpReview.reviewAuthor ? yelpReview.reviewAuthor : "" }}
                                                            </h3>
                                                            <p>
                                                                <img v-for="index in 5" :key="index + 'yelp'" src="resources/assets/yelp-star.svg" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 mb-4 mb-sm-0 d-flex">
                                                        <div class="form-outline form-white inner-white" style="margin-left: 5%;"></div>
                                                        <div class="form-outline form-white">
                                                            <p class="communicationFormLabel">
                                                                {{ yelpReview.reviewText ? yelpReview.reviewText : "" }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <button type="button" style="margin: 15px auto;" class="d-flex view-more-btn">
                                                    <a style="margin: 0 auto; text-decoration: none;" :href="yelpView" target="_blank">
                                                        <p style="background: white; color: #00053c; margin: 10px auto;">
                                                            View on Yelp
                                                        </p>
                                                    </a>
                                                </button>
                                                <hr size="2" />
                                            </div>
                                        </div>
                                    </div>
                                    <button @click="showReport()" type="button" style="margin: 15px auto;" class="d-flex platFormCardButton">
                                        <p class="buttonText" style="margin: 10px auto;">
                                            View Full Report
                                        </p>
                                    </button>
                                </div>
                            </div>

                            <div class="row g-6 mb-6" v-if="zillowRecentReview.length > 0">
                                <div class="col-xl-3 col-sm-6 col-12 dashboard-card">
                                    <div>
                                        <div class="col-md-5 pt-4">
                                            <p class="review-journey">Review Journey</p>
                                        </div>
                                        <div class="col-md-7 pt-4">
                                            <div class="card-body text-center rating-wrap">
                                                <img src="resources/assets/zillowLogo.png" />
                                                <p>
                                                    <img v-for="index in 5" :key="index + 'zillow'" src="resources/assets/zillow-star.svg" />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-12">
                                                    <div class="dashboard-view-card dv-card">
                                                        <h2 class="dashboard-review-text"><span class="dashboard-review-count">{{ zillowAllReview }}</span>&nbsp;Reviews</h2>
                                                        <div class="inner-head-wrap">
                                                            <p class="dashboard-review-star"><img src="resources/assets/zillow-star.svg" />&nbsp; {{ zillowAllReviewRating ? zillowAllReviewRating : "" }}</p>
                                                        </div>
                                                        <p class="date-color">
                                                            Started: {{ zillowStarted ? zillowStarted : "" }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-12">
                                                    <div class="dashboard-view-card dv-card">
                                                        <h2 class="dashboard-review-text"><span class="dashboard-review-count">{{ zillowCurrentReview ? zillowCurrentReview : "" }}</span>&nbsp;Reviews This Period</h2>
                                                        <div class="inner-head-wrap">
                                                            <p class="dashboard-review-star"><img src="resources/assets/zillow-star.svg" />&nbsp; {{ zillowCurrentReviewRating ? zillowCurrentReviewRating : "" }}</p>
                                                        </div>
                                                        <p class="date-color">Current</p>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-12">
                                                    <div class="dashboard-view-card dashboard-per-card dv-card">
                                                        <div class="inner-head-wrap">
                                                            <p class="dashboard-all-perc">
                                                                {{ zillowAllTime }}
                                                            </p>
                                                        </div>
                                                        <p class="date-color">All Time Growth</p>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-12">
                                                    <div class="dashboard-view-card dashboard-per-card dv-card">
                                                        <div class="inner-head-wrap">
                                                            <p class="dashboard-month-perc">
                                                                {{ zillowThisMonth }}
                                                            </p>
                                                        </div>
                                                        <p class="date-color">Growth This Period</p>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="dashboard-graph-card dv-card">
                                                        <div class="col-12 mb-4 mb-sm-0">
                                                            <p class="dashboard-recent-reviews" style="margin: 10px;">
                                                                New Reviews
                                                            </p>
                                                        </div>
                                                        <div id="chart">
                                                            <apexchart type="line" height="350" :options="chartOptions_zillow" :series="series_zillow"> </apexchart>
                                                            <button type="button" style="margin: 15px auto;" class="d-flex view-more-btn" @click="showReport('zillow')">
                                                                <a style="margin: 0 auto; text-decoration: none;">
                                                                    <p style="background: white; color: #00053c; margin: 10px auto;">
                                                                        View More
                                                                    </p>
                                                                </a>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-5">
                                            <div class="dashboard-review-card dv-card">
                                                <div class="col-12 mb-4 mb-sm-0">
                                                    <p class="dashboard-recent-reviews" style="margin: 10px;">
                                                        Recent Reviews
                                                    </p>
                                                </div>

                                                <div class="row" v-for="zillowReview in zillowRecentReview" :key="zillowReview.id">
                                                    <div class="col-12 d-flex">
                                                        <div v-if="zillowReview.reviewAuthorImage">
                                                            <img style="margin: 10px;" alt="..." src="" class="avatar avatar-xl rounded-circle me-2 loginPic" />
                                                        </div>

                                                        <div class="customer-name form-outline form-white inner-white star-wrapper">
                                                            <h3 class="heading">
                                                                {{ zillowReview.reviewAuthor ? zillowReview.reviewAuthor : "" }}
                                                            </h3>
                                                            <p>
                                                                <img v-for="index in 5" :key="index + 'zillow'" src="resources/assets/zillow-star.svg" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 mb-4 mb-sm-0 d-flex">
                                                        <div class="form-outline form-white inner-white" style="margin-left: 5%;"></div>
                                                        <div class="form-outline form-white">
                                                            <p class="communicationFormLabel">
                                                                {{ zillowReview.reviewText ? zillowReview.reviewText : "" }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <button type="button" style="margin: 15px auto;" class="d-flex view-more-btn">
                                                    <a style="margin: 0 auto; text-decoration: none;" :href="zillowView" target="_blank">
                                                        <p style="background: white; color: #00053c; margin: 10px auto;">
                                                            View on Zillow
                                                        </p>
                                                    </a>
                                                </button>
                                                <hr size="2" />
                                            </div>
                                        </div>
                                    </div>
                                    <button @click="showReport()" type="button" style="margin: 15px auto;" class="d-flex platFormCardButton">
                                        <p class="buttonText" style="margin: 10px auto;">
                                            View Full Report
                                        </p>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    </div>
</template>

<script async defer crossorigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js"></script>
  <script>
  import Vue from "vue";
  import Vuex from "vuex";
  import { mapActions, mapGetters } from "vuex";
  import moment from "moment";
  import VueApexCharts from "vue-apexcharts";
  Vue.use(Vuex);
  import eventHub from './EventHub';
  import Header from "./Header";
  import Menu from "./Menu";
  export default {
    name: "Dashboard",
    components: {
      apexchart: VueApexCharts,
      Menu,
      Header
    },
    data() {
    return {
        steps: [
            {
                target: '#v-step-0',
            },
            {
                target: '#v-step-1',
            }
        ],
        myCallbacks: {
            onNextStep: this.myCustomNextStepCallback,
            onPreviousStep: this.myCustomPreviousStepCallback,
            onStop:this.myCustomStopCallback,
        },
        tour: (localStorage.getItem("loginFirstTime") == 1 && localStorage.getItem("isAdmin") == 1)? true : false,
        moment: moment,
        isActive: false,
        call: true,
        showProfile: "basic",
        show: "dashboard",
        currentReview: [],
        userLoginId: "",
        platForm: "",
        loading: false,
        checkPermission: false,
        apiLoading: false,
        series: [
            {
                name: "Reviews",
                type: "column",
                data: [],
            },
        ],
        series_fb: [
            {
                name: "Reviews",
                type: "column",
                data: [],
            },
        ],
        series_yelp: [
            {
                name: "Reviews",
                type: "column",
                data: [],
            },
        ],
        series_zillow: [
            {
                name: "Reviews",
                type: "column",
                data: [],
            },
        ],
        chartOptions: {
            plotOptions: {
                bar: {
                    columnWidth: "12%",
                    startingShape: "round",
                    endingShape: "round",
                    borderRadius: 8,
                    dataLabels: {
                        position: "top", // top, center, bottom
                    },
                },
            },
            colors: ["#FA5244", "#6A8FCD"],
            chart: {
                height: 350,
                type: "line",
                toolbar: {
                show: false,
                    offsetX: 0,
                    offsetY: 0,
                },
            },
            stroke: {
                show: true,
                width: [0, 4],
                curve: "smooth",
            },
            title: {
                text: "",
            },
            dataLabels: {
                enabled: true,
                enabledOnSeries: [1],
                distributed: false,
                background: {
                    enabled: true,
                    foreColor: "#fff",
                    padding: 4,
                    borderRadius: 2,
                    borderWidth: 1,
                    borderColor: "#6A8FCD",
                    opacity: 0.9,
                },
            },
            fill: {
                colors: ["#FA5144"],
                type: "gradient",
                gradient: {
                    shade: "dark",
                    type: "vertical",
                    shadeIntensity: 0.5,
                    gradientToColors: ["#FDD640"],
                    inverseColors: true,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 100],
                },
            },
            xaxis: {
                categories: [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                ],
            },
            yaxis: [
                {
                    title: {
                        text: "Reviews",
                    },
                },
            ],
            markers: {
                size: 10,
                colors: "#6A8FCD",
                strokeColors: "#6A8FCD",
                strokeWidth: 2,
                strokeOpacity: 0.9,
                strokeDashArray: 0,
                fillOpacity: 1,
                shape: "circle",
                radius: 4,
                offsetX: 0,
                offsetY: 0,
                onClick: function (e) {
                // do something on marker click
                console.log("e", e);
                },
            },
            legend: {
                show: true,
            },
            tooltip: {
                shared: false,
                intersect: true,
                enabled: true,
                marker: {
                    show: true,
                },
            },
        },
        chartOptions_fb: {
            plotOptions: {
                bar: {
                    columnWidth: "12%",
                    startingShape: "round",
                    endingShape: "round",
                    borderRadius: 8,
                    dataLabels: {
                        position: "top", // top, center, bottom
                    },
                },
            },
            colors: ["#FA5244", "#6A8FCD"],
            chart: {
                height: 350,
                type: "line",
                toolbar: {
                    show: false,
                    offsetX: 0,
                    offsetY: 0,
                },
            },
            stroke: {
                show: true,
                width: [0, 4],
                curve: "smooth",
            },
            title: {
                text: "",
            },
            dataLabels: {
                enabled: true,
                enabledOnSeries: [1],
                distributed: false,
                background: {
                enabled: true,
                foreColor: "#fff",
                padding: 4,
                borderRadius: 2,
                borderWidth: 1,
                borderColor: "#6A8FCD",
                opacity: 0.9,
                },
            },
            fill: {
                colors: ["#FA5144"],
                type: "gradient",
                gradient: {
                    shade: "dark",
                    type: "vertical",
                    shadeIntensity: 0.5,
                    gradientToColors: ["#FDD640"],
                    inverseColors: true,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 100],
                },
            },
            xaxis: {
                categories: [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                ],
            },
            yaxis: [
                {
                    title: {
                        text: "Reviews",
                    },
                },
            ],
            markers: {
                size: 10,
                colors: "#6A8FCD",
                strokeColors: "#6A8FCD",
                strokeWidth: 2,
                strokeOpacity: 0.9,
                strokeDashArray: 0,
                fillOpacity: 1,
                shape: "circle",
                radius: 4,
                offsetX: 0,
                offsetY: 0,
                onClick: function (e) {
                    // do something on marker click
                    console.log("e", e);
                },
            },
            legend: {
                show: true,
            },
            tooltip: {
                shared: false,
                intersect: true,
                enabled: true,
                marker: {
                    show: true,
                },
            },
        },
        chartOptions_yelp: {
            plotOptions: {
                bar: {
                    columnWidth: "12%",
                    startingShape: "round",
                    endingShape: "round",
                    borderRadius: 8,
                    dataLabels: {
                        position: "top", // top, center, bottom
                    },
                },
            },
            colors: ["#FA5244", "#6A8FCD"],
            chart: {
                height: 350,
                type: "line",
                toolbar: {
                    show: false,
                    offsetX: 0,
                    offsetY: 0,
                },
            },
            stroke: {
                show: true,
                width: [0, 4],
                curve: "smooth",
            },
            title: {
                text: "",
            },
            dataLabels: {
                enabled: true,
                enabledOnSeries: [1],
                distributed: false,
                background: {
                    enabled: true,
                    foreColor: "#fff",
                    padding: 4,
                    borderRadius: 2,
                    borderWidth: 1,
                    borderColor: "#6A8FCD",
                    opacity: 0.9,
                },
            },
            fill: {
                colors: ["#FA5144"],
                type: "gradient",
                gradient: {
                    shade: "dark",
                    type: "vertical",
                    shadeIntensity: 0.5,
                    gradientToColors: ["#FDD640"],
                    inverseColors: true,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 100],
                },
            },
            xaxis: {
                categories: [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                ],
            },
            yaxis: [
                {
                    title: {
                        text: "Reviews",
                    },
                },
            ],
            markers: {
                size: 10,
                colors: "#6A8FCD",
                strokeColors: "#6A8FCD",
                strokeWidth: 2,
                strokeOpacity: 0.9,
                strokeDashArray: 0,
                fillOpacity: 1,
                shape: "circle",
                radius: 4,
                offsetX: 0,
                offsetY: 0,
                onClick: function (e) {
                    // do something on marker click
                    console.log("e", e);
                },
            },
            legend: {
                show: true,
            },
            tooltip: {
                shared: false,
                intersect: true,
                enabled: true,
                marker: {
                    show: true,
                },
            },
        },
        chartOptions_zillow: {
            plotOptions: {
                bar: {
                    columnWidth: "12%",
                    startingShape: "round",
                    endingShape: "round",
                    borderRadius: 8,
                    dataLabels: {
                        position: "top", // top, center, bottom
                    },
                },
            },
            colors: ["#FA5244", "#6A8FCD"],
            chart: {
                height: 350,
                type: "line",
                toolbar: {
                    show: false,
                    offsetX: 0,
                    offsetY: 0,
                },
            },
            stroke: {
                show: true,
                width: [0, 4],
                curve: "smooth",
            },
            title: {
                text: "",
            },
            dataLabels: {
                enabled: true,
                enabledOnSeries: [1],
                distributed: false,
                background: {
                    enabled: true,
                    foreColor: "#fff",
                    padding: 4,
                    borderRadius: 2,
                    borderWidth: 1,
                    borderColor: "#6A8FCD",
                    opacity: 0.9,
                },
            },
            fill: {
                colors: ["#FA5144"],
                type: "gradient",
                gradient: {
                    shade: "dark",
                    type: "vertical",
                    shadeIntensity: 0.5,
                    gradientToColors: ["#FDD640"],
                    inverseColors: true,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 100],
                },
            },
            xaxis: {
                categories: [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                ],
            },
            yaxis: [
                {
                    title: {
                        text: "Reviews",
                    },
                },
            ],
            markers: {
                size: 10,
                colors: "#6A8FCD",
                strokeColors: "#6A8FCD",
                strokeWidth: 2,
                strokeOpacity: 0.9,
                strokeDashArray: 0,
                fillOpacity: 1,
                shape: "circle",
                radius: 4,
                offsetX: 0,
                offsetY: 0,
                onClick: function (e) {
                    // do something on marker click
                    console.log("e", e);
                },
            },
            legend: {
                show: true,
            },
            tooltip: {
                shared: false,
                intersect: true,
                enabled: true,
                marker: {
                    show: true,
                },
            },
        },
        googleAllReview: "",
        googleAllReviewRating: "",
        googleStarted: "",
        googleCurrentReview: "",
        googleCurrentReviewRating: "",
        googleRecentReview: [],
        googleAllTime: "",
        googleView: '',
        googleThisMonth: "",
        fbAllReview: "",
        fbAllReviewRating: "",
        fbStarted: "",
        fbCurrentReview: "",
        fbCurrentReviewRating: "",
        fbRecentReview: [],
        fbAllTime: "",
        fbThisMonth: "",
        fbView: "",
        yelpAllReview: "",
        yelpAllReviewRating: "",
        yelpStarted: "",
        yelpCurrentReview: "",
        yelpCurrentReviewRating: "",
        yelpRecentReview: [],
        yelpAllTime: "",
        yelpThisMonth: "",
        yelpView: "",
        zillowAllReview: "",
        zillowAllReviewRating: "",
        zillowStarted: "",
        zillowCurrentReview: "",
        zillowCurrentReviewRating: "",
        zillowRecentReview: [],
        zillowAllTime: "",
        zillowThisMonth: "",
        zillowView: "",
        superAdminToken: "",
        filterPlatform: "",
        isAdmin: false,
    };
    },

    methods: {
      ...mapActions({
        createNewUser: "users/createUser",
        userDelete: "users/deleteUser",
        userUpdate: "users/updateUser",
        multipleUserDelete: "users/deleteMultipleUser",
        getAllUserList: "users/getAllUserList",
        getAllReviewList: "reviews/getAllReviewList",
        deleteReviews: "reviews/deleteReview",
        getFeedbackLists: "feedbacks/getFeedbackList",
        feedbackDelete: "feedbacks/deleteFeedback",
        userProfileSettings: "settings/userProfileSettings",
        updateProfiles: "settings/updateProfile",
        billingInfo: "settings/billingInfo",
      }),
      logout() {
        localStorage.removeItem("token");
        localStorage.clear();
        //this.$router.push({ path: "/reviewzy/" });
        this.$router.push({ path: "/" });
      },
      showReport(data){
        this.filterPlatform = data ? data : "";
        console.log("this.filterPlatform",this.filterPlatform);
        this.$router.push('/reports');
      },
      dashboardApiGoogleData() {
        let userID = localStorage.getItem("adminId") != "null" ? localStorage.getItem("adminId") : this.userLoginId;
        let data = {
          platformName: "google",
          userID: userID,
          // userID: this.userLoginId,
          Number: 2,
        };

        this.$store.dispatch("dashboardApi", data).then((response) => {
          let totalReivew = response.TotalCount;
          let currentReview = response.yearly;
          let LastThreeMonths = response.LastThreeMonths;
          let current = response.CurrentMonth;

          this.googleAllReview = totalReivew.count ? totalReivew.count : 0;
          this.googleAllReviewRating = (
            totalReivew.rating / totalReivew.count
          ).toFixed(1);

          this.googleStarted = response.date;

          this.googleCurrentReview = current.monthReviews
            ? current.monthReviews
            : "0";
          this.googleCurrentReviewRating = current.monthAverageRating
            ? current.monthAverageRating
            : "0";

          this.googleAllTime = current.OverPercentageGrowth
            ? `${current.OverPercentageGrowth}%`
            : `0%`;
          this.googleThisMonth = current.thisMonthpPercentageGrowth
            ? `${current.thisMonthpPercentageGrowth}%`
            : `0%`;

          let categoriesData = LastThreeMonths.map((categories) => {
            return categories.Month;
          });
          this.chartOptions = {
            ...this.chartOptions,
            ...{
              xaxis: {
                categories: [...categoriesData],
              },
            },
          };
          this.series[0].data = LastThreeMonths.map((categories) => {
            return categories.count ? categories.count : 0;
          });

          this.googleRecentReview =
            response.reviews.length > 0 ? response.reviews : [];
            this.googleView  = response.url && response.url.googlePlaceID ? response.url.googlePlaceID : ""
        });
      },

      dashboardApiFBData() {
        let userID = localStorage.getItem("adminId") != "null" ? localStorage.getItem("adminId") : this.userLoginId;

        let data = {
          platformName: "facebook",
          userID: userID,
          Number: 2,
        };

        this.$store.dispatch("dashboardApi", data).then((response) => {
          let totalReivew = response.TotalCount;
          let currentReview = response.yearly;
          let LastThreeMonths = response.LastThreeMonths;
          let current = response.CurrentMonth;

          this.fbAllReview = totalReivew.count ? totalReivew.count : 0;
          this.fbAllReviewRating = totalReivew.rating
            ? (totalReivew.rating / totalReivew.count).toFixed(1)
            : 0;

          this.fbStarted = response.date;

          this.fbCurrentReview = currentReview.count;
          this.fbCurrentReviewRating = currentReview.rating
            ? (currentReview.rating / currentReview.count).toFixed(1)
            : 0;

          this.fbAllTime = current.OverPercentageGrowth
            ? `${current.OverPercentageGrowth}%`
            : `0%`;
          this.fbThisMonth = current.thisMonthpPercentageGrowth
            ? `${current.thisMonthpPercentageGrowth}%`
            : `0%`;

          let categoriesData = LastThreeMonths.map((categories) => {
            return categories.Month;
          });
          this.chartOptions_fb = {
            ...this.chartOptions_fb,
            ...{
              xaxis: {
                categories: [...categoriesData],
              },
            },
          };

          this.chartOptions_fb.xaxis.categories = LastThreeMonths.map(
            (categories) => {
              return categories.Month;
            }
          );
          this.series_fb[0].data = LastThreeMonths.map((categories) => {
            return categories.count ? categories.count : 0;
          });

          this.fbRecentReview =
            response.reviews.length > 0 ? response.reviews : [];
            this.fbView  = response.url && response.url.facebook ? response.url.facebook : ""

        });
      },

      dashboardApiYelpData() {
        let userID = localStorage.getItem("adminId") != "null" ? localStorage.getItem("adminId") : this.userLoginId;
        let data = {
          platformName: "yelp",
          userID: userID,
          Number: 2,
        };

        this.$store.dispatch("dashboardApi", data).then((response) => {
          let totalReivew = response.TotalCount;
          let currentReview = response.yearly;
          let LastThreeMonths = response.LastThreeMonths;
          let current = response.CurrentMonth;

          this.yelpAllReview = totalReivew.count ? totalReivew.count : 0;
          this.yelpAllReviewRating = (
            totalReivew.rating / totalReivew.count
          ).toFixed(1);

          this.yelpStarted = response.date;

          this.yelpCurrentReview = current.monthReviews
            ? current.monthReviews
            : "0";
          this.yelpCurrentReviewRating = current.monthAverageRating
            ? current.monthAverageRating
            : "0";

          this.yelpAllTime = current.OverPercentageGrowth
            ? `${current.OverPercentageGrowth}%`
            : `0%`;
          this.yelpThisMonth = current.thisMonthpPercentageGrowth
            ? `${current.thisMonthpPercentageGrowth}%`
            : `0%`;

          let categoriesData = LastThreeMonths.map((categories) => {
            return categories.Month;
          });
          this.chartOptions_yelp = {
            ...this.chartOptions_yelp,
            ...{
              xaxis: {
                categories: [...categoriesData],
              },
            },
          };

          this.series_yelp[0].data = LastThreeMonths.map((categories) => {
            return categories.count ? categories.count : 0;
          });

          this.yelpRecentReview =
            response.reviews.length > 0 ? response.reviews : [];
            this.yelpView  = response.url && response.url.yelpBusinessesId ? response.url.yelpBusinessesId : ""

        });
      },

      dashboardApizillowData() {
        let userID = localStorage.getItem("adminId") != "null" ? localStorage.getItem("adminId") : this.userLoginId;
        let data = {
          platformName: "zillow",
          userID: userID,
          Number: 2,
        };

        this.$store.dispatch("dashboardApi", data).then((response) => {
          let totalReivew = response.TotalCount;
          let currentReview = response.yearly;
          let LastThreeMonths = response.LastThreeMonths;
          let current = response.CurrentMonth;

          this.zillowAllReview = totalReivew.count ? totalReivew.count : 0;
          this.zillowAllReviewRating = (
            totalReivew.rating / totalReivew.count
          ).toFixed(1);

          this.zillowStarted = response.date;

          // this.zillowCurrentReview = currentReview.count;
          // this.zillowCurrentReviewRating = (
          //   currentReview.rating / currentReview.count
          // ).toFixed(1);

          this.zillowCurrentReview = current.monthReviews
            ? current.monthReviews
            : "0";
          this.zillowCurrentReviewRating = current.monthAverageRating
            ? current.monthAverageRating
            : "0";

          this.zillowAllTime = current.OverPercentageGrowth
            ? `${current.OverPercentageGrowth}%`
            : `0%`;
          this.zillowThisMonth = current.thisMonthpPercentageGrowth
            ? `${current.thisMonthpPercentageGrowth}%`
            : `0%`;

          let categoriesData = LastThreeMonths.map((categories) => {
            return categories.Month;
          });

          this.chartOptions_zillow = {
            ...this.chartOptions_zillow,
            ...{
              xaxis: {
                categories: [...categoriesData],
              },
            },
          };

          this.series_zillow[0].data = LastThreeMonths.map((categories) => {
            return categories.count ? categories.count : 0;
          });

          this.zillowRecentReview =
            response.reviews.length > 0 ? response.reviews : [];
            this.zillowView  = response.url && response.url.zillowReviewRequestURL ? response.url.zillowReviewRequestURL : ""

        });
      },
      myCustomPreviousStepCallback(currentStep){
        //
      },
      myCustomNextStepCallback(currentStep){
        this.$router.push({ path: "/settings" });
      },
      myCustomStopCallback(){
        this.tour = false;
        localStorage.setItem("loginFirstTime",0);
        localStorage.setItem("isSetupWizardPlay", 'false');
      },
      startTour(){
        this.tour = true;
        setTimeout(() => {
            this.$tours['myTour'].start();
        }, 300);
      }
    },
    mounted() {
      this.tour ? this.$tours['myTour'].start() : "";
      let token = localStorage.getItem("token");
      this.superAdminToken = localStorage.getItem("adminToken") && localStorage.getItem("change") ? localStorage.getItem("adminToken") : "";
      if (token == "" || token == undefined) {
        this.$router.push({ path: "/" });
      }
      this.userLoginId = localStorage.getItem("id");
      this.isAdmin = localStorage.getItem("isAdmin");
      if (this.isAdmin == true) {
        this.checkPermission = true;
      } else {
        this.checkPermission = false;
      }
      this.dashboardApiGoogleData();
      this.dashboardApiFBData();
      this.dashboardApiYelpData();
      this.dashboardApizillowData();
      console.clear();
      window.dispatchEvent(new Event("resize"));

      if (localStorage.getItem("isSetupWizardPlay") === 'true') {
        this.tour = true;
        setTimeout(() => {
            this.$tours['myTour'].start();
        }, 300);
      }
    },
    created: function () {
      window.fbAsyncInit = function () {
        FB.init({
          appId: "614634686913225",
          cookie: true,
          xfbml: true,
          version: "v14.0",
        });

        //This function should be here, inside window.fbAsyncInit
        FB.getLoginStatus(function (response) {
          console.log(response);
        });
      };

      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = "//connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      })(document, "script", "facebook-jssdk");

      const context = this;
      eventHub.$on('run_setup_wizard', function () {
        context.startTour();
      });
    },

    computed: {},
  };
  </script>
<style scoped>
.heading {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-size: 12px;
    line-height: 24px;
    align-items: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #00053c !important;
    font-weight: 600;
    align-items: center;
}
.row {
    margin-right: 0px;
}
.fbRatingStar {
    color: white;
    padding: 10px 10px;
    padding: 8px 10px 5px;
    background: #1778f2;
    border-radius: 26px;
    background: #1778f2;
    font-weight: 500;
    font-size: 16px;
    margin-left: 15px;
    white-space: nowrap;
}


/* vue2-editior style start */
.form-white>div {
    width: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    background: #f4f5fa;
    border-radius: 15px;
    font-weight: 500;
    font-size: 20px;
    color: #9194b3;
}

.loginPic {
    width: 47px;
    height: 47px;
}

.tourButton {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 29px;
    background: #00053c;
    color: #ffffff;
    width: 125px;
    height: 40px;
    border-radius: 15px;
}

.platFormCardButton {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    /* or 181% */
    background: #00053c;
    color: #ffffff;
    width: 180px;
    height: 51px;
    border-radius: 15px;
}

.communicationFormLabel {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 29px;
    /* or 207% */

    display: flex;
    align-items: center;
    letter-spacing: 1px;

    color: #9194b3;
}

.navOutDiv {
    position: fixed;
    width: 100%;
}

.dashScroll {
    margin-right: 0 !important;
    padding-right: 15px;
}
/* file upload style */
input#file-upload {
    padding-left: 76px;
    font-size: 13px;
}

input#file-upload::after {
    content: "Upload Profile Image";
    position: absolute;
    top: 7px;
    left: 20px;
    background: white;
    width: 150px;
    height: 30px;
    line-height: 30px;
    padding: 0 5px;
    font-size: 14px;
    font-weight: 600;
    color: #68696a;
    border-radius: 10px 0 0 10px;
}

.rating-wrap {
    display: inline-block;
}

.star-wrapper {
    display: inline-flex;
    align-items: center;
    margin: 10px !important;
    padding: 0 !important;
}

.star-wrapper+p {
    display: flex;
    align-items: center;
}



@media (min-width: 1400px) {
}

@media (max-width: 1599.98px) {
}

@media (max-width: 1299.98px) {
}

@media (max-width: 1199.98px) {
}

@media (max-width: 991.98px) {
    .navOutDiv {
        position: unset;
    }

    .dashboard-view-card {
        width: 100%;
    }

    .dashboard-graph-card {
        width: 100%;
        height: 350px;
        height: auto;
    }

    .dashboard-review-card {
        width: 100%;
        height: 350px;
        height: 100%;
    }
}

@media (max-width: 767.98px) {
    main .container {
        max-width: 100%;
    }

    .dashboard-view-card {
        width: 100%;
    }

    .dashboard-graph-card {
        width: 100%;
        height: 350px;
        height: auto;
    }

    .dashboard-review-card {
        width: 100%;
        height: 350px;
        height: 100%;
    }

    .table-outer-wrap { padding: 12px; }
}

/* Dashboard CSS */
.dashboard-card { position: static; width: auto; height: auto; background: #f4f5fa; border-radius: 20px; }
 .relative-pos { position: relative; }
 .review-journey { font-family: "Inter"; font-style: normal; font-weight: 600; font-size: 24px; line-height: 29px; display: flex; align-items: center; color: #00053c; }
 .mt-35 { margin-top: 35px; }
 .dashboard-view-card { width: 239px; height: 137px; background: #ffffff; border-radius: 15px; float: left; padding: 10px; margin: 10px; }
 .dashboard-per-card { height: 116px; }
 .dashboard-graph-card { width: 500px; height: auto; background: #ffffff; border-radius: 15px; padding: 10px; float: left; margin: 10px; }
 .dashboard-review-count { color: #00053c; }
 .dashboard-review-text { /* width: 89.97px; */ height: 12px; font-family: "Inter"; font-style: normal; font-weight: 500; font-size: 14px; line-height: 29px; display: flex; align-items: center; color: #9194b3; margin: 15px; }
 .date-color { font-family: "Inter"; font-style: normal; font-weight: 500; font-size: 14px; line-height: 1.3; display: flex; align-items: center; color: #9194b3; margin-left: 10px; }
 .dashboard-review-star { width: 94px; height: 20px; font-family: "Inter"; font-style: normal; font-weight: 600; font-size: 24px; line-height: 29px; display: flex; align-items: center; color: #00053c; margin: 10px; }
 .dashboard-all-perc { font-family: "Inter"; font-style: normal; font-weight: 600; font-size: 32px; line-height: 29px; display: flex; align-items: center; color: #38cb89; margin: 10px; }
 .dashboard-month-perc { font-family: "Inter"; font-style: normal; font-weight: 600; font-size: 32px; line-height: 29px; display: flex; align-items: center; color: #4285f4; margin: 10px; }
 .dashboard-recent-reviews { width: 193px; height: 14px; left: 883px; top: 362px; font-family: "Inter"; font-style: normal; font-weight: 600; font-size: 20px; line-height: 29px; display: flex; align-items: center; color: #00053c; margin: 10px; }
 .customer-name { margin: 10px; }
 .review-star { position: absolute; right: 10px; }
 .dashboard-customer-review-text { font-family: "Inter"; font-style: normal; font-weight: 500; font-size: 14px; line-height: 27px; display: flex; align-items: center; color: #9194b3; }
 .review-time { position: absolute; top: 25px; right: 10px; font-family: "Inter", sans-serif; font-style: normal; font-weight: 500; font-size: 14px; line-height: 29px; display: flex; align-items: center; color: #9194b3; }
 .dashboard-owner-response { position: absolute; left: 18%; right: 12%; top: 41.71%; bottom: 30.17%; background: #f4f5fa; border-radius: 15px; }
 div#userForDiv { margin: 0; margin-top: 10px; }
 .table-outer-wrap { padding: 30px; background: rgb(244, 245, 250); border-radius: 36px; }
 table td h1 { font-size: 16px; }
 .inner-white-wrap { border-radius: 36px; background: #fff; /* overflow: hidden; */ height: 100%; padding: 15px; }
/* Clear floats after the columns */
.row:after { content: ""; display: table; clear: both; }
.connectMessage { height: 80vh; text-align: center; color: #9194b3; align-items: center; justify-content: center; }

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    .fbStarWidth { width: 100%; }
    .dashboard-view-card { width: 100%; }
    .dashboard-graph-card { width: 100%; height: 350px; height: auto; }
    .dashboard-review-card { width: 100%; height: 350px; height: 100%; }
    .relative-pos { width: 100%; }
    .form-white.inner-white { margin-left: 0 !important; }
    .form-outline.form-white.inner-white { margin-left: 10px !important; }
    .inner-white { padding: 10px 0; }
    .action_btn-platform-wrap { justify-content: center; }
    .action_btn-platform { flex: 0 0 100%; }
}
.inner-head-wrap { display: flex; display: -ms-flexbox; align-items: baseline; }
.inner-head-wrap .data-h1 { padding-right: 10px; }
.data-h1 { font-family: "Inter"; font-style: normal; font-weight: 600; font-size: 32px; line-height: 29px; display: flex; align-items: center; color: #00053c; }
.buttons-platform { width: 200px; margin: 0 auto; display: inline; }
.action_btn-platform-wrap { display: flex; align-items: center; justify-content: space-between; flex-wrap: wrap; }
.action_btn-platform { /* width: 200px; */ /* margin: 20px auto 0; */ display: flex; align-items: center; justify-content: center; font-family: "Inter", sans-serif; font-style: normal; font-weight: 600; font-size: 16px; line-height: 29px; color: #ffffff; height: 40px; border-radius: 10px; margin: 10px 0; }
.action_btn_color__connected { background: #38cb89; }
.action_btn_color__disconnected { background: #ff8c00; }
.platform__connected { color: #38cb89; font-family: "Inter"; font-style: normal; font-weight: 500; font-size: 14px; line-height: 29px; display: flex; align-items: center; letter-spacing: 1px; }
#editorid .ql-editor { width: 100%; height: 150px; background: #f4f5fa; border-radius: 15px; }
.feeback-action { border-radius: 20px; background: #f4f5fa; }
.dashboard-review-card { width: 100%; background: #ffffff; border-radius: 15px; padding: 10px; margin: 10px; }
.view-more-btn { border-radius: 15px; border: 1px solid #00053c; background: white; font-family: "Inter", sans-serif; font-size: 18px; width: 50%; /* height: 50px; */ font-weight: 600; margin: 5px auto; }

:deep(.v-step) { background: #D7E9F7 !important; color: #000 !important; box-shadow: none !important; /* max-width: none !important; */ width: 450px !important; max-width: 500px !important; border-radius: 15px !important; z-index: 1 !important; }
:deep(.v-step__button) { font-family: "Inter", sans-serif !important; font-style: normal !important; font-weight: 600 !important; font-size: 16px !important; line-height: 29px !important; background: #00053c !important; color: #ffffff !important; width: 125px !important; height: 40px !important; border-radius: 15px !important; }
:deep(.vue-para) { font-size: 16px }
</style>
