<template>
  <main class="col-md-12 d-flex justify-content-center">
    <form class="d-block" style="padding-top: 50px">
      <div class="d-flex justify-content-center align-items-center mb-5">
        <h1 class="user-modal-heading">Reset Password</h1>
      </div>

      <div class="mb-4 pb-2">
        <div class="form-outline form-white">
          <input v-model="user.email" type="text" id="form3Examplea4" class="userForm inputOrange"
            placeholder="Email Address" style="padding-left: 25px" v-validate="'required|email'" v-bind:name="'email'"
            v-bind:ref="'email'" :class="{ 'is-invalid': errors.has('email') }" disabled />
        </div>
      </div>

      <div class="mb-4 pb-2">
        <input v-model="user.password" id="form3Examplea5" class="userForm inputOrange" placeholder="Enter Password"
          style="padding-left: 25px" :type="passwordFieldType" v-validate="'required|min:6'" v-bind:name="'password'"
          v-bind:ref="'password'" :class="{ 'is-invalid': errors.has('password') }" @keydown.space.prevent />
        <span @click="switchVisibility()">
          <i :class="passwordIcon" style="font-size: 18px"></i>
        </span>
      </div>

      <div class="mb-4 pb-2">
        <div class="form-outline form-white">
          <input v-model="user.cpassword" :type="cPasswordFieldType" id="form3Examplea2" class="userForm inputOrange"
            placeholder="Confirm your password" style="padding-left: 25px"
            v-validate="'required|min:6|confirmed:password'" v-bind:name="'cpassword'" v-bind:ref="'cpassword'"
            :class="{ 'is-invalid': errors.has('cpassword') }" @keydown.space.prevent />
          <span @click="cSwitchVisibility()">
            <i :class="cPasswordIcon" style="font-size: 18px"></i>
          </span>
        </div>
      </div>
      <div class="mb-4 pb-2">
        <div class="form-outline form-white">
          <input v-model="user.otp" type="text" id="form3Examplea2" class="userForm inputOrange" placeholder="Enter OTP"
            style="padding-left: 25px" maxlength="6" v-validate="'required|min:6|max:6'" v-bind:name="'otp'"
            v-bind:ref="'otp'" :class="{ 'is-invalid': errors.has('otp') }" />
        </div>
      </div>

      <button type="button" class="loginButton spinner-center" @click="updatePassword()" style="height: 45px"
        :disabled="apiLoading == 1">
        <b-spinner v-if="apiLoading" class="spinner-center" variant="primary" label="Spinning"></b-spinner>
        <p v-else style="height: 40px" class="loginButtonText">
          Update Password
        </p>
      </button>
    </form>
  </main>
</template>

<script>
export default {
  name: "ForgotPassword",
  data() {
    return {
      user: {
        email: "",
        password: "",
        cpassword: "",
        otp: "",
      },
      passwordFieldType: "password",
      passwordIcon: "bi bi-eye-slash",
      cPasswordFieldType: "password",
      cPasswordIcon: "bi bi-eye-slash",
      apiLoading: false,
    };
  },

  mounted() {
    this.user.email = localStorage.getItem("email");
  },
  created() { },
  computed: {},
  methods: {
    async updatePassword() {
      let result = await this.$validator.validateAll();
      if (result) {
        this.apiLoading = true;
        let data = {
          email: this.user.email,
          password: this.user.password,
          c_password: this.user.cpassword,
          otp: this.user.otp,
        };
        this.$store
          .dispatch("resetPassword", data)
          .then((response) => {
            if (response.success) {
              this.$toastr.s(`${response.message}`);
              localStorage.clear();
              this.$router.push({ path: "/login" });
            } else {
              this.$toastr.e(`${response.message}`);
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => ((this.apiLoading = false), (this.call = true)));
      }
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.passwordIcon =
        this.passwordIcon === "bi bi-eye-slash"
          ? "bi bi-eye"
          : "bi bi-eye-slash";
    },
    cSwitchVisibility() {
      this.cPasswordFieldType =
        this.cPasswordFieldType === "password" ? "text" : "password";
      this.cPasswordIcon =
        this.cPasswordIcon === "bi bi-eye-slash"
          ? "bi bi-eye"
          : "bi bi-eye-slash";
    },
  },
};
</script>
<style scoped>
.loginButtonText {
  justify-content: center;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  /* or 181% */
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  /* height: 50px; */
}

.loginButton {
  margin-top: 20px;

  right: 53.47%;
  top: 703px;
  bottom: 25.68%;
  width: 100%;
  height: 58px;
  background: #00053c;
  border-radius: 15px;
}

.form-control1 {
  display: inline-block !important;
}

.userForm {
  height: 50px;
  /* height: 58px; */
  border-radius: 15px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px !important;
}

.form-white>div {
  width: 26vw;
  margin: 0 5vw;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 21px;
  color: #9194b3;
}

form i {
  margin-left: -30px;
  cursor: pointer;
}

.inputOrange {
  outline: none !important;
  border: 1.5px solid #f4f5fa;
}

.is-invalid {
  background-image: none !important;
  border-color: #dc3545 !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545 !important;
}

.spinner-center {
  justify-content: center;
  align-items: center;
  display: flex;
}
</style>
