<template>
  <header class="bg-surface-primary pt-6" style="margin-right: 32px; margin-bottom: 15px">
    <div>
      <div class="mb-npx">
        <div class="row align-items-center">
          <div class="col-md-4 col-12 mb-4 mb-sm-0">
            <!-- Title -->
            <!-- <div class="searchBoxDiv">
              <input
                type="text"
                class="form-control searchBox"
                placeholder="Search"
                name="search"
              />
              <img
                src="resources/assets/searchIcon.png"
                style="margin-left: -32px"
              />
            </div> -->
          </div>
          <!-- Actions -->
          <div class="dropdown profile-wrap col-md-8 col-12 mb-4 mb-sm-0 d-flex justify-content-end">
            <div class="profile-inner">
                <span class="profile-img" v-if="adminDetails.profile">
                    <img v-if="adminDetails.profile" alt="..." :src="adminDetails.profile" />
                </span>
                <div id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="cursor: pointer;">
                    <p class="loginName">
                        {{ adminDetails.firstName }} {{ adminDetails.lastName ? adminDetails.lastName : "" }}
                        <img class="profile-dropdown" src="resources/assets/profile-dropdown.svg" alt="" />
                    </p>
                </div>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a class="nav-link" @click="showDiv('settings')" style="cursor: pointer;">
                        <p class="d-flex align-items-center loginName">
                            Settings
                        </p>
                    </a>
                    <a style="cursor: pointer;" class="nav-link" @click="logout">
                        <p class="d-flex align-items-center loginName">
                            Logout
                        </p>
                    </a>
                </div>
            </div>
        </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      adminDetails: {
        id: "",
        firstName: "",
        lastName: "",
        profile: "",
      },
      userLoginId: "",
    };
  },

  mounted() {
    this.userLoginId = localStorage.getItem("id");
    this.getUserProfileSettings();
  },
  created() { },
  computed: {},
  methods: {
    getUserProfileSettings() {
      this.$store.dispatch("userProfileSettings").then((response) => {
        let res = response.data[0];
        this.adminDetails.id = res.id;
        this.adminDetails.firstName = res.first_name;
        this.adminDetails.lastName = res.last_name;
        this.adminDetails.profile = res.file;
      });
    },
    showDiv() {
      this.$router.push('/settings');
    },
    logout() {
      localStorage.removeItem("token");
      localStorage.clear();
      //this.$router.push({ path: "/reviewzy/" });
      this.$router.push({ path: "/" });
    },
  },
};
</script>
<style scoped>
.leftPanel {
  background: #27293b;
  position: static;
}

.sideBar {
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  height: 100vh !important;
  margin-bottom: 0px;
  border-bottom-width: 0px !important;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* display: table; */
  display: block;
  transition: opacity 0.3s ease;
  overflow: auto;
}

.modal-wrapper {
  display: grid;
  /* display: table-cell; */
  /* vertical-align: middle; */
}

.modal-container {
  margin-left: 324px;
  margin-right: 66px;
  margin-top: 100px;
  margin-bottom: 100px;
  padding: 30px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.feedback-modal-container {
  /* margin-left: 400px;
    margin-right: 400px;
    margin-top: 100px;
    margin-bottom: 100px; */
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  margin: 100px 550px;
}

.business-modal-container {
  margin-left: 600px;
  margin-right: 600px;
  margin-top: 100px;
  margin-bottom: 100px;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  width: 40%;
}

.business-input {
  height: 45px;
  border-radius: 15px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px !important;
  margin: 10px;
}

.business-btn {
  margin-top: 4px;
  right: 53.47%;
  top: 703px;
  bottom: 25.68%;
  width: 100%;
  height: 45px;
  background: #00053c;
  border-radius: 15px;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

.loginFormHeading {
  margin-bottom: 50px;
  /* width: 176px;
height: 73px; */
  top: 294px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 7px;
  color: #323b4b;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.nav-item active {
  background: #00b0ac;
  background-color: #00b0ac;
}

.navbar-light .navbar-nav .nav-link {
  margin-left: 1vw;
  margin-top: 2vh;
  font-size: 14px;
  color: #9194b3;
}

.navbar-vertical.navbar-light .navbar-nav>.nav-item>.nav-link.active {
  color: #fdd640;
  font-size: 14px;
  background-color: #27293b;
}

.navbar>.container .navbar-brand,
.navbar>.container-fluid .navbar-brand {
  margin-left: 1vw;
  margin-top: 2vh;
}

.searchBox {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  align-items: center;
  color: #9194b3;
  padding: 0px 20px;
  height: 54px;
  background: #f4f5fa;
  border-radius: 15px;
  min-width: 350px;
  max-width: 350px;
  border: none;
  box-shadow: none;
}

.text-heading {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px;
  text-transform: capitalize;
  color: #00053c;
}

.formtable {
  margin: 30px 35px;
  width: 95% !important;
  text-align: center;
}

.fontWeightBolder {
  font-weight: bolder;
}

.createButton {
  font-family: "Inter", sans-serif;
  background: #00053c;
  color: white;
  font-size: 18px;
  border-radius: 15px;
  width: 170px;
  height: 51px;
}

.heading {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 12px;
  line-height: 24px;
  align-items: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #00053c !important;
}

.loginInputsEmail {
  width: 100%;
  height: 58px;
  top: 467px;
  padding: 20px;
  background: #f4f5fa;
  /* BG 2 */

  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  border-radius: 15px;
}

::placeholder {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  /* or 181% */
  display: flex;
  align-items: center;

  color: #9194b3;
}

.loginInputsPassword {
  margin-top: 50px;
  width: 100%;
  padding: 20px;
  height: 58px;

  top: 555px;
  /* BG 4 */

  background: #ffffff;
  /* BG 2 */

  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  border-radius: 15px;
}

.userForm {
  height: 58px;
  border-radius: 15px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px !important;
}

.loginButtonText {
  justify-content: center;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  /* or 181% */
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.loginButton {
  margin-top: 20px;

  right: 53.47%;
  top: 703px;
  bottom: 25.68%;
  width: 100%;
  height: 58px;
  background: #00053c;
  border-radius: 15px;
}

.yellowButton {
  right: 53.47%;
  top: 703px;
  bottom: 25.68%;
  width: 25%;
  height: 50px;
  border-radius: 15px;
  padding: 10px;
  background: #fdd640;
  margin: 0px 12px 12px;
}

.row {
  margin-right: 0px;
}

.reviewModalName {
  margin: 0px 7px;
  text-decoration: none;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  /* or 181% */
  align-items: center;

  color: #1778f2;
}

.searchBoxDiv {
  max-width: 281px;
  min-width: 281px;
  display: flex;
  align-items: center;
}

.table>tbody>tr>td {
  padding: 30px 15px;
}

/* Review Css  Start*/
.ratingSpan {
  color: white;
  padding: 10px 10px;
  background: #1778f2;
  border-radius: 26px;
  font-weight: 500;
  font-size: 16px;
}

.ratingSpan>img {
  padding: 0 5px;
  width: 30px;
  margin-top: -4px;
}

.form-white>div {
  width: 26vw;
  margin: 0 5vw;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 21px;
  color: #9194b3;
}

.loginName { margin-left: 10px; font-family: "Inter", sans-serif; font-style: normal; font-weight: 600; font-size: 14px; line-height: 29px; color: #00053c; }

.loginPic {
  width: 47px;
  height: 47px;
}

.viewReviewInnerBox {
  background-color: white;
  margin: 30px 30px;
}

.viewReviewInnerBoxImg {
  margin: 20px 20px;
}

.viewReviewInnerBoxPara {
  word-wrap: break-word;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #9194b3;
}

.viewReviewInnerBoxAnchor {
  text-decoration: none;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #4285f4;
}

.viewReviewSecondPartTopText {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #9194b3;
}

.viewReviewSecondPartTopName {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 20px;
  color: #00053c;
}

.viewReviewSecondPartTextArea {
  margin: 15px 0px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  /* or 181% */

  display: flex;
  align-items: center;

  color: #9194b3;

  width: 451px;
  height: 150px;
  left: 889px;
  top: 491px;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  border-radius: 15px;
}

.loginButtonText {
  justify-content: center;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  /* or 181% */
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.viewReviewSecondPartdropdown {
  margin: 15px 0px;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  border-radius: 15px;
}

.viewReviewSecondPartdropdownText {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px !important;
  /* font-size: 16px !important; */
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #9194b3;
}

.profilePic {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 5px solid #ffffff;
  position: absolute;
  margin: -80px 50px;
}

/* Review Css End */
.profileFormTitle {
  text-decoration-line: none;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  align-items: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #9194b3 !important;
}

.profileFormTitleValue {
  text-decoration-line: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #00053c !important;
}

.profileName {
  margin-left: 226px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #00053c;
}

.profileSettingSwitch {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 29px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #9194b3;
  text-decoration-line: none;
}

.profileSettingSwitch.active {
  color: #ffab00;
}

.profileSettingIcons {
  padding-right: 15px;
  margin-top: 5px;
  height: 33px;
}

.platFormCard {
  padding: 60px 0px;
  border-radius: 15px !important;
  height: 271px;
}

.platFormCardGroup {
  column-gap: 37px;
  margin: 30px 20px;
}

.platFormCardButton {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  /* or 181% */
  background: #00053c;
  color: #ffffff;
  width: 180px;
  height: 51px;
  border-radius: 15px;
}

.profileSettingHeading {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #00053c;
  margin-top: 24px;
  margin-left: 24px;
}

.communicationFormLabel {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 29px;
  /* or 207% */

  display: flex;
  align-items: center;
  letter-spacing: 1px;

  color: #9194b3;
}

.choose-files {
  align-self: flex-end;
  z-index: 10;
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;

  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.button-wrapper.btn:hover {
  background-color: #4e5056;
}

.label {
  margin-bottom: 20px;
  color: #4e5056;
  font-size: 18px;
}

.button-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.btn {
  border: none;
  padding: 10px 20px;
  border-radius: 50px;
  background-color: #f4f5fa;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 200ms ease-in;
}

.drop-it-hot {
  margin: 34px 0px;
  background-color: #41162a;
  border-radius: 15px;
  height: 518px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 8px 15px 0 rgba(black, 0.1);
  overflow: hidden;
}

.drop-it-hot:after {
  content: "";
  position: absolute;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  border: 2px dashed #e6e7f0;
  border-radius: 5px;
  z-index: 0;
}

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  color: #f4f5fa;
  background-color: white;
  border: 3px solid black;
  width: 100px;
  height: 100px;
}

.circle {
  transition: transform 150ms ease-in;
  z-index: 10;
}

svg {
  width: 40px;
  height: 40px;
}

.circle:before {
  content: "";
  background-color: #f4f5fa;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  position: absolute;
  opacity: 0;
  transition: transform 250ms ease-in, opacity 200ms ease-in;
  z-index: 0;
}

.circle:after {
  content: "";
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.circle:hover {
  transform: scale(1.2);
  opacity: 0.9;
}

.circle:before {
  transform: scale(8);
  opacity: 1;
}

.circle:after {
  border: 3px solid white;
}

svg {
  color: white;
  z-index: 1;
}

.loginInputsTextArea {
  width: 100%;
  height: 150px;

  background: #f4f5fa;
  border-radius: 15px;
}

.assignFollowUp {
  height: 50px;
  left: 889px;
  top: 413px;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  border-radius: 15px;
  width: 100%;
}

.respondButton {
  margin-top: 20px;
  right: 53.47%;
  top: 703px;
  bottom: 25.68%;
  width: 100%;
  height: 50px;
  background: #00053c;
  border-radius: 15px;
}

textarea {
  resize: none;
}

.inputOrange:focus {
  outline: none !important;
  border: 1.5px solid #ff8c00;
  box-shadow: none;
}

form i {
  margin-left: -30px;
  cursor: pointer;
}

.form-control1 {
  display: inline-block !important;
}

.navOutDiv {
  position: fixed;
  width: 100%;
}

.dashScroll {
  margin-right: 0 !important;
  padding-right: 15px;
}

.reviewDropDown {
  width: 96%;
  border-radius: 6px;
  position: absolute;
  inset: 0px auto auto 0px;
  margin: 0px;
  transform: translate(9px, 233px);
}

.outer-circle-new {
  background: rgba(137, 176, 240, 0.1);
  border-radius: 15px;
  padding: 10px;
}

.outer-circle-pending {
  background: rgba(255, 171, 0, 0.12);
  border-radius: 15px;
  padding: 10px;
}

.outer-circle-resolved {
  background: #e5f7ef;
  border-radius: 15px;
  padding: 10px;
}

.inner-new {
  color: #89b0f0;
}

.inner-pending {
  color: #ffab00;
}

.inner-resolved {
  color: #38cb89;
}

.center-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.view-button {
  margin-top: 20px;
  right: 53.47%;
  top: 703px;
  bottom: 25.68%;
  width: 25%;
  height: 50px;
  background: #00053c;
  border-radius: 15px;
  padding: 10px;
}

.filter-bg-color {
  background-color: #f4f5fa;
  border-radius: 15px;
  padding: 10px;
  margin: 5px;
}

.assignFilter {
  height: 50px;
  left: 889px;
  top: 413px;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 15px;
  width: 100%;
  justify-content: flex-end;
}

.basic-info {
  margin: 20px;
  border-radius: 20px;
  background: rgb(244, 245, 250);
  padding: 20px;
}

.edit-text {
  width: 33px;
  height: 16px;
  left: 1302px;
  top: 703px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #9194b3;
  padding: 10px 10px 0px;
}

.basic-card {
  background: rgb(255, 255, 255);
  margin: 20px;
  padding: 20px;
  border-radius: 15px;
}

.communication-setting {
  padding: 36px;
  border-radius: 15px;
  margin-bottom: 20px;
}

.role-table-pagination .pagination {
  justify-content: center;
}

.admin-new-pagination ul.pagination {
  background: none;
}

.admin-new-pagination ul li {
  flex-grow: initial !important;
  box-shadow: 0px 2px 6px #0000000a;
}

.admin-new-pagination {
  padding: 35px 0;
  background: #fff;
  overflow: auto;
}

.admin-new-pagination .pagination {
  /* justify-content: flex-end; */
  height: auto;
  box-shadow: none;
}

.admin-new-pagination .pagination li.active a.page-link {
  background: #623cea;
  border-radius: 0px;
  border-color: #623cea;
  color: #fff;
}

.admin-new-pagination .pagination li.active a.page-link:focus {
  box-shadow: none;
}

.admin-new-pagination .pagination a.page-link {
  color: #a4afb7;
  background: #fff;
}

.admin-new-pagination ul li span,
.admin-new-pagination ul li a,
.admin-new-pagination ul li button {
  padding: 10px 15px !important;
  font-size: 13px !important;
}

.admin-new-pagination .pagination a.page-link:focus {
  box-shadow: none;
}

.dark-theme .admin-new-pagination .pagination li.active a.page-link {
  background: #1a73e8;
  border-color: #1a73e8;
}

.spinner-center {
  justify-content: center;
  align-items: center;
  display: flex;
}

.isDisabled {
  pointer-events: none;
  color: red;
}

.is-invalid {
  background-image: none !important;
  border-color: #dc3545 !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545 !important;
}
.profile-img {
  display: inline-flex;
  height: 47px;
  width: 47px;
  border-radius: 50%;
  background: #f7f7f7;
  border: 1px solid #f7f7f7;
  object-fit: cover;
  align-items: center;
  justify-content: center;
}

.profile-img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #f7f7f7;
  object-fit: cover;
}
.profile-inner {
  display: inline-flex !important;
  align-items: center;
  padding: 5px;
  background: #f7f7f7;
  border-radius: 30px;
  white-space: nowrap;
}

.profile-inner .loginName {
  margin-bottom: 0;
}
.profile-dropdown { margin-left: 10px; /* margin-bottom: 10px; */ }
@media (max-width: 991.98px) {
  .profile-wrap {
    display: none !important;
  }
}
</style>
