<template>
  <div>
    <div class="d-flex flex-column flex-lg-row h-lg-full navOutDiv">
      <!-- Vertical Navbar -->

      <Menu></Menu>
      <!-- Main content -->
      <div class="h-screen flex-grow-1 overflow-y-lg-auto m-4 dashScroll">
        <!-- Header -->
        <Header></Header>
        <div v-if="show == ''">
            <main class="py-6">
                <div class="row align-items-center">
                    <div class="col-sm-3 col-12 mb-4 mb-sm-0">
                        <div class="card-header" style="background: none; border: none;">
                            <h2 class="mb-0">Feedback</h2>
                        </div>
                    </div>
                    <div class="col-md-9 col-12 mb-4 mb-sm-0 d-flex justify-content-end">
                        <div id="v-step-0" class="mb-0 profileSettingHeading"></div>
                        <div class="mb-0 profileSettingHeading v-step-3"></div>
                    </div>
                </div>
                <transition name="modal" v-if="feedbackModal">
                    <div class="modal-mask">
                        <div class="modal-wrapper">
                            <div class="modal-container">
                                <div>
                                    <form>
                                        <div class="d-flex justify-content-between align-items-center mb-5">
                                            <h1>Create New</h1>
                                            <a @click="cancel()"><img src="resources/assets/crossicon.png" /></a>
                                        </div>

                                        <h1>Feedback form</h1>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>

                <transition name="modal" v-if="feedbackViewModal">
                    <div class="modal-mask">
                        <div class="modal-wrapper">
                            <div class="feedback-modal-container">
                                <div>
                                    <form class="d-block">
                                        <div class="d-flex justify-content-between mb-5">
                                            <h1></h1>
                                            <div class="align-items-center">
                                                <p class="center-item viewReviewSecondPartTopName">
                                                    {{ feedbackViewData.customerName }}
                                                </p>
                                                <p class="center-item">
                                                    <img
                                                        v-for="index in parseInt(
                            feedbackViewData.rating
                        )"
                                                        :key="index + 'yellow'"
                                                        src="resources/assets/yellowStar.png"
                                                    />
                                                    <img
                                                        v-for="index in 5 -
                        parseInt(feedbackViewData.rating)"
                                                        :key="index + 'blank'"
                                                        src="resources/assets/blankStar.png"
                                                    />
                                                </p>
                                            </div>
                                            <a @click="cancel()"><img src="resources/assets/crossicon.png" /></a>
                                        </div>
                                        <div class="row">
                                            <div class="form-outline form-white"></div>
                                            <div class="center-item">
                                                <a class="yellowButton" v-if="feedbackViewData.phoneNumber" :href="`tel:${feedbackViewData.phoneNumber}`" style="text-decoration: none;">
                                                    <span class="callButton">Call</span>
                                                </a>
                                                <button v-else class="yellowButton" disabled>
                                                    <p class="callButton">Call</p>
                                                </button>
                                                <button type="button" v-if="feedbackViewData.email" class="yellowButton" @click="changeScrollView()">
                                                    <p style="margin: 0;" class="loginButtonText">Email</p>
                                                </button>
                                                <button type="button" class="yellowButton" v-else disabled>
                                                    <p style="margin: 0;" class="loginButtonText">Email</p>
                                                </button>
                                            </div>
                                            <div class="center-item">
                                                <span class="viewReviewSecondPartdropdownText">
                                                    {{ feedbackViewData.feedback }}
                                                </span>
                                            </div>
                                            <div class="center-item">
                                                <button type="button" class="view-button">
                                                    <a @click="getFeedbackId(feedbackViewData)">
                                                        <p style="margin: 0;" class="loginButtonText">View</p>
                                                    </a>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>

                <transition name="modal" v-if="customerFeedbackModal">
                    <div class="modal-mask">
                        <div class="modal-wrapper">
                            <div class="modal-container-view">
                                <div>
                                    <form>
                                        <div class="d-flex justify-content-between align-items-center mb-5">
                                            <h1>Customer Details</h1>
                                            <div class="d-flex">
                                                <a v-if="!customerFeedbackModalEdit" v-b-tooltip.hover @click="editFeedback()" style="cursor: pointer;" title="Edit"><img src="resources/assets/editIcon.png" /></a>
                                                <a @click="cancel()"><img src="resources/assets/crossicon.png" /></a>
                                            </div>
                                        </div>

                                        <h1></h1>
                                        <form class="d-block">
                                            <div class="row align-items-center">
                                                <div class="col-md-4 col-12 mb-4 mb-sm-0">
                                                    <div class="form-outline viewReviewInnerBox d-flex">
                                                        <div class="text-start">
                                                            <a class="profileFormTitle"> customerName</a>
                                                            <br />
                                                            <a class="profileFormTitleValue"> {{ feedbackViewData.customerName }}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4 col-12 mb-4 mb-sm-0">
                                                    <div class="form-outline viewReviewInnerBox d-flex">
                                                        <div class="text-start">
                                                            <a class="profileFormTitle"> Rating</a>
                                                            <br />
                                                            <a class="profileFormTitleValue"> {{ feedbackViewData.rating ? feedbackViewData.rating : "NA" }}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4 col-12 mb-4 mb-sm-0">
                                                    <div class="form-outline viewReviewInnerBox d-flex">
                                                        <div class="text-start">
                                                            <a class="profileFormTitle"> Email</a>
                                                            <br />
                                                            <a v-if="customerFeedbackModalEdit" class="profileFormTitleValue">
                                                                <input
                                                                    type="email"
                                                                    class="form-control userForm form-control-lg inputOrange"
                                                                    placeholder="Customer Email Address"
                                                                    v-model="feedbackViewData.email"
                                                                    v-validate="'required'"
                                                                    v-bind:name="'feedbackViewData.email'"
                                                                    v-bind:ref="'feedbackViewData.email'"
                                                                    :class="{
                                    'is-invalid': errors.has(
                                    'feedbackViewData.email'
                                    ),
                                }"
                                                                />
                                                            </a>
                                                            <a v-else class="profileFormTitleValue"> {{ feedbackViewData.email ? feedbackViewData.email : "NA" }}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row align-items-center">
                                                <div class="col-md-4 col-12 mb-4 mb-sm-0">
                                                    <div class="form-outline viewReviewInnerBox d-flex">
                                                        <div class="text-start">
                                                            <a class="profileFormTitle"> Country Code</a>
                                                            <br />
                                                            <a v-if="customerFeedbackModalEdit" class="profileFormTitleValue">
                                                                <select
                                                                    class="viewReviewSecondPartdropdownText inputOrange assignFollowUp form-select"
                                                                    v-model="feedbackCode"
                                                                    v-validate="'required'"
                                                                    v-bind:name="'requestData.code'"
                                                                    v-bind:ref="'requestData.code'"
                                                                    :class="{
                                    'is-invalid':
                                    errors.has('requestData.code'),
                                }"
                                                                >
                                                                    <option class="viewReviewSecondPartdropdownText" value="">
                                                                        Select Country
                                                                    </option>
                                                                    <option class="viewReviewSecondPartdropdownText" :value="location.shortName" v-for="location in countryCodelist" :key="location.id">
                                                                        {{ `${location.name} (+${location.code})` }}
                                                                    </option>
                                                                </select>
                                                            </a>
                                                            <a v-else class="profileFormTitleValue"> {{ feedbackCountryCode ? feedbackCountryCode : "NA" }}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4 col-12 mb-4 mb-sm-0">
                                                    <div class="form-outline viewReviewInnerBox d-flex">
                                                        <div class="text-start">
                                                            <a class="profileFormTitle"> Number</a>
                                                            <br />
                                                            <a v-if="customerFeedbackModalEdit" class="profileFormTitleValue">
                                                                <input
                                                                    type="number"
                                                                    class="form-control userForm form-control-lg inputOrange"
                                                                    placeholder="Customer Phone Number"
                                                                    v-model="feedbackViewData.phoneNumber"
                                                                    v-validate="'required'"
                                                                    v-bind:name="'feedbackViewData.phoneNumber'"
                                                                    v-bind:ref="'feedbackViewData.phoneNumber'"
                                                                    :class="{
                                    'is-invalid': errors.has(
                                    'feedbackViewData.phoneNumber'
                                    ),
                                }"
                                                                />
                                                            </a>
                                                            <a v-else class="profileFormTitleValue"> {{ feedbackViewData.phoneNumber ? feedbackViewData.phoneNumber : "NA" }}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4 col-12 mb-4 mb-sm-0">
                                                    <div class="form-outline viewReviewInnerBox d-flex">
                                                        <div class="text-start">
                                                            <a class="profileFormTitle"> Follow Up Notes</a>
                                                            <br />
                                                            <a class="profileFormTitleValue">
                                                                {{ feedbackViewData.followUpNotes ? feedbackViewData.followUpNotes : "NA" }}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row align-items-center">
                                                <div class="form-outline viewReviewInnerBox d-flex">
                                                    <div class="text-start">
                                                        <a class="profileFormTitle"> Feedback</a>
                                                        <br />
                                                        <a class="profileFormTitleValue">
                                                            {{ feedbackViewData.feedback ? feedbackViewData.feedback : "NA" }}
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <a v-if="customerFeedbackModalEdit" @click.prevent="updateFeedback()" class="d-flex justify-content-end">
                                                <button class="platFormCardButton" style="width: 180px; margin-top: 15px;">
                                                    Save
                                                </button>
                                            </a>
                                        </form>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>

                <transition name="modal" v-if="feedbackUserList">
                    <div class="modal-mask">
                        <div class="modal-wrapper">
                            <div class="modal-container">
                                <div>
                                    <UsersModal :actionType="'feedback'" @changeTitle="ChangeT($event)" :selected="filterList.user" @closeModal="closeM($event)" />
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>

                <transition name="modal" v-if="tour">
                    <v-tour name="myTour" :steps="steps" :callbacks="myCallbacks">
                        <template slot-scope="tour" style="background: #fff; color: #000">
                            <transition name="fade">
                                <v-step class="v-step" v-if="tour.steps[tour.currentStep]" :key="tour.currentStep"
                                    :step="tour.steps[tour.currentStep]" :previous-step="tour.previousStep"
                                    :next-step="tour.nextStep" :stop="tour.stop" :skip="tour.skip"
                                    :is-first="tour.isFirst" :is-last="tour.isLast" :labels="tour.labels"
                                >
                                    <div slot="content">
                                        <h3>Feedback Page Step 9 of 10 <img @click="tour.stop" src="resources/assets/crossicon.png" /></h3>
                                        <p class="vue-para">
                                            Don't forget to regularly check your feedback page for negative reviews that came back. Click the "eye" icon to assign to see what they said and then "view" to respond to it. Remember, the
                                            quicker you deal with negative reviews the more likely they won't make it public. You can also thank the people who left great reviews left here!
                                        </p>
                                    </div>
                                    <div slot="actions">
                                        <button @click="tutorialVideo(9)" class="tourButton">Tutorial Video</button>
                                        <button @click="tour.previousStep; myCustomPreviousStepCallback()" class="tourButton">Previous Step</button>
                                        <button @click="tour.nextStep" class="tourButton">Next Step</button>
                                    </div>
                                </v-step>
                            </transition>
                        </template>
                    </v-tour>
                </transition>
                <transition name="modal" v-if="vSteps">
                    <div class="modal-mask">
                        <div class="modal-wrapper">
                            <div class="modal-container modal-video" style="background: none; box-shadow: none;">
                                <div>
                                    <div class="d-flex mb-2" style="justify-content: end;">
                                        <a @click="cancel()" class="f-start"><img src="resources/assets/crossicon.png" /></a>
                                    </div>
                                    <div class="table-responsive">
                                        <div class="plyr__video-embed" id="player">
                                            <iframe class="youtube-video" title="YouTube video" :src="vStepsVideo" allowfullscreen allowtransparency allow="autoplay"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>

                <div class="card border-0 mb-7 table-align" id="userForDiv">
                    <div class="table-outer-wrap">
                        <div class="inner-white-wrap">
                            <ActionBar @changeTitle="ChangeT($event)" @updateList="updateListing($event)" :selected-users="selectedUsers" :filter-options="filterOptions" :actionType="'feedback'" :dateStyle="true" />
                            <div class="container">
                                <ul class="viewReviewSecondPartdropdownText inputOrange assignFilter">
                                    <li class="viewReviewSecondPartdropdownText" v-for="(data, index) in filterList.platform" v-bind:key="index">
                                        <a class="filter-bg-color">
                                            {{ data }}
                                            <img src="resources/assets/close.svg" alt="" @click="removeFilter(data)" />
                                        </a>
                                    </li>
                                    <li class="viewReviewSecondPartdropdownText" v-for="(data, index) in filterList.ranking" v-bind:key="index">
                                        <a class="filter-bg-color">
                                            {{ data }}
                                            <img src="resources/assets/close.svg" alt="" @click="removeFilter(data)" />
                                        </a>
                                    </li>
                                    <li class="filterText" v-for="(data, index) in filterList.userName" v-bind:key="index">
                                        <a class="filter-bg-color">
                                            {{ `${data.first_name} ${data.last_name ? data.last_name : ""}` }}
                                            <img src="resources/assets/close.svg" alt="" @click="removeFilter(data)" style="padding-left: 15px;" />
                                        </a>
                                    </li>
                                    <li v-if="filterList.startDate" class="viewReviewSecondPartdropdownText">
                                        <a class="filter-bg-color">
                                            {{ `Startdate ${filterList.startDate}` }}
                                            <img src="resources/assets/close.svg" alt="" @click="removeFilter(filterList.startDate)" />
                                        </a>
                                    </li>
                                    <li v-if="filterList.endDate" class="viewReviewSecondPartdropdownText">
                                        <a class="filter-bg-color">
                                            {{ `Enddate ${filterList.endDate}` }}
                                            <img src="resources/assets/close.svg" alt="" @click="removeFilter(filterList.endDate)" />
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div class="table-responsive">
                                <table class="table table-hover table-nowrap formtable">
                                    <thead class="thead-light">
                                        <tr class="header">
                                            <th class="heading" scope="col" style="padding-left: 15px;">
                                                <div class="custom-control custom-checkbox checkbox-lg form-check-inline">
                                                    <input type="checkbox" class="custom-control-input" @change="pushAllSelectedFeedbacks($event)" id="all" />
                                                    <label class="custom-control-label checkLabel" for="all"></label>
                                                </div>
                                            </th>
                                            <th class="heading" scope="col"></th>
                                            <th class="heading" scope="col">Date</th>
                                            <th class="heading" scope="col">CUSTOMER NAME</th>
                                            <th class="heading" scope="col">RATINGS</th>
                                            <th class="heading" scope="col">ASSIGNMENT</th>
                                            <th class="heading" scope="col">FEEDBACK</th>
                                            <th class="heading" scope="col">STATUS</th>
                                            <th class="heading" scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="feedbackList.length > 0">
                                        <tr v-for="feedback in feedbackList" v-bind:key="feedback.id">
                                            <td>
                                                <div class="custom-control custom-checkbox checkbox-lg form-check-inline">
                                                    <input
                                                        :id="feedback.id"
                                                        type="checkbox"
                                                        class="custom-control-input"
                                                        @change="
                            pushSelectedFeedbacks($event, feedback.id)
                        "
                                                    />
                                                    <label class="custom-control-label checkLabel" :for="feedback.id"></label>
                                                </div>
                                            </td>
                                            <td>
                                                <a class="text-heading new-color" style="cursor: pointer;" @click="viewFeedback(feedback.id, feedback.feedbackStatus)">
                                                    {{ feedback.feedbackStatus ? "New!" : "" }}
                                                </a>
                                            </td>
                                            <td>
                                                <p class="text-heading">
                                                    {{ moment(String(feedback.created_at)).format( "MM-DD-YYYY" ) }}
                                                </p>
                                            </td>
                                            <td>
                                                <a style="cursor: pointer;" class="text-heading" @click="customerFeedbackModalOpen(feedback.id)">
                                                    {{ ` ${feedback.customerName}` }}
                                                </a>
                                            </td>
                                            <td>
                                                <p>
                                                    <img v-for="index in parseInt(feedback.rating)" :key="index + 'yellow'" src="resources/assets/yellowStar.png" />
                                                    <img v-for="index in 5 - parseInt(feedback.rating)" :key="index + 'blank'" src="resources/assets/blankStar.png" />
                                                </p>
                                            </td>
                                            <td>
                                                <p class="text-heading">
                                                    {{ `${feedback.assignment ? feedback.assignment : "NA" }` }}
                                                </p>
                                            </td>
                                            <td>
                                                <a href="#" class="btn btn-sm btn-neutral" @click="viewFeedback(feedback.id, feedback.feedbackStatus)" style="background: none;"><img src="resources/assets/view_icon.svg" /></a>
                                            </td>
                                            <td>
                                                <p
                                                    class="text-heading"
                                                    :class="{
                        'outer-circle-new': feedback.status === 0,
                        'outer-circle-pending': feedback.status === 1,
                        'outer-circle-resolved': feedback.status === 2,
                        }"
                                                >
                                                    <span
                                                        :class="{
                            'inner-new': feedback.status === 0,
                            'inner-pending': feedback.status === 1,
                            'inner-resolved': feedback.status === 2,
                        }"
                                                    >
                                                        {{ feedback.status === 0 ? "New" : "" }} {{ feedback.status === 1 ? "Pending" : "" }} {{ feedback.status === 2 ? "Resolved" : "" }}
                                                    </span>
                                                </p>
                                            </td>
                                            <td>
                                                <a href="#" class="btn btn-sm btn-neutral" style="background: none;" @click="viewFeedback(feedback.id, feedback.feedbackStatus)"><img src="resources/assets/view_icon.svg" /></a>
                                                <a href="#" class="btn btn-sm btn-neutral" @click="deleteFeedback(feedback.id)" style="background: none;">
                                                    <img src="resources/assets/trash_icon.svg" />
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr>
                                            <td colspan="9" style="text-align: center; font-weight: 600;">
                                                <h1>
                                                    No Feedback yet! Go request a review to begin.
                                                </h1>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div v-if="feedbackCount > feedbackPerPage" class="admin-new-pagination role-table-pagination">
                                <b-pagination v-model="feedbackCurrentPage" :total-rows="feedbackCount" :per-page="feedbackPerPage" @change="showFeedbackPage" first-number last-number align="center" pills size="lg"></b-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
        <div v-if="show == 'feedback3'">
            <Feedback3 :scrollToEmaill="scrollToEmaill" :feedbackId="feedbackId" />
        </div>
        <div v-if="show == 'feedback5'">
            <Feedback5 :scrollToEmaill="scrollToEmaill" :feedbackId="feedbackId" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "./Header";
import Menu from "./Menu";
import ActionBar from "./ActionBar";
import UsersModal from "./UsersModal";
import moment from "moment";
import Feedback3 from "./Feedback3.vue";
import Feedback5 from "./Feedback5.vue";
export default {
  name: "Feedback",
  components: {
    ActionBar,
    Header,
    Menu,
    UsersModal,
    Feedback3,
    Feedback5,
  },
  data() {
    return {
        moment: moment,
        apiLoading:false,
        vSteps: false,
        vStepsVideo: "",
        vStepsVideoList: [],
        steps: [
            {
                target: '#v-step-0',
            },
            {
                target: '#v-step-1',
            }
        ],
        myCallbacks: {
            onNextStep: this.myCustomNextStepCallback,
            onPreviousStep: this.myCustomPreviousStepCallback,
            onStop:this.myCustomStopCallback,
        },
        tour: localStorage.getItem("isSetupWizardPlay") === 'true' ? true : false,

        show: "",
        feedbackId: "",
        userLoginId: '',
        feedbackModal: false,
        feedbackViewModal: false,
        customerFeedbackModal: false,
        customerFeedbackModalEdit: false,
        feedbackUserList: false,
        selectedUsers: [],
        filterOptions: [
            {
                platform: {
                    title: "platform",
                    data: ["Google", "Facebook", "Yelp", "Zillow"],
                },
                "By User": {
                    title: "By User",
                },
                "By Ranking": {
                    title: "By Ranking",
                    data: ["1", "2", "3", "4", "5"],
                },
            },
        ],
        filterList: {
            platform: [],
            user: [],
            ranking: [],
            startDate: "",
            endDate: "",
            userName: []
        },
        feedbackList: [],
        feedbackCurrentPage: 1,
        feedbackPerPage: 20,
        feedbackCount: 0,
    };
  },

  mounted() {
    this.feedbackCurrentPage = 1;
    this.userLoginId = localStorage.getItem("id");
    this.scrollToEmaill = false;
    this.feedbackViewModal = false;
    this.customerFeedbackModal = false;
    this.getFeedbackList();
    this.countryCode();
    if (this.tour) {
        this.$tours['myTour'].start();
        this.wizardData();
    }
  },
  created() { },
  computed: {},
  methods: {
    getFeedbackList(filterConditions) {
      let data;
      if (filterConditions) {
        data = filterConditions;
      } else {
        data = {
          page: this.feedbackCurrentPage ? this.feedbackCurrentPage : 1,
          userId: localStorage.getItem("isAdmin") == 0 ? this.userLoginId : "",
          platformName: this.filterList.platform
            ? this.filterList.platform
            : "",
          reviewRating: this.filterList.ranking ? this.filterList.ranking : "",
          assignedUser: this.filterList.user ? this.filterList.user : "",
          fromDate: this.filterList.startDate ? this.filterList.startDate : "",
          toDate: this.filterList.endDate ? this.filterList.endDate : "",
          module: this.module,
          parentUserID:
            localStorage.getItem("isAdmin") == 1 ? this.userLoginId : "",
        };
      }
      // this.getFeedbackLists().then((response) => {
      this.$store.dispatch("feedbackList", data).then((response) => {
        this.feedbackList = response.data;
        this.feedbackCount = response.total;
      });
    },

    deleteFeedback(id) {
      // await this.feedbackDelete(id).then((response) => {
      this.$store.dispatch("deleteFeedback", id).then((response) => {
        if (response.success) {
          let reqParam = {
            page: this.feedbackCurrentPage ? this.feedbackCurrentPage : 1,
            userId:
              localStorage.getItem("isAdmin") == 0 ? this.userLoginId : "",
            platformName: this.filterList.platform
              ? this.filterList.platform
              : "",
            reviewRating: this.filterList.ranking
              ? this.filterList.ranking
              : "",
            assignedUser: this.filterList.user ? this.filterList.user : "",
            fromDate: this.filterList.startDate
              ? this.filterList.startDate
              : "",
            toDate: this.filterList.endDate ? this.filterList.endDate : "",
            module: this.module,
            parentUserID:
              localStorage.getItem("isAdmin") == 1 ? this.userLoginId : "",
          };
          this.getFeedbackList(reqParam);
          this.newFeedabck();
          this.$toastr.s(`${response.message}`);
        } else {
          this.$toastr.e(`${response.message}`);
        }
      });
    },

    pushAllSelectedFeedbacks(e) {
      if (e.target.checked) {
        this.selectedUsers = this.feedbackList.map((x) => x.id);
        this.selectedUsers.forEach((element) => {
          document.getElementById(element).checked = true;
        });
      } else {
        this.selectedUsers.forEach((element) => {
          document.getElementById(element).checked = false;
        });
        this.selectedUsers = [];
      }
    },

    pushSelectedFeedbacks(e, id) {
      if (e.target.checked) {
        this.selectedUsers.push(id);
      } else {
        const index = this.selectedUsers.indexOf(id);
        if (index > -1) {
          this.selectedUsers.splice(index, 1);
        }
      }
    },

    viewFeedback(id, status) {
      if (status) {
        this.$store.dispatch("feedbackReadCount", id).then((response) => {
          if (response.success) {
            this.getFeedbackList();
            this.newFeedabck();
          }
        });
      }
      this.feedbackViewData = [];
      this.$store.dispatch("viewFeedback", id).then((response) => {
        this.feedbackViewData = response.data[0];
        this.feedbackViewModal = true;
      });
    },

    feedbackModalOpen() {
      this.feedbackModal = true;
    },

    customerFeedbackModalOpen(id) {
      this.customerFeedbackModalEdit = false;
      this.feedbackViewData = [];
      this.$store.dispatch("viewFeedback", id).then((response) => {
        this.feedbackViewData = response.data[0];
        let countryData = this.countryCodelist.find(
          (element) => element.shortName == this.feedbackViewData.shortName
        );
        this.feedbackCode =
          countryData && countryData.shortName ? countryData.shortName : "";
        this.feedbackCountryCode =
          countryData && countryData.name && countryData.code
            ? `${countryData.name} (+${countryData.code}) `
            : "";
        this.customerFeedbackModal = true;
      });
    },
    editFeedback() {
      this.customerFeedbackModalEdit = true;
    },
    countryCode() {
      this.$store.dispatch("requestCountryCode").then((response) => {
        if (response.success) {
          this.countryCodelist = response.data;
        }
      });
    },
    async updateFeedback() {
      let result = await this.$validator.validateAll();
      if (result) {
        let countryCode = this.countryCodelist.find(
          (element) => element.shortName == this.feedbackCode
        );

        let data = {
          id: this.feedbackViewData.id,
          userID: this.userLoginId,
          email: this.feedbackViewData.email,
          phone: this.feedbackViewData.phoneNumber,
          code: countryCode && countryCode.code ? "+" + countryCode.code : "+1",
          shortName:
            countryCode && countryCode.shortName ? countryCode.shortName : "US",
        };
        this.$store
          .dispatch("feedbackUpdate", data)
          .then((response) => {
            if (response.success) {
              this.customerFeedbackModalEdit = false;
              this.feedbackCountryCode =
                countryCode && countryCode.name && countryCode.code
                  ? `${countryCode.name} (+${countryCode.code}) `
                  : "";

              this.$toastr.s(`${response.message}`);
            } else {
              if (response.data.email) {
                this.$toastr.e(response.data.email);
              }
              if (response.data.phone) {
                this.$toastr.e(response.data.phone);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    ChangeT(data) {
      let title = data.title;
      let singleData = data.data;
      let modal = data && data.modal ? data.modal : "";
      this.module = data.actionType;

      if (title == "platform") {
        !this.filterList.platform.includes(singleData)
          ? (this.filterList.platform = [
            ...this.filterList.platform,
            singleData,
          ])
          : "";
      }
      if (title == "By Ranking") {
        !this.filterList.ranking.includes(singleData)
          ? (this.filterList.ranking = [...this.filterList.ranking, singleData])
          : "";
      }

      if (title == "By User" && this.module == "reviews") {
        this.userListData = true;
      }

      if (title == "By User" && this.module == "feedback") {
        this.feedbackUserList = true;
      }
      if (singleData != null && modal == "usersFilter") {
        this.filterList.user = singleData.data;
        this.filterList.userName = singleData.userName;
        this.feedbackUserList = false;
        this.userListData = false;
      }

      if (title == "Select Data Range") {
        let startDate = moment(singleData.startDate).format("YYYY-MM-DD");
        let endDate = moment(singleData.endDate).format("YYYY-MM-DD");
        this.filterList.startDate = startDate;
        this.filterList.endDate = endDate;
      }

      if (title != "By User") {
        this.reviewCurrentPage = this.reviewsPageNumber
        let reqParam = {
          page: this.reviewsPageNumber,
          platformName: this.filterList.platform
            ? this.filterList.platform
            : "",
          reviewRating: this.filterList.ranking ? this.filterList.ranking : "",
          assignedUser: this.filterList.user ? this.filterList.user : "",
          fromDate: this.filterList.startDate ? this.filterList.startDate : "",
          toDate: this.filterList.endDate ? this.filterList.endDate : "",
          module: this.module,
        };
        this.callFilterApis(reqParam);
      }
    },
    removeFilter(data) {
      let platform = this.filterList.platform.indexOf(data);
      if (platform > -1) {
        this.filterList.platform.splice(platform, 1);
      }
      let ranking = this.filterList.ranking.indexOf(data);
      if (ranking > -1) {
        this.filterList.ranking.splice(ranking, 1);
      }
      let user;
      data && data.id ? user = this.filterList.user.indexOf(data.id) : "";
      if (user > -1) {
        this.filterList.user.splice(user, 1);
      }

      let userName = this.filterList.userName.findIndex(function (userName, index) {
        if (userName.id == data.id)
          return true;
      });
      if (userName > -1) {
        this.filterList.userName.splice(userName, 1);
      }

      let rangeStart;
      let rangeEnd;
      if ((data == this.filterList.startDate) || (data == this.filterList.endDate)) {
        let dateRangeStart = this.filterList.startDate.split(": ");
        let dateRangeEnd = this.filterList.endDate.split(": ");
        rangeStart = this.filterList.startDate.indexOf(dateRangeStart[0]);
        rangeEnd = this.filterList.endDate.indexOf(dateRangeEnd[0]);
      }

      if (rangeStart > -1 || rangeEnd > -1) {
        this.filterList.startDate = "";
        this.filterList.endDate = "";
      }

      let reqParam = {
        page: 1,
        platformName: this.filterList.platform ? this.filterList.platform : "",
        reviewRating: this.filterList.ranking ? this.filterList.ranking : "",
        assignedUser: this.filterList.user ? this.filterList.user : "",
        fromDate: this.filterList.startDate ? this.filterList.startDate : "",
        toDate: this.filterList.endDate ? this.filterList.endDate : "",
        module: this.module,
      };
      this.callFilterApis(reqParam);
    },
    callFilterApis(data) {
      if (data.module == "feedback") {
        (data.userId =
          localStorage.getItem("isAdmin") == 0 ? this.userLoginId : ""),
          (data.parentUserID =
            localStorage.getItem("isAdmin") == 1 ? this.userLoginId : ""),
          this.getFeedbackList(data);
      }
    },
    updateListing(data) {
      let reqParam = {
        platformName: this.filterList.platform ? this.filterList.platform : "",
        reviewRating: this.filterList.ranking ? this.filterList.ranking : "",
        fromDate: this.filterList.startDate ? this.filterList.startDate : "",
        toDate: this.filterList.endDate ? this.filterList.endDate : "",
      };
      if (data.actionType == "feedback") {
        reqParam.page = this.feedbackCurrentPage ? this.feedbackCurrentPage : 1;
        (reqParam.userId =
          localStorage.getItem("isAdmin") == 0 ? this.userLoginId : ""),
          (reqParam.parentUserID =
            localStorage.getItem("isAdmin") == 1 ? this.userLoginId : ""),
          (reqParam.module = data.actionType),
          this.getFeedbackList(reqParam);
      }
    },
    newFeedabck() {
      let data = {
        userID: this.userLoginId,
      };
      this.$store.dispatch("feedbackNewCount", data).then((response) => {
        this.feedbackStatus =
          response.data && response.data.feedbackStatus && response.data.feedbackStatus != 0
            ? response.data.feedbackStatus
            : "";
      });
    },
    getFeedbackId(data) {
        if (data.rating > 0 && data.rating <= 3) {
            this.show = "feedback3";
        }
        if (data.rating > 3 && data.rating <= 5) {
            this.show = "feedback5";
        }
        this.feedbackId = data.id;
    },
    cancel() {
        this.vSteps = false;
        this.feedbackModal = false;
        this.feedbackViewModal = false;
        this.customerFeedbackModal = false;
    },
    closeM(data) {
      this.userListData = false;
      this.feedbackUserList = false;
    },
    wizardData() {
        this.apiLoading = true;
        let data = {
            page: 1,
        };
        this.$store
        .dispatch("wizardList", data)
        .then((response) => {
            if (response.success) {
                this.vStepsVideoList = response.data.data;
            }
        })
        .catch((err) => {
            console.log(err);
        }).finally(() => (this.apiLoading = false));
    },
    tutorialVideo(data){
        if(this.vStepsVideoList[data-1].tutorialLink){
        this.vSteps = true;
        this.vStepsVideo = `${this.vStepsVideoList[data-1].tutorialLink}?origin=https://plyr.io&amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1&output=embed`
        }
    },
    myCustomPreviousStepCallback(currentStep){
        this.$router.push({ path: "/request-reviews" });
    },
    myCustomNextStepCallback(currentStep){
        this.$router.push({ path: "/reviews" });
    },
    myCustomStopCallback(){
        this.tour = false;
        localStorage.setItem("loginFirstTime", 0);
        localStorage.setItem("isSetupWizardPlay", 'false');
    }
  },
};
</script>
<style scoped>
 a { text-decoration: none; }

.leftPanel {
  background: #27293b;
  position: static;
}

.sideBar {
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  height: 100vh !important;
  margin-bottom: 0px;
  border-bottom-width: 0px !important;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  display: block;
  transition: opacity 0.3s ease;
  overflow: auto;
}

.modal-wrapper {
  display: grid;
}

.modal-container {
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  margin-bottom: 100px;
  padding: 30px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.youtube-video {
  width: 600px;
  height: 300px;
}

.feedback-modal-container {
  padding: 30px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 600px;
}

.business-modal-container {
  padding: 20px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  width: 500px;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.business-modal-history-container {
  padding: 20px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  width: 800px;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.business-input {
  height: 45px;
  border-radius: 15px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px !important;
  margin: 10px 0;
}

.yelp-form-control1 {
  width: 90%;
  display: inline-block !important;
}

.business-btn {
  margin-top: 4px;
  right: 53.47%;
  top: 703px;
  bottom: 25.68%;
  width: 100%;
  height: 45px;
  background: #00053c;
  border-radius: 15px;
  color: white
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

.loginFormHeading {
  margin-bottom: 50px;
  top: 294px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 7px;
  color: #323b4b;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container .modal-container-youtube {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.nav-item active {
  background: #00b0ac;
  background-color: #00b0ac;
}

.navbar-light .navbar-nav .nav-link {
  margin-left: 1vw;
  margin-top: 2vh;
  font-size: 14px;
  color: #9194b3;
}

.navbar-vertical.navbar-light .navbar-nav>.nav-item>.nav-link.active {
  color: #fdd640;
  font-size: 14px;
  background-color: #27293b;
}

.navbar>.container .navbar-brand,
.navbar>.container-fluid .navbar-brand {
  margin-left: 1vw;
  margin-top: 2vh;
}

.searchBox {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  align-items: center;
  color: #9194b3;
  padding: 0px 20px;
  height: 46px;
  background: #f4f5fa;
  border-radius: 15px;
  min-width: 350px;
  max-width: 350px;
  border: none;
  box-shadow: none;
}

.text-heading {
  font-weight: 500 !important;
  text-transform: capitalize;
  color: #00053c;
  font-family: "Inter";
  font-style: normal;
  font-size: 14px;
  align-items: center;
  white-space: initial;
}

.formtable {
  text-align: center;
  border-collapse: inherit;
}

.fontWeightBolder {
  font-weight: bolder;
}

.createButton {
  font-family: "Inter", sans-serif;
  background: #00053c;
  color: white;
  font-size: 18px;
  border-radius: 15px;
  width: 170px;
  height: 51px;
}

.heading {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 12px;
  line-height: 24px;
  align-items: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #00053c !important;
  font-weight: 600;
  align-items: center;
}

.loginInputsEmail {
  width: 100%;
  width: 100%;
  height: 45px;
  padding: 10px;
  background: #f4f5fa;
  /* BG 2 */

  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  border-radius: 15px;
}

::placeholder {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 29px;
  /* or 181% */
  display: flex;
  align-items: center;

  color: #9194b3;
}

.loginInputsPassword {
  margin-top: 50px;
  width: 100%;
  padding: 20px;
  height: 58px;

  top: 555px;
  /* BG 4 */

  background: #ffffff;
  /* BG 2 */

  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  border-radius: 15px;
}

.userForm {
  height: 50px;
  /* height: 58px; */
  border-radius: 15px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px !important;
}

.loginButtonText {
  justify-content: center;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  /* or 181% */
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  /* height: 50px; */
}

.callButton {
  justify-content: center;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  margin: 0px
}

.loginButton {
  margin-top: 20px;
  width: 100%;
  height: 58px;
  background: #00053c;
  border-radius: 15px;
  transition: 0.2s ease;
}

.yellowButton {
  right: 53.47%;
  top: 703px;
  bottom: 25.68%;
  width: 25%;
  height: 50px;
  border-radius: 15px;
  padding: 10px;
  background: #fdd640;
  margin: 0px 12px 12px;
  transition: 0.2s ease;
}

.yellowButton:hover {
  background: #00053c;
}

.row {
  margin-right: 0px;
}

.reviewModalName {
  margin: 0px 20px;
  /* margin: 0px 7px; */
  text-decoration: none;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  /* or 181% */
  align-items: center;

  color: #1778f2 !important;
}

.searchBoxDiv {
  max-width: 281px;
  min-width: 281px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.table>tbody>tr>td {
  padding: 30px 15px;
}

/* Review Css  Start*/
.ratingSpan {
  color: white;
  /* padding: 10px 10px; */
  padding: 8px 10px 5px;
  /* background: #1778f2; */
  border-radius: 26px;
  font-weight: 500;
  font-size: 16px;
  margin-left: 15px;
}

.yelpStarColor {
  background: #bf2519;
}

.googleStarColor {
  background: #fdd640;
}

.fbStarColor {
  background: #39559a;
}

.zillowStarColor {
  background: #38cb89;
}

.fbRatingStar {
  color: white;
  padding: 10px 10px;
  padding: 8px 10px 5px;
  background: #1778f2;
  border-radius: 26px;
  background: #1778f2;
  font-weight: 500;
  font-size: 16px;
  margin-left: 15px;
  white-space: nowrap;
}

.ratingSpan>img {
  padding: 0 5px;
  width: 30px;
  margin-top: -4px;
}

/* vue2-editior style start */
.form-white>div {
  width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  background: #f4f5fa;
  border-radius: 15px;
  font-weight: 500;
  font-size: 20px;
  color: #9194b3;
}

::v-deep .ql-toolbar.ql-snow {
  border: 0;
  background: rgba(145, 148, 179, 0.1);
  border-top: 1px solid rgba(145, 148, 179, 0.2);
}

::v-deep .ql-container.ql-snow {
  border: 0;
  height: 150px;
  overflow: scroll;
  color: black !important;
}

::v-deep .quillWrapper.ql-editor {
  display: flex;
  display: -ms-flexbox;
  flex-direction: column;
  /* flex-direction: column-reverse; */
}

::v-deep .ql-toolbar.ql-snow .ql-formats {
  margin-right: 0;
  color: #9194b3 !important;
}

::v-deep .ql-toolbar.ql-snow span.ql-formats svg {
  width: 16px;
  height: 16px;
}

::v-deep .ql-toolbar.ql-snow span.ql-formats svg line,
.ql-toolbar.ql-snow span.ql-formats svg path {
  stroke: #9194b3;
}

::v-deep .ql-snow .ql-picker {
  color: #9194b3;
}

/* vue2-editior style ends */
.loginName {
  margin-left: 10px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 29px;
  color: #00053c;
}

.loginPic {
  width: 47px;
  height: 47px;
}

.viewReviewInnerBox {
  background-color: white;
  margin-bottom: 20px;
}

.viewReviewInnerBoxImg {
  margin: 20px 20px;
}

.viewReviewInnerBoxPara {
  word-wrap: break-word;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #9194b3;
}

.viewReviewInnerBoxAnchor {
  text-decoration: none;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #4285f4;
}

.viewReviewSecondPartTopText {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #9194b3;
}

.viewReviewSecondPartTopName {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 20px;
  color: #00053c;
}

.viewReviewSecondPartTextArea {
  margin: 15px 0px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  /* or 181% */

  display: flex;
  align-items: center;

  color: #9194b3;

  width: 451px;
  height: 150px;
  left: 889px;
  top: 491px;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  border-radius: 15px;
}

.loginButtonText {
  justify-content: center;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  /* or 181% */
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.viewReviewSecondPartdropdown {
  margin: 15px 0px;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  border-radius: 15px;
}

.viewReviewSecondPartdropdownText {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px !important;
  /* font-size: 16px !important; */
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #9194b3;
}

.filterText {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  display: flex;
  color: #9194b3;
}

.profilePic {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 5px solid #ffffff;
  position: absolute;
  margin: -80px 50px;
}

/* Review Css End */
.profileFormTitle {
  text-decoration-line: none;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  align-items: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #9194b3 !important;
}

.profileFormTitleValue {
  text-decoration-line: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  /* font-size: 12px; */
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #00053c !important;
}

.profileName {
  margin-left: 226px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #00053c;
}

.profileSettingSwitch {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.3;
  display: block;
  text-align: center;
  color: #9194b3;
  text-decoration-line: none;
  padding-bottom: 15px;
}

.profileSettingSwitch.active {
  color: #ffab00;
}

.profileSettingIcons {
  padding-right: 15px;
  margin-top: 5px;
  height: 33px;
}

.platFormCard {
  padding: 60px 0px 10px 0;
  border-radius: 15px !important;
}

.platFormCardGroup {
  column-gap: 20px;
  margin: 30px 20px;
}

.tourButton {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 29px;
  background: #00053c;
  color: #ffffff;
  width: 125px;
  height: 40px;
  border-radius: 15px;
}

.platFormCardButton {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  /* or 181% */
  background: #00053c;
  color: #ffffff;
  width: 180px;
  height: 51px;
  border-radius: 15px;
}

.platFormCardButtonColor {
  background: #38cb89;
}

.profileSettingHeading {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #00053c;
  margin-top: 24px;
  margin-left: 24px;
}

.communicationFormLabel {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 29px;
  /* or 207% */

  display: flex;
  align-items: center;
  letter-spacing: 1px;

  color: #9194b3;
}

.choose-files {
  align-self: flex-end;
  z-index: 10;
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;

  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.button-wrapper.btn:hover {
  background-color: #4e5056;
}

.label {
  margin-bottom: 20px;
  color: #4e5056;
  font-size: 18px;
}

.button-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.btn {
  border: none;
  padding: 10px;
  /* padding: 10px 20px; */
  border-radius: 50px;
  background-color: #f4f5fa;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 200ms ease-in;
}

.drop-it-hot {
  margin: 34px 0px;
  border-radius: 15px;
  /* height: 485px; */
  height: 375px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 8px 15px 0 rgba(black, 0.1);
  overflow: hidden;
  color: #f4f5fa;
  border: 1px dashed rgba(145, 148, 179, 0.5);
  box-sizing: border-box;
}

.communicationImg {
  margin: 34px 0px;
  border-radius: 15px;
  /* height: 485px; */
  height: 375px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 8px 15px 0 rgba(black, 0.1);
  overflow: hidden;
  color: #f4f5fa;
}

.drop-it-hot:after {
  content: "";
  position: absolute;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  border: 2px dashed #e6e7f0;
  border-radius: 5px;
  z-index: 0;
}

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  color: #f4f5fa;
  background-color: white;
  /* border: 3px solid black; */
  width: 100px;
  height: 100px;
}

.circle {
  transition: transform 150ms ease-in;
  z-index: 10;
}

svg {
  width: 40px;
  height: 40px;
}

.circle:before {
  content: "";
  background-color: #f4f5fa;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  position: absolute;
  opacity: 0;
  transition: transform 250ms ease-in, opacity 200ms ease-in;
  z-index: 0;
}

.circle:after {
  content: "";
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.circle:hover {
  transform: scale(1.2);
  opacity: 0.9;
}

.circle:before {
  transform: scale(8);
  opacity: 1;
}

.circle:after {
  border: 3px solid white;
}

svg {
  color: white;
  z-index: 1;
}

.loginInputsTextArea {
  width: 100%;
  height: 150px;

  background: #f4f5fa;
  border-radius: 15px;
  border-style: none;
}

.assignFollowUp {
  height: 50px;
  left: 889px;
  top: 413px;
  /* background: #ffffff; */
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  border-radius: 15px;
  width: 100%;
  padding: 10px;
}

.respondButton {
  margin-top: 20px;
  right: 53.47%;
  top: 703px;
  bottom: 25.68%;
  width: 100%;
  height: 50px;
  background: #00053c;
  border-radius: 15px;
}

textarea {
  resize: none;
}

.inputOrange:focus {
  outline: none !important;
  border: 1.5px solid #ff8c00;
  box-shadow: 0 0 2px #ff8c00;
}

form i {
  margin-left: -30px;
  cursor: pointer;
}

.form-control1 {
  display: inline-block !important;
}


.navOutDiv {
  position: fixed;
  width: 100%;
}

.dashScroll {
  margin-right: 0 !important;
  padding-right: 15px;
}

.reviewDropDown {
  width: 96%;
  border-radius: 6px;
  position: absolute;
  inset: 0px auto auto 0px;
  margin: 0px;
  transform: translate(9px, 233px);
}

.outer-circle-new {
  background: rgba(137, 176, 240, 0.1);
  border-radius: 15px;
  padding: 10px;
  margin: 0;
}

.outer-circle-pending {
  background: rgba(255, 171, 0, 0.12);
  border-radius: 15px;
  padding: 10px;
}

.outer-circle-resolved {
  background: #e5f7ef;
  border-radius: 15px;
  padding: 10px;
}

.inner-new {
  color: #89b0f0;
}

.inner-pending {
  color: #ffab00;
}

.inner-resolved {
  color: #38cb89;
}

.center-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.view-button {
  margin-top: 20px;
  right: 53.47%;
  top: 703px;
  bottom: 25.68%;
  width: 25%;
  height: 50px;
  background: #00053c;
  border-radius: 15px;
  padding: 10px;
  transition: 0.2s ease;
}

.view-button:hover {
  background: #00053c;
}

.filter-bg-color {
  background-color: #f4f5fa;
  border-radius: 15px;
  padding: 5px 10px;
  margin: 0 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.assignFilter {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 15px;
  width: 100%;
  justify-content: flex-end;
  margin: 0 0 10px;
}

.basic-info {
  margin: 20px;
  border-radius: 20px;
  background: rgb(244, 245, 250);
  padding: 20px;
}

.edit-text {
  width: 33px;
  height: 16px;
  left: 1302px;
  top: 703px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #9194b3;
  padding: 10px 10px 0px;
}

.basic-card {
  background: rgb(255, 255, 255);
  margin: 20px;
  padding: 20px;
  border-radius: 15px;
}

.communication-setting {
  padding: 36px;
  border-radius: 15px;
  margin-bottom: 20px;
}

.role-table-pagination .pagination {
  justify-content: center;
}

.admin-new-pagination ul.pagination {
  background: none;
}

.admin-new-pagination ul li {
  flex-grow: initial !important;
  box-shadow: 0px 2px 6px #0000000a;
}

.admin-new-pagination {
  padding: 35px 0;
  background: #fff;
  overflow: auto;
}

.admin-new-pagination .pagination {
  /* justify-content: flex-end; */
  height: auto;
  box-shadow: none;
}

.admin-new-pagination .pagination li.active a.page-link {
  background: #623cea;
  border-radius: 0px;
  border-color: #623cea;
  color: #fff;
}

.admin-new-pagination .pagination li.active a.page-link:focus {
  box-shadow: none;
}

.admin-new-pagination .pagination a.page-link {
  color: #a4afb7;
  background: #fff;
}

.admin-new-pagination ul li span,
.admin-new-pagination ul li a,
.admin-new-pagination ul li button {
  padding: 10px 15px !important;
  font-size: 13px !important;
}

.admin-new-pagination .pagination a.page-link:focus {
  box-shadow: none;
}

.dark-theme .admin-new-pagination .pagination li.active a.page-link {
  background: #1a73e8;
  border-color: #1a73e8;
}

.spinner-center {
  justify-content: center;
  align-items: center;
  display: flex;
}

.isDisabled {
  pointer-events: none;
  color: red;
}

.is-invalid {
  background-image: none !important;
  border-color: #dc3545 !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545 !important;
}

.logo-center {
  justify-content: center;
  margin-left: -15px !important;
}

.profile-dropdown {
  margin-left: 10px;
  /* margin-bottom: 10px; */
}

.table-align {
  margin-top: 15px;
  border-radius: 36px;
  margin-left: 10px;
}

table th:first-child {
  border-radius: 15px 0 0 15px;
}

table th:last-child {
  border-radius: 0 15px 15px 0;
}

.table>tbody>tr>td,
.table>thead>tr>th {
  /* border: 0 !important; */
  border-top: none;
}

.header th {
  height: 10px;
  /* line-height: 2px;
}
.user-modal {
 /*  margin-left: 600px !important;
  margin-right: 600px !important; */
  max-width: 600px;
  margin: 100px auto;
}

.user-modal-heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #323b4b;
}

.profileSettingSwitch.active {
  border-bottom: 2px solid #ffab00;
  display: block;
}

.checkbox-lg .custom-control-label::before,
.checkbox-lg .custom-control-label::after {
  top: 0.8rem;
  width: 1.55rem;
  height: 1.55rem;
}

.checkbox-lg .custom-control-label {
  padding-top: 8px;
  padding-left: 6px;
}

.checkbox-xl .custom-control-label::before,
.checkbox-xl .custom-control-label::after {
  top: 1.2rem;
  width: 1.85rem;
  height: 1.85rem;
}

.checkbox-xl .custom-control-label {
  padding-top: 23px;
  padding-left: 10px;
}

table th {
  background-color: #f4f5fa !important;
  height: 51px !important;
  padding: 15px !important;
  text-align: center !important;
}

.file-upload-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #9194b3;
}

.account-outline {
  outline: currentcolor none medium !important;
  border: none;
  box-shadow: none;
}

.initialletter {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  background: #41c7db;
  font-size: 35px;
  color: #fff;
  text-align: center;
  line-height: 110px;
  margin: 20px 0;
}

/* style start */
.inner-white-wrap {
  border-radius: 36px;
  background: #fff;
  /* overflow: hidden; */
  height: 100%;
  padding: 15px;
}

.table-outer-wrap {
  padding: 30px;
  background: rgb(244, 245, 250);
  border-radius: 36px;
}

a.navbar-brand {
  padding: 30px 0 0 50px !important;
}

#sidebarCollapse {
  padding: 0;
  margin-top: 40px;
}

#sidebarCollapse li.nav-item a {
  position: relative;
}

#sidebarCollapse li.nav-item a::before {
  position: absolute;
  content: "";
  width: 46px;
  height: 46px;
  left: -36px;
  top: -6px;
  background: #fdd640;
  box-shadow: 0px 0px 17px 5px rgba(253, 214, 64, 0.12);
  border-radius: 15px;
  transform: rotate(45deg);
  opacity: 0;
  transition: 0.3s ease all;
}

#sidebarCollapse li.nav-item a {
  margin: 0;
  padding-left: 30px;
  margin-top: 10px;
}

#sidebarCollapse li.nav-item a.active::before {
  opacity: 1;
}

#sidebarCollapse li.nav-item a:hover::before {
  opacity: 1;
}

#sidebarCollapse li.nav-item a:hover {
  background: transparent;
}

.card-header h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 32px;
  color: #00053c;
}

.loginButton:hover {
  background: #fdd640 !important;
}

.loginButton:hover p.loginButtonText {
  color: #fff !important;
}

.action_btn-platform .loginButtonText {
  height: auto !important;
}

.table-outer-wrap .pagination button,
.table-outer-wrap .pagination span {
  margin: 0 3px;
}

table td h1 {
  font-size: 16px;
}

.modal-container-view {
  width: 800px;
  margin: 0 !important;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  padding: 30px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

/* file upload style */
input#file-upload {
  padding-left: 76px;
  font-size: 13px;
}

input#file-upload::after {
  content: "Upload Profile Image";
  position: absolute;
  top: 7px;
  left: 20px;
  background: white;
  width: 150px;
  height: 30px;
  line-height: 30px;
  padding: 0 5px;
  font-size: 14px;
  font-weight: 600;
  color: #68696a;
  border-radius: 10px 0 0 10px;
}

.rating-wrap {
  display: inline-block;
}

.star-wrapper {
  display: inline-flex;
  align-items: center;
  margin: 10px !important;
  padding: 0 !important;
}

.star-wrapper+p {
  display: flex;
  align-items: center;
}

button p {
  /* margin: 5px 0; */
}

.profile-img {
  display: inline-flex;
  height: 47px;
  width: 47px;
  border-radius: 50%;
  background: #f7f7f7;
  border: 1px solid #f7f7f7;
  object-fit: cover;
  align-items: center;
  justify-content: center;
}

.profile-img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #f7f7f7;
  object-fit: cover;
}

.profile-inner {
  display: inline-flex !important;
  align-items: center;
  padding: 5px;
  background: #f7f7f7;
  border-radius: 30px;
  white-space: nowrap;
}

.profile-inner .loginName {
  margin-bottom: 0;
}

.new-color {
  text-decoration: none;
  color: #ff8c00 !important;
}

.modal-container.modal-sm {
  width: 100% !important;
  max-width: 500px;
}

.modal-container.modal-video {
  width: 100% !important;
  max-width: 600px;
}

@media (min-width: 1400px) {
  .modal-container {
    max-width: 1000px;
    width: 100% !important;
    margin: 0 auto !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    max-height: calc(100vh - 0px);
    overflow-y: auto;
  }

  .modal-container-youtube {
    max-width: 1000px;
    width: 100% !important;
    margin: 0 auto !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    max-height: calc(100vh - 0px);
    overflow-y: auto;
  }

  .formInput {
    padding-top: 20px;
  }
}

@media (max-width: 1599.98px) {
  .table>tbody>tr>td {
    padding: 10px;
  }

  table .btn {
    padding: 0 5px !important;
  }
}

@media (max-width: 1299.98px) {
  .modal-container {
    max-width: 85% !important;
  }

  .modal-container-youtube {
    max-width: 85% !important;

  }

  .text-width {
    width: auto !important;
  }

  .platFormCardGroup {
    margin: 0;
    -moz-column-gap: 15px;
    column-gap: 15px;
  }

  .platFormCard {
    padding: 50px 0 5px;
    height: auto;
  }
}

@media (max-width: 1199.98px) {
  .platFormCardGroup {
    margin: 0;
    -moz-column-gap: 15px;
    column-gap: 15px;
  }

  .platFormCard {
    padding: 30px 0 5px;
    height: auto;
  }

  .profileSettingHeading {
    margin: 0;
  }

  .com-set-wrap {
    margin: 0 !important;
  }

  .youtube-video {
    width: 300px;
    height: 150px;
    margin: 150px auto;
  }
}

@media (max-width: 991.98px) {

  .login-wrap .formInput {
    margin-top: 0 !important;
  }

  .fbStarWidth {
    width: 100%;
  }

  .viewReviewInnerBoxImg {
    display: block;
  }

  .text-width {
    margin: 10px !important;
  }

  .feedback-modal-container {
    top: 0;
    transform: translate(-50%, 50%);
  }

  /* responsive sidebar */
  .navbar-collapse.collapse {
    display: none !important;
    background: #27293b;
    width: 100%;
    height: auto;
    padding: 10px 20px !important;
    margin: 0;
  }

  .sideBar {
    height: auto !important;
  }

  a.navbar-brand {
    padding: 0 !important;
  }

  .profile-wrap {
    display: none !important;
  }

  #sidebarCollapse {
    margin-top: 0;
  }

  .btn-logo-wrap button.navbar-toggler {
    position: absolute;
    right: 30px;
    background: #fff !important;
  }

  .container-fluid.leftPanel {
    position: relative;
  }

  .btn-logo-wrap {
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 0 30px;
  }

  .navbar-collapse.collapse.show {
    display: block !important;
  }

  .navOutDiv {
    position: unset;
  }

  #sidebarCollapse ul.navbar-nav {
    width: 100%;
  }

  .dashboard-view-card {
    width: 100%;
  }

  .relative-pos {
    width: 100%;
  }

  .dashboard-graph-card {
    width: 100%;
    height: 350px;
    height: auto;
  }

  .dashboard-review-card {
    width: 100%;
    height: 350px;
    height: 100%;
  }

  .modal-container-view {
    top: 0;
    transform: translate(-50%, 50%);
    width: 90%;
  }

  .youtube-video {
    width: 300px;
    height: 150px;
    margin: 150px auto;
  }
}

@media (max-width: 767.98px) {
  .youtube-video {
    width: 300px;
    height: 150px;
    margin: 150px auto;
  }

  .fbStarWidth {
    width: 100%;
  }

  .table-align>.card {
    margin: 12px !important;
  }

  .card.border-0.mb-7.inner-feedback-action {
    margin: 0 10px;
  }

  .card.border-0.feeback-action.col-9 {
    width: 100% !important;
  }

  .detail-wrap {
    margin-left: 0 !important;
  }

  .modal-container {
    /* margin-left: 20px;
    margin-right: 20px; */
  }

  .feedback-modal-container {
    width: 90%;
  }

  .navbar-collapse.collapse {
    padding: 10px 0px !important;
  }

  main .container {
    max-width: 100%;
  }

  table td h1 {
    font-size: 15px;
  }

  .card-header h2 {
    font-size: 26px;
  }

  .requestFrom {
    margin: 20px;
  }

  ul.navbar-nav {
    margin: 0 !important;
  }

  #sidebarCollapse li.nav-item a::before {
    left: -26px;
    width: 36px;
    height: 36px;
    top: 0;
  }

  .searchBox {
    min-width: auto;
    max-width: unset;
    width: 100%;
  }

  .table-responsive {
    border: 0;
  }

  .table-responsive .table th {
    border: 0;
  }

  .modal-wrapper a {
    cursor: pointer;
  }

  .table-outer-wrap {
    padding: 12px;
  }

  div#userForDiv {
    margin: 0;
    margin-top: 10px;
  }

  .profileName {
    margin-left: 0;
    margin-top: 0;
    font-size: 16px;
  }

  .tab-wrap {
    flex-wrap: wrap;
    width: 100% !important;
  }

  .tab-wrap>.col-12 {
    width: 50%;
  }

  .tabs-outer {
    margin: 0 !important;
  }

  .basic-info {
    margin: 10px 0 0 0;
  }

  .basic-card {
    margin: 0;
  }

  .profilePic {
    margin: -80px 30px;
  }

  .profile-text-wrap {
    padding: 0 !important;
    margin-top: 60px;
  }

  .com-set-wrap {
    margin: 0 !important;
  }

  .communication-setting {
    padding: 10px 5px 0px 20px;
  }

  .dashboard-view-card {
    width: 100%;
  }

  .relative-pos {
    width: 100%;
  }

  .dashboard-graph-card {
    width: 100%;
    height: 350px;
    height: auto;
  }

  .dashboard-review-card {
    width: 100%;
    height: 350px;
    height: 100%;
  }

  .business-modal-container {
    width: 90%;
  }

  .business-modal-history-container {
    width: 90%;
  }

  div#drop-area {
    margin-top: 0;
  }

  .connectButton {
    width: 50%;
    height: 75px;
  }

  .frontrunnerText {
    font-size: 12px;
    margin: 0 auto;
  }
}

.connectButton {
  border-radius: 15px;
  border: 1px solid #00053c;
  background: white;

  font-family: "Inter", sans-serif;
  font-size: 18px;
  width: 75%;
  height: 50px;
  font-weight: 600;
}

.frontrunnerText {
  font-size: 14px;
  background: white;
  color: #00053c;
  margin: 0 auto;
}

.sync-svg {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 40px;
  top: 10px;
  cursor: pointer;
}

/* Dashboard CSS */
.dashboard-card {
  position: static;
  width: auto;
  height: auto;
  background: #f4f5fa;
  border-radius: 20px;
}

.relative-pos {
  position: relative;
}

.review-journey {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #00053c;
}

.mt-35 {
  margin-top: 35px;
}

.dashboard-view-card {
  width: 239px;
  height: 137px;

  background: #ffffff;
  border-radius: 15px;
  float: left;
  padding: 10px;
  margin: 10px;
}

.dashboard-per-card {
  height: 116px;
}

.dashboard-graph-card {
  width: 500px;
  height: auto;
  background: #ffffff;
  border-radius: 15px;
  padding: 10px;
  float: left;
  margin: 10px;
}

.dashboard-review-count {
  color: #00053c;
}

.dashboard-review-text {
  width: 89.97px;
  height: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #9194b3;
  margin: 15px;
}

.date-color {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.3;
  display: flex;
  align-items: center;
  color: #9194b3;
  margin-left: 10px;
}

.dashboard-review-star {
  width: 94px;
  height: 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #00053c;
  margin: 10px;
}

.dashboard-all-perc {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #38cb89;
  margin: 10px;
}

.dashboard-month-perc {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #4285f4;
  margin: 10px;
}

.dashboard-recent-reviews {
  width: 193px;
  height: 14px;
  left: 883px;
  top: 362px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #00053c;
  margin: 10px;
}

.customer-name {
  margin: 10px;
}

.review-star {
  position: absolute;
  right: 10px;
}

.dashboard-customer-review-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: #9194b3;
}

.review-time {
  position: absolute;
  top: 25px;
  right: 10px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #9194b3;
}

.dashboard-owner-response {
  position: absolute;
  left: 18%;
  right: 12%;
  top: 41.71%;
  bottom: 30.17%;
  background: #f4f5fa;
  border-radius: 15px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .fbStarWidth {
    width: 100%;
  }

  .dashboard-view-card {
    width: 100%;
  }

  .dashboard-graph-card {
    width: 100%;
    height: 350px;
    height: auto;
  }

  .dashboard-review-card {
    width: 100%;
    height: 350px;
    height: 100%;
  }

  .relative-pos {
    width: 100%;
  }

  .form-white.inner-white {
    margin-left: 0 !important;
  }

  .form-outline.form-white.inner-white {
    margin-left: 10px !important;
  }

  .inner-white {
    padding: 10px 0;
  }

  .action_btn-platform-wrap {
    justify-content: center;
  }

  .action_btn-platform {
    flex: 0 0 100%;
  }
}

.inner-head-wrap {
  display: flex;
  display: -ms-flexbox;
  align-items: baseline;
}

.inner-head-wrap .data-h1 {
  padding-right: 10px;
}

.data-h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #00053c;
}

.buttons-platform {
  width: 200px;
  margin: 0 auto;
  display: inline;
}

.action_btn-platform-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.action_btn-platform {
  /* width: 200px; */
  /* margin: 20px auto 0; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  color: #ffffff;
  height: 40px;
  border-radius: 10px;
  margin: 10px 0;
}

.action_btn_color__connected {
  background: #38cb89;
}

.action_btn_color__disconnected {
  background: #ff8c00;
}

.platform__connected {
  color: #38cb89;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 29px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
}

#editorid .ql-editor {
  width: 100%;
  height: 150px;
  background: #f4f5fa;
  border-radius: 15px;
}

.feeback-action {
  border-radius: 20px;
  background: #f4f5fa;
}

.dashboard-review-card {
  width: 100%;
  background: #ffffff;
  border-radius: 15px;
  padding: 10px;
  margin: 10px;
}

.view-more-btn {
  border-radius: 15px;
  border: 1px solid #00053c;
  background: white;

  font-family: "Inter", sans-serif;
  font-size: 18px;
  width: 50%;
  /* height: 50px; */
  font-weight: 600;
  margin: 5px auto;
}

.business-modal-container img {
  word-break: break-all;
  word-wrap: break-word;
}

.f-start {
  align-self: flex-start;
}

.fbStarWidth {
  width: 100px;
  margin: 0;
}

.imgBtnCenter {
  margin: 0 auto;
}

:deep(.v-step) {
  background: #D7E9F7 !important;
  color: #000 !important;
  box-shadow: none !important;
  /* max-width: none !important; */
  width: 450px !important;
  max-width: 500px !important;
  border-radius: 15px !important;
  z-index: 1 !important;
}

:deep(.v-step__button) {
  font-family: "Inter", sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 29px !important;
  background: #00053c !important;
  color: #ffffff !important;
  width: 125px !important;
  height: 40px !important;
  border-radius: 15px !important;
}

.squareWizard {
  cursor: pointer;
  border: 4px solid red;
}

:deep(.vue-para) {
  font-size: 16px
}

.hiddenimg {
  display: none;
}

.hiddentxt {
  font-weight: bold;
  color: #F00;
  z-index: 99;
}

.hiddentxt a {
  color: #F00;
  text-decoration: none;
  z-index: 99;
}

.hiddenclick {
  font-weight: bold;
  color: #F00;
  text-decoration: none;
  cursor: pointer;
}

.hiddenclick a {
  color: #F00;
  text-decoration: none;
}

.hiddenclick a:visited {
  color: #F00;
  text-decoration: none;
}

.hiddentxt:hover~.hiddenimg {
  display: block;
  position: absolute;
  z-index: 2
}

.hiddenclickimg {
  display: none;

}

.formInput {
  margin-right: 10px;
  position: absolute;
  padding: 15px;
  min-width: 40px;
  text-align: center;
}
</style>
