<template>
  <footer class="footer site-footer">
    <div class="container">
      <div class="site-footer-inner">
        <div class="footer-left">
          <h1 class="logo">
            <img src="resources/assets/logo_landing.png" alt="" />
          </h1>
          <p>Copyright {{ new Date().getFullYear() }} Reviewzy - All right reserved</p>
        </div>
        <div class="footer-right">
          <ul class="footer-links">
            <li><a href="#id">Home</a></li>
            <li><a href="#features">Features</a></li>
            <li><a href="#pricing">Pricing</a></li>
            <li><a href="#about">About Us</a></li>
            <li><a href="#faq">FAQ</a></li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "LandingFooter",
  // data() {},
  mounted() { },
  methods: {},
};
</script>

<style scoped>
.container {
  flex: 1;
  /* same as flex-grow: 1; */
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

h2,
h3,
a {
  color: #34495e;
}

a {
  text-decoration: none;
}


.main-footer {
  padding: 10px 15px;
  /* text-transform: uppercase; */
  text-align: center;
  display: block;
}

.main-footer a {
  padding: 10px 15px;
  /* text-transform: uppercase; */
  text-align: center;
  display: block;
}

.main-footer a {
  color: #9194b3;
  font-size: 0.99em;
}

.main-footer a:hover {
  color: #718daa;
}

.signUpText {
  justify-content: center;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  /* or 181% */
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

footer {
  background-color: #fff;
  color: #bbb;
  line-height: 1.5;
  overflow: hidden;
  /* position: fixed; */
  bottom: 0;
  width: 100%;
}

.float-container {
  border: 3px solid #fff;
  padding: 20px;
}

.float-child {
  width: 50%;
  float: left;
  padding: 20px;
}

/* ================================= 
      Media Queries
    ==================================== */

.main-footer {
  display: flex;
  place-content: flex-end;
}

.footer-links li a {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 29px;
  color: #9194B3;
}

.footer-links li:not(:last-child) {
  padding-right: 60px;
}

.footer-links li {
  display: inline-block;
  vertical-align: middle;
}

.site-footer-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 -15px;
  flex-wrap: wrap;
}

.footer-left {
  flex: 0 0 auto;
}

.footer-right {
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;
}

.footer-left,
.footer-right {
  padding: 50px 15px 50px 15px;
}

.footer-left p {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  margin: 0;
  color: #9194B3;
}

@media (max-width: 991.98px) {

  .footer-left,
  .footer-right {
    text-align: center;
  }

  .footer-right {
    padding-top: 0 !important;
    justify-content: center;
  }

  .site-footer-inner {
    justify-content: center;
  }
}

@media (max-width: 767.98px) {

  .footer-left,
  .footer-right {
    flex: 0 0 100%;
  }

  .footer-right {
    padding-top: 0 !important;
    justify-content: center;
  }

  .site-footer-inner {
    justify-content: center;
  }

  .footer-left {
    text-align: center;
  }

  .footer-right {
    padding-top: 0;
  }

  .footer-links {
    text-align: center;
    margin: 0 -15px;
    align-items: center;
    justify-content: center;
  }

  .footer-links li {
    padding: 10px 15px !important;
  }
}
</style>