<template>
    <!-- Vertical Navbar -->
    <div>
        <div v-if="apiLoading" class="loading">
            Loading&#8230;
        </div>
        <div class="d-flex flex-column flex-lg-row h-lg-full navOutDiv">
            <!-- Vertical Navbar -->

            <Sidebar></Sidebar>
            <!-- Main content -->
            <div class="h-screen flex-grow-1 overflow-y-lg-auto m-4 dashScroll">
                <main class="py-6">
                    <div class="row align-items-center">
                        <div class="col-sm-6 col-12 mb-4 mb-sm-0">
                            <div class="card-header" style="background: none; border: none">
                                <h2 class="mb-0">Client Details</h2>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-11 col-12 mb-4 mb-sm-0 basic-info">
                        <div style="margin-top: 20px">
                            <div class="com-set-wrap"
                                style=" background-color: white; margin: 0px 27px; border-radius: 15px;">

                                <header class="bg-surface-primary border-bottom pt-6 profile-text-wrap"
                                    style="margin: 15px; padding-bottom: 50px">
                                    <div>
                                        <form class="d-block" enctype="multipart/form-data">
                                            <div style="padding: 15px">
                                                <div class="d-flex justify-content-between align-items-center mb-5">
                                                    <h1>Basic Information</h1>
                                                </div>
                                                <div class="row">
                                                    <div class="col-6 col-md-6 mb-4 pb-1">
                                                        <div class="form-outline form-white">
                                                            <p class="communicationFormLabel">
                                                                First Name*
                                                            </p>
                                                            <input type="text" v-model="clientData.first_name"
                                                                v-validate="'required'"
                                                                v-bind:name="'clientData.first_name'"
                                                                v-bind:ref="'clientData.first_name'" :class="{
                                                                  'is-invalid': errors.has('clientData.first_name'),
                                                                }"
                                                                class="form-control userForm form-control-lg inputOrange"
                                                                placeholder="First Name" />
                                                        </div>
                                                    </div>
                                                    <div class="col-6 col-md-6 mb-4 pb-1">
                                                        <div class="form-outline form-white">
                                                            <p class="communicationFormLabel">
                                                                Last Name*
                                                            </p><input type="text" v-model="clientData.last_name"
                                                                v-validate="'required'"
                                                                v-bind:name="'clientData.last_name'"
                                                                v-bind:ref="'clientData.last_name'" :class="{
                                                                  'is-invalid': errors.has('clientData.last_name'),
                                                                }"
                                                                class="form-control userForm form-control-lg inputOrange"
                                                                placeholder="Last Name (Optional)" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="mb-4 pb-2">
                                                        <div class="form-outline form-white">
                                                            <p class="communicationFormLabel">
                                                                Organization Name*
                                                            </p>
                                                            <input type="text"
                                                                class="form-control userForm form-control-lg inputOrange"
                                                                v-model="clientData.organisation_name"
                                                                v-bind:name="'clientData.organisation_name'"
                                                                v-validate="'required'"
                                                                v-bind:ref="'clientData.organisation_name'" :class="{
                                                                  'is-invalid': errors.has('clientData.organisation_name'),
                                                                }" placeholder="Business/Organization Name"
                                                                autocomplete="off" @input="initializeOrganization"
                                                                id="autocompleteOrganization" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-6 col-md-6 mb-4 pb-1">
                                                        <div class="form-outline form-white">
                                                            <p class="communicationFormLabel">
                                                                Email Address*
                                                            </p>
                                                            <input type="email" v-model="clientData.email"
                                                                v-validate="'required'" v-bind:name="'clientData.email'"
                                                                v-bind:ref="'clientData.email'" :class="{
                                                                  'is-invalid': errors.has('clientData.email'),
                                                                }"
                                                                class="form-control userForm form-control-lg inputOrange"
                                                                placeholder="Enter Email Address" />
                                                        </div>
                                                    </div>
                                                    <div class="col-6 col-md-6 mb-4 pb-1">
                                                        <div class="form-outline form-white">
                                                            <p class="communicationFormLabel">
                                                                Password*
                                                            </p>
                                                            <input type="password" v-model="clientData.password"
                                                                v-validate="'required|min:6'"
                                                                v-bind:name="'clientData.password'"
                                                                v-bind:ref="'clientData.password'" :class="{
                                                                  'is-invalid': errors.has('clientData.password'),
                                                                }"
                                                                class="form-control userForm form-control-lg inputOrange"
                                                                @keydown.space.prevent placeholder="Enter Password" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style="padding: 15px">
                                                <div class="d-flex justify-content-between align-items-center mb-5">
                                                    <h1>Billing Information</h1>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6 mb-4 pb-2">
                                                        <input type="checkbox" id="formChecked" v-model="formChecked"
                                                            @change="check()" />
                                                        <label class="form-check-label fadeColor" for="formChecked">
                                                            <!-- My Billing Name and Address -->
                                                            Use My Previous Information
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-6 col-md-6 mb-4 pb-1">
                                                        <div class="form-outline form-white">
                                                            <p class="communicationFormLabel">
                                                                First Name*
                                                            </p>
                                                            <input type="text" v-model="clientData.b_first_name"
                                                                v-validate="'required'"
                                                                v-bind:name="'clientData.b_first_name'"
                                                                v-bind:ref="'clientData.b_first_name'" :class="{
                                                                  'is-invalid': errors.has('clientData.b_first_name'),
                                                                }"
                                                                class="form-control userForm form-control-lg inputOrange"
                                                                placeholder="First Name" />
                                                        </div>
                                                    </div>
                                                    <div class="col-6 col-md-6 mb-4 pb-1">
                                                        <div class="form-outline form-white">
                                                            <p class="communicationFormLabel">
                                                                Last Name*
                                                            </p><input type="text" v-model="clientData.b_last_name"
                                                                v-validate="'required'"
                                                                v-bind:name="'clientData.b_last_name'"
                                                                v-bind:ref="'clientData.b_last_name'" :class="{
                                                                  'is-invalid': errors.has('clientData.b_last_name'),
                                                                }"
                                                                class="form-control userForm form-control-lg inputOrange"
                                                                placeholder="Last Name (Optional)" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="mb-4 pb-2">
                                                        <div class="form-outline form-white">
                                                            <p class="communicationFormLabel">
                                                                Address*
                                                            </p>
                                                            <input type="text" v-model="clientData.b_billing_address"
                                                                id="autocomplete" @input="initialize"
                                                                v-validate="'required'"
                                                                v-bind:name="'clientData.b_billing_address'"
                                                                v-bind:ref="'clientData.b_billing_address'" :class="{
                                                                  'is-invalid': errors.has('clientData.b_billing_address'),
                                                                }"
                                                                class="form-control userForm form-control-lg inputOrange"
                                                                placeholder="Address" autocomplete="off" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-6 col-md-6 mb-4 pb-1">
                                                        <div class="form-outline form-white">
                                                            <p class="communicationFormLabel">
                                                                City*
                                                            </p>
                                                            <input type="text" v-model="clientData.b_city"
                                                                v-validate="'required'"
                                                                v-bind:name="'clientData.b_city'"
                                                                v-bind:ref="'clientData.b_city'" :class="{
                                                                  'is-invalid': errors.has('clientData.b_city'),
                                                                }"
                                                                class="form-control userForm form-control-lg inputOrange"
                                                                placeholder="City" />
                                                        </div>
                                                    </div>
                                                    <div class="col-6 col-md-6 mb-4 pb-1">
                                                        <div class="form-outline form-white">
                                                            <p class="communicationFormLabel">
                                                                State*
                                                            </p>
                                                            <input type="text" v-model="clientData.b_state"
                                                                v-bind:name="'clientData.b_state'"
                                                                v-validate="'required'"
                                                                v-bind:ref="'clientData.b_state'" :class="{
                                                                  'is-invalid': errors.has('clientData.b_state'),
                                                                }"
                                                                class="form-control userForm form-control-lg inputOrange"
                                                                placeholder="State" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-6 col-md-6 mb-4 pb-1">
                                                        <div class="form-outline form-white">
                                                            <p class="communicationFormLabel">
                                                                Zip code*
                                                            </p>
                                                            <input type="text" v-model="clientData.b_zip_code"
                                                                v-validate="'required'"
                                                                class="form-control userForm form-control-lg inputOrange"
                                                                placeholder="Zip code"
                                                                v-bind:name="'clientData.b_zip_code'"
                                                                v-bind:ref="'clientData.b_zip_code'" :class="{
                                                                  'is-invalid': errors.has('clientData.b_zip_code'),
                                                                }" />
                                                        </div>
                                                    </div>
                                                    <div class="col-6 col-md-6 mb-4 pb-1">
                                                        <div class="form-outline form-white">
                                                            <p class="communicationFormLabel">
                                                                Stripe ID*
                                                            </p>
                                                            <input type="text" v-model="clientData.stripe_id"
                                                                class="form-control userForm form-control-lg inputOrange"
                                                                placeholder="Stripe ID" v-validate="'required'"
                                                                v-bind:name="'clientData.stripe_id'"
                                                                v-bind:ref="'clientData.stripe_id'" :class="{
                                                                  'is-invalid': errors.has('clientData.stripe_id'),
                                                                }" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-6 col-md-6 mb-4 pb-1">
                                                        <div class="form-outline form-white">
                                                            <p class="communicationFormLabel">
                                                                Customer ID*
                                                            </p>
                                                            <input type="text" v-model="clientData.stripecustomerID"
                                                                class="form-control userForm form-control-lg inputOrange"
                                                                placeholder="Customer ID" v-validate="'required'"
                                                                v-bind:name="'clientData.stripecustomerID'"
                                                                v-bind:ref="'clientData.stripecustomerID'" :class="{
                                                                  'is-invalid': errors.has('clientData.stripecustomerID'),
                                                                }" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12 mb-4 pb-2">
                                            <div class="form-outline form-white d-flex flex-row-reverse">
                                                <button class="platFormCardButton" style="width: 150px"
                                                    @click="addClient()"> Save </button>
                                            </div>
                                        </div>
                                    </div>
                                </header>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    </div>
</template>

<script>
import Sidebar from './Sidebar'
export default {
    name: "AddClient",
    components: {
        Sidebar
    },
    data() {
        return {
            apiLoading: false,
            clientData: {
                id: "",
                first_name: "",
                last_name: "",
                email: "",
                password: "",
                organisation_name: "",
                billing_address: "",
                city: "",
                state: "",
                zip_code: "",
                b_first_name: "",
                b_last_name: "",
                b_billing_address: "",
                b_city: "",
                b_state: "",
                b_zip_code: "",
                frontRunner: "",
                is_status: "",
                stripe_id: "",
                stripecustomerID: "",
            },
            formChecked: false,
        };
    },

    mounted() {
        console.clear();
        this.clientData.id = this.$route.params.id ? this.$route.params.id : ""
        this.clientData.id ? this.editClient() : "";
    },
    created() { },
    computed: {},
    methods: {
        async addClient() {
            let result = await this.$validator.validateAll();
            if (result) {
                this.apiLoading = true;
                let data = {
                    userID: this.clientData.id,
                    first_name: this.clientData.first_name,
                    last_name: this.clientData.last_name,
                    organisation_name: this.clientData.organisation_name,
                    email: this.clientData.email,
                    billing_address: this.clientData.billing_address,
                    city: this.clientData.city,
                    state: this.clientData.state,
                    zip_code: this.clientData.zip_code,
                    b_first_name: this.clientData.b_first_name,
                    b_last_name: this.clientData.b_last_name,
                    b_billing_address: this.clientData.b_billing_address,
                    b_city: this.clientData.b_city,
                    b_state: this.clientData.b_state,
                    b_zip_code: this.clientData.b_zip_code,
                    frontRunner: this.clientData.frontRunner,
                    is_status: this.clientData.is_status,
                    password: this.clientData.password,
                    stripe_id: this.clientData.stripe_id,
                    stripecustomerID: this.clientData.stripecustomerID,
                }
                if (this.clientData.id) {
                    this.$store
                        .dispatch("clientUpdate", data)
                        .then((response) => {
                            if (response.success) {
                                this.$toastr.s(`${response.message}`);
                                this.$router.push({ path: "/admin/clients" });
                            } else {
                                if(response.data){
                                    this.$toastr.e(`${Object.values(response.data)}`);
                                }else{
                                this.$toastr.e(`${response.message}`);
                                }
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        })
                        .finally(() => (this.apiLoading = false));
                } else {
                    this.$store
                        .dispatch("clientAdd", data)
                        .then((response) => {
                            if (response.success) {
                                this.$router.push({ path: "/admin/clients" });
                                this.$toastr.s(`${response.message}`);
                            } else {
                                this.$toastr.e(`${Object.values(response.data)}`);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        })
                        .finally(() => (this.apiLoading = false));
                }

            }
        },
        editClient() {
            this.apiLoading = true;
            let data = {
                userID: this.clientData.id,
            };
            this.$store
                .dispatch("clientEdit", data)
                .then((response) => {
                    if (response.success) {
                        let res = response.data[0];
                        this.clientData.id
                        this.clientData.first_name = res.first_name;
                        this.clientData.last_name = res.last_name;
                        this.clientData.organisation_name = res.organisation_name;
                        this.clientData.email = res.email;
                        this.clientData.billing_address = res.billing_address;
                        this.clientData.city = res.city;
                        this.clientData.state = res.state;
                        this.clientData.zip_code = res.zip_code;
                        this.clientData.b_first_name = res.b_first_name;
                        this.clientData.b_last_name = res.b_last_name;
                        this.clientData.b_billing_address = res.b_billing_address;
                        this.clientData.b_city = res.b_city;
                        this.clientData.b_state = res.b_state;
                        this.clientData.b_zip_code = res.b_zip_code;
                        this.clientData.frontRunner = res.frontRunner;
                        this.clientData.is_status = res.is_status;
                        this.clientData.password = res.userToken;
                        this.clientData.stripe_id = res.stripe_id;
                        this.clientData.stripecustomerID = res.stripecustomerID;
                    } else {
                        this.$toastr.e(`${response.message}`);
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => (this.apiLoading = false));
        },
        initialize(e) {
            var self = this;
            let input = document.getElementById("autocomplete");
            let autocomplete = new google.maps.places.Autocomplete(input);
            autocomplete.addListener("place_changed", function () {
                let place = autocomplete.getPlace();
                self.clientData.b_billing_address = place.name;
                self.clientData.b_city =
                    place.address_components[
                        place.address_components.length - 4
                    ].long_name;
                self.clientData.b_state =
                    place.address_components[
                        place.address_components.length - 3
                    ].long_name;
                self.clientData.b_zip_code =
                    place.address_components[
                        place.address_components.length - 1
                    ].long_name;
            });
        },
        initializeOrganization(e) {
            var self = this;
            let input = document.getElementById("autocompleteOrganization");
            let autocomplete = new google.maps.places.Autocomplete(input);
            autocomplete.addListener("place_changed", function () {
                let place = autocomplete.getPlace();
                self.clientData.organisation_name = place.name;
            });
        },
        check() {
            if (this.formChecked) {
                this.clientData.b_first_name = this.clientData.first_name;
                this.clientData.b_last_name = this.clientData.last_name;
                this.clientData.b_billing_address = this.clientData.billing_address;
                this.clientData.b_city = this.clientData.city;
                this.clientData.b_state = this.clientData.state;
                this.clientData.b_zip_code = this.clientData.zip_code;
            } else {
                this.resetPaymentForm();
            }
        },
        resetPaymentForm() {
            this.clientData.b_first_name = "";
            this.clientData.b_last_name = "";
            this.clientData.b_billing_address = "";
            this.clientData.b_city = "";
            this.clientData.b_state = "";
            this.clientData.b_zip_code = "";
        },
    },
};
</script>
<style scoped>
::placeholder {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 29px;
    /* or 181% */
    display: flex;
    align-items: center;

    color: #9194b3;
}

.userForm {
    height: 50px;
    /* height: 58px; */
    border-radius: 15px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px !important;
}

.row {
    margin-right: 0px;
}

.platFormCardButton {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    /* or 181% */
    background: #00053c;
    color: #ffffff;
    width: 180px;
    height: 51px;
    border-radius: 15px;
}

.communicationFormLabel {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 29px;
    /* or 207% */

    display: flex;
    align-items: center;
    letter-spacing: 1px;

    color: #9194b3;
}


.navOutDiv {
    position: fixed;
    width: 100%;
}

.dashScroll {
    margin-right: 0 !important;
    padding-right: 15px;
}

.basic-info {
    margin: 20px;
    border-radius: 20px;
    background: rgb(244, 245, 250);
    padding: 20px;
}

.card-header h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600 !important;
    font-size: 32px;
    color: #00053c;
}

@media (min-width: 1400px) {}

@media (max-width: 1599.98px) {}

@media (max-width: 1299.98px) {}

@media (max-width: 1199.98px) {
    .com-set-wrap {
        margin: 0 !important;
    }
}

@media (max-width: 991.98px) {
    .sideBar {
        height: auto !important;
    }


    .navOutDiv {
        position: unset;
    }

}

@media (max-width: 767.98px) {
    main .container {
        max-width: 100%;
    }

    .card-header h2 {
        font-size: 26px;
    }

    .basic-info {
        margin: 10px 0 0 0;
    }

    .profile-text-wrap {
        padding: 0 !important;
        margin-top: 60px;
    }

    .com-set-wrap {
        margin: 0 !important;
    }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    .form-white.inner-white {
        margin-left: 0 !important;
    }

    .form-outline.form-white.inner-white {
        margin-left: 10px !important;
    }
}
</style>
