<template>
    <!-- Vertical Navbar -->
    <div>
        <div class="d-flex flex-column flex-lg-row h-lg-full navOutDiv">
            <!-- Vertical Navbar -->
            <Sidebar></Sidebar>
            <!-- Main content -->
            <div class="h-screen flex-grow-1 overflow-y-lg-auto m-4 dashScroll">
                <main class="py-6">
                    <div class="row align-items-center">
                        <div class="col-12 mb-4 mb-sm-0">
                            <div class="card-header" style="background: none; border: none">
                                <h2 class="mb-0 fontWeightBolder">
                                    Dashboard
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div class="card border-0 mb-7 table-align" id="userForDiv">
                        <div class="table-outer-wrap">
                            <div class="inner-white-wrap">
                                <div class="table-responsive">
                                    <h2 style="text-align:center">You are logged in!</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    </div>
</template>
  
<script>
import Sidebar from './Sidebar'
export default {
    name: "AdminDashboard",
    components: {
        Sidebar
    },
    data() {
        return {
            show: "dashboard",
        };
    },

    mounted() { },
    created() { },
    computed: {},
    methods: {

    },
};
</script>

<style scoped>
.table-align {
    margin-top: 15px;
    border-radius: 36px;
    margin-left: 10px;
}

.inner-white-wrap {
    border-radius: 36px;
    background: #fff;
    /* overflow: hidden; */
    height: 100%;
    padding: 15px;
}

.table-outer-wrap {
    padding: 30px;
    background: rgb(244, 245, 250);
    border-radius: 36px;
}

@media (max-width: 767.98px) {

    .table-align>.card {
        margin: 12px !important;
    }

    .card.border-0.mb-7.inner-feedback-action {
        margin: 0 10px;
    }

    .card.border-0.feeback-action.col-9 {
        width: 100% !important;
    }


    table td h1 {
        font-size: 15px;
    }

    .card-header h2 {
        font-size: 26px;
    }

    .table-responsive {
        border: 0;
    }

    .table-responsive {
        border: 0;
    }

    .table-outer-wrap {
        padding: 12px;
    }

    div#userForDiv {
        margin: 0;
        margin-top: 10px;
    }

}
</style>