<template>
    <div>
        <div v-if="apiLoading" class="loading">
            Loading&#8230;
        </div>
        <div class="d-flex flex-column flex-lg-row h-lg-full navOutDiv">
            <!-- Vertical Navbar -->

            <Menu></Menu>
            <!-- Main content -->
            <div class="h-screen flex-grow-1 overflow-y-lg-auto m-4 dashScroll">
                <!-- Header -->
                <Header></Header>

                <!-- Main -->
                <div>
                    <main class="py-6">
                        <div class="row align-items-center">
                            <div class="col-sm-6 col-12 mb-4 mb-sm-0">
                                <div class="card-header" style="background: none; border: none">
                                    <h2 class="mb-0">Settings/ Account</h2>
                                </div>
                            </div>
                            <div class="col-md-6 col-12 mb-4 mb-sm-0 d-flex justify-content-end">
                            </div>

                        </div>

                        <header class="bg-surface-primary border-bottom pt-6 tabs-outer" style="margin-left: 50px">
                            <div>
                                <div class="mb-npx">
                                    <div class="row align-items-center tab-wrap">
                                        <div class="col-md-2 col-sm-3 col-12 mb-4 mb-sm-0">
                                            <a href="#" @click="showProfiles('basic')"
                                                :class="{ active: showProfile == 'basic' }"
                                                class="profileSettingSwitch">
                                                <span :class="{ 'account-active': showProfile == 'basic' }">
                                                    Basic Information
                                                </span></a>
                                        </div>
                                        <div class="col-md-2 col-sm-3 col-12 mb-4 mb-sm-0" v-if="checkPermission">
                                            <a href="#" @click="showProfiles('billing')"
                                                :class="{ active: showProfile == 'billing' }"
                                                class="profileSettingSwitch">
                                                <span :class="{
                                                    'account-active': showProfile == 'billing',
                                                }">
                                                    Billing Information
                                                </span>
                                            </a>
                                        </div>
                                        <div class="col-md-2 col-sm-3 col-12 mb-4 mb-sm-0" v-if="checkPermission">
                                            <a href="#" @click="showProfiles('platform')"
                                                :class="{ active: showProfile == 'platform' }"
                                                class="profileSettingSwitch">
                                                <span :class="{
                                                    'account-active': showProfile == 'platform',
                                                }">
                                                    Platform Connections
                                                </span></a>
                                        </div>
                                        <div class="col-md-2 col-sm-3 col-12 mb-4 mb-sm-0" v-if="checkPermission">
                                            <a href="#" @click="showProfiles('communication')"
                                                :class="{ active: showProfile == 'communication' }"
                                                class="profileSettingSwitch">
                                                <span :class="{
                                                    'account-active': showProfile == 'communication',
                                                }">
                                                    Communication Settings
                                                </span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>
                        <transition name="modal" v-if="adminProfileModal">
                            <div class="modal-mask">
                                <div class="modal-wrapper">
                                    <div class="modal-container">
                                        <div>
                                            <form class="d-block" enctype="multipart/form-data">
                                                <div class="
                            d-flex
                            justify-content-between
                            align-items-center
                            mb-5
                          ">
                                                    <h1>Edit Profile</h1>
                                                    <a @click="cancel()" class="f-start"><img
                                                            src="resources/assets/crossicon.png" /></a>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6 mb-4 pb-2">
                                                        <div class="form-outline form-white">
                                                            <input v-model="adminDetails.firstName" type="text"
                                                                id="form3Examplea4" class="
                                  form-control
                                  userForm
                                  form-control-lg
                                  inputOrange
                                " placeholder="First Name" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 mb-4 pb-2">
                                                        <div class="form-outline form-white">
                                                            <input v-model="adminDetails.lastName" type="text"
                                                                id="form3Examplea5" class="
                                  form-control
                                  userForm
                                  form-control-lg
                                  inputOrange
                                " placeholder="Last Name" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6 mb-4 pb-2">
                                                        <div class="form-outline form-white">
                                                            <input v-model="adminDetails.phone" type="number"
                                                                id="form3Examplea2" class="
                                  form-control
                                  userForm
                                  form-control-lg
                                  inputOrange
                                " placeholder="Phone" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 mb-4 pb-2">
                                                        <div class="form-outline form-white">
                                                            <input type="file" @change="onChangeFile" id="file-upload"
                                                                class="loginInputsEmail inputOrange"
                                                                placeholder="Select File" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="mb-4 pb-2">
                                                    <div class="form-outline form-white">
                                                        <input v-model="adminDetails.email" type="email"
                                                            id="form3Examplea2" class="
                                form-control
                                userForm
                                form-control-lg
                                inputOrange
                              " placeholder="Email Address" />
                                                    </div>
                                                </div>
                                                <div class="mb-4 pb-2">
                                                    <div class="form-outline form-white">
                                                        <input v-model="adminDetails.organisation_name" type="text"
                                                            id="form3Examplea2" class="
                                form-control
                                userForm
                                form-control-lg
                                inputOrange
                              " placeholder="Organization Name" />
                                                    </div>
                                                </div>
                                                <div class="mb-4 pb-2">
                                                    <div class="form-outline form-white">
                                                        <input v-model="adminDetails.billing_address" type="text"
                                                            id="billing_address" class="
                                form-control
                                userForm
                                form-control-lg
                                inputOrange
                              " placeholder="Billing Address" @input="initializeBillingAddress" />
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-4 mb-4 pb-2">
                                                        <div class="form-outline form-white">
                                                            <input v-model="adminDetails.city" type="text"
                                                                id="form3Examplea4" class="
                                  form-control
                                  userForm
                                  form-control-lg
                                  inputOrange
                                " placeholder="City" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 mb-4 pb-2">
                                                        <div class="form-outline form-white">
                                                            <input v-model="adminDetails.state" type="text"
                                                                id="form3Examplea5" class="
                                  form-control
                                  userForm
                                  form-control-lg
                                  inputOrange
                                " placeholder="State" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 mb-4 pb-2">
                                                        <div class="form-outline form-white">
                                                            <input v-model="adminDetails.pincode" type="text"
                                                                id="form3Examplea5" class="
                                  form-control
                                  userForm
                                  form-control-lg
                                  inputOrange
                                " placeholder="Zip code" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <button v-if="buttonText == 'Create'" type="button" class="loginButton"
                                                    @click="updateProfile()">
                                                    <p class="loginButtonText">Update Profile</p>
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <transition name="modal" v-if="adminBillingInfoModal">
                            <div class="modal-mask">
                                <div class="modal-wrapper">
                                    <div class="modal-container">
                                        <div>
                                            <form class="d-block">
                                                <div class="
                            d-flex
                            justify-content-between
                            align-items-center
                            mb-5
                          ">
                                                    <h1>Edit Blling Info</h1>
                                                    <a @click="cancel()"><img
                                                            src="resources/assets/crossicon.png" /></a>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-4 mb-4 pb-2">
                                                        <div class="form-outline form-white">
                                                            <input v-model="adminDetails.b_firstName" type="text"
                                                                id="form3Examplea4" class="
                                  form-control
                                  userForm
                                  form-control-lg
                                  inputOrange
                                " placeholder="First Name" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 mb-4 pb-2">
                                                        <div class="form-outline form-white">
                                                            <input v-model="adminDetails.b_lastNAme" type="text"
                                                                id="form3Examplea5" class="
                                  form-control
                                  userForm
                                  form-control-lg
                                  inputOrange
                                " placeholder="Last Name" />
                                                        </div>
                                                    </div>
                                                    <div class="mb-4 pb-2"></div>
                                                </div>

                                                <div class="mb-4 pb-2">
                                                    <div class="form-outline form-white">
                                                        <input v-model="adminDetails.b_billingAddress" type="text"
                                                            id="b_billingAddress" class="
                                form-control
                                userForm
                                form-control-lg
                                inputOrange
                              " placeholder="Address" @input="initializeBillingInfo" />
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-4 mb-4 pb-2">
                                                        <div class="form-outline form-white">
                                                            <input v-model="adminDetails.b_city" type="text"
                                                                id="form3Examplea4" class="
                                  form-control
                                  userForm
                                  form-control-lg
                                  inputOrange
                                " placeholder="City" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 mb-4 pb-2">
                                                        <div class="form-outline form-white">
                                                            <input v-model="adminDetails.b_state" type="text"
                                                                id="form3Examplea5" class="
                                  form-control
                                  userForm
                                  form-control-lg
                                  inputOrange
                                " placeholder="State" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 mb-4 pb-2">
                                                        <div class="form-outline form-white">
                                                            <input v-model="adminDetails.b_zip" type="text"
                                                                id="form3Examplea5" class="
                                  form-control
                                  userForm
                                  form-control-lg
                                  inputOrange
                                " placeholder="Zip" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <button v-if="buttonText == 'Create'" type="button" class="loginButton"
                                                    @click="updateBillingInfo()">
                                                    <p class="loginButtonText">Update Billing Info</p>
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <transition name="modal" v-if="adminUpdateCardModal">
                            <div class="modal-mask">
                                <div class="modal-wrapper">
                                    <div class="business-modal-container">
                                        <div>
                                            <UpdateCardInfoModal @closeModal="cancel()" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <transition name="modal" v-if="adminBusinessModal">
                            <div class="modal-mask">
                                <div class="modal-wrapper">
                                    <div class="business-modal-container">
                                        <div>
                                            <form class="d-block" enctype="multipart/form-data">
                                                <div class="d-flex justify-content-between align-items-center mb-5">
                                                    <h1>Add Business</h1>
                                                    <a @click="cancel()" :class="{ isDisabled: !call }" class="f-start"><img src="resources/assets/crossicon.png" /></a>
                                                </div>

                                                <div class="mb-4 pb-2">
                                                    <div class="form-outline form-white">
                                                        <input type="text" class="form-control business-input form-control-lg inputOrange" placeholder="Business name" id="autocomplete"
                                                            v-model="businessName" @input="initialize"
                                                            v-validate="'required'" v-bind:name="'businessName'" v-bind:ref="'businessName'" :class="{ 'is-invalid': errors.has('businessName') }"
                                                        />
                                                        <button type="button" class="business-btn spinner-center" @click="saveBusiness()" :disabled="loading == 1">
                                                            <span v-if="loading" class="loginButtonText">
                                                                Gathering reviews, please do not close the box.
                                                                <b-spinner class="spinner-center" variant="light" label="Spinner"> </b-spinner>
                                                            </span>
                                                            <p v-else class="loginButtonText">Submit</p>
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <transition name="modal" v-if="yelpBusinessModal">
                            <div class="modal-mask">
                                <div class="modal-wrapper">
                                    <div class="business-modal-container">
                                        <div>
                                            <form class="d-block" enctype="multipart/form-data">
                                                <div class="
                            d-flex
                            justify-content-between
                            align-items-center
                            mb-5
                          ">
                                                    <h1>
                                                        Add Yelp Business
                                                    </h1>
                                                    <a @click="cancel()" :class="{ isDisabled: !call }"
                                                        class="f-start"><img src="resources/assets/crossicon.png" /></a>
                                                </div>

                                                <div class="mb-4 pb-2">
                                                    <div class="form-outline form-white">
                                                        <input type="text" class="
                                form-control yelp-form-control1
                                business-input
                                form-control-lg
                                inputOrange
                              " placeholder="Enter Yelp Business Name" v-model="searchText" v-validate="'required'"
                                                            v-bind:name="'searchText'" v-bind:ref="'searchText'"
                                                            :class="{ 'is-invalid': errors.has('searchText') }" />
                                                        <span class="hiddentxt formInput"><img
                                                                src="resources/assets/info.svg" /></span><span
                                                            class="hiddenimg"><img src="resources/assets/JY-Yelp.png"
                                                                width="1000" /></span>
                                                    </div>
                                                </div>

                                                <div class="mb-4 pb-2">
                                                    <div class="form-outline form-white">
                                                        <input v-model="location" type="text" id="yelpComplete" class="
                            form-control yelp-form-control1
                                business-input
                                form-control-lg
                                inputOrange
                              " placeholder="Enter Yelp Address" @input="initializeYelp" v-validate="'required'"
                                                            v-bind:name="'location'" v-bind:ref="'location'"
                                                            :class="{ 'is-invalid': errors.has('location') }" />
                                                        <span class="hiddentxt formInput"><img
                                                                src="resources/assets/info.svg" /></span><span
                                                            class="hiddenimg"><img
                                                                src="resources/assets/JY-Yelp-address.png"
                                                                width="1000" /></span>
                                                        <span class="spinner-center">
                                                            <b-spinner v-if="loading" class="spinner-center"
                                                                variant="primary" label="Spinning">
                                                            </b-spinner>
                                                        </span>
                                                        <button type="button" class="business-btn spinner-center"
                                                            @click="saveYelpBusiness()" :disabled="yelpLoading == 1">
                                                            <b-spinner v-if="yelpLoading" class="spinner-center"
                                                                variant="primary" label="Spinning">
                                                            </b-spinner>
                                                            <p v-else class="loginButtonText">Submit</p>
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <transition name="modal" v-if="zillowModal">
                            <div class="modal-mask">
                                <div class="modal-wrapper">
                                    <div class="business-modal-container">
                                        <div>
                                            <form class="d-block" enctype="multipart/form-data">
                                                <div class="
                            d-flex
                            justify-content-between
                            align-items-center
                            mb-5
                          ">
                                                    <h1>
                                                        Add Business Link
                                                        <img src="resources/assets/info.svg"
                                                            title="Enter Business Link Like 'https://www.zillow.com/profile/FASTREALESTATE'" />
                                                    </h1>
                                                    <a @click="cancel()" :class="{ isDisabled: !call }"
                                                        class="f-start"><img src="resources/assets/crossicon.png" /></a>
                                                </div>

                                                <div class="mb-4 pb-2">
                                                    <div class="form-outline form-white">
                                                        <input type="text" class="
                                form-control
                                business-input
                                form-control-lg
                                inputOrange
                              " placeholder="Ex:-https://www.zillow.com/profile/FASTREALESTATE" v-model="screenName"
                                                            v-validate="'required'" v-bind:name="'screenName'"
                                                            v-bind:ref="'screenName'"
                                                            :class="{ 'is-invalid': errors.has('screenName') }" />
                                                        <button type="button" class="business-btn spinner-center"
                                                            @click="saveZillow()" :disabled="loading == 1">
                                                            <b-spinner v-if="loading" class="spinner-center"
                                                                variant="primary" label="Spinning">
                                                            </b-spinner>
                                                            <p v-else class="loginButtonText">Submit</p>
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <transition name="modal" v-if="disconnectModal">
                            <div class="modal-mask">
                                <div class="modal-wrapper">
                                    <div class="modal-container modal-sm">
                                        <div class="buttons-platform">
                                            <div class="row viewReviewSecondPartTopText">
                                                <p>
                                                    {{
                                                            `Are you sure you want to disconnect ${platfomeName} platform?`
                                                    }}
                                                </p>
                                            </div>
                                            <div class="action_btn-platform-wrap">
                                                <button name="submit" class="
                            action_btn-platform
                            action_btn_color__disconnected
                          " type="button" value="Connected" @click="disconnect()">
                                                    <p style="height: 40px; width: 200px" class="loginButtonText">
                                                        Yes
                                                    </p>
                                                </button>
                                                <button name="submit" class="
                            action_btn-platform
                            action_btn_color__connected
                          " type="button" value="Disconnect" @click.prevent="cancel()">
                                                    <p style="height: 40px; width: 200px" class="loginButtonText">
                                                        No
                                                    </p>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <transition name="modal" v-if="platformHistoryModal">
                            <div class="modal-mask">
                                <div class="modal-wrapper">
                                    <div class="business-modal-history-container">
                                        <div>
                                            <form class="d-block" enctype="multipart/form-data">
                                                <div class="
                            d-flex
                            justify-content-between
                            align-items-center
                            mb-5
                          ">
                                                    <h1>Platform Connection History</h1>
                                                    <a @click="cancel()" class="f-start"><img
                                                            src="resources/assets/crossicon.png" /></a>
                                                </div>
                                                <div class="table-responsive">
                                                    <table class="table table-hover table-nowrap formtable">
                                                        <thead class="thead-light">
                                                            <tr class="header">
                                                                <th class="heading" scope="col">Id</th>
                                                                <th class="heading" scope="col">Business Name</th>
                                                                <th class="heading" scope="col">Platform Name</th>
                                                                <th class="heading" scope="col">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody v-if="platformHistoryList.length > 0">
                                                            <tr v-for="(platform, index) in platformHistoryList"
                                                                v-bind:key="index">
                                                                <td>
                                                                    <a class="text-heading"
                                                                        style="text-decoration: none" href="#">
                                                                        {{ index + 1 }}
                                                                    </a>
                                                                </td>
                                                                <td>
                                                                    <a class="text-heading"
                                                                        style="text-decoration: none" href="#">
                                                                        {{ platform.businessName }}
                                                                    </a>
                                                                </td>
                                                                <td>
                                                                    <a class="text-heading"
                                                                        style="text-decoration: none" href="#">
                                                                        {{ platform.platformName }}

                                                                    </a>
                                                                </td>
                                                                <td>
                                                                    <a href="#" class="btn btn-sm btn-neutral"
                                                                        style="background: none"
                                                                        @click="deletePlatform(platform)">
                                                                        <img src="resources/assets/trash_icon.svg" />
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        <tbody v-else>
                                                            <tr>
                                                                <td colspan="6"
                                                                    style="text-align: center; font-weight: 600">
                                                                    No History Found.
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <transition name="modal" v-if="adminCancelSubscriptionModal">
                            <div class="modal-mask">
                                <div class="modal-wrapper">
                                    <div class="business-modal-container">
                                        <div>
                                            <form class="d-block">
                                                <div class="d-flex justify-content-between align-items-center mb-5">
                                                    <h1>Cancel Subscription</h1>
                                                    <a @click="cancel()"><img src="resources/assets/crossicon.png" /></a>
                                                </div>
                                                <div class="row" v-if="cancelSubscriptionForm.id > 0">
                                                    <div class="col-md-12 mb-4 pb-2">
                                                        <p class="communicationFormLabel communicationFormLabelColor">
                                                            Thanks for your feedback. One of our team members will review and process the cancellation within 2-3 business days. If you have any questions in the meantime please email
                                                            support@reviewzy.com
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row" v-else>
                                                    <div class="col-md-12 mb-4 pb-2">
                                                        <p class="communicationFormLabel communicationFormLabelColor">
                                                            We are sad to see you go! Would you mind providing some feedback below so we can serve you and others better in the future?
                                                        </p>
                                                    </div>
                                                    <div class="col-md-12 mb-4 pb-2">
                                                        <div class="form-outline form-white">
                                                            <p class="communicationFormLabel communicationFormLabelColor">
                                                                Feedback
                                                            </p>
                                                            <textarea v-model="cancelSubscriptionForm.feedback" id="form3Examplea2" class="userForm form-control-lg account-outline loginInputsTextArea" cols="30" rows="10"></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="mb-4 pb-2"></div>
                                                </div>

                                                <button type="button" class="loginButton" v-if="cancelSubscriptionForm.id == 0" @click="requestCancelSubscription()">
                                                    <p class="loginButtonText">Request Cancel</p>
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <transition name="modal" v-if="tour">
                            <v-tour name="myTour" :steps="steps" :callbacks="myCallbacks">
                                <template slot-scope="tour" style="background: #fff; color: #000">
                                    <transition name="fade">
                                        <v-step class="v-step" v-if="tour.steps[tour.currentStep]" :key="tour.currentStep"
                                            :step="tour.steps[tour.currentStep]" :previous-step="tour.previousStep"
                                            :next-step="tour.nextStep" :stop="tour.stop" :skip="tour.skip"
                                            :is-first="tour.isFirst" :is-last="tour.isLast" :labels="tour.labels">
                                            <template v-if="tour.currentStep === 0">
                                                <div slot="content">
                                                    <h3>Settings | Basic Information Step 1 of 10 <img @click="tour.stop" src="resources/assets/crossicon.png" /></h3>
                                                    <p class="vue-para">Click edit below to update your information</p>
                                                </div>

                                                <div slot="actions">
                                                    <button @click="tutorialVideo(1)" class="tourButton">Tutorial Video</button>
                                                    <button @click="tour.nextStep" class="tourButton">Next Step</button>
                                                </div>
                                            </template>
                                            <template v-if="tour.currentStep === 1">
                                                <div slot="content">
                                                    <h3>
                                                        Connect Google Step 2 of 10
                                                        <img @click="tour.stop" src="resources/assets/crossicon.png"/>
                                                    </h3>
                                                    <p class="vue-para">Click connect to connect your google account for reviews! Once you log in you'll be able to search for your business. Google will bring in your last 260 Reviews.</p>
                                                </div>
                                                <div slot="actions">
                                                    <button @click="tutorialVideo(2)" class="tourButton">Tutorial Video</button>
                                                    <button @click="tour.previousStep" class="tourButton">Previous Step</button>
                                                    <button @click="tour.nextStep" class="tourButton">Next Step</button>
                                                </div>
                                            </template>

                                            <template v-if="tour.currentStep === 2">
                                                <div slot="content">
                                                    <h3>Connect Facebook Step 3 of 10 <img @click="tour.stop" src="resources/assets/crossicon.png" /></h3>
                                                    <p class="vue-para">Click connect to connect your Facebook Account Business Page</p>
                                                </div>
                                                <div slot="actions">
                                                    <button @click="tutorialVideo(3)" class="tourButton">Tutorial Video</button>
                                                    <button @click="tour.previousStep" class="tourButton">Previous Step</button>
                                                    <button @click="tour.nextStep" class="tourButton">Next Step</button>
                                                </div>
                                            </template>

                                            <template v-if="tour.currentStep === 3">
                                                <div slot="content">
                                                    <h3>Connect Yelp Step 4 of 10 <img @click="tour.stop" src="resources/assets/crossicon.png" /></h3>
                                                    <p class="vue-para">
                                                        Connect Your Yelp Account. Note the format below and select the address. Yelp will bring in your last 3 new reviews. Don't worry, more will come in after requesting them! Example
                                                        <a href="https://www.yelp.com/biz/yourprofilename"></a>
                                                    </p>
                                                </div>
                                                <div slot="actions">
                                                    <button @click="tutorialVideo(4)" class="tourButton">Tutorial Video</button>
                                                    <button @click="tour.previousStep" class="tourButton">Previous Step</button>
                                                    <button @click="tour.nextStep" class="tourButton">Next Step</button>
                                                </div>
                                            </template>

                                            <template v-if="tour.currentStep === 4">
                                                <div slot="content">
                                                    <h3>Connect Zillow Step 5 of 10 <img @click="tour.stop" src="resources/assets/crossicon.png" /></h3>
                                                    <p class="vue-para">
                                                        Connect Your zillow Account. Please Note the format below. Zillow will bring in your last 3 reviews but don't worry more will come in after you request them! Example
                                                        <a href="https://www.zillow.com/profile/yourprofilename"></a>
                                                    </p>
                                                </div>
                                                <div slot="actions">
                                                    <button @click="tutorialVideo(5)" class="tourButton">Tutorial Video</button>
                                                    <button @click="tour.previousStep" class="tourButton">Previous Step</button>
                                                    <button @click="tour.nextStep" class="tourButton">Next Step</button>
                                                </div>
                                            </template>

                                            <template v-if="tour.currentStep === 5">
                                                <div slot="content">
                                                    <h3>Communication Settings Step 6 of 10 <img @click="tour.stop" src="resources/assets/crossicon.png" /></h3>
                                                    <p class="vue-para">
                                                        Decide how you will communicate with your customers and upload a custom logo to be displayed on your review request form! We highly recommend watching the short step tutorial video before
                                                        filling out this page.
                                                    </p>
                                                </div>
                                                <div slot="actions">
                                                    <button @click="tutorialVideo(6)" class="tourButton">Tutorial Video</button>
                                                    <button @click="tour.previousStep" class="tourButton">Previous Step</button>
                                                    <button @click="tour.nextStep" class="tourButton">Next Step</button>
                                                </div>
                                            </template>
                                        </v-step>
                                    </transition>
                                </template>
                            </v-tour>
                        </transition>
                        <transition name="modal" v-if="vSteps">
                            <div class="modal-mask">
                                <div class="modal-wrapper">
                                    <div class="modal-container modal-video" style="background: none; box-shadow: none;">
                                        <div>
                                            <div class="d-flex mb-2" style="justify-content: end;">
                                                <a @click="cancel()" class="f-start"><img src="resources/assets/crossicon.png" /></a>
                                            </div>
                                            <div class="table-responsive">
                                                <div class="plyr__video-embed" id="player">
                                                    <iframe class="youtube-video" title="YouTube video" :src="vStepsVideo" allowfullscreen allowtransparency allow="autoplay"></iframe>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </transition>

                        <div class="col-sm-11 col-12 mb-4 mb-sm-0 basic-info">
                            <div style="width:90%; text-align: right;">
                                <span id="v-step-1"></span>
                                <span id="v-step-2"></span>
                                <span id="v-step-3"></span>
                                <span id="v-step-4"></span>
                                <span id="v-step-5"></span>
                            </div>
                            <h4 v-if="showProfile == 'basic'" class="mb-0 profileSettingHeading">
                                Basic Information
                            </h4>
                            <h4 v-else-if="showProfile == 'billing'" class="mb-0 profileSettingHeading">
                                Billing Information
                            </h4>
                            <h4 v-else-if="showProfile == 'platform'" class="mb-0 profileSettingHeading">
                                Platform Connections
                            </h4>
                            <h4 v-else-if="showProfile == 'communication'" class="mb-0 profileSettingHeading">
                                Communication Settings
                            </h4>
                            <!-- Communication Settings   -->
                            <div style="margin-top: 20px">
                                <div class="com-set-wrap" style="
                    background-color: white;
                    margin: 0px 27px;
                    border-radius: 15px;
                  " v-if="showProfile == 'communication'">
                                    <div class="row communication-setting">
                                        <div class="col-md-6 firstPart">
                                            <div class="row">
                                                <div class="col-md-12 mb-4 pb-2">
                                                    <div class="form-outline form-white">
                                                        <p class="communicationFormLabel">
                                                            Select Timezone
                                                        </p>
                                                        <select class="
                                viewReviewSecondPartdropdownText
                                userForm
                                form-control-lg
                                loginInputsEmail
                              " style="height: 58px" v-model="communication.timezone" v-validate="'required'"
                                                            v-bind:name="'timezone'" v-bind:ref="'timezone'"
                                                            :class="{ 'is-invalid': errors.has('timezone') }">
                                                            <option class="viewReviewSecondPartdropdownText"
                                                                :value="timezone.id" v-for="timezone in timeZoneList"
                                                                :key="timezone.id">
                                                                {{ `${timezone.zone}` }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12 mb-4 pb-2">
                                                    <div class="form-outline form-white">
                                                        <p class="communicationFormLabel">
                                                            Email Send From Name
                                                        </p>
                                                        <input v-model="communication.emailSendFromName" type="text"
                                                            id="form3Examplea2" class="
                                userForm
                                form-control-lg
                                loginInputsEmail
                                account-outline
                              " placeholder="Email" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12 mb-4 pb-2">
                                                    <div class="form-outline form-white">
                                                        <p class="communicationFormLabel">
                                                            Text Send From Name
                                                        </p>
                                                        <input v-model="communication.textSendFromName" type="text"
                                                            id="form3Examplea2" class="
                                userForm
                                form-control-lg
                                loginInputsEmail
                                account-outline
                              " placeholder="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12 mb-4 pb-2">
                                                    <div class="form-outline form-white">
                                                        <p class="communicationFormLabel">
                                                            Default “Reply to Email”
                                                        </p>
                                                        <input v-model="communication.replyEmail" type="email"
                                                            id="form3Examplea2" class="
                                userForm
                                form-control-lg
                                loginInputsEmail
                                account-outline
                              " placeholder="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12 mb-4 pb-2">
                                                    <div class="form-outline form-white">
                                                        <p class="communicationFormLabel">
                                                            Review Request Text Default Message
                                                        </p>
                                                        <textarea v-model="
                                                            communication.reviewRequestTextDefaultMessage
                                                        " id="form3Examplea2" class="
                                userForm
                                form-control-lg
                                account-outline
                                loginInputsTextArea
                              " cols="30" rows="10"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12 mb-4 pb-2">
                                                    <div class="form-outline form-white">
                                                        <p class="communicationFormLabel">
                                                            Default 3 Star or Less Response Email
                                                        </p>
                                                        <vue-editor v-model="
                                                            communication.defaultThreeStarResponseEmail
                                                        " :editorToolbar="customToolbar" class="ql-editor">
                                                        </vue-editor>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12 mb-4 pb-2">
                                                    <div class="form-outline form-white">
                                                        <p class="communicationFormLabel">
                                                            Default 3 Star for Text Message
                                                        </p>
                                                        <vue-editor v-model="
                                                            communication.defaultThreeStarResponseText
                                                        " :editorToolbar="customToolbar" class="ql-editor">
                                                        </vue-editor>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12 mb-4 pb-2">
                                                    <div class="form-outline form-white">
                                                        <p class="communicationFormLabel">
                                                            4-5 Star Thank You Email
                                                        </p>
                                                        <vue-editor v-model="communication.thankyouEmail"
                                                            :editorToolbar="customToolbar" class="ql-editor">
                                                        </vue-editor>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12 mb-4 pb-2">
                                                    <div class="form-outline form-white">
                                                        <p class="communicationFormLabel">
                                                            Review Request Message for Feedback
                                                        </p>
                                                        <vue-editor v-model="
                                                            communication.reviewRequestMainPageMessage
                                                        " :editorToolbar="customToolbar" class="ql-editor">
                                                        </vue-editor>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12 mb-4 pb-2">
                                                    <div class="form-outline form-white">
                                                        <p class="communicationFormLabel">
                                                            Review Request 3 or Less Star Message
                                                        </p>
                                                        <vue-editor v-model="
                                                            communication.reviewRequestThreeStarMessage
                                                        " :editorToolbar="customToolbar" class="ql-editor">
                                                        </vue-editor>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 firstPart" @dragover.prevent @drop.prevent>
                                            <div @drop="dragCommunicationFile" v-if="!communicationImg">
                                                <div class="drop-it-hot" id="drop-area">
                                                    <div class="circle">
                                                        <svg width="74" height="73" viewBox="0 0 74 73" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="37" cy="36.4998" r="36.5" fill="#9194B3"
                                                                fill-opacity="0.2" />
                                                            <path opacity="0.3"
                                                                d="M22.416 37.9582C22.416 37.229 23.1452 36.4998 23.8743 36.4998C24.6035 36.4998 25.3327 37.229 25.3327 37.9582C25.3327 38.4443 25.3327 40.8748 25.3327 45.2498C25.3327 46.8606 26.6385 48.1665 28.2493 48.1665H45.7494C47.3602 48.1665 48.666 46.8606 48.666 45.2498V37.9582C48.666 37.1527 49.3189 36.4998 50.1244 36.4998C50.9298 36.4998 51.5827 37.1527 51.5827 37.9582V45.2498C51.5827 48.4715 48.971 51.0832 45.7494 51.0832H28.2493C25.0277 51.0832 22.416 48.4715 22.416 45.2498C22.416 40.8748 22.416 38.4443 22.416 37.9582Z"
                                                                fill="#9194B3" />
                                                            <path opacity="0.3"
                                                                d="M38.4577 23.3751C38.4577 22.5697 37.8048 21.9168 36.9993 21.9168C36.1939 21.9168 35.541 22.5697 35.541 23.3751V40.8751C35.541 41.6806 36.1939 42.3335 36.9993 42.3335C37.8048 42.3335 38.4577 41.6806 38.4577 40.8751V23.3751Z"
                                                                fill="#9194B3" />
                                                            <path
                                                                d="M37.0528 23.9257L30.7395 30.239C30.17 30.8085 29.2467 30.8085 28.6771 30.239C28.1076 29.6695 28.1076 28.7461 28.6771 28.1766L35.9688 20.885C36.5173 20.3365 37.3991 20.3133 37.9756 20.8322L45.2672 27.3947C45.8659 27.9335 45.9144 28.8556 45.3756 29.4542C44.8368 30.0529 43.9148 30.1014 43.3161 29.5626L37.0528 23.9257Z"
                                                                fill="#9194B3" />
                                                        </svg>
                                                    </div>
                                                    <form class="choose-files">
                                                        <div class="button-wrapper">
                                                            <label class="label file-upload-text" for="fileElem">Drag
                                                                and Drop or Browse to upload
                                                                your
                                                                logo</label>

                                                            <input type="file" id="fileElem" multiple accept="image/*"
                                                                @change="onChangeCommunicationFile" />
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>

                                            <div v-if="communicationImg">
                                                <div class="communicationImg" id="drop-area">
                                                    <form class="choose-files">
                                                        <div class="button-wrapper">
                                                            <img :src="communicationImg" alt="" class="img" />
                                                        </div>
                                                        <button class="d-flex imgBtnCenter createButton"
                                                            @click="removeFile">
                                                            <span class="imgBtnCenter"
                                                                style="padding-top: 10px">REMOVE</span>
                                                        </button>
                                                    </form>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-md-12 mb-4 pb-2">
                                                    <div class="form-outline form-white">
                                                        <p class="communicationFormLabel">
                                                            Review Request Email Default Message
                                                        </p>
                                                        <textarea v-model="
                                                            communication.reviewRequestEmailDefaultMessage
                                                        " id="form3Examplea2" class="
                                userForm
                                form-control-lg
                                account-outline
                                loginInputsTextArea
                              " cols="30" rows="10"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12 mb-4 pb-2">
                                                    <div class="form-outline form-white">
                                                        <p class="communicationFormLabel">
                                                            3 Star or Less Thank You Message
                                                        </p>
                                                        <vue-editor v-model="communication.threeStarThankyouMessage"
                                                            :editorToolbar="customToolbar" class="ql-editor">
                                                        </vue-editor>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12 mb-4 pb-2">
                                                    <div class="form-outline form-white">
                                                        <p class="communicationFormLabel">
                                                            4-5 Star Leave Review Message
                                                        </p>
                                                        <vue-editor v-model="communication.FourFiveReviewMessage"
                                                            :editorToolbar="customToolbar" class="ql-editor">
                                                        </vue-editor>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12 mb-4 pb-2">
                                                    <div class="form-outline form-white">
                                                        <p class="communicationFormLabel">
                                                            4-5 Star Thank You Text Message
                                                        </p>
                                                        <vue-editor v-model="communication.thankyouMessage"
                                                            :editorToolbar="customToolbar" class="ql-editor">
                                                        </vue-editor>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12 mb-4 pb-2">
                                                    <div class="form-outline form-white">
                                                        <p class="communicationFormLabel">
                                                            Review Request 4-5 Star Message
                                                        </p>
                                                        <vue-editor v-model="
                                                            communication.reviewRequestFourFiveStarMessage
                                                        " :editorToolbar="customToolbar" class="ql-editor">
                                                        </vue-editor>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12 mb-4 pb-2">
                                                    <div class="
                              form-outline form-white
                              d-flex
                              flex-row-reverse
                            ">
                                                        <button class="platFormCardButton" style="width: 150px"
                                                            @click="saveCommunication()">
                                                            Save
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Communication Settings   -->

                                <!--PlatForm Connections   -->
                                <div v-if="showProfile == 'platform'">
                                    <div class="card-group platFormCardGroup">
                                        <div class="card platFormCard">
                                            <img v-if="googlePlatform" @click="syncGoogleBusiness()" class="sync-svg"
                                                src="resources/assets/sync_icon.svg" />
                                            <img class="sync-svg" src="resources/assets/history-icon.svg"
                                                style="right:10px" @click="businessHistory('google')" />
                                            <div class=" card-body text-center">
                                                <img src="resources/assets/googleLogo.png" />

                                                <p>
                                                    <img v-for="index in 5" :key="index + 'yellow'"
                                                        src="resources/assets/yellowStar.png" />
                                                </p>
                                                <a v-if="googlePlatform" class="d-flex justify-content-center"
                                                    @click.prevent="disconnectPlatform('google')">
                                                    <button class="platFormCardButton action_btn_color__disconnected" style="width: 180px; margin-top: 43px" :class="{ platFormCardButtonColor: googlePlatform, squareWizard: googleConnectIcon}">
                                                        Disconnect
                                                    </button>
                                                </a>
                                                <a v-else @click="googleInit" class="d-flex justify-content-center">
                                                    <button class="platFormCardButton" style="width: 180px; margin-top: 43px" :class="{ platFormCardButtonColor: googlePlatform, squareWizard: googleConnectIcon }">
                                                        Connect
                                                    </button>
                                                </a>
                                                <!-- <a v-else @click.prevent="googleInit" class="d-flex justify-content-center">
                                                    <button class="platFormCardButton" style="width: 180px; margin-top: 43px" :class="{ platFormCardButtonColor: googlePlatform, squareWizard: googleConnectIcon }">
                                                        Connect
                                                    </button>
                                                </a> -->
                                                <p v-if="googlePlatform"
                                                    class="platform__connected justify-content-center">
                                                    {{ `Connected to ${googleName} on ${googleDate}` }}
                                                    <!-- Connected -->
                                                </p>
                                                <!-- Connect -->
                                            </div>
                                        </div>
                                        <div class="card platFormCard">
                                            <img v-if="facebookPlatform" @click="syncFacebookBusiness()"
                                                class="sync-svg" src="resources/assets/sync_icon.svg" />
                                            <img class="sync-svg" src="resources/assets/history-icon.svg"
                                                style="right:10px" @click="businessHistory('facebook')" />
                                            <div class="card-body text-center">
                                                <img src="resources/assets/facebookLogo.png" />

                                                <p>
                                                    <img v-for="index in 5" :key="index + 'fb'"
                                                        src="resources/assets/fb-star.svg" />
                                                </p>
                                                <a v-if="facebookPlatform" class="d-flex justify-content-center"
                                                    @click.prevent="disconnectPlatform('facebook')">
                                                    <button class="
                              platFormCardButton
                              action_btn_color__disconnected
                            " style="width: 180px; margin-top: 43px" :class="{
                                platFormCardButtonColor: facebookPlatform, squareWizard: facebookConnectIcon
                            }">
                                                        Disconnect
                                                    </button>
                                                </a>
                                                <a v-else @click.prevent="checkLoginState"
                                                    class="d-flex justify-content-center">
                                                    <button class="platFormCardButton"
                                                        style="width: 180px; margin-top: 43px" :class="{
                                                            platFormCardButtonColor: facebookPlatform, squareWizard: facebookConnectIcon
                                                        }">
                                                        Connect
                                                    </button>
                                                </a>
                                                <p v-if="facebookPlatform"
                                                    class="platform__connected justify-content-center">
                                                    {{ `Connected to ${fbName} on ${facebookDate}` }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="card platFormCard">
                                            <img v-if="yelpPlatform" class="sync-svg"
                                                src="resources/assets/sync_icon.svg" @click="syncYelpBusiness()" />
                                            <img class="sync-svg" src="resources/assets/history-icon.svg"
                                                style="right:10px" @click="businessHistory('yelp')" />
                                            <div class="card-body text-center">
                                                <img src="resources/assets/yellpLogo.png" />

                                                <p>
                                                    <img v-for="index in 5" :key="index + 'yelp'"
                                                        src="resources/assets/yelp-star.svg" />
                                                </p>
                                                <a v-if="yelpPlatform" class="d-flex justify-content-center"
                                                    @click.prevent="disconnectPlatform('yelp')">
                                                    <button class="
                              platFormCardButton
                              action_btn_color__disconnected
                            " style="width: 180px; margin-top: 43px"
                                                        :class="{ platFormCardButtonColor: yelpPlatform, squareWizard: yelpConnectIcon }">
                                                        Disconnect
                                                    </button>
                                                </a>
                                                <a class="d-flex justify-content-center" v-else>
                                                    <button class="platFormCardButton"
                                                        style="width: 180px; margin-top: 43px"
                                                        @click="searchYelpBusiness()"
                                                        :class="{ platFormCardButtonColor: yelpPlatform, squareWizard: yelpConnectIcon }">
                                                        Connect
                                                    </button>
                                                </a>
                                                <p v-if="yelpPlatform"
                                                    class="platform__connected justify-content-center">
                                                    {{ `Connected to ${yelpName} on ${yelpDate}` }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="card platFormCard">
                                            <img @click="synczillowBusiness()" v-if="zillowPlatform" class="sync-svg" src="resources/assets/sync_icon.svg" />
                                            <img class="sync-svg" src="resources/assets/history-icon.svg" style="right:10px" @click="businessHistory('zillow')" />
                                            <div class="card-body text-center">
                                                <img src="resources/assets/zillowLogo.png" />
                                                <p>
                                                    <img v-for="index in 5" :key="index + 'zillow'" src="resources/assets/zillow-star.svg" />
                                                </p>
                                                <a v-if="zillowPlatform" class="d-flex justify-content-center"
                                                    @click.prevent="disconnectPlatform('zillow')">
                                                    <button class="platFormCardButton action_btn_color__disconnected" style="width: 180px; margin-top: 43px" :class="{ platFormCardButtonColor: zillowPlatform, squareWizard: zillowConnectIcon }">
                                                        Disconnect
                                                    </button>
                                                </a>
                                                <a v-else class="d-flex justify-content-center">
                                                    <button class="platFormCardButton"
                                                        style="width: 180px; margin-top: 43px"
                                                        @click="openZillowModal()"
                                                        :class="{ platFormCardButtonColor: zillowPlatform, squareWizard: zillowConnectIcon }">
                                                        Connect
                                                    </button>
                                                </a>
                                                <p v-if="zillowPlatform"
                                                    class="platform__connected justify-content-center">
                                                    {{ `Connected to ${zillowName} on ${zillowDate}` }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--PlatForm Connections   -->

                                <div v-if="showProfile == 'basic'" style="background: #ffffff; margin-top: 70px" class="basic-card">
                                    <img v-if="showProfile == 'basic' && adminDetails.profile" alt="..." :src="adminDetails.profile" class="profilePic" />
                                    <img v-else-if="showProfile == 'basic'" alt="..." src="https://reviewzy.com/images.png" class="profilePic" />
                                    <header class="bg-surface-primary border-bottom pt-6 profile-text-wrap" style="margin-right: 32px; padding-bottom: 50px">
                                        <div>
                                            <div class="mb-npx">
                                                <div class="row align-items-center" style="padding: 20px">
                                                    <div class="col-md-11 col-6 mb-4 mb-sm-0">
                                                        <h3 class="mb-0 profileName">
                                                            Hi {{ adminDetails.firstName }}!
                                                        </h3>
                                                    </div>
                                                    <!-- Actions -->
                                                    <div id="v-step-0" class="col-md-1 col-6 mb-4 mb-sm-0 d-flex justify-content-end" @click="editProfile()" :class="{ 'squareWizard': userEditIcon }">
                                                        <img src="resources/assets/editIcon.png" />
                                                        <p class="edit-text">Edit</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </header>

                                    <div>
                                        <header class="bg-surface-primary border-bottom pt-6"
                                            style="margin-right: 32px">
                                            <div>
                                                <div class="mb-npx">
                                                    <div class="row align-items-center">
                                                        <div class="col-md-4 col-12 mb-4 mb-sm-0">
                                                            <div class="form-outline viewReviewInnerBox d-flex">
                                                                <div class="profileSettingIcons">
                                                                    <img src="resources/assets/userIcon.png" />
                                                                </div>
                                                                <div class="text-start">
                                                                    <a class="profileFormTitle"> FULL NAME</a>
                                                                    <br />
                                                                    <a class="profileFormTitleValue">
                                                                        {{
                                                                                adminDetails.firstName
                                                                                    ? adminDetails.firstName
                                                                                    : "NA"
                                                                        }}
                                                                        {{
                                                                                adminDetails.firstName &&
                                                                                    adminDetails.lastName
                                                                                    ? adminDetails.lastName
                                                                                    : ""
                                                                        }}
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4 col-12 mb-4 mb-sm-0">
                                                            <div class="form-outline viewReviewInnerBox d-flex">
                                                                <div class="profileSettingIcons">
                                                                    <img src="resources/assets/briefcaseIcon.png" />
                                                                </div>
                                                                <div class="text-start">
                                                                    <a class="profileFormTitle"> COMAPNY NAME</a>
                                                                    <br />
                                                                    <a class="profileFormTitleValue">
                                                                        {{
                                                                                adminDetails.organisation_name
                                                                                    ? adminDetails.organisation_name
                                                                                    : "NA"
                                                                        }}</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4 col-12 mb-4 mb-sm-0">
                                                            <div class="form-outline viewReviewInnerBox d-flex">
                                                                <div class="profileSettingIcons">
                                                                    <img src="resources/assets/mailIcon.png" />
                                                                </div>
                                                                <div class="text-start">
                                                                    <a class="profileFormTitle"> EMAIL </a>
                                                                    <br />
                                                                    <a class="profileFormTitleValue">
                                                                        {{
                                                                                adminDetails.email
                                                                                    ? adminDetails.email
                                                                                    : "NA"
                                                                        }}</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </header>

                                        <header class="bg-surface-primary border-bottom pt-6"
                                            style="margin-right: 32px">
                                            <div>
                                                <div class="mb-npx">
                                                    <div class="row align-items-center">
                                                        <div class="col-md-4 col-12 mb-4 mb-sm-0">
                                                            <div class="form-outline viewReviewInnerBox d-flex">
                                                                <div class="profileSettingIcons">
                                                                    <img src="resources/assets/callIcon.png" />
                                                                </div>
                                                                <div class="text-start">
                                                                    <a class="profileFormTitle">PHONE NUMBER </a>
                                                                    <br />
                                                                    <a class="profileFormTitleValue">
                                                                        {{
                                                                                adminDetails.phone
                                                                                    ? adminDetails.phone
                                                                                    : "NA"
                                                                        }}</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4 col-12 mb-4 mb-sm-0">
                                                            <div class="form-outline viewReviewInnerBox d-flex">
                                                                <div class="profileSettingIcons">
                                                                    <img src="resources/assets/mapIcon.png" />
                                                                </div>
                                                                <div class="text-start">
                                                                    <a class="profileFormTitle"> ADDRESS </a>
                                                                    <br />
                                                                    <a class="profileFormTitleValue">
                                                                        {{
                                                                                adminDetails.billing_address
                                                                                    ? adminDetails.billing_address
                                                                                    : ""
                                                                        }}
                                                                        {{
                                                                                adminDetails.city ? adminDetails.city : ""
                                                                        }}
                                                                        {{
                                                                                adminDetails.state
                                                                                    ? adminDetails.state
                                                                                    : ""
                                                                        }}
                                                                        {{
                                                                                adminDetails.pincode
                                                                                    ? adminDetails.pincode
                                                                                    : ""
                                                                        }}
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4 col-12 mb-4 mb-sm-0" v-if="isFrontrunner">
                                                            <div class="form-outline viewReviewInnerBox d-flex">
                                                                <button type="button" class="connectButton">
                                                                    <a class="profileFormTitleValue"
                                                                        @click="connectFrontrunner()">
                                                                        <p class="frontrunnerText">
                                                                            Connect To Frontrunner
                                                                        </p>
                                                                    </a>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </header>
                                    </div>
                                </div>
                                <div v-if="showProfile == 'billing'" style="background: #ffffff" class="basic-card">
                                    <img v-if="showProfile == 'basic'" alt="..."
                                        src="https://images.unsplash.com/photo-1502823403499-6ccfcf4fb453?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80"
                                        class="profilePic" />
                                    <header class="bg-surface-primary pt-6" style="margin-right: 32px">
                                        <div>
                                            <div class="mb-npx">
                                                <div class="row align-items-center">
                                                    <div class="col-md-11 col-6 mb-4 mb-sm-0">
                                                        <!-- Title -->
                                                    </div>
                                                    <!-- Actions -->
                                                    <div class="
                              col-md-1 col-6
                              mb-4 mb-sm-0
                              d-flex
                              justify-content-end
                            " @click="editBillingInfo()" style="cursor: pointer">
                                                        <img src="resources/assets/editIcon.png" />
                                                        <p class="edit-text">Edit</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </header>

                                    <div>
                                        <header class="bg-surface-primary border-bottom pt-6"
                                            style="margin-right: 32px">
                                            <div>
                                                <div class="mb-npx">
                                                    <div class="row align-items-center">
                                                        <div class="col-md-4 col-12 mb-4 mb-sm-0">
                                                            <div class="form-outline viewReviewInnerBox d-flex">
                                                                <div class="profileSettingIcons">
                                                                    <img src="resources/assets/planType.png" />
                                                                </div>
                                                                <div class="text-start">
                                                                    <a class="profileFormTitle">Plan Type</a>
                                                                    <br />
                                                                    <a class="profileFormTitleValue">
                                                                        BUSINESS
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4 col-12 mb-4 mb-sm-0">
                                                            <div class="form-outline viewReviewInnerBox d-flex">
                                                                <div class="profileSettingIcons">
                                                                    <img src="resources/assets/mapIcon.png" />
                                                                </div>
                                                                <div class="text-start">
                                                                    <a class="profileFormTitle">
                                                                        BILLING ADDRESS</a>
                                                                    <br />
                                                                    <a class="profileFormTitleValue">
                                                                        {{
                                                                                adminDetails.b_billingAddress
                                                                                    ? adminDetails.b_billingAddress
                                                                                    : ""
                                                                        }}
                                                                        {{
                                                                                adminDetails.b_city
                                                                                    ? adminDetails.b_city
                                                                                    : ""
                                                                        }}
                                                                        {{
                                                                                adminDetails.b_state
                                                                                    ? adminDetails.b_state
                                                                                    : ""
                                                                        }}
                                                                        {{
                                                                                adminDetails.b_zip
                                                                                    ? adminDetails.b_zip
                                                                                    : ""
                                                                        }}</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4 col-12 mb-4 mb-sm-0"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </header>

                                        <header class="bg-surface-primary border-bottom pt-6"
                                            style="margin-right: 32px">
                                            <div>
                                                <div class="mb-npx">
                                                    <div class="row align-items-center">
                                                        <div class="col-md-4 col-12 mb-4 mb-sm-0">
                                                            <div class="form-outline viewReviewInnerBox d-flex">
                                                                <div class="profileSettingIcons">
                                                                    <img src="resources/assets/creditCard.png" />
                                                                </div>
                                                                <div class="text-start">
                                                                    <a class="profileFormTitle"> CARD DETAILS</a>
                                                                    <br />
                                                                    <a class="profileFormTitleValue">
                                                                        {{ `XXXX XXXX XXXX ${adminDetails.b_cardNumber}`
                                                                        }}</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4 col-12 mb-4 mb-sm-0">
                                                            <div class="form-outline viewReviewInnerBox d-flex">
                                                                <div class="profileSettingIcons"></div>
                                                                <div class="text-start">
                                                                    <a class="profileFormTitle"> EXP. DATE</a>
                                                                    <br />
                                                                    <a class="profileFormTitleValue"> {{
                                                                            `${adminDetails.b_expDate}`
                                                                    }}</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4 col-12 mb-4 mb-sm-0">
                                                            <div class="form-outline viewReviewInnerBox d-flex">
                                                                <div class="profileSettingIcons"></div>
                                                                <div class="text-start">
                                                                    <a class="profileFormTitle"> CCV</a>
                                                                    <br />
                                                                    <a class="profileFormTitleValue"> {{
                                                                            `${adminDetails.b_cvv}`
                                                                    }} </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </header>
                                        <div class="row align-items-center mt-3">
                                            <div class="col-md-6 col-6 mb-4 mb-sm-0">
                                                <!-- Title -->
                                            </div>
                                            <!-- Actions -->
                                            <div class="col-md-6 col-6 mb-4 mb-sm-0 d-flex justify-content-end">
                                                <button type="button" class="connectButton" style="margin-right: 15px;" @click="adminUpdateCardModal = true">
                                                    Update Card Info
                                                </button>
                                                <button type="button" class="cancelSubscriptionButton" @click="cancelSubscription()">
                                                    Cancel Subscription
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Header from "./Header";
import Menu from "./Menu";
import moment from "moment";
import UpdateCardInfoModal from "./UpdateCardInfoModal";
export default {
    name: "Help",
    props: {},
    components: {
        Menu,
        Header,
        UpdateCardInfoModal
    },
    data() {
        return {
            moment: moment,
            apiLoading: false,
            vSteps: false,
            vStepsVideo: "",
            vStepsVideoList: [],
            steps: [
                {
                    target: '#v-step-0',
                },
                {
                    target: '#v-step-1',
                },
                {
                    target: '#v-step-2',
                },
                {
                    target: '#v-step-3',
                },
                {
                    target: '#v-step-4',
                },
                {
                    target: '#v-step-5',
                },
                {
                    target: '#v-step-6',
                }
            ],
            myCallbacks: {
                onNextStep: this.myCustomNextStepCallback,
                onPreviousStep: this.myCustomPreviousStepCallback,
                onStop:this.myCustomStopCallback,
            },
            tour: localStorage.getItem("isSetupWizardPlay") === 'true' ? true : false,

            adminDetails: {
                id: "",
                firstName: "",
                lastName: "",
                phone: "",
                billing_address: "",
                city: "",
                state: "",
                email: "",
                pincode: "",
                organisation_name: "",
                adminId: "",
                b_firstName: "",
                b_lastNAme: "",
                b_billingAddress: "",
                b_city: "",
                b_state: "",
                b_zip: "",
                b_cardNumber: "",
                b_expDate: "",
                b_cvv: "",
                fileSelected: null,
                profile: "",
            },
            communication: {
                id: "",
                timezone: "",
                emailSendFromName: "",
                textSendFromName: "",
                replyEmail: "",
                // textNumberAreaCode: "",
                reviewRequestTextDefaultMessage: "",
                defaultThreeStarResponseEmail: "",
                defaultThreeStarResponseText: "",
                reviewRequestEmailDefaultMessage: "",
                threeStarThankyouMessage: "",
                FourFiveReviewMessage: "",
                thankyouMessage: "",
                thankyouEmail: "",
                communicationLogo: null,
                reviewRequestMainPageMessage: "",
                reviewRequestThreeStarMessage: "",
                reviewRequestFourFiveStarMessage: "",
            },
            cancelSubscriptionForm: {
                id: 0,
                user_id: 0,
                feedback: ""
            },
            showProfile: "basic",
            checkPermission: false,
            adminProfileModal: false,
            adminBillingInfoModal: false,
            adminBusinessModal: false,
            yelpBusinessModal: false,
            zillowModal: false,
            disconnectModal: false,
            platformHistoryModal: false,
            adminUpdateCardModal: false,
            adminCancelSubscriptionModal: false,
            userEditIcon: false,
            isFrontrunner: false,
            buttonText: "Create",
            businessName: "",
            business_name: "",
            placeID: "",
            platForm: "",
            googlePlatform: false,
            googleDate: "",
            facebookDate: "",
            yelpDate: "",
            zillowDate: "",
            facebookPlatform: false,
            yelpPlatform: false,
            zillowPlatform: false,
            googleConnectIcon: false,
            facebookConnectIcon: false,
            yelpConnectIcon: false,
            zillowConnectIcon: false,
            userLoginId: "",
            call: true,
            loading: false,
            yelpLoading: false,
            searchText: "",
            location: "",
            platformHistoryList: [],
            communicationImg: "",
            customToolbar: [
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ["bold", "italic", "underline"],
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" },
                ],
                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
            ],
        };
    },
    mounted() {
        if (this.tour) {
            this.$tours['myTour'].start();
            this.userEditIcon = true;
            this.wizardData();
        }
        this.userLoginId = localStorage.getItem("id");
        let isAdmin = localStorage.getItem("isAdmin");
        let frontRunner = localStorage.getItem("frontRunner");
        if (isAdmin == true) {
            this.checkPermission = true;
        } else {
            this.checkPermission = false;
        }
        if (isAdmin == true && frontRunner == true) {
            this.isFrontrunner = true;
        } else {
            this.isFrontrunner = false;
        }
        this.getUserProfileSettings();
        this.checkConnectedPlatform();
        this.communication.communicationLogo = "";
        this.fetchCommunication();
        this.timezoneList();
        this.show = 'settings'
        this.showProfile = 'basic';
    },
    created: function () {
        window.fbAsyncInit = function () {
            FB.init({
                appId: "614634686913225",
                cookie: true,
                xfbml: true,
                version: "v14.0",
            });

            //This function should be here, inside window.fbAsyncInit
            FB.getLoginStatus(function (response) {
                console.log(response);
            });
        };

        (function (d, s, id) {
            var js,
                fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = "//connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        })(document, "script", "facebook-jssdk");

        if (localStorage.getItem("isSetupWizardPlay") === 'true') {
            this.$tours['myTour'].start();
        }
    },
    computed: {},
    methods: {
        getUserProfileSettings() {
            this.$store.dispatch("userProfileSettings").then((response) => {
                let res = response.data[0];

                this.adminDetails.id = res.id;
                this.adminDetails.firstName = res.first_name;
                this.adminDetails.lastName = res.last_name;
                this.adminDetails.phone = res.phone;
                this.adminDetails.billing_address = res.billing_address;
                this.adminDetails.city = res.city;
                this.adminDetails.state = res.state;
                this.adminDetails.email = res.email;
                this.adminDetails.adminId = res.adminId;
                this.adminDetails.pincode = res.zip_code;
                this.adminDetails.organisation_name = res.organisation_name;
                this.adminDetails.b_firstName = res.b_first_name;
                this.adminDetails.b_lastNAme = res.b_last_name;
                this.adminDetails.b_billingAddress = res.b_billing_address;
                this.adminDetails.b_city = res.b_city;
                this.adminDetails.b_state = res.b_state;
                this.adminDetails.b_zip = res.b_zip_code;
                this.adminDetails.b_cvv = '***';
                this.adminDetails.b_cardNumber = res.last4;
                this.adminDetails.b_expDate = `${res.exp_month}/${res.exp_year}`;
                this.adminDetails.profile = res.file;
            });
        },
        checkConnectedPlatform() {
            let data = {
                userId: this.userLoginId,
            };
            this.$store.dispatch("platformCheck", data).then((response) => {
                let res = response.data ? response.data[0] : "";
                this.googleName = res.googleBusinessName ? res.googleBusinessName : "";
                this.fbName = res.facebookPages ? res.facebookPages[0] : "";
                this.yelpName = res.yelpTerm ? res.yelpTerm : "";
                this.zillowName = res.zillowscreenName
                    ? res.zillowscreenName.substring(
                        res.zillowscreenName.lastIndexOf("/") + 1,
                        res.zillowscreenName.length
                    )
                    : "";
                res.google
                    ? (this.googlePlatform = true)
                    : (this.googlePlatform = false);
                res.facebook
                    ? (this.facebookPlatform = true)
                    : (this.facebookPlatform = false);
                res.yelp ? (this.yelpPlatform = true) : (this.yelpPlatform = false);
                res.zillow
                    ? (this.zillowPlatform = true)
                    : (this.zillowPlatform = false);

                this.googleDate = res.googleDate
                    ? moment(String(res.googleDate)).format("MM-DD-YYYY")
                    : "";
                this.facebookDate = res.facebookDate
                    ? moment(String(res.facebookDate)).format("MM-DD-YYYY")
                    : "";
                this.yelpDate = res.yelpDate
                    ? moment(String(res.yelpDate)).format("MM-DD-YYYY")
                    : "";
                this.zillowDate = res.zillowDate
                    ? moment(String(res.zillowDate)).format("MM-DD-YYYY")
                    : "";
            });
        },
        fetchCommunication() {
            let data = {
                id: this.userLoginId,
            };
            this.$store
                .dispatch("fetchCommunicationSetting", data)
                .then((response) => {
                    let res = response.data[0];
                    (this.communication.id = res.id),
                        (this.communication.timezone = res.timezone);
                    this.communication.emailSendFromName = res.emailSendFromName && res.emailSendFromName != null ? res.emailSendFromName : "";
                    this.communication.textSendFromName = res.textSendFromName && res.textSendFromName != null ? res.textSendFromName : "";
                    this.communication.replyEmail = res.replyEmail ? res.replyEmail : "";
                    // this.communication.textNumberAreaCode = res.textNumberAreaCode;
                    this.communication.reviewRequestTextDefaultMessage =
                        res.reviewRequestTextDefaultMessage ? res.reviewRequestTextDefaultMessage : "";
                    this.communication.defaultThreeStarResponseEmail =
                        res.defaultThreeStarResponseEmail ? res.defaultThreeStarResponseEmail : "";
                    this.communication.defaultThreeStarResponseText =
                        res.defaultThreeStarResponseText ? res.defaultThreeStarResponseText : "";
                    this.communication.reviewRequestEmailDefaultMessage =
                        res.reviewRequestEmailDefaultMessage ? res.reviewRequestEmailDefaultMessage : "";
                    this.communication.threeStarThankyouMessage =
                        res.threeStarThankyouMessage ? res.threeStarThankyouMessage : "";
                    this.communication.FourFiveReviewMessage = res.FourFiveReviewMessage ? res.FourFiveReviewMessage : "";
                    (this.communication.reviewRequestMainPageMessage =
                        res.reviewRequestMainPageMessage ? res.reviewRequestMainPageMessage : ""),
                        (this.communication.reviewRequestThreeStarMessage =
                            res.reviewRequestThreeStarMessage ? res.reviewRequestThreeStarMessage : ""),
                        (this.communication.reviewRequestFourFiveStarMessage =
                            res.reviewRequestFourFiveStarMessage ? res.reviewRequestFourFiveStarMessage : ""),
                        (this.communication.thankyouMessage = res.thankyouMessage ? res.thankyouMessage : "");
                    this.communication.thankyouEmail = res.thankyouEmail ? res.thankyouEmail : "";
                    // this.communication.communicationLogo = res.logo ? res.logo : "";
                    this.communicationImg = res.logourl ? res.logourl : "";
                });
        },
        showProfiles(data) {
            console.log(data);
            console.log("I am clicked");
            if (data === "basic" || data === "billing") {
                this.getUserProfileSettings();
            }
            if (data === "communication") {
                this.fetchCommunication();
                this.communication.communicationLogo = "";
            }
            if (data === "platform") {
                this.checkConnectedPlatform();
            }

            this.showProfile = data;
        },
        cancel() {
            this.vSteps = false;
            this.adminProfileModal = false;
            this.adminBillingInfoModal = false;
            this.adminBusinessModal = false;
            this.buttonText = "Create";
            this.yelpBusinessModal = false;
            this.zillowModal = false;
            this.disconnectModal = false;
            this.platformHistoryModal = false;
            this.adminUpdateCardModal = false;
            this.adminCancelSubscriptionModal = false;
            this.platfomeName = "";
        },
        editProfile() {
            this.adminProfileModal = true;
        },
        editBillingInfo() {
            this.adminBillingInfoModal = true;
        },
        updateProfile() {
            if (
                this.adminDetails.firstName === "" ||
                this.adminDetails.lastName === "" ||
                this.adminDetails.email === "" ||
                this.adminDetails.phone === "" ||
                this.adminDetails.billing_address === "" ||
                this.adminDetails.city === "" ||
                this.adminDetails.state === "" ||
                this.adminDetails.pincode === "" ||
                this.adminDetails.organisation_name === ""
            ) {
                this.$toastr.e("Please fill all the required fields");
            } else {
                this.apiLoading = true;
                // await this.updateProfiles(this.adminDetails)
                this.$store
                    .dispatch("updateProfile", this.adminDetails)
                    .then((response) => {
                        if (response.success) {
                            this.$toastr.s(`${response.message}`);
                            this.getUserProfileSettings();
                            this.adminProfileModal = false;
                        } else {
                            this.$toastr.e(`${response.message}`);
                        }
                    }).catch((err) => {
                        console.log(err);
                    })
                    .finally(() => {
                        this.apiLoading = false;
                    });
            }
        },
        updateBillingInfo() {
            if (
                this.adminDetails.b_firstName === "" ||
                this.adminDetails.b_lastNAme === "" ||
                this.adminDetails.b_billingAddress === "" ||
                this.adminDetails.b_city === "" ||
                this.adminDetails.b_state === "" ||
                this.adminDetails.b_zip === "" ||
                this.adminDetails.b_cardNumber === "" ||
                this.adminDetails.b_expDate === "" ||
                this.adminDetails.b_cvv === ""
            ) {
                this.$toastr.e("Please fill all the required fields");
            } else {
                this.apiLoading = true;
                // await this.billingInfo(this.adminDetails)
                this.$store
                    .dispatch("billingInfo", this.adminDetails)
                    .then((response) => {
                        if (response.success) {
                            this.$toastr.s(`${response.message}`);
                            this.adminBillingInfoModal = false;
                        } else {
                            this.$toastr.e("Invalid Details.");
                        }
                    }).catch((err) => {
                        console.log(err);
                    })
                    .finally(() => {
                        this.apiLoading = false;
                    });
            }
        },
        connectFrontrunner() {
            let data = {
                id: this.userLoginId,
            };
            this.apiLoading = true;
            this.$store
                .dispatch("frontrunnerConnect", data)
                .then((response) => {
                    if (response.status) {
                        this.$toastr.s(`${response.message}`);
                    } else {
                        this.$toastr.e(`${response.message}`);
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    this.apiLoading = false;
                });
        },
        onChangeFile: function (event) {
            this.adminDetails.fileSelected = event.target.files[0];
        },
        initialize(e) {
            var self = this;
            let input = document.getElementById("autocomplete");
            let autocomplete = new google.maps.places.Autocomplete(input);
            autocomplete.addListener("place_changed", function () {
                let place = autocomplete.getPlace();
                self.business_name = place.name;
                self.businessName = place.name;
                self.placeID = place.place_id;
                self.platForm = "google";
            });
        },
        async saveBusiness() {
            let result = await this.$validator.validateAll();
            if(result){
                let businessData = {
                    user_id: this.userLoginId,
                    platformName: this.platForm,
                    business_name: this.business_name,
                    placeID: this.placeID,
                };
                this.loading = true;
                this.call = false;
                this.$store.dispatch("businessAdd", businessData)
                    .then((response) => {
                        if (response.success) {
                        this.$toastr.s(`${response.message}`);
                        this.adminBusinessModal = false;
                        this.businessName = "";
                        this.checkConnectedPlatform();
                        } else {
                        this.$toastr.e(`${response.message}`);
                        }
                    })
                    .catch((error) => {
                        console.log("error", error);
                    })
                    .finally(() => ((this.loading = false), (this.call = true)));
            }
        },
        initializeYelp(e) {
            var self = this;
            let input = document.getElementById("yelpComplete");
            let autocomplete = new google.maps.places.Autocomplete(input);
            autocomplete.addListener("place_changed", function () {
                let place = autocomplete.getPlace();
                self.location = place.formatted_address;
            });
        },
        initializeBillingAddress(e) {
            var self = this;
            let input = document.getElementById("billing_address");
            let autocomplete = new google.maps.places.Autocomplete(input);
            autocomplete.addListener("place_changed", function () {
                let place = autocomplete.getPlace();
                let addressComponent = place.address_components
                let city = addressComponent.find(d => {
                    return d.types[0] === 'locality'
                }
                );

                let postal_code = addressComponent.find(d => {
                    return d.types[0] === 'postal_code'
                }
                );
                let state = addressComponent.find(d => {
                    return d.types[0] === 'administrative_area_level_1'
                }
                );

                self.adminDetails.billing_address = place.name
                self.adminDetails.city = city.long_name
                self.adminDetails.state = state.long_name
                self.adminDetails.pincode = postal_code.long_name
            });
        },
        initializeBillingInfo(e) {
            var self = this;
            let input = document.getElementById("b_billingAddress");
            let autocomplete = new google.maps.places.Autocomplete(input);
            autocomplete.addListener("place_changed", function () {
                let place = autocomplete.getPlace();
                let addressComponent = place.address_components
                let city = addressComponent.find(d => {
                    return d.types[0] === 'locality'
                }
                );
                let postal_code = addressComponent.find(d => {
                    return d.types[0] === 'postal_code'
                }
                );
                let state = addressComponent.find(d => {
                    return d.types[0] === 'administrative_area_level_1'
                }
                );

                self.adminDetails.b_billingAddress = place.name
                self.adminDetails.b_city = city.long_name
                self.adminDetails.b_state = state.long_name
                self.adminDetails.b_zip = postal_code.long_name
            });
        },
        googleInit() {
            this.businessName = "";
            this.adminBusinessModal = true;
        },
        async googleInitOld() {
            this.checkConnectedPlatform();
            if (this.adminBusinessModal) {
                this.businessName = "";
                this.adminBusinessModal = true;
            } else {
                const googleUser = await this.$gAuth.signIn();
                googleUser.userId = this.userLoginId ? this.userLoginId : "";
                this.$store.dispatch("googleConnect", googleUser)
                    .then((response) => {
                    if (response.success) {
                        this.businessName = "";
                        this.adminBusinessModal = true;
                    }
                })
                .catch((err) => {
                    console.log({ err: err });
                });
            }
        },
        disconnectPlatform(platform) {
            this.platfomeName = platform;
            this.disconnectModal = true;
        },
        disconnect() {
            let data = {
                data: {
                    userId: this.userLoginId,
                },
                platform: this.platfomeName,
            };
            (this.disconnectLoading = true),
                this.$store
                    .dispatch("platformDisconnect", data)
                    .then((response) => {
                        if (response.success) {
                            this.$toastr.s(`${response.message}`);
                            this.checkConnectedPlatform();
                            this.disconnectModal = false;
                            if (this.platfomeName == 'facebook') {
                                this.fbLogout();
                            }
                        } else {
                            this.$toastr.e(`${response.message}`);
                        }
                    })
                    .catch((err) => {
                        console.log({ err: err });
                    })
                    .finally(() => {
                        this.platfomeName = "";
                        this.disconnectLoading = false;
                    });
        },
        syncGoogleBusiness() {
            let data = {
                userId: this.userLoginId,
            };
            this.apiLoading = true;

            this.$store
                .dispatch("googleSync", data)
                .then((response) => {
                    if (response.success) {
                        this.$toastr.s(`${response.message}`);
                    } else {
                        this.$toastr.e(`${response.message}`);
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    this.apiLoading = false;
                });
        },
        syncFacebookBusiness() {
            let data = {
                userId: this.userLoginId,
            };
            this.apiLoading = true;

            this.$store
                .dispatch("fbSync", data)
                .then((response) => {
                    if (response.success) {
                        this.$toastr.s(`${response.message}`);
                    } else {
                        this.$toastr.e(`${response.message}`);
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    this.apiLoading = false;
                });
        },

        syncYelpBusiness() {
            let data = {
                userId: this.userLoginId,
            };
            this.apiLoading = true;

            this.$store
                .dispatch("yelpSync", data)
                .then((response) => {
                    if (response.success) {
                        this.$toastr.s(`${response.message}`);
                    } else {
                        this.$toastr.e(`${response.message}`);
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    this.apiLoading = false;
                });
        },
        synczillowBusiness() {
            let data = {
                userId: this.userLoginId,
            };
            this.apiLoading = true;
            this.$store
                .dispatch("zillowSync", data)
                .then((response) => {
                    if (response.success) {
                        this.$toastr.s(`${response.message}`);
                    } else {
                        this.$toastr.e(`${response.message}`);
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    this.apiLoading = false;
                });
        },
        businessHistory(platform) {
            let data = {
                userId: this.userLoginId,
                platformName: platform
            };
            this.apiLoading = true;
            this.$store
                .dispatch("connectedHistory", data)
                .then((response) => {
                    if (response.success) {
                        this.platformHistoryList = response.data.data ? response.data.data : [];
                    }
                    console.log("this.platformHistoryList", this.platformHistoryList);
                    this.platformHistoryModal = true;
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    this.apiLoading = false;
                });
        },
        deletePlatform(platform) {
            let data = {
                userId: this.userLoginId,
                platformName: platform.platformName,
                businessName: platform.businessName
            }
            this.apiLoading = false;

            this.$store
                .dispatch("platformHistoryDelete", data)
                .then((response) => {
                    if (response.success) {
                        this.$toastr.s(`${response.message}`);
                        this.businessHistory(platform.platformName);
                    } else {
                        this.$toastr.e(`${response.message}`);
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    this.apiLoading = false;
                });
        },
        searchYelpBusiness() {
            this.searchText = "";
            this.location = "";
            this.yelpBusinessModal = true;
        },

        openZillowModal() {
            this.screenName = "";
            this.zillowModal = true;
        },
        async saveYelpBusiness() {
            let result = await this.$validator.validateAll();
            if (result && this.searchText) {
                let data = {
                    userId: this.userLoginId,
                    url: this.yelpBusinessText,
                    term: this.searchText,
                    location: this.location,
                };
                this.yelpLoading = true;
                this.call = false;
                this.$store
                    .dispatch("yelpReview", data)
                    .then((response) => {
                        if (response.success) {
                            this.$toastr.s(`${response.message}`);
                            this.yelpBusinessModal = false;
                            this.yelpPlatform = true;
                            this.checkConnectedPlatform();
                        } else {
                            this.$toastr.e(`${response.message}`);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    })
                    .finally(() => ((this.yelpLoading = false), (this.call = true)));
            }
        },
        async saveZillow() {
            let result = await this.$validator.validateAll();
            if (result && this.screenName) {
                let data = {
                    userId: this.userLoginId,
                    screenname: this.screenName,
                };
                this.loading = true;
                this.call = false;
                this.$store
                    .dispatch("zillowReview", data)
                    .then((response) => {
                        if (response.success) {
                            this.$toastr.s(`${response.message}`);
                            this.zillowModal = false;
                            this.zillowPlatform = true;
                            this.checkConnectedPlatform();
                        } else {
                            this.$toastr.e(`${response.message}`);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    })
                    .finally(() => ((this.loading = false), (this.call = true)));
            }
        },

        statusChangeCallback(response) {
            // Called with the results from FB.getLoginStatus().
            if (response.status === "connected") {
                // Logged into your webpage and Facebook.
                this.testAPI();
            } else {
                // Not logged into your webpage or we are unable to tell.
                document.getElementById("status").innerHTML =
                    "Please log " + "into this webpage.";
            }
        },

        checkLoginState() {
            let vm = this;
            FB.getLoginStatus(function (response) {
                if (response.status === "connected") {
                    // Logged into your webpage and Facebook.
                    response.user_id = vm.userLoginId;
                    vm.$store.dispatch("facebookConnect", response).then((response) => {
                        vm.checkConnectedPlatform();
                    });
                } else {
                    FB.login(
                        (response) => {
                            if (response.status === "connected") {
                                vm.facebookCheck = true;
                                // Logged into your webpage and Facebook.
                                response.user_id = vm.userLoginId;

                                vm.$store
                                    .dispatch("facebookConnect", response)
                                    .then((response) => {
                                        vm.checkConnectedPlatform();
                                    });
                            }
                        },
                        {
                            scope:
                                "email,pages_read_user_content,pages_show_list,pages_read_engagement",
                            return_scopes: true,
                        }
                    );
                }
            });

            if (vm.facebookCheck) {
                response.user_id = vm.userLoginId;

                vm.$store.dispatch("facebookConnect", response).then((response) => {
                    vm.checkConnectedPlatform();
                });
            }
            // vm.checkConnectedPlatform();
        },
        fbLogout() {
            FB.logout(function (response) {
                console.log("response", response);
                // Person is now logged out
            });
            vm.checkConnectedPlatform();
        },
        timezoneList() {
            this.$store.dispatch("timezoneList").then((response) => {
                this.timeZoneList = response.data;
            });
        },
        onChangeCommunicationFile(e) {
            // this.communication.communicationLogo = e.target.files[0];
            this.createFile(e.target.files[0]);
        },
        dragCommunicationFile(e) {
            // this.communication.communicationLogo = e.dataTransfer.files[0];
            this.createFile(e.dataTransfer.files[0]);
        },
        createFile(file) {
            this.communication.communicationLogo = file;
            var reader = new FileReader();
            var vm = this;

            reader.onload = function (e) {
                vm.communicationImg = e.target.result;
            };
            reader.readAsDataURL(file);
        },
        removeFile() {
            this.communicationImg = "";
            this.removeLogo();
        },
        removeLogo() {
            let data = {
                userID: this.userLoginId,
            };
            this.$store
                .dispatch("removeCommunicationSettingLogo", data)
                .then((response) => {
                    this.communication.communicationLogo = "";
                    if (response.success) {
                        this.$toastr.s(`${response.message}`);
                        this.fetchCommunication();
                    } else {
                        this.$toastr.e(`${response.message}`);
                    }
                });
        },
        saveCommunication() {
            let data = {
                communicationId: this.communication.id ? this.communication.id : "",
                user_id: this.userLoginId,
                timezone: this.communication.timezone,
                emailSendFromName: this.communication.emailSendFromName,
                textSendFromName: this.communication.textSendFromName,
                replyEmail: this.communication.replyEmail,
                // textNumberAreaCode: this.communication.textNumberAreaCode,
                reviewRequestTextDefaultMessage:
                    this.communication.reviewRequestTextDefaultMessage,
                defaultThreeStarResponseEmail:
                    this.communication.defaultThreeStarResponseEmail,
                defaultThreeStarResponseText:
                    this.communication.defaultThreeStarResponseText.replace(/<\/?[^>]+>/ig, ""),
                reviewRequestEmailDefaultMessage:
                    this.communication.reviewRequestEmailDefaultMessage,
                threeStarThankyouMessage: this.communication.threeStarThankyouMessage,
                FourFiveReviewMessage: this.communication.FourFiveReviewMessage,
                reviewRequestMainPageMessage:
                    this.communication.reviewRequestMainPageMessage,
                reviewRequestThreeStarMessage:
                    this.communication.reviewRequestThreeStarMessage,
                reviewRequestFourFiveStarMessage:
                    this.communication.reviewRequestFourFiveStarMessage,
                logo: this.communication.communicationLogo
                    ? this.communication.communicationLogo
                    : null,
                thankyouMessage: this.communication.thankyouMessage.replace(/<\/?[^>]+>/ig, ""),
                thankyouEmail: this.communication.thankyouEmail,
            };
            this.apiLoading = true;
            if (data.communicationId) {
                this.$store
                    .dispatch("updateCommunicationSetting", data)
                    .then((response) => {
                        if (response.success) {
                            this.$toastr.s(`${response.message}`);
                        } else {
                            this.$toastr.e(`${response.message}`);
                        }
                    }).catch((err) => {
                        console.log(err);
                    })
                    .finally(() => {
                        this.apiLoading = false;
                    });
            } else {
                this.$store
                    .dispatch("saveCommunicationSetting", data)
                    .then((response) => {
                        if (response.success) {
                            this.$toastr.s(`${response.message}`);
                            this.fetchCommunication();
                        } else {
                            this.$toastr.e(`${response.message}`);
                        }
                    }).catch((err) => {
                        console.log(err);
                    })
                    .finally(() => {
                        this.apiLoading = false;
                    });
            }
        },
        cancelSubscription(){
            this.adminCancelSubscriptionModal = true;
        },
        requestCancelSubscription() {

            this.cancelSubscriptionForm.user_id = this.userLoginId;
            this.$store
                .dispatch("requestCancelSubscription", this.cancelSubscriptionForm)
                .then((response) => {
                if (response.success) {
                    this.cancelSubscriptionForm = response.data;
                    this.$toastr.s(response.message);
                } else {
                    this.$toastr.e(response.message);
                }
                })
                .catch((err) => {
                console.log(err);
                });

        },
        wizardClear(){
            this.userEditIcon = false;
            this.googleConnectIcon = false;
            this.facebookConnectIcon = false;
            this.yelpConnectIcon = false;
            this.zillowConnectIcon = false;
            this.userCreateIcon = false;
        },
        wizardData() {
            this.apiLoading = true;
            let data = {
                page: 1,
            };
            this.$store
            .dispatch("wizardList", data)
            .then((response) => {
                if (response.success) {
                    this.vStepsVideoList = response.data.data;
                }
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => (this.apiLoading = false));
        },
        tutorialVideo(data){
            if(this.vStepsVideoList[data-1].tutorialLink){
            this.vSteps = true;
            this.vStepsVideo = `${this.vStepsVideoList[data-1].tutorialLink}?origin=https://plyr.io&amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1&output=embed`
            }
        },
        myCustomPreviousStepCallback(currentStep){
            this.wizardClear();
            this.vSteps = false;
            if(currentStep == 1){
                this.showProfile = 'basic';
                this.userEditIcon = true;
            }
            if(currentStep == 2){
                this.googleConnectIcon = true;
            }
            if(currentStep == 3){
                this.facebookConnectIcon = true;
            }
            if(currentStep == 4){
                this.yelpConnectIcon = true;
            }
            if(currentStep == 5){
                this.showProfile = 'platform';
                this.zillowConnectIcon = true;
            }
      },
      myCustomNextStepCallback(currentStep){
        this.wizardClear();
        this.vSteps = false;
        if(currentStep == 0){
            this.showProfile = 'platform';
            this.googleConnectIcon = true;
            this.adminProfileModal = false;
        }
        if(currentStep == 1){
            this.facebookConnectIcon = true;
        }
        if(currentStep == 2){
            this.yelpConnectIcon = true;
        }
        if(currentStep == 3){
            this.zillowConnectIcon = true;
        }
        else if(currentStep == 4){
            this.showProfile = 'communication';
        }
        else if(currentStep == 5){
            this.wizardClear();
            this.vSteps = false;
            this.$router.push({ path: "/users" });
        }
      },
      myCustomStopCallback(){
        this.wizardClear();
        this.tour = false;
        localStorage.setItem("loginFirstTime", 0);
        localStorage.setItem("isSetupWizardPlay", 'false');
      },
    },
};
</script>

<style scoped>
a {
    text-decoration: none;
}
.leftPanel {
    background: #27293b;
    position: static;
}

.sideBar {
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    height: 100vh !important;
    margin-bottom: 0px;
    border-bottom-width: 0px !important;
}

.modal-mask {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    display: block;
    transition: opacity 0.3s ease;
    overflow: auto;
}

.modal-wrapper {
    display: grid;
}

.modal-container {
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    margin-bottom: 100px;
    padding: 30px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
    min-width: 600px;
}

.youtube-video {
    width: 600px;
    height: 300px;
}

.feedback-modal-container {
    padding: 30px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    width: 600px;
}

.business-modal-container {
    padding: 20px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    width: 600px;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.business-modal-history-container {
    padding: 20px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    width: 800px;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.business-input {
    height: 45px;
    border-radius: 15px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px !important;
    margin: 10px 0;
}

.yelp-form-control1 {
    width: 90%;
    display: inline-block !important;
}

.business-btn {
    margin-top: 4px;
    right: 53.47%;
    top: 703px;
    bottom: 25.68%;
    width: 100%;
    height: 45px;
    background: #00053c;
    border-radius: 15px;
    color: white
}

.modal-header h3 {
    margin-top: 0;
    color: #42b983;
}

.modal-body {
    margin: 20px 0;
}

.modal-default-button {
    float: right;
}

.loginFormHeading {
    margin-bottom: 50px;
    top: 294px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 7px;
    color: #323b4b;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container .modal-container-youtube {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.nav-item active {
    background: #00b0ac;
    background-color: #00b0ac;
}

.navbar-light .navbar-nav .nav-link {
    margin-left: 1vw;
    margin-top: 2vh;
    font-size: 14px;
    color: #9194b3;
}

.navbar-vertical.navbar-light .navbar-nav>.nav-item>.nav-link.active {
    color: #fdd640;
    font-size: 14px;
    background-color: #27293b;
}

.navbar>.container .navbar-brand,
.navbar>.container-fluid .navbar-brand {
    margin-left: 1vw;
    margin-top: 2vh;
}

.searchBox {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 16px;
    align-items: center;
    color: #9194b3;
    padding: 0px 20px;
    height: 46px;
    background: #f4f5fa;
    border-radius: 15px;
    min-width: 350px;
    max-width: 350px;
    border: none;
    box-shadow: none;
}

.text-heading {
    font-weight: 500 !important;
    text-transform: capitalize;
    color: #00053c;
    font-family: "Inter";
    font-style: normal;
    font-size: 14px;
    align-items: center;
    white-space: initial;
}

.formtable {
    text-align: center;
    border-collapse: inherit;
}

.fontWeightBolder {
    font-weight: bolder;
}

.createButton {
    font-family: "Inter", sans-serif;
    background: #00053c;
    color: white;
    font-size: 18px;
    border-radius: 15px;
    width: 170px;
    height: 51px;
}

.heading {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-size: 12px;
    line-height: 24px;
    align-items: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #00053c !important;
    font-weight: 600;
    align-items: center;
}

.loginInputsEmail {
    width: 100%;
    width: 100%;
    height: 45px;
    padding: 10px;
    background: #f4f5fa;
    /* BG 2 */

    border: 1px solid #f3f3f3;
    box-sizing: border-box;
    border-radius: 15px;
}

::placeholder {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 29px;
    /* or 181% */
    display: flex;
    align-items: center;

    color: #9194b3;
}

.loginInputsPassword {
    margin-top: 50px;
    width: 100%;
    padding: 20px;
    height: 58px;

    top: 555px;
    /* BG 4 */

    background: #ffffff;
    /* BG 2 */

    border: 1px solid #f3f3f3;
    box-sizing: border-box;
    border-radius: 15px;
}


.tourButton {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 29px;
    background: #00053c;
    color: #ffffff;
    width: 125px;
    height: 40px;
    border-radius: 15px;
}
.userForm {
    height: 50px;
    /* height: 58px; */
    border-radius: 15px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px !important;
}

.loginButtonText {
    justify-content: center;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    /* or 181% */
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
    /* height: 50px; */
}

.callButton {
    justify-content: center;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
    margin: 0px
}

.loginButton {
    margin-top: 20px;
    width: 100%;
    height: 58px;
    background: #00053c;
    border-radius: 15px;
    transition: 0.2s ease;
}

.yellowButton {
    right: 53.47%;
    top: 703px;
    bottom: 25.68%;
    width: 25%;
    height: 50px;
    border-radius: 15px;
    padding: 10px;
    background: #fdd640;
    margin: 0px 12px 12px;
    transition: 0.2s ease;
}

.yellowButton:hover {
    background: #00053c;
}

.row {
    margin-right: 0px;
}

.reviewModalName {
    margin: 0px 20px;
    /* margin: 0px 7px; */
    text-decoration: none;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    /* or 181% */
    align-items: center;

    color: #1778f2 !important;
}

.searchBoxDiv {
    max-width: 281px;
    min-width: 281px;
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.table>tbody>tr>td {
    padding: 30px 15px;
}

/* Review Css  Start*/
.ratingSpan {
    color: white;
    /* padding: 10px 10px; */
    padding: 8px 10px 5px;
    /* background: #1778f2; */
    border-radius: 26px;
    font-weight: 500;
    font-size: 16px;
    margin-left: 15px;
}

.yelpStarColor {
    background: #bf2519;
}

.googleStarColor {
    background: #fdd640;
}

.fbStarColor {
    background: #39559a;
}

.zillowStarColor {
    background: #38cb89;
}

.fbRatingStar {
    color: white;
    padding: 10px 10px;
    padding: 8px 10px 5px;
    background: #1778f2;
    border-radius: 26px;
    background: #1778f2;
    font-weight: 500;
    font-size: 16px;
    margin-left: 15px;
    white-space: nowrap;
}

.ratingSpan>img {
    padding: 0 5px;
    width: 30px;
    margin-top: -4px;
}

/* vue2-editior style start */
.form-white>div {
    width: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    background: #f4f5fa;
    border-radius: 15px;
    font-weight: 500;
    font-size: 20px;
    color: #9194b3;
}

::v-deep .ql-toolbar.ql-snow {
    border: 0;
    background: rgba(145, 148, 179, 0.1);
    border-top: 1px solid rgba(145, 148, 179, 0.2);
}

::v-deep .ql-container.ql-snow {
    border: 0;
    height: 150px;
    overflow: scroll;
    color: black !important;
}

::v-deep .quillWrapper.ql-editor {
    display: flex;
    display: -ms-flexbox;
    flex-direction: column;
    /* flex-direction: column-reverse; */
}

::v-deep .ql-toolbar.ql-snow .ql-formats {
    margin-right: 0;
    color: #9194b3 !important;
}

::v-deep .ql-toolbar.ql-snow span.ql-formats svg {
    width: 16px;
    height: 16px;
}

::v-deep .ql-toolbar.ql-snow span.ql-formats svg line,
.ql-toolbar.ql-snow span.ql-formats svg path {
    stroke: #9194b3;
}

::v-deep .ql-snow .ql-picker {
    color: #9194b3;
}

/* vue2-editior style ends */
.loginName {
    margin-left: 10px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 29px;
    color: #00053c;
}

.loginPic {
    width: 47px;
    height: 47px;
}

.viewReviewInnerBox {
    background-color: white;
    margin-bottom: 20px;
}

.viewReviewInnerBoxImg {
    margin: 20px 20px;
}

.viewReviewInnerBoxPara {
    word-wrap: break-word;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #9194b3;
}

.viewReviewInnerBoxAnchor {
    text-decoration: none;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #4285f4;
}

.viewReviewSecondPartTopText {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #9194b3;
}

.viewReviewSecondPartTopName {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: #00053c;
}

.viewReviewSecondPartTextArea {
    margin: 15px 0px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    /* or 181% */

    display: flex;
    align-items: center;

    color: #9194b3;

    width: 451px;
    height: 150px;
    left: 889px;
    top: 491px;
    background: #ffffff;
    border: 1px solid #f3f3f3;
    box-sizing: border-box;
    border-radius: 15px;
}

.loginButtonText {
    justify-content: center;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    /* or 181% */
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
}

.viewReviewSecondPartdropdown {
    margin: 15px 0px;
    background: #ffffff;
    border: 1px solid #f3f3f3;
    box-sizing: border-box;
    border-radius: 15px;
}

.viewReviewSecondPartdropdownText {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px !important;
    /* font-size: 16px !important; */
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #9194b3;
}

.filterText {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    display: flex;
    color: #9194b3;
}

.profilePic { width: 150px; height: 150px; border-radius: 50%; border: 5px solid #ffffff; position: absolute; margin: -80px 50px; object-fit: contain; background: #FFFFFF; border: 1px solid #00053C; }

/* Review Css End */
.profileFormTitle {
    text-decoration-line: none;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    align-items: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #9194b3 !important;
}

.profileFormTitleValue {
    text-decoration-line: none;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    /* font-size: 12px; */
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #00053c !important;
}

.profileName {
    margin-left: 226px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #00053c;
}

.profileSettingSwitch {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 1.3;
    display: block;
    text-align: center;
    color: #9194b3;
    text-decoration-line: none;
    padding-bottom: 15px;
}

.profileSettingSwitch.active {
    color: #ffab00;
}

.profileSettingIcons {
    padding-right: 15px;
    margin-top: 5px;
    height: 33px;
}

.platFormCard {
    padding: 60px 0px 10px 0;
    border-radius: 15px !important;
}

.platFormCardGroup {
    column-gap: 20px;
    margin: 30px 20px;
}
.platFormCardButton {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    /* or 181% */
    background: #00053c;
    color: #ffffff;
    width: 180px;
    height: 51px;
    border-radius: 15px;
}

.platFormCardButtonColor {
    background: #38cb89;
}

.profileSettingHeading {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #00053c;
    margin-top: 24px;
    margin-left: 24px;
}

.communicationFormLabel {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 29px;
    /* or 207% */

    display: flex;
    align-items: center;
    letter-spacing: 1px;

    color: #9194b3;
}

.choose-files {
    align-self: flex-end;
    z-index: 10;
}

.button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;

    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.button-wrapper.btn:hover {
    background-color: #4e5056;
}

.label {
    margin-bottom: 20px;
    color: #4e5056;
    font-size: 18px;
}

.button-wrapper input[type="file"] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

.btn {
    border: none;
    padding: 10px;
    /* padding: 10px 20px; */
    border-radius: 50px;
    background-color: #f4f5fa;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 200ms ease-in;
}

.drop-it-hot {
    margin: 34px 0px;
    border-radius: 15px;
    /* height: 485px; */
    height: 375px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 8px 15px 0 rgba(black, 0.1);
    overflow: hidden;
    color: #f4f5fa;
    border: 1px dashed rgba(145, 148, 179, 0.5);
    box-sizing: border-box;
}

.communicationImg {
    margin: 34px 0px;
    border-radius: 15px;
    /* height: 485px; */
    height: 375px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 8px 15px 0 rgba(black, 0.1);
    overflow: hidden;
    color: #f4f5fa;
}

.drop-it-hot:after {
    content: "";
    position: absolute;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    border: 2px dashed #e6e7f0;
    border-radius: 5px;
    z-index: 0;
}

.circle {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    color: #f4f5fa;
    background-color: white;
    /* border: 3px solid black; */
    width: 100px;
    height: 100px;
}

.circle {
    transition: transform 150ms ease-in;
    z-index: 10;
}

svg {
    width: 40px;
    height: 40px;
}

.circle:before {
    content: "";
    background-color: #f4f5fa;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    position: absolute;
    opacity: 0;
    transition: transform 250ms ease-in, opacity 200ms ease-in;
    z-index: 0;
}

.circle:after {
    content: "";
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.circle:hover {
    transform: scale(1.2);
    opacity: 0.9;
}

.circle:before {
    transform: scale(8);
    opacity: 1;
}

.circle:after {
    border: 3px solid white;
}

svg {
    color: white;
    z-index: 1;
}

.loginInputsTextArea {
    width: 100%;
    height: 150px;

    background: #f4f5fa;
    border-radius: 15px;
    border-style: none;
}

.assignFollowUp {
    height: 50px;
    left: 889px;
    top: 413px;
    /* background: #ffffff; */
    border: 1px solid #f3f3f3;
    box-sizing: border-box;
    border-radius: 15px;
    width: 100%;
    padding: 10px;
}

.respondButton {
    margin-top: 20px;
    right: 53.47%;
    top: 703px;
    bottom: 25.68%;
    width: 100%;
    height: 50px;
    background: #00053c;
    border-radius: 15px;
}

textarea {
    resize: none;
}

.inputOrange:focus {
    outline: none !important;
    border: 1.5px solid #ff8c00;
    box-shadow: 0 0 2px #ff8c00;
}

form i {
    margin-left: -30px;
    cursor: pointer;
}

.form-control1 {
    display: inline-block !important;
}

.navOutDiv {
    position: fixed;
    width: 100%;
}

.dashScroll {
    margin-right: 0 !important;
    padding-right: 15px;
}

.reviewDropDown {
    width: 96%;
    border-radius: 6px;
    position: absolute;
    inset: 0px auto auto 0px;
    margin: 0px;
    transform: translate(9px, 233px);
}

.outer-circle-new {
    background: rgba(137, 176, 240, 0.1);
    border-radius: 15px;
    padding: 10px;
    margin: 0;
}

.outer-circle-pending {
    background: rgba(255, 171, 0, 0.12);
    border-radius: 15px;
    padding: 10px;
}

.outer-circle-resolved {
    background: #e5f7ef;
    border-radius: 15px;
    padding: 10px;
}

.inner-new {
    color: #89b0f0;
}

.inner-pending {
    color: #ffab00;
}

.inner-resolved {
    color: #38cb89;
}

.center-item {
    display: flex;
    justify-content: center;
    align-items: center;
}

.view-button {
    margin-top: 20px;
    right: 53.47%;
    top: 703px;
    bottom: 25.68%;
    width: 25%;
    height: 50px;
    background: #00053c;
    border-radius: 15px;
    padding: 10px;
    transition: 0.2s ease;
}

.view-button:hover {
    background: #00053c;
}

.filter-bg-color {
    background-color: #f4f5fa;
    border-radius: 15px;
    padding: 5px 10px;
    margin: 0 5px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    display: flex;
    align-items: center;
}

.assignFilter {
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 15px;
    width: 100%;
    justify-content: flex-end;
    margin: 0 0 10px;
}

.basic-info {
    margin: 20px;
    border-radius: 20px;
    background: rgb(244, 245, 250);
    padding: 20px;
}

.edit-text {
    width: 33px;
    height: 16px;
    left: 1302px;
    top: 703px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #9194b3;
    padding: 10px 10px 0px;
}

.basic-card {
    background: rgb(255, 255, 255);
    margin: 20px;
    padding: 20px;
    border-radius: 15px;
}

.communication-setting {
    padding: 36px;
    border-radius: 15px;
    margin-bottom: 20px;
}

.role-table-pagination .pagination {
    justify-content: center;
}

.admin-new-pagination ul.pagination {
    background: none;
}

.admin-new-pagination ul li {
    flex-grow: initial !important;
    box-shadow: 0px 2px 6px #0000000a;
}

.admin-new-pagination {
    padding: 35px 0;
    background: #fff;
    overflow: auto;
}

.admin-new-pagination .pagination {
    /* justify-content: flex-end; */
    height: auto;
    box-shadow: none;
}

.admin-new-pagination .pagination li.active a.page-link {
    background: #623cea;
    border-radius: 0px;
    border-color: #623cea;
    color: #fff;
}

.admin-new-pagination .pagination li.active a.page-link:focus {
    box-shadow: none;
}

.admin-new-pagination .pagination a.page-link {
    color: #a4afb7;
    background: #fff;
}

.admin-new-pagination ul li span,
.admin-new-pagination ul li a,
.admin-new-pagination ul li button {
    padding: 10px 15px !important;
    font-size: 13px !important;
}

.admin-new-pagination .pagination a.page-link:focus {
    box-shadow: none;
}

.dark-theme .admin-new-pagination .pagination li.active a.page-link {
    background: #1a73e8;
    border-color: #1a73e8;
}

.spinner-center {
    justify-content: center;
    align-items: center;
    display: flex;
}

.isDisabled {
    pointer-events: none;
    color: red;
}

.is-invalid {
    background-image: none !important;
    border-color: #dc3545 !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    border-color: #dc3545 !important;
}

.logo-center {
    justify-content: center;
    margin-left: -15px !important;
}

.profile-dropdown {
    margin-left: 10px;
    /* margin-bottom: 10px; */
}

.table-align {
    margin-top: 15px;
    border-radius: 36px;
    margin-left: 10px;
}

table th:first-child {
    border-radius: 15px 0 0 15px;
}

table th:last-child {
    border-radius: 0 15px 15px 0;
}

.table>tbody>tr>td,
.table>thead>tr>th {
    /* border: 0 !important; */
    border-top: none;
}

.header th {
    height: 10px;
    /* line-height: 2px;
}
.user-modal {
 /*  margin-left: 600px !important;
  margin-right: 600px !important; */
    max-width: 600px;
    margin: 100px auto;
}

.user-modal-heading {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #323b4b;
}

.profileSettingSwitch.active {
    border-bottom: 2px solid #ffab00;
    display: block;
}

.checkbox-lg .custom-control-label::before,
.checkbox-lg .custom-control-label::after {
    top: 0.8rem;
    width: 1.55rem;
    height: 1.55rem;
}

.checkbox-lg .custom-control-label {
    padding-top: 8px;
    padding-left: 6px;
}

.checkbox-xl .custom-control-label::before,
.checkbox-xl .custom-control-label::after {
    top: 1.2rem;
    width: 1.85rem;
    height: 1.85rem;
}

.checkbox-xl .custom-control-label {
    padding-top: 23px;
    padding-left: 10px;
}

table th {
    background-color: #f4f5fa !important;
    height: 51px !important;
    padding: 15px !important;
    text-align: center !important;
}

.file-upload-text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #9194b3;
}

.account-outline {
    outline: currentcolor none medium !important;
    border: none;
    box-shadow: none;
}

.initialletter {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    background: #41c7db;
    font-size: 35px;
    color: #fff;
    text-align: center;
    line-height: 110px;
    margin: 20px 0;
}

/* style start */
.inner-white-wrap {
    border-radius: 36px;
    background: #fff;
    /* overflow: hidden; */
    height: 100%;
    padding: 15px;
}

.table-outer-wrap {
    padding: 30px;
    background: rgb(244, 245, 250);
    border-radius: 36px;
}

a.navbar-brand {
    padding: 30px 0 0 50px !important;
}

#sidebarCollapse {
    padding: 0;
    margin-top: 40px;
}

#sidebarCollapse li.nav-item a {
    position: relative;
}

#sidebarCollapse li.nav-item a::before {
    position: absolute;
    content: "";
    width: 46px;
    height: 46px;
    left: -36px;
    top: -6px;
    background: #fdd640;
    box-shadow: 0px 0px 17px 5px rgba(253, 214, 64, 0.12);
    border-radius: 15px;
    transform: rotate(45deg);
    opacity: 0;
    transition: 0.3s ease all;
}

#sidebarCollapse li.nav-item a {
    margin: 0;
    padding-left: 30px;
    margin-top: 10px;
}

#sidebarCollapse li.nav-item a.active::before {
    opacity: 1;
}

#sidebarCollapse li.nav-item a:hover::before {
    opacity: 1;
}

#sidebarCollapse li.nav-item a:hover {
    background: transparent;
}

.card-header h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600 !important;
    font-size: 32px;
    color: #00053c;
}

.loginButton:hover {
    background: #fdd640 !important;
}

.loginButton:hover p.loginButtonText {
    color: #fff !important;
}

.action_btn-platform .loginButtonText {
    height: auto !important;
}

.table-outer-wrap .pagination button,
.table-outer-wrap .pagination span {
    margin: 0 3px;
}

table td h1 {
    font-size: 16px;
}

.modal-container-view {
    width: 800px;
    margin: 0 !important;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    padding: 30px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
}

/* file upload style */
input#file-upload {
    padding-left: 76px;
    font-size: 13px;
}

input#file-upload::after {
    content: "Upload Profile Image";
    position: absolute;
    top: 7px;
    left: 20px;
    background: white;
    width: 150px;
    height: 30px;
    line-height: 30px;
    padding: 0 5px;
    font-size: 14px;
    font-weight: 600;
    color: #68696a;
    border-radius: 10px 0 0 10px;
}

.rating-wrap {
    display: inline-block;
}

.star-wrapper {
    display: inline-flex;
    align-items: center;
    margin: 10px !important;
    padding: 0 !important;
}

.star-wrapper+p {
    display: flex;
    align-items: center;
}

button p {
    /* margin: 5px 0; */
}

.profile-img {
    display: inline-flex;
    height: 47px;
    width: 47px;
    border-radius: 50%;
    background: #f7f7f7;
    border: 1px solid #f7f7f7;
    object-fit: cover;
    align-items: center;
    justify-content: center;
}

.profile-img img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #f7f7f7;
    object-fit: cover;
}

.profile-inner {
    display: inline-flex !important;
    align-items: center;
    padding: 5px;
    background: #f7f7f7;
    border-radius: 30px;
    white-space: nowrap;
}

.profile-inner .loginName {
    margin-bottom: 0;
}

.new-color {
    text-decoration: none;
    color: #ff8c00 !important;
}

.modal-container.modal-sm {
    width: 100% !important;
    max-width: 500px;
}

.modal-container.modal-video {
    width: 100% !important;
    max-width: 600px;
}

@media (min-width: 1400px) {
    .modal-container {
        max-width: 1000px;
        width: 100% !important;
        margin: 0 auto !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        max-height: calc(100vh - 0px);
        overflow-y: auto;
        min-width: 600px;
    }

    .modal-container-youtube {
        max-width: 1000px;
        width: 100% !important;
        margin: 0 auto !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        max-height: calc(100vh - 0px);
        overflow-y: auto;
    }

    .formInput {
        padding-top: 20px;
    }
}

@media (max-width: 1599.98px) {
    .table>tbody>tr>td {
        padding: 10px;
    }

    table .btn {
        padding: 0 5px !important;
    }
}

@media (max-width: 1299.98px) {
    .modal-container {
        max-width: 85% !important;
    }

    .modal-container-youtube {
        max-width: 85% !important;

    }

    .text-width {
        width: auto !important;
    }

    .platFormCardGroup {
        margin: 0;
        -moz-column-gap: 15px;
        column-gap: 15px;
    }

    .platFormCard {
        padding: 50px 0 5px;
        height: auto;
    }
}

@media (max-width: 1199.98px) {
    .platFormCardGroup {
        margin: 0;
        -moz-column-gap: 15px;
        column-gap: 15px;
    }

    .platFormCard {
        padding: 30px 0 5px;
        height: auto;
    }

    .profileSettingHeading {
        margin: 0;
    }

    .com-set-wrap {
        margin: 0 !important;
    }

    .youtube-video {
        width: 300px;
        height: 150px;
        margin: 150px auto;
    }
}

@media (max-width: 991.98px) {

    .login-wrap .formInput {
        margin-top: 0 !important;
    }

    .fbStarWidth {
        width: 100%;
    }

    .viewReviewInnerBoxImg {
        display: block;
    }

    .text-width {
        margin: 10px !important;
    }

    .feedback-modal-container {
        top: 0;
        transform: translate(-50%, 50%);
    }

    /* responsive sidebar */
    .navbar-collapse.collapse {
        display: none !important;
        background: #27293b;
        width: 100%;
        height: auto;
        padding: 10px 20px !important;
        margin: 0;
    }

    .sideBar {
        height: auto !important;
    }

    a.navbar-brand {
        padding: 0 !important;
    }

    .profile-wrap {
        display: none !important;
    }

    #sidebarCollapse {
        margin-top: 0;
    }

    .btn-logo-wrap button.navbar-toggler {
        position: absolute;
        right: 30px;
        background: #fff !important;
    }

    .container-fluid.leftPanel {
        position: relative;
    }

    .btn-logo-wrap {
        display: flex;
        display: -ms-flexbox;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        padding: 0 30px;
    }

    .navbar-collapse.collapse.show {
        display: block !important;
    }

    .navOutDiv {
        position: unset;
    }

    #sidebarCollapse ul.navbar-nav {
        width: 100%;
    }

    .dashboard-view-card {
        width: 100%;
    }

    .relative-pos {
        width: 100%;
    }

    .dashboard-graph-card {
        width: 100%;
        height: 350px;
        height: auto;
    }

    .dashboard-review-card {
        width: 100%;
        height: 350px;
        height: 100%;
    }

    .modal-container-view {
        top: 0;
        transform: translate(-50%, 50%);
        width: 90%;
    }

    .youtube-video {
        width: 300px;
        height: 150px;
        margin: 150px auto;
    }
}

@media (max-width: 767.98px) {
    .youtube-video {
        width: 300px;
        height: 150px;
        margin: 150px auto;
    }

    .fbStarWidth {
        width: 100%;
    }

    .table-align>.card {
        margin: 12px !important;
    }

    .card.border-0.mb-7.inner-feedback-action {
        margin: 0 10px;
    }

    .card.border-0.feeback-action.col-9 {
        width: 100% !important;
    }

    .detail-wrap {
        margin-left: 0 !important;
    }

    .modal-container {
        /* margin-left: 20px;
    margin-right: 20px; */
    }

    .feedback-modal-container {
        width: 90%;
    }

    .navbar-collapse.collapse {
        padding: 10px 0px !important;
    }

    main .container {
        max-width: 100%;
    }

    table td h1 {
        font-size: 15px;
    }

    .card-header h2 {
        font-size: 26px;
    }

    .requestFrom {
        margin: 20px;
    }

    ul.navbar-nav {
        margin: 0 !important;
    }

    #sidebarCollapse li.nav-item a::before {
        left: -26px;
        width: 36px;
        height: 36px;
        top: 0;
    }

    .searchBox {
        min-width: auto;
        max-width: unset;
        width: 100%;
    }

    .table-responsive {
        border: 0;
    }

    .table-responsive .table th {
        border: 0;
    }

    .modal-wrapper a {
        cursor: pointer;
    }

    .table-outer-wrap {
        padding: 12px;
    }

    div#userForDiv {
        margin: 0;
        margin-top: 10px;
    }

    .profileName {
        margin-left: 0;
        margin-top: 0;
        font-size: 16px;
    }

    .tab-wrap {
        flex-wrap: wrap;
        width: 100% !important;
    }

    .tab-wrap>.col-12 {
        width: 50%;
    }

    .tabs-outer {
        margin: 0 !important;
    }

    .basic-info {
        margin: 10px 0 0 0;
    }

    .basic-card {
        margin: 0;
    }

    .profilePic {
        margin: -80px 30px;
    }

    .profile-text-wrap {
        padding: 0 !important;
        margin-top: 60px;
    }

    .com-set-wrap {
        margin: 0 !important;
    }

    .communication-setting {
        padding: 10px 5px 0px 20px;
    }

    .dashboard-view-card {
        width: 100%;
    }

    .relative-pos {
        width: 100%;
    }

    .dashboard-graph-card {
        width: 100%;
        height: 350px;
        height: auto;
    }

    .dashboard-review-card {
        width: 100%;
        height: 350px;
        height: 100%;
    }

    .business-modal-container {
        width: 90%;
    }

    .business-modal-history-container {
        width: 90%;
    }

    div#drop-area {
        margin-top: 0;
    }

    .connectButton {
        width: 50%;
        height: 75px;
    }

    .frontrunnerText {
        font-size: 12px;
        margin: 0 auto;
    }
}

.connectButton { border-radius: 15px; border: 1px solid #00053c; background: white; font-family: "Inter", sans-serif; font-size: 14px; width: auto; height: 40px; font-weight: 600; }

.frontrunnerText {
    font-size: 14px;
    background: white;
    color: #00053c;
    margin: 0 auto;
}

.sync-svg {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 40px;
    top: 10px;
    cursor: pointer;
}

/* Dashboard CSS */
.dashboard-card {
    position: static;
    width: auto;
    height: auto;
    background: #f4f5fa;
    border-radius: 20px;
}

.relative-pos {
    position: relative;
}

.review-journey {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #00053c;
}

.mt-35 {
    margin-top: 35px;
}

.dashboard-view-card {
    width: 239px;
    height: 137px;

    background: #ffffff;
    border-radius: 15px;
    float: left;
    padding: 10px;
    margin: 10px;
}

.dashboard-per-card {
    height: 116px;
}

.dashboard-graph-card {
    width: 500px;
    height: auto;
    background: #ffffff;
    border-radius: 15px;
    padding: 10px;
    float: left;
    margin: 10px;
}

.dashboard-review-count {
    color: #00053c;
}

.dashboard-review-text {
    width: 89.97px;
    height: 12px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #9194b3;
    margin: 15px;
}

.date-color {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.3;
    display: flex;
    align-items: center;
    color: #9194b3;
    margin-left: 10px;
}

.dashboard-review-star {
    width: 94px;
    height: 20px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #00053c;
    margin: 10px;
}

.dashboard-all-perc {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #38cb89;
    margin: 10px;
}

.dashboard-month-perc {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #4285f4;
    margin: 10px;
}

.dashboard-recent-reviews {
    width: 193px;
    height: 14px;
    left: 883px;
    top: 362px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #00053c;
    margin: 10px;
}

.customer-name {
    margin: 10px;
}

.review-star {
    position: absolute;
    right: 10px;
}

.dashboard-customer-review-text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
    display: flex;
    align-items: center;
    color: #9194b3;
}

.review-time {
    position: absolute;
    top: 25px;
    right: 10px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #9194b3;
}

.dashboard-owner-response {
    position: absolute;
    left: 18%;
    right: 12%;
    top: 41.71%;
    bottom: 30.17%;
    background: #f4f5fa;
    border-radius: 15px;
}

/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    .fbStarWidth {
        width: 100%;
    }

    .dashboard-view-card {
        width: 100%;
    }

    .dashboard-graph-card {
        width: 100%;
        height: 350px;
        height: auto;
    }

    .dashboard-review-card {
        width: 100%;
        height: 350px;
        height: 100%;
    }

    .relative-pos {
        width: 100%;
    }

    .form-white.inner-white {
        margin-left: 0 !important;
    }

    .form-outline.form-white.inner-white {
        margin-left: 10px !important;
    }

    .inner-white {
        padding: 10px 0;
    }

    .action_btn-platform-wrap {
        justify-content: center;
    }

    .action_btn-platform {
        flex: 0 0 100%;
    }
}

.inner-head-wrap {
    display: flex;
    display: -ms-flexbox;
    align-items: baseline;
}

.inner-head-wrap .data-h1 {
    padding-right: 10px;
}

.data-h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #00053c;
}

.buttons-platform {
    width: 200px;
    margin: 0 auto;
    display: inline;
}

.action_btn-platform-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.action_btn-platform {
    /* width: 200px; */
    /* margin: 20px auto 0; */
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    color: #ffffff;
    height: 40px;
    border-radius: 10px;
    margin: 10px 0;
}

.action_btn_color__connected {
    background: #38cb89;
}

.action_btn_color__disconnected {
    background: #ff8c00;
}

.platform__connected {
    color: #38cb89;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 29px;
    display: flex;
    align-items: center;
    letter-spacing: 1px;
}

#editorid .ql-editor {
    width: 100%;
    height: 150px;
    background: #f4f5fa;
    border-radius: 15px;
}

.feeback-action {
    border-radius: 20px;
    background: #f4f5fa;
}

.dashboard-review-card {
    width: 100%;
    background: #ffffff;
    border-radius: 15px;
    padding: 10px;
    margin: 10px;
}

.view-more-btn {
    border-radius: 15px;
    border: 1px solid #00053c;
    background: white;

    font-family: "Inter", sans-serif;
    font-size: 18px;
    width: 50%;
    /* height: 50px; */
    font-weight: 600;
    margin: 5px auto;
}

.business-modal-container img {
    word-break: break-all;
    word-wrap: break-word;
}

.f-start {
    align-self: flex-start;
}

.fbStarWidth {
    width: 100px;
    margin: 0;
}

.imgBtnCenter {
    margin: 0 auto;
}

:deep(.v-step) {
    background: #D7E9F7 !important;
    color: #000 !important;
    box-shadow: none !important;
    /* max-width: none !important; */
    width: 450px !important;
    max-width: 500px !important;
    border-radius: 15px !important;
    z-index: 1 !important;
}

:deep(.v-step__button) {
    font-family: "Inter", sans-serif !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 29px !important;
    background: #00053c !important;
    color: #ffffff !important;
    width: 125px !important;
    height: 40px !important;
    border-radius: 15px !important;
}

.squareWizard {
    cursor: pointer;
    border: 4px solid red;
}

:deep(.vue-para) {
    font-size: 16px
}

.hiddenimg {
    display: none;
}

.hiddentxt {
    font-weight: bold;
    color: #F00;
    z-index: 99;
}

.hiddentxt a {
    color: #F00;
    text-decoration: none;
    z-index: 99;
}

.hiddenclick {
    font-weight: bold;
    color: #F00;
    text-decoration: none;
    cursor: pointer;
}

.hiddenclick a {
    color: #F00;
    text-decoration: none;
}

.hiddenclick a:visited {
    color: #F00;
    text-decoration: none;
}

.hiddentxt:hover~.hiddenimg {
    display: block;
    position: absolute;
    z-index: 2
}

.hiddenclickimg {
    display: none;

}

.formInput {
    margin-right: 10px;
    position: absolute;
    padding: 15px;
    min-width: 40px;
    text-align: center;
}
.pac-container {
    z-index: 1050 !important;
}

.cancelSubscriptionButton { border-radius: 15px; border: 1px solid #FF0000; color: #FF0000; background: white; font-family: "Inter", sans-serif; font-size: 14px; width: auto; height: 40px; font-weight: 600; }
</style>
