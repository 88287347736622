<template>
    <main class="col-md-12 d-flex justify-content-center">
        <form class="d-block" style="padding-top: 50px">
            <div class="row">
                <div class="mb-4 pb-2 requestFrom">
                    <div class="formPTag">
                        <h2>• ERROR •</h2>
                    </div>
                </div>

                <div>
                    <h1>
                        404
                    </h1>
                </div>
                <hr />
                <div>
                    <p class="formPTag">Page Not Found</p>
                    <button><a @click.prevent="landingPage">Go To Website</a></button>
                </div>
            </div>
        </form>
    </main>
</template>

<script>
export default {
    name: "PageNotFound",
    methods: {
        landingPage() {
            this.$router.push('/')
        }
    },
};
</script>
<style scoped>
main {
    height: 100vh;
    background: #EDF6FC;
    text-align: center;
    color: #9194b3;

}

h1 {
    font-size: 25rem;
}

.formPTag {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    align-items: center;
}

button {
    font-family: "Inter", sans-serif;
    background: #00053c;
    color: white;
    font-size: 18px;
    border-radius: 15px;
    width: 50%;
    height: 50px;
    font-weight: 600;
    align-items: center;
}
</style>