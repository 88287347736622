<template>
  <!-- All Header Content will be written here.. -->
  <div class="bg-repeat" style="background-image: url('resources/assets/Headerbg.png')">
    <header class="site-header">
      <nav class="navbar navbar-expand-lg navbar-light">
        <div class="container-fluid">
          <a href="#" class="navbar-brand"><img src="resources/assets/logo_landing.png" alt="" /></a>
          <button type="button" class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarCollapse">
            <div class="navbar-nav navbar-nav ms-auto">
              <ul class="main-nav">
                <li class="nav-text">
                  <a class="nav-item nav-link nav-text-color" href="#id">Home</a>
                </li>
                <li class="nav-text">
                  <a class="nav-item nav-link nav-text-color" href="#features">Features</a>
                </li>
                <li class="nav-text">
                  <a class="nav-item nav-link nav-text-color" href="#pricing">Pricing</a>
                </li>
                <li class="nav-text">
                  <a class="nav-item nav-link nav-text-color" href="#about">About Us</a>
                </li>
                <li class="nav-text">
                  <a class="nav-item nav-link nav-text-color" href="#faq">FAQ</a>
                </li>
              </ul>
            </div>
            <div class="navbar-nav ms-auto">
              <ul class="right-nav">
                <li>
                  <a @click="login()" href="#" class="nav-btn">Log In</a>
                </li>
                <li>
                  <a href="#signup">
                    <button type="button" class="btn btn-solid">Sign
                      Up</button></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </header>
    <section class="hero-section" v-if="moreReviews">
      <div class="container">
        <div class="hero-inner">
          <div class="hero-left">
            <h1 class="multi-heading">
              <span v-html="moreReviews.heading"></span>
            </h1>
            <p class="dark-desc">
              <span v-html="moreReviews.description"></span>
            </p>
            <div class="hero-actions">
              <div class="hero-action">
                <a href="#signup">
                  <button type="button" class="btn btn-solid">Sign Up</button></a>
              </div>
              <div class="hero-action">
                <a target="_blank" href="https://calendly.com/">
                  <button type="button" class="btn btn-outline">Find Out More</button>
                </a>
              </div>
              <div class="hero-action">
                <button type="button" @click="openDemoVideoFormModel()" class="btn btn-outline">Demo Video</button>
              </div>
            </div>
          </div>
          <div class="hero-right">
            <img :src="moreReviews.image_url" alt="">
          </div>
        </div>
      </div>

      <transition name="modal" v-if="demoVideoFormModel">
        <div class="modal-mask">
            <div class="">
            <div class="modal-container-schedule">
                <div class="row">
                <div class="pb-2 requestFrom">
                    <div class="d-flex justify-content-between align-items-center mb-5 formPTag">
                    <p> Fill out the form below to see video</p>
                    <div class="d-flex">
                        <a @click="demoVideoFormModel=false"><img src="resources/assets/crossicon.png" /></a>
                    </div>
                    </div>

                    <div style="padding: 15px">
                      <div class="row">
                          <div class="col-6 col-md-6 mb-4 pb-1">
                            <div class="form-outline form-white">
                                <input type="text" class="form-control userForm form-control-lg inputOrange"
                                placeholder="First Name" v-model="requestData.first_name" v-validate="'required'"
                                v-bind:name="'requestData.first_name'" v-bind:ref="'requestData.first_name'" :class="{
                                    'is-invalid': errors.has('requestData.first_name'),
                                }" />
                            </div>
                          </div>
                          <div class="col-6 col-md-6 mb-4 pb-1">
                            <div class="form-outline form-white">
                                <input type="text" class="form-control userForm form-control-lg inputOrange"
                                placeholder="Last Name (Optional)" v-model="requestData.last_name" />
                            </div>
                          </div>
                      </div>

                      <div class="row">
                          <div class="col-6 col-md-6 mb-4 pb-1">
                            <div class="form-outline form-white">
                                <input type="number" class="form-control userForm form-control-lg inputOrange"
                                placeholder="Mobile Phone" v-model="requestData.phone"
                                v-validate="{ 'required': (!requestData.link && (requestData.email.length < 1 || requestData.SMS || requestData.both)), 'max': 15, 'numeric': true }"
                                v-bind:name="'requestData.phone'" v-bind:ref="'requestData.phone'" :class="{
                                    'is-invalid': errors.has('requestData.phone'),
                                }" />
                            </div>
                          </div>
                          <div class="col-6 col-md-6 mb-4 pb-1">
                            <div class="form-outline form-white">
                                <input type="text" class="form-control userForm form-control-lg inputOrange"
                                placeholder="Email Address " v-model="requestData.email"
                                v-validate="{ 'required': (!requestData.link && (requestData.phone.length < 1 || requestData.MAIL || requestData.both)), 'email': true }"
                                v-bind:name="'requestData.email'" v-bind:ref="'requestData.email'" :class="{
                                    'is-invalid': errors.has('requestData.email'),
                                }" />
                            </div>
                          </div>
                      </div>

                    <div class="row mt-5">
                        <div class="col-12 col-md-12 mb-2 pb-1">
                          <button type="button" class="sendNowButton" @click="sendDemoVideoRequest()">
                              <p class="buttonText">View Demo Video</p>
                          </button>
                        </div>
                    </div>
                    </div>
                </div>

                </div>
            </div>
            </div>
        </div>
    </transition>
    
    <transition name="modal" v-if="showDemoVideo">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-container" style="background: none;box-shadow:none">
              <form class="d-block">
                <div class="d-flex mb-2" style="justify-content: end;">
                  <a @click="showDemoVideo = false" class="f-start"><img src="resources/assets/crossicon.png" /></a>
                </div>
                <div class="table-responsive">
                  <div class="plyr__video-embed" id="player">
                    <iframe class="youtube-video" style="width: 600px; height: 300px;" title="YouTube video" src="https://www.youtube.com/embed/OkY26bYWLi4" allowfullscreen allowtransparency allow="autoplay"></iframe>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </transition>

    </section>
  </div>
</template>


<script>
export default {
  name: "LandingHeader",
  props: {
    moreReviews: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      apiLoading:false,
      showDemoVideo: false,
      demoVideoFormModel: false,
      requestData: {
        first_name: "",
        last_name: "",
        email: "",
        phone: ""
      },
    }
  },
  mounted() { },
  methods: {
    login() {
      this.$router.push({ path: '/login' })
    },
    openDemoVideoFormModel(){
        this.clearDemoVideoForm();
        this.demoVideoFormModel = true;
      },
    sendDemoVideoRequest() {
        let data = {
          first_name: this.requestData.first_name.trim(),
          last_name: this.requestData.last_name.trim(),
          phone: this.requestData.phone,
          email: this.requestData.email,
        };
        this.apiLoading = true;
        this.$store.dispatch("sendDemoVideoRequest", data).then((response) => {
          if (response.success) {
            this.$toastr.s(`${response.message}`);
            this.clearDemoVideoForm();
            this.demoVideoFormModel = false;
            this.showDemoVideo = true;
          } else {
            if (response.message == "Validation Error.") {
              this.$toastr.e(`${response.data.email}`);
            } else {
              this.$toastr.e(`${response.message}`);
            }
          }
        }).catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.apiLoading = false;
          });
      },
      clearDemoVideoForm() {
        this.requestData.first_name = "";
        this.requestData.last_name = "";
        this.requestData.email = "";
        this.requestData.phone = "";
      }
  },
};
</script>
<style scoped>
.container {
  flex: 1;
  /* same as flex-grow: 1; */
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

h2,
h3,
a {
  color: #34495e;
}

a {
  text-decoration: none;
}

header {
  width: 100%;
}

.logo {
  margin: 0;
  font-size: 1.45em;
}

.main-nav {
  margin-top: 5px;
}

.logo a,
.main-nav a {
  padding: 10px 15px;
  text-align: center;
  display: block;
}

.main-nav a {
  color: #9194b3;
  font-size: 0.99em;
}

.main-nav a:hover {
  color: #718daa;
}

.right-nav {
  display: flex;
}

.signUpButton {
  width: 100px;
  height: 35px;
  background: #00053c;
  border-radius: 10px;
}

.signUpText {
  justify-content: center;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.float-container {
  border: 3px solid #fff;
  padding: 20px;
}

.float-child {
  width: 50%;
  float: left;
  padding: 20px;
  margin: 50px auto;
}

.bg-image {
  height: 80vh;
}

.nav-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  display: flex;
  align-items: center;
}

.nav-text-color {
  color: #9194b3;
}

.nav-btn {
  padding-right: 50px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;

  display: flex;
  align-items: center;
  text-align: center;
}

.btn-color {
  color: #00053c;
}

.positive-review {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 60px;
  line-height: 73px;
}

.positive-review-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: rgba(0, 5, 60, 0.6);
}

.report-bg-image {
  height: 80vh;
  position: absolute;
  width: 100%;
  width: 1077.34px;
  height: 673.66px;
  left: 649.66px;
  top: 14.81px;
}

.relative-position {
  position: relative;
}

.right-star {
  position: absolute;
  width: 224.95px;
  height: 58.8px;
  left: 404.83px;
  top: 225.42px;
}

.right-perfomance {
  position: absolute;
  width: 456.35px;
  height: 326.34px;
  left: 592.83px;
  top: 189.16px;
}

.right-delivery {
  position: absolute;
  width: 425.15px;
  height: 367.5px;
  left: 445.85px;
  top: 346.45px;
}

/* =================================
  Media Queries
==================================== */

@media (min-width: 769px) {

  .header,
  .main-nav {
    display: flex;
  }

  .header {
    flex-direction: column;
    align-items: center;
  }

  .header {
    width: 100%;
    margin: 0 auto;
    max-width: 1150px;
  }
}

@media (min-width: 1025px) {
  .header {
    flex-direction: row;
    justify-content: space-between;
  }
}

.flexbox {
  display: flex;
  height: 100px;
  column-gap: 20px;
}

.flexbox>div {
  flex: auto;
}

.section-center {
  margin: 25px auto;
}

.ecllipse-bg {
  width: 646.5px;
  height: 646.5px;
  left: -91.5px;
  top: 838.75px;
  background: rgba(0, 5, 60, 0.02);
  border-radius: 50%;
  display: inline-block;
}

.yellow-ecllipse-img {
  padding: 100px 0 100px 100px;
}

.yellow-ecllipse-paragraph {
  width: 543px;
  height: auto;
  left: 757px;
  top: 1090px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  color: #9194b3;
  border-left: thick solid #fdd640;
  border-radius: 3px;
  margin: 25px;
  padding: 0 25px;
}

.yellow-ecllipse-header {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
  color: #00053c;
}

.slider-tag {
  position: absolute;
  width: 564.13px;
  height: 310.66px;
  left: 59.99px;
  top: 254.82px;
}

.readMoreBtn {
  border-radius: 15px;
  border: 1px solid #00053c;
  background: white;

  font-family: "Inter", sans-serif;
  font-size: 18px;
  width: 25%;
  height: 50px;
  font-weight: 600;
  width: 100px;
}

.buttonText {
  left: 54.13%;
  right: 38.58%;
  top: 28.17%;
  bottom: 71.36%;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;

  display: flex;
  align-items: center;
  text-align: center;
}

.bg-repeat {
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.site-header .main-nav .nav-item.nav-link {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  color: #9194B3;
  padding: 0;
}

.site-header .main-nav>li:not(:last-child) {
  margin-right: 45px;
}

.site-header .nav-btn {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  color: #00053C;
  cursor: pointer;
}

.site-header .nav-btn:hover,
.site-header .main-nav .nav-item.nav-link:hover {
  color: #000000;
}

.right-nav li {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.btn.btn-solid {
  background: #00053C;
  color: #ffffff;
}

.btn.btn-outline {
  border: 1px solid #00053C;
  color: #00053C;
  background: transparent;
}

.btn {
  border-radius: 15px;
  height: 50px;
  min-width: 150px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #FFFFFF;
  background: #00053C;
}

.btn-solid:hover {
  background: #5b95f5 !important;
  color: #ffffff !important;
}

.btn-outline:hover {
  background: #00053C !important;
  color: #ffffff !important;
}

.hero-actions {
  display: flex;
  align-items: center;
  margin: 0 -15px;
  flex-wrap: wrap;
}

.hero-actions .hero-action {
  padding: 10px 15px;
}

.multi-heading {
  font-style: normal;
  font-weight: 300;
  font-size: 60px;
  line-height: 73px;
  color: #00053C;
  margin-bottom: 30px;
}

.multi-heading strong {
  font-weight: 600;
}

.dark-desc {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: rgba(0, 5, 60, 0.6);
  margin-bottom: 20px;
}

.hero-inner {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.hero-left,
.hero-right {
  padding: 30px 15px;
  flex: 0 0 50%;
}

header.site-header {
  padding: 25px 0;
}

@media (max-width: 991.98px) {

  .form-left,
  .form-right,
  .hero-left,
  .hero-right {
    flex: 0 0 100%;
  }

  .navbar-collapse.collapse:not(.show) {
    display: none !important;
  }

  .navbar-collapse {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    background: #f7f7f7;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0;
  }

  .site-header .container-fluid:before,
  .site-header .container-fluid:after {
    display: none;
  }

  .navbar-collapse .navbar-nav.navbar-nav {
    flex: 0 0 100%;
    width: 100%;
    margin: 0;
  }

  header.site-header {
    padding: 0;
  }

  .navbar-brand {
    padding: 10px;
  }

  .navbar-collapse .navbar-nav.navbar-nav .main-nav li {
    width: 100%;
    margin-bottom: 15px;
    margin-right: 0;
  }

  .navbar-collapse .navbar-nav.navbar-nav .main-nav {
    flex-wrap: wrap;
  }

  .navbar-collapse .navbar-nav.navbar-nav .right-nav li {
    flex: 0 0 100%;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 20px;
  }

  .navbar-collapse .navbar-nav.navbar-nav .right-nav {
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
  }

  .site-header .navbar.navbar-expand-lg>.container-fluid {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex: 0 0 100%;
  }

  .site-header .navbar.navbar-expand-lg>.container-fluid .navbar-brand {
    margin: 0;
    flex: 0 0 auto;
    justify-content: flex-start;
    float: none !important;
  }

  .site-header .navbar.navbar-expand-lg>.container-fluid button.navbar-toggler {
    justify-content: flex-end;
    flex: 0 0 auto;
    background: transparent !important;
  }

}

.hero-right>svg {
  max-width: 100%;
}

@media (max-width: 767.98px) {
  .multi-heading {
    font-size: 45px;
    line-height: 50px;
  }

  .section-bd-heading,
  .section-bd-heading strong {
    font-size: 32px;
    line-height: 45px;
  }
}
</style>
