<template>
  <div>
    <div v-if="apiLoading" class="loading">Loading&#8230;</div>
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-12">
        <div class="card-registration card-registration-2" style="border-radius: 15px">
          <div class="card-body p-0">
            <div class="row row-height">
              <div>
                <div class="signUpForm">
                  <div class="topText">
                    <p>
                      You're just one step away from more positive reviews and
                      deep insight into how your customers really feel! Fill out
                      the formbelow get started.
                    </p>
                  </div>
                  <div>
                    <h1 class="mb-5 mt-5 largeFont">Step 2</h1>
                    <h1 class="doller-per-month mb-5">Payment Information</h1>
                    <form class="w-100">
                      <div class="row">
                        <div class="col-md-6 mb-4 pb-2">
                          <input type="checkbox" id="registerFormChecked" v-model="registerFormChecked"
                            @change="check()" />
                          <label class="form-check-label fadeColor" for="registerFormChecked">
                            <!-- My Billing Name and Address -->
                            Use My Previous Information
                          </label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6 mb-4 pb-2">
                          <div class="form-outline form-white">
                            <input v-model="registerForm.b_firstName" type="text" id="form3Examplea4"
                              class="loginInputsEmail inputOrange" placeholder="First Name" v-validate="'required'"
                              v-bind:name="'registerForm.b_firstName'" v-bind:ref="'registerForm.b_firstName'" :class="{
                                'is-invalid': errors.has(
                                  'registerForm.b_firstName'
                                ),
                              }" autocomplete="off" />
                          </div>
                        </div>
                        <div class="col-md-6 mb-4 pb-2">
                          <div class="form-outline form-white">
                            <input v-model="registerForm.b_lastNAme" type="text" id="form3Examplea5"
                              class="loginInputsEmail inputOrange" placeholder="Last Name" v-validate="'required'"
                              v-bind:name="'registerForm.b_lastNAme'" v-bind:ref="'registerForm.b_lastNAme'" :class="{
                                'is-invalid': errors.has(
                                  'registerForm.b_lastNAme'
                                ),
                              }" autocomplete="off" />
                          </div>
                        </div>
                      </div>
                      <div class="mb-4 pb-2">
                        <div class="form-outline form-white">
                          <input v-model="registerForm.b_billingAddress" type="text" id="autocomplete"
                            class="loginInputsEmail inputOrange" placeholder="Billing Address" v-validate="'required'"
                            v-bind:name="'registerForm.b_billingAddress'" v-bind:ref="'registerForm.b_billingAddress'"
                            :class="{
                              'is-invalid': errors.has(
                                'registerForm.b_billingAddress'
                              ),
                            }" autocomplete="off" @input="initialize" />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6 mb-4 pb-2">
                          <div class="form-outline form-white">
                            <input v-model="registerForm.b_city" type="text" id="form3Examplea4"
                              class="loginInputsEmail inputOrange" placeholder="City" v-validate="'required'"
                              v-bind:name="'registerForm.b_city'" v-bind:ref="'registerForm.b_city'" :class="{
                                'is-invalid': errors.has('registerForm.b_city'),
                              }" autocomplete="off" />
                          </div>
                        </div>
                        <div class="col-md-3 mb-4 pb-2">
                          <div class="form-outline form-white">
                            <input v-model="registerForm.b_state" type="text" id="form3Examplea5"
                              class="loginInputsEmail inputOrange" placeholder="State" v-validate="'required'"
                              v-bind:name="'registerForm.b_state'" v-bind:ref="'registerForm.b_state'" :class="{
                                'is-invalid': errors.has(
                                  'registerForm.b_state'
                                ),
                              }" autocomplete="off" />
                          </div>
                        </div>
                        <div class="col-md-3 mb-4 pb-2">
                          <div class="form-outline form-white">
                            <input v-model="registerForm.b_zip" type="text" id="form3Examplea5"
                              class="loginInputsEmail inputOrange" placeholder="Zip" v-validate="'required'"
                              v-bind:name="'registerForm.b_zip'" v-bind:ref="'registerForm.b_zip'" :class="{
                                'is-invalid': errors.has('registerForm.b_zip'),
                              }" autocomplete="off" />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6 mb-4 pb-2">
                          <div class="form-outline form-white">
                            <div id="card-number" type="text" class="loginInputsStripe inputOrange"
                              placeholder="Credit Card Number" autocomplete="off"></div>
                          </div>
                        </div>
                        <div class="col-md-3 mb-4 pb-2">
                          <div class="form-outline form-white">
                            <div id="card-expiry" type="text" class="loginInputsStripe inputOrange"
                              placeholder="Exp. Date" autocomplete="off"></div>
                          </div>
                        </div>
                        <div class="col-md-3 mb-4 pb-2">
                          <div class="form-outline form-white">
                            <div id="card-cvc" type="password" class="loginInputsStripe inputOrange" placeholder="CVV"
                              autocomplete="off"></div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6 mb-4 pb-2">
                          <div class="form-outline form-white">
                            <input v-model="registerForm.stripe_coupon" type="text" id="form3Examplea5"
                              class="loginInputsEmail inputOrange" placeholder="Coupon (Optional)"
                              v-bind:name="'registerForm.stripe_coupon'" v-bind:ref="'registerForm.stripe_coupon'" autocomplete="off" />
                          </div>
                        </div>
                      </div>

                      <div class="
                          mb-4
                          pb-2
                          custom-control custom-checkbox
                          checkbox-lg
                        ">
                        <input v-model="terms_cond" type="checkbox" id="terms_cond" v-validate="'required'"
                          v-bind:name="'terms_cond'" v-bind:ref="'terms_cond'" autocomplete="off" />
                        <label class="form-check-label fadeColor" for="terms_cond" :class="{
                          'is-invalid1': errors.has('terms_cond'),
                        }">
                          I Agree to the Terms of Service
                        </label>
                      </div>
                      <div class="d-grid gap-2">
                        <button class="loginButton" style="background-color: #00001a; border-radius: 12px" type="button"
                          @click="billingInfo">
                          <p class="loginButtonText">Submit</p>
                        </button>
                      </div>
                    </form>
                    <div class="form-check d-flex justify-content-center">
                      <label class="
                          form-check-label
                          text-grey
                          mt-3
                          w-100
                          text-center
                          d-flex
                          justify-content-center
                        " for="form2Example3">
                        <p class="front-sub d-flex justify-content-center" @click="directLogin()"
                          style="cursor: pointer">
                          Already a FrontRunner Subscriber?
                        </p>
                      </label>
                    </div>
                    <div class="form-check d-flex justify-content-center">
                      <label class="
                          form-check-label
                          text-grey
                          mt-3
                          w-100
                          text-center
                          d-flex
                          justify-content-center
                        " for="form2Example3">
                        <p class="front-sub d-flex justify-content-center">
                          Visit step1 of your account to bundle and save
                          Reviewsy
                        </p>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Payment",
  props: {
    registerData: {
      type: Object,
      default: null,
    },
  },
  components: {},
  data() {
    return {
      stripe: "",
      firstPart: true,
      registerForm: {
        id: "",
        first_name: "",
        last_name: "",
        organisation_name: "",
        email: "",
        phone: "",
        billing_address: "",
        city: "",
        state: "",
        zip_code: "",
        password: "",
        b_firstName: "",
        b_lastNAme: "",
        b_billingAddress: "",
        b_city: "",
        b_state: "",
        b_zip: "",
        stripe_coupon: "",
      },
      registerFormChecked: false,
      terms_cond: false,
      passwordFieldType: "password",
      passwordIcon: "bi bi-eye-slash",
      token: null,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      apiLoading: false,
    };
  },
  computed: {
    stripeElements() {
      return this.$stripe.elements();
    },
  },
  mounted() {
    this.registerForm.first_name = this.registerData.first_name;
    this.registerForm.last_name = this.registerData.last_name;
    this.registerForm.organisation_name = this.registerData.organisation_name;
    this.registerForm.email = this.registerData.email;
    this.registerForm.phone = this.registerData.phone;
    this.registerForm.billing_address = this.registerData.billing_address;
    this.registerForm.city = this.registerData.city;
    this.registerForm.state = this.registerData.state;
    this.registerForm.zip_code = this.registerData.zip_code;
    this.registerForm.password = this.registerData.password;
    // Style Object documentation here: https://stripe.com/docs/js/appendix/style
    const style = {
      base: {
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "29px",
        border: "1px solid #f3f3f3",
        boxSizing: "border-box",
        borderRadius: "15px",
        background: "#ffffff",
        fontFamily: '"Roboto", sans-serif',
        "::placeholder": {
          color: "#9194b3",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    };
    this.cardNumber = this.stripeElements.create("cardNumber", { style });
    this.cardNumber.mount("#card-number");
    this.cardNumber.update({ placeholder: "Credit Card Number" });
    this.cardExpiry = this.stripeElements.create("cardExpiry", { style });
    this.cardExpiry.mount("#card-expiry");
    this.cardExpiry.update({ placeholder: "Exp. Date" });
    this.cardCvc = this.stripeElements.create("cardCvc", { style });
    this.cardCvc.mount("#card-cvc");
    this.cardCvc.update({ placeholder: "CVV" });
  },
  beforeDestroy() {
    this.cardNumber.destroy();
    this.cardExpiry.destroy();
    this.cardCvc.destroy();
  },
  methods: {
    async billingInfo() {
      let result = await this.$validator.validateAll();
      if (result) {
        this.apiLoading = true;
        const { token, error } = await this.$stripe.createToken(
          this.cardNumber
        );
        if (error) {
          this.apiLoading = false;
          this.$toastr.e(`${error.message}`);
          return;
        }
        let data = {
          token: token,
          registerForm: this.registerForm,
        };
        this.$store
          .dispatch("subscribe", data)
          .then((response) => {
            console.log("response",response);
            if (response.status == true && response.code == 200) {
              this.$router.push({ path: "/dashboard" });
            } else {
              this.$toastr.e(`${response.message}`);
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.apiLoading = false;
          });
      } else {
        this.$toastr.e("Please fill the required fields");
      }
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    check() {
      if (this.registerFormChecked) {
        this.registerForm.b_firstName = this.registerData.first_name;
        this.registerForm.b_lastNAme = this.registerData.last_name;
        this.registerForm.b_billingAddress = this.registerData.billing_address;
        this.registerForm.b_city = this.registerData.city;
        this.registerForm.b_state = this.registerData.state;
        this.registerForm.b_zip = this.registerData.zip_code;
      } else {
        this.resetPaymentForm();
      }
    },
    resetPaymentForm() {
      this.registerForm.b_firstName = "";
      this.registerForm.b_lastNAme = "";
      this.registerForm.b_billingAddress = "";
      this.registerForm.b_city = "";
      this.registerForm.b_state = "";
      this.registerForm.b_zip = "";
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.passwordIcon =
        this.passwordIcon === "bi bi-eye-slash"
          ? "bi bi-eye"
          : "bi bi-eye-slash";
    },
    directLogin() {
      localStorage.clear();
      this.$router.push({ path: "/login" });
    },
    initialize(e) {
      var self = this;
      let input = document.getElementById("autocomplete");
      let autocomplete = new google.maps.places.Autocomplete(input);
      autocomplete.addListener("place_changed", function () {
        let place = autocomplete.getPlace();
        let addressComponent = place.address_components
        let city =addressComponent.find(d => {
          return d.types[0] === 'locality'}
        );

        let postal_code =addressComponent.find(d => {
          return d.types[0] === 'postal_code'}
        );
        let state =addressComponent.find(d => {
          return d.types[0] === 'administrative_area_level_1'}
        );
        self.registerForm.b_billingAddress = place.name
        self.registerForm.b_city = city.long_name
        self.registerForm.b_state =state.long_name
        self.registerForm.b_zip= postal_code.long_name
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bgBlack {
  background: #27293b;
}

.review-feature {
  padding: 215px 15px 0px 50px;
}

.review-feature .colorWhite {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 60px;
  line-height: 73px;
  color: #ffffff;
}

.colorWhite {
  color: white;
  font-size: 3.25rem;
  font-weight: 500;
}

.weight300 {
  font-weight: 300;
  margin-left: 30px;
}

.logo {
  margin-left: 15px;
  margin-top: 15px;
}

.list {
  width: 557px;
  height: 431px;
  left: 92.91px;
  top: 433px;
}

.list li {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 40px;
  display: flex;
  align-items: center;
  color: #9194b3;
}

.list li img {
  margin-right: 2vw;
  /* content: '\f00c';
  color: greenyellow; */
}

.signUpForm {
  margin: 100px;
}

.doller-per-month {
  margin-left: 30px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 73px;
  color: #00053c;
}

.largeFont {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 50px;
  line-height: 73px;
  color: #00053c;
}

.firstPart {
  background: #27293b;
}

.topText {
  margin-top: 4.268943436499466vh;
  margin-right: 100px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #9194b3;
}

.fadeColor {
  width: 207px;
  height: 22.66px;
  left: 798px;
  top: 733.79px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  /* display: flex; */
  align-items: center;

  color: #9194b3;
}

.signUpHeading {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 60px;
  line-height: 73px;
  color: #00053c;
}

.signUpButton {
  width: 32.291666666666664vw;
  height: 6.189967982924227vh;
}

.loginInputsEmail {
  width: 100%;
  height: 58px;
  top: 467px;
  padding: 20px;
  background: #ffffff;
  /* BG 2 */
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px !important;
  line-height: 29px;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  border-radius: 15px;
}

.loginInputsStripe {
  width: 100%;
  height: 58px;
  top: 467px;
  padding: 15px;
  background: #ffffff;
  /* BG 2 */
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px !important;
  line-height: 29px;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  border-radius: 15px;
}

.loginInputsPassword {
  width: 100%;
  height: 58px;
  top: 467px;
  padding: 20px;
  background: #ffffff;
  /* BG 2 */
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px !important;
  line-height: 29px;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  border-radius: 15px;
}

.field-icon {
  float: right;
  margin-left: -25px;
  margin-top: -25px;
  position: relative;
  z-index: 2;
}

.loginButton {
  margin-top: 20px;

  right: 53.47%;
  top: 703px;
  bottom: 25.68%;
  width: 100%;
  height: 58px;
  background: #00053c;
  border-radius: 15px;
}

.loginButtonText {
  justify-content: center;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  /* or 181% */
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.is-invalid {
  background-image: none !important;
  border-color: #dc3545 !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545 !important;
}

.is-invalid1 {
  background-image: none !important;
  color: #dc3545 !important;
}

.form-control.is-invalid1,
.was-validated .form-control:invalid {
  color: #dc3545 !important;
}

.inputOrange:focus {
  outline: none !important;
  border: 1.5px solid #ff8c00;
  /* box-shadow: 0 0 10px #719ECE; */
}

form i {
  margin-left: -30px;
  cursor: pointer;
}

.front-sub {
  width: 380px;
  height: 40px;
  left: 890px;
  top: 878px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  display: flex;
  align-items: center;
  text-align: center;

  color: #9194b3;
}

#custom-button {
  height: 30px;
  outline: 1px solid grey;
  background-color: green;
  padding: 5px;
  color: white;
}

#card-error {
  color: red;
}

.checkbox-lg .custom-control-label::before,
.checkbox-lg .custom-control-label::after {
  top: 0.8rem;
  width: 1.55rem;
  height: 1.55rem;
}

.checkbox-lg .custom-control-label {
  padding-top: 8px;
  padding-left: 6px;
}

.breakAll {
  word-break: break-all;
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));

  background: -webkit-radial-gradient(rgba(20, 20, 20, 0.8),
      rgba(0, 0, 0, 0.8));
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
