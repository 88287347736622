<template>
    <div>
        <div v-if="apiLoading" class="loading">
            Loading&#8230;
        </div>
        <form class="d-block">
            <div class="d-flex justify-content-between align-items-center mb-5">
                <h1>Update Card Info</h1>
                <a @click="close()"><img src="resources/assets/crossicon.png" /></a>
            </div>
            <div class="row">
                <div class="col-md-6 mb-4 pb-2">
                    <div class="form-outline form-white">
                    <div id="card-number" type="text" class="loginInputsStripe inputOrange"
                        placeholder="Credit Card Number" autocomplete="off"></div>
                    </div>
                </div>
                <div class="col-md-3 mb-4 pb-2">
                    <div class="form-outline form-white">
                    <div id="card-expiry" type="text" class="loginInputsStripe inputOrange"
                        placeholder="Exp. Date" autocomplete="off"></div>
                    </div>
                </div>
                <div class="col-md-3 mb-4 pb-2">
                    <div class="form-outline form-white">
                    <div id="card-cvc" type="password" class="loginInputsStripe inputOrange" placeholder="CVV"
                        autocomplete="off"></div>
                    </div>
                </div>
            </div>
            <div class="d-grid gap-2">
                <button class="loginButton" style="background-color: #00001a; border-radius: 12px" type="button" @click="cardInfo">
                    <p class="loginButtonText">Update</p>
                </button>
            </div>
        </form>
    </div>
</template>
<script src="https://js.stripe.com/v3"></script>
<script>
export default {
  name: "Payment",
  props: {
    registerData: {
      type: Object,
      default: null,
    },
  },
  components: {},
  data() {
    return {
        userLoginId: "",
        stripe: "",
        token: null,
        cardNumber: null,
        cardExpiry: null,
        cardCvc: null,
        apiLoading: false,
    };
  },
  computed: {
    stripeElements() {
      return this.$stripe.elements();
    },
  },
  mounted() {
    const style = {
      base: {
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "29px",
        border: "1px solid #f3f3f3",
        boxSizing: "border-box",
        borderRadius: "15px",
        background: "#ffffff",
        fontFamily: '"Roboto", sans-serif',
        "::placeholder": {
          color: "#9194b3",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    };

    this.userLoginId = localStorage.getItem("id");
    this.cardNumber = this.stripeElements.create("cardNumber", { style });
    this.cardNumber.mount("#card-number");
    this.cardNumber.update({ placeholder: "Credit Card Number" });
    this.cardExpiry = this.stripeElements.create("cardExpiry", { style });
    this.cardExpiry.mount("#card-expiry");
    this.cardExpiry.update({ placeholder: "Exp. Date" });
    this.cardCvc = this.stripeElements.create("cardCvc", { style });
    this.cardCvc.mount("#card-cvc");
    this.cardCvc.update({ placeholder: "CVV" });
  },
  beforeDestroy() {
    this.cardNumber.destroy();
    this.cardExpiry.destroy();
    this.cardCvc.destroy();
  },
  methods: {
    async cardInfo() {
      let result = await this.$validator.validateAll();
      if (result) {
        this.apiLoading = true;
        const { token, error } = await this.$stripe.createToken(
          this.cardNumber
        );
        if (error) {
          this.apiLoading = false;
          this.$toastr.e(`${error.message}`);
          return;
        }
        let data = {
            userId: this.userLoginId,
            token: token
        };

        this.$store.dispatch("updateCardDetails", data).then((response) => {
            if (response.success) {
                this.$toastr.s(`${response.message}`);
                this.$emit("closeModal", "true");
            } else {
              this.$toastr.e(`${response.message}`);
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.apiLoading = false;
          });
      } else {
        this.$toastr.e("Please fill the required fields");
      }
    },
    close() {
      this.$emit("closeModal", "true");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.loginInputsStripe {
  width: 100%;
  height: 58px;
  top: 467px;
  padding: 15px;
  background: #ffffff;
  /* BG 2 */
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px !important;
  line-height: 29px;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  border-radius: 15px;
}
.loginButton {
  margin-top: 20px;

  right: 53.47%;
  top: 703px;
  bottom: 25.68%;
  width: 100%;
  height: 58px;
  background: #00053c;
  border-radius: 15px;
}

.loginButtonText {
  justify-content: center;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  /* or 181% */
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.is-invalid {
  background-image: none !important;
  border-color: #dc3545 !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545 !important;
}

.is-invalid1 {
  background-image: none !important;
  color: #dc3545 !important;
}

.form-control.is-invalid1,
.was-validated .form-control:invalid {
  color: #dc3545 !important;
}

.inputOrange:focus {
  outline: none !important;
  border: 1.5px solid #ff8c00;
  /* box-shadow: 0 0 10px #719ECE; */
}

form i {
  margin-left: -30px;
  cursor: pointer;
}

.front-sub {
  width: 380px;
  height: 40px;
  left: 890px;
  top: 878px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  display: flex;
  align-items: center;
  text-align: center;

  color: #9194b3;
}

#custom-button {
  height: 30px;
  outline: 1px solid grey;
  background-color: green;
  padding: 5px;
  color: white;
}

#card-error {
  color: red;
}

.checkbox-lg .custom-control-label::before,
.checkbox-lg .custom-control-label::after {
  top: 0.8rem;
  width: 1.55rem;
  height: 1.55rem;
}

.checkbox-lg .custom-control-label {
  padding-top: 8px;
  padding-left: 6px;
}

.breakAll {
  word-break: break-all;
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));

  background: -webkit-radial-gradient(rgba(20, 20, 20, 0.8),
      rgba(0, 0, 0, 0.8));
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
