<template>
    <main class="col-md-12 d-flex justify-content-center">
        <div v-if="apiLoading" class="loading">
            Loading&#8230;
        </div>
        <form class="d-block" style="padding-top: 50px">
            <div class="row">
                <div class="mb-4 pb-2 requestFrom">
                    <div class=" d-flex justify-content-between align-items-center mb-5 formPTag">
                        <h1> Request Review Form</h1>
                    </div>

                    <div style="padding: 15px">
                        <div class="row">
                            <div class="col-6 col-md-6 mb-4 pb-1">
                                <div class="form-outline form-white">
                                    <input type="text" class="userForm form-control-lg inputOrange"
                                        placeholder="First Name" v-model="requestData.first_name"
                                        v-validate="'required'" v-bind:name="'requestData.first_name'"
                                        v-bind:ref="'requestData.first_name'" :class="{
                                            'is-invalid': errors.has('requestData.first_name'),
                                        }" />
                                </div>
                            </div>
                            <div class="col-6 col-md-6 mb-4 pb-1">
                                <div class="form-outline form-white">
                                    <input type="text" class="userForm form-control-lg inputOrange"
                                        placeholder="Last Name (Optional)" v-model="requestData.last_name" />
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-6 col-md-6 mb-4 pb-1">
                                <div class="form-outline form-white">
                                    <input type="number" class="userForm form-control-lg inputOrange"
                                        placeholder="Mobile Phone" v-model="requestData.phone"
                                        v-validate="{ 'required': requestData.email.length < 1 || requestData.SMS || requestData.both, 'max': 15, 'numeric': true }"
                                        v-bind:name="'requestData.phone'" v-bind:ref="'requestData.phone'" :class="{
                                            'is-invalid': errors.has('requestData.phone'),
                                        }" />
                                </div>
                            </div>
                            <div class="col-6 col-md-6 mb-4 pb-1">
                                <div class="form-outline form-white">
                                    <input type="text" class="userForm form-control-lg inputOrange"
                                        placeholder="Email Address " v-model="requestData.email"
                                        v-validate="{ 'required': requestData.phone.length < 1 || requestData.MAIL || requestData.both, 'email': true }"
                                        v-bind:name="'requestData.email'" v-bind:ref="'requestData.email'" :class="{
                                            'is-invalid': errors.has('requestData.email'),
                                        }" />
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-6 col-md-6 mb-2 pb-1">
                                <div class="form-outline form-white">
                                    <select
                                        class="viewReviewSecondPartdropdownText inputOrange assignFollowUp form-select"
                                        v-model="requestData.shortName" v-validate="'required'"
                                        v-bind:name="'requestData.code'" v-bind:ref="'requestData.code'" :class="{
                                            'is-invalid': errors.has('requestData.code'),
                                        }">
                                        <option class="viewReviewSecondPartdropdownText" value="">
                                            Select Country
                                        </option>
                                        <option class="viewReviewSecondPartdropdownText" :value="location.shortName"
                                            v-for="location in countryCodelist" :key="location.id">
                                            {{ `${location.name}` }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <p class="formPTag" style="padding-top: 20px; line-height: 29px">
                                SEND BY
                            </p>
                            <div>
                                <div class="custom-control custom-checkbox checkbox-lg form-check-inline">
                                    <input type="checkbox" class="custom-control-input" id="SMS" name="SMS" value="SMS"
                                        v-model="requestData.SMS" />
                                    <label class="custom-control-label checkLabel" for="SMS">
                                        SMS Text</label>
                                </div>

                                <div class="custom-control custom-checkbox checkbox-lg form-check-inline">
                                    <input type="checkbox" class="custom-control-input" id="email" name="email"
                                        value="email" v-model="requestData.MAIL" />
                                    <label class="custom-control-label checkLabel" for="email">Email</label>
                                </div>

                                <div class="custom-control custom-checkbox checkbox-lg form-check-inline">
                                    <input type="checkbox" class="custom-control-input" name="both" id="both"
                                        value="both" v-model="requestData.both" />
                                    <label class="custom-control-label checkLabel" for="both">Both</label>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-5">
                            <div class="col-6 col-md-6 mb-2 pb-1">
                                <div class="center-item">
                                    <button type="button" class="scheduleButton">
                                        <a>
                                            <p @click="openscheduleModel()" class="buttonText"
                                                style="background: white; color: #00053c">
                                                Schedule for Later
                                            </p>
                                        </a>
                                    </button>
                                </div>
                            </div>
                            <div class="col-6 col-md-6 mb-2 pb-1">
                                <button type="button" class="sendNowButton" @click="singleRequest()">
                                    <p class="buttonText">Send Now</p>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </form>
        <transition name="modal" v-if="scheduleModel">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container-schedule">
                        <div>
                            <form class="d-block">
                                <div class="d-flex justify-content-between align-items-center"
                                    style="margin-bottom: 25px">
                                    <h1 class="bulk-title">Select Time</h1>
                                    <a @click="cancel()"><img src="/resources/assets/crossicon.png" /></a>
                                </div>
                                <template>
                                    <datetime type="datetime" v-model="scheduleDate"
                                        input-class="form-control userForm form-control-lg inputOrange ml-2"
                                        :phrases="{ ok: 'Continue', cancel: 'Exit' }" :hour-step="1" :minute-step="1"
                                        :week-start="7" use12-hour auto></datetime>
                                </template>
                            </form>
                        </div>

                        <div class="col-md-4 col-12 mb-4 mb-sm-0"></div>
                        <div class="row mt-5 d-flex justify-content-center">
                            <div class="col-6 col-md-6 mb-2 pb-1" style="padding: 0px 25px">
                                <button type="button" class="sendNowButton" @click="singleRequest()">
                                    <p class="buttonText">Send Now</p>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </main>
</template>

<script>
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
export default {
    name: "FrontrunnerRequestReview",
    data() {
        return {
            requestData: {
                first_name: "",
                last_name: "",
                phone: "",
                email: "",
                userID: "",
                SMS: false,
                MAIL: false,
                both: false,
                shortName: "US",
            },
            countryCodelist: [],
            scheduleModel: false,
            scheduleDate: "",
            apiLoading: false,
            UniqueUserID: "",
            sellId: ""
        };
    },
    components: {
        Datetime,
    },
    mounted() {
        this.UniqueUserID = this.$route.params.id;
        this.sellId = this.$route.params.sellId;
        this.getUserId(this.UniqueUserID);
        this.countryCode();
        console.clear();
    },
    created() { },
    methods: {
        openTab(){
            let fullPath = this.$route.fullPath;
            const result = window.location.origin;
            let url = `${result}${fullPath}`;
            window.open(url,'popUpWindow','height=600,width=800,left=10,top=10,,scrollbars=yes,menubar=no'); return false;
        },
        getUserId(UniqueUserID) {
            let data = { UniqueUserID }
            this.$store.dispatch("frontrunneruserId", data).then((response) => {
                this.requestData.userID = response.data;
            });
        },
        countryCode() {
            this.$store.dispatch("requestCountryCode").then((response) => {
                if (response.success) {
                    this.countryCodelist = response.data;
                }
            });
        },

        async singleRequest() {
            let result = await this.$validator.validateAll();
            if (result) {
                let countryName = this.countryCodelist.find(
                    (element) => element.shortName == this.requestData.shortName
                );

                if (
                    this.requestData.SMS == "" &&
                    this.requestData.MAIL == "" &&
                    this.requestData.both == ""
                ) {
                    this.$toastr.e("Please select atleast one option for contact.");
                } else {
                    let data = {
                        first_name: this.requestData.first_name.trim(),
                        last_name: this.requestData.last_name
                            ? this.requestData.last_name.trim()
                            : "",
                        phone: this.requestData.phone,
                        email: this.requestData.email,
                        userID: this.requestData.userID,
                        SMS: this.requestData.both ? 0 : this.requestData.SMS ? 0 : "",
                        MAIL: this.requestData.both ? 0 : this.requestData.MAIL ? 0 : "",
                        item: this.scheduleDate ? "later" : "now",
                        date_string: this.scheduleDate ? this.scheduleDate : "",
                        code: countryName ? "+" + countryName.code : "+1",
                        countryName: countryName ? countryName.name : "UNITED STATES",
                        UserKeyID: this.UniqueUserID,
                        SaleID: this.sellId
                    };
                    this.apiLoading = true;
                    this.$store.dispatch("frontrunnerRequestReview", data).then((response) => {
                        if (response.success) {
                            this.$toastr.s(`${response.message}`);
                            this.clearSingleRequest();
                            this.scheduleModel = false;
                            window.close();
                        } else {
                            if (response.message == "Validation Error.") {
                                this.$toastr.e(`${response.data.email}`);
                            } else {
                                this.$toastr.e(`${response.message}`);
                            }
                        }
                    }).catch((err) => {
                        console.log(err);
                    })
                        .finally(() => {
                            this.apiLoading = false;
                        });
                }
            }
        },

        async openscheduleModel() {
            let result = await this.$validator.validateAll();
            if (result) {
                if (
                    this.requestData.SMS == "" &&
                    this.requestData.MAIL == "" &&
                    this.requestData.both == ""
                ) {
                    this.$toastr.e("Please select atleast one option for contact.");
                } else {
                    this.scheduleModel = true;
                }
            }
        },
        cancel() {
            this.scheduleModel = false;
        },
        clearSingleRequest() {
            this.requestData.first_name = "";
            this.requestData.last_name = "";
            this.requestData.phone = "";
            this.requestData.email = "";
            this.requestData.SMS = false;
            this.requestData.MAIL = false;
            this.requestData.both = false;
            this.requestData.shortName = "US";
            this.isActiveSMS = false;
            this.isActiveEmail = false;
            this.isActiveBoth = false;
            this.$nextTick(() => {
                this.errors.clear();
                this.$nextTick(() => {
                    this.$validator.reset();
                });
            });
        },
    },
};
</script>
<style scoped>
main {
    height: 100vh;
    background: #F0F8FF;
}

.loginButtonText {
    justify-content: center;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
}

.loginButton {
    margin-top: 20px;
    right: 53.47%;
    top: 703px;
    bottom: 25.68%;
    width: 100%;
    height: 58px;
    background: #00053c;
    border-radius: 15px;
}

.form-control1 {
    display: inline-block !important;
}

.userForm {
    height: 50px;
    border-radius: 15px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px !important;
}

.form-white>div {
    width: 26vw;
    margin: 0 5vw;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 21px;
    color: #9194b3;
}

form i {
    margin-left: -30px;
    cursor: pointer;
}

.inputOrange {
    outline: none !important;
    border: 1.5px solid #f4f5fa;
}

.is-invalid {
    background-image: none !important;
    border-color: #dc3545 !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    border-color: #dc3545 !important;
}

.spinner-center {
    justify-content: center;
    align-items: center;
    display: flex;
}

.sendButtons {
    display: flex;
    justify-content: space-around;
}

.sendNowButton {
    font-family: "Inter", sans-serif;
    background: #00053c;
    color: white;
    font-size: 18px;
    border-radius: 15px;
    height: 50px;
    width: 100%;
    font-weight: 600;
}

.scheduleButton {
    border-radius: 15px;
    border: 1px solid #00053c;
    background: white;

    font-family: "Inter", sans-serif;
    font-size: 18px;
    width: 100%;
    height: 50px;
    font-weight: 600;
}

.viewReviewSecondPartdropdownText {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px !important;
    line-height: 29px;
    display: flex;
    align-items: center;
}

.assignFollowUp {
    height: 50px;
    left: 889px;
    top: 413px;
    border: 1px solid #f3f3f3;
    box-sizing: border-box;
    border-radius: 15px;
    width: 100%;
    padding: 10px;
}

.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: grid;
    transition: opacity 0.3s ease;
    overflow: auto;
}

.modal-wrapper {
    display: grid;
}

.modal-container-schedule {
    width: 600px;
    padding: 30px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.buttonText {
    justify-content: center;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
    color: #ffffff;
    border-radius: 15px;
    padding: 0px 25px;
    margin-top: 10px;
    display: contents;
}

.checkbox-lg .custom-control-label::before,
.checkbox-lg .custom-control-label::after {
    top: 0.8rem;
    width: 1.55rem;
    height: 1.55rem;
}

.checkbox-lg .custom-control-label {
    padding-top: 8px;
    padding-left: 6px;
}

.checkbox-xl .custom-control-label::before,
.checkbox-xl .custom-control-label::after {
    top: 1.2rem;
    width: 1.85rem;
    height: 1.85rem;
}

.checkbox-xl .custom-control-label {
    padding-top: 23px;
    padding-left: 10px;
}
</style>