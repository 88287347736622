<template>
  <!-- Vertical Navbar -->
  <nav class="
      navbar
      show
      navbar-vertical
      h-lg-screen
      navbar-expand-lg
      px-0
      py-0
      navbar-light
      bg-white
      border-bottom border-bottom-lg-0 border-end-lg
      sideBar
    " id="navbarVertical">
    <div class="container-fluid leftPanel">
      <!-- Toggler -->
      <button class="navbar-toggler ms-n2" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarCollapse"
        aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <!-- Brand -->
      <a class="navbar-brand logo-center" role="button" @click="showDiv('dashboard')">
        <img src="resources/assets/logo-sidebar.svg" alt="..." />
      </a>
      <!-- User menu (mobile) -->
      <div class="navbar-user d-lg-none">
        <!-- Dropdown -->
        <div class="dropdown">
          <!-- Toggle -->
          <a href="#" id="sidebarAvatar" role="button" data-bs-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
            <div class="avatar-parent-child">
              <img 
                v-if="adminDetails.profile"
                alt="Image Placeholder"
                :src="adminDetails.profile"
                class="avatar avatar- rounded-circle" />
              <span class="avatar-child avatar-badge bg-success"></span>
            </div>
          </a>
          <!-- Menu -->
          <div class="dropdown-menu dropdown-menu-end" aria-labelledby="sidebarAvatar">
            <a href="#" class="dropdown-item">Profile</a>
            <a href="#" class="dropdown-item">Settings</a>
            <a href="#" class="dropdown-item">Billing</a>
            <hr class="dropdown-divider" />
            <a href="#" class="dropdown-item">Settings/Account</a>
            <a href="#" class="dropdown-item">Logout</a>
          </div>
        </div>
      </div>
      <!-- Collapse -->
      <div class="collapse navbar-collapse" id="sidebarCollapse">
        <!-- Navigation -->
        <ul class="navbar-nav">
            <li class="nav-item">
                <a :class="{ active: show == 'dashboard' }" class="nav-link" @click="showDiv('dashboard')" href="#">
                    <img :src="show == 'dashboard' ? 'resources/assets/activedashboard.svg' : 'resources/assets/dashboard.svg'" alt />
                    &nbsp;Dashboard
                </a>
            </li>
            <li class="nav-item">
                <a :class="{ active: show == 'reviews' }" class="nav-link" @click="showDiv('reviews')" href="#">
                    <img :src="show == 'reviews' ? 'resources/assets/active-reviews.svg' : 'resources/assets/reviews.svg'" alt />
                    &nbsp;Reviews &nbsp;<sup style="color: red;">{{ reviewStatus }}</sup>
                </a>
            </li>
            <li class="nav-item">
                <a :class="{ active: show == 'request-reviews' }" class="nav-link" @click="showDiv('request-reviews')" href="#">
                    <img :src="show == 'request-reviews' ? 'resources/assets/active-request-review.svg' : 'resources/assets/request-review.svg'" alt />
                    &nbsp;Request Reviews
                </a>
            </li>
            <li class="nav-item" v-if="checkPermission">
                <a :class="{ active: show == 'users' }" class="nav-link" @click="showDiv('users')" href="#">
                    <img :src="show == 'users' ? 'resources/assets/active-user.svg' : 'resources/assets/users.svg'" alt />
                    &nbsp;Users
                </a>
            </li>
            <li class="nav-item">
                <a :class="{ active: show == 'feedback' }" class="nav-link" @click="showDiv('feedback')" href="#">
                    <img :src="show == 'feedback' ? 'resources/assets/active-feedback.svg' : 'resources/assets/feedback.svg'" alt />
                    &nbsp;Feedback&nbsp;<sup style="color: red;">{{ feedbackStatus }}</sup>
                </a>
            </li>
            <li class="nav-item">
                <a :class="{ active: show == 'reports' }" class="nav-link" @click="showDiv('reports')" href="#">
                    <img :src="show == 'reports' ? 'resources/assets/active-reports.svg' : 'resources/assets/reports.svg'" alt />
                    &nbsp;Reports
                </a>
            </li>
            <li class="nav-item">
                <a :class="{ active: show == 'settings' }" class="nav-link" @click="showDiv('settings')" href="#">
                    <img :src="show == 'settings' ? 'resources/assets/active-seetings.svg' : 'resources/assets/settings.svg'" alt />
                    &nbsp;Settings/Account
                </a>
            </li>
            <li class="nav-item">
                <a :class="{ active: show == 'setup' }" class="nav-link" @click="showDiv('setup')" href="#"> <img height="24" width="24" src="resources/assets/setup.svg" alt />&nbsp;Setup Wizard </a>
            </li>
            <li class="nav-item">
                <a :class="{ active: show == 'help' }" class="nav-link" @click="showDiv('help')" href="#">
                    <img :src="show == 'help' ? 'resources/assets/active-help.svg' : 'resources/assets/help.svg'" alt height="24" width="24" />
                    &nbsp;&nbsp;Help
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#" @click="logout"> <i class="bi bi-box-arrow-left"></i>&nbsp;Logout </a>
            </li>
            <li class="nav-item" v-if="superAdminToken">
                <a class="nav-link" href="#" @click="adminLogin"> <i class="bi bi-box-arrow-left"></i>&nbsp;Login Back as Admin </a>
            </li>
        </ul>
      </div>
    </div>
  </nav>
  <!-- </div> -->
</template>

<script>
import eventHub from './EventHub';
import Header from "./Header.vue";
export default {
  name: "Menu",
  components: {
    Header,
  },
  data() {
    return {
        show: this.$route.name.toLowerCase(),
        checkPermission: false,
        userLoginId: "",
        reviewStatus: "",
        feedbackStatus: "",
        superAdminToken: "",
        adminDetails: {
          id: "",
          firstName: "",
          lastName: "",
          profile: "",
        },
    };
  },

  mounted() {
    this.superAdminToken = localStorage.getItem("adminToken") && localStorage.getItem("change") ? localStorage.getItem("adminToken") : "";
    this.userLoginId = localStorage.getItem("id");
    this.getUserProfileSettings();
    this.newReviews();
    this.newFeedabck();
  },
  created() {
    let isAdmin = localStorage.getItem("isAdmin");
    if (isAdmin == true) {
        this.checkPermission = true;
    } else {
        this.checkPermission = false;
    }
  },
  computed: {},
  methods: {
    showDiv(data) {
      console.log(data);
      this.selectedUsers = [];
      if (data === "dashboard") {
        this.$router.push({ path: "/dashboard" });
      }
      if (data === "settings") {
        this.$router.push({ path: "/settings" });
      }
      if (data === "reviews") {
        this.$router.push({ path: "/reviews" });
      }
      if (data === "request-reviews") {
        this.$router.push({ path: "/request-reviews" });
      }
      if (data === "feedback") {
        this.$router.push({ path: "/feedback" });
      }
      if (data === "users") {
        this.$router.push({ path: "/users" });
      }
      if (data === "reports") {
        this.$router.push({ path: "/reports" });
      }
      if (data === "help") {
        this.$router.push({ path: "/help" });
      }
      if (data === "setup") {
        localStorage.setItem("isSetupWizardPlay", 'true');
        if (this.$route.name.toLowerCase() == 'dashboard') {
            eventHub.$emit("run_setup_wizard");
        } else {
            this.$router.push('/dashboard');
        }
     }
      this.show = data;
    },
    getUserProfileSettings() {
      this.$store.dispatch("userProfileSettings").then((response) => {
        let res = response.data[0];
        this.adminDetails.id = res.id;
        this.adminDetails.firstName = res.first_name;
        this.adminDetails.lastName = res.last_name;
        this.adminDetails.profile = res.file;
      });
    },
    newReviews() {
        let data = {
          userID: this.userLoginId,
        };
        this.$store.dispatch("reviewsNewCount", data).then((response) => {
          this.reviewStatus =
            response.data && response.data.reviewStatus
              ? response.data.reviewStatus
              : "";
        });
    },
    newFeedabck() {
        let data = {
          userID: this.userLoginId,
        };
        this.$store.dispatch("feedbackNewCount", data).then((response) => {
          this.feedbackStatus =
            response.data && response.data.feedbackStatus && response.data.feedbackStatus != 0
              ? response.data.feedbackStatus
              : "";
        });
    },
    adminLogin(){
        if(localStorage.getItem("adminToken") && localStorage.getItem("change")){
          this.apiLoading = true;
          let data = {
                  id: localStorage.getItem("change"),
              };
              this.$store
                  .dispatch("clientLogin", data)
                  .then((response) => {
                    if (response.success) {
                      this.$router.push({ path: "/admin/clients" });
                    }else{
                      this.$toastr.e(`${response.message}`);
                    }
                  })
                  .catch((err) => {
                      console.log(err);
                  }).finally(() => (this.apiLoading = false));
          return;
        }
    },
    logout() {
      localStorage.removeItem("token");
      localStorage.clear();
      //this.$router.push({ path: "/reviewzy/" });
      this.$router.push({ path: "/" });
    },
  },
};
</script>
<style scoped>
.leftPanel {
  background: #27293b;
  position: static;
}

.sideBar {
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  height: 100vh !important;
  margin-bottom: 0px;
  border-bottom-width: 0px !important;
}
.nav-item active {
  background: #00b0ac;
  background-color: #00b0ac;
}

.navbar-light .navbar-nav .nav-link {
  margin-left: 1vw;
  margin-top: 2vh;
  font-size: 14px;
  color: #9194b3;
}
.navbar-vertical.navbar-light .navbar-nav>.nav-item>.nav-link.active {
  color: #fdd640;
  font-size: 14px;
  background-color: #27293b;
}
.navbar>.container .navbar-brand,
.navbar>.container-fluid .navbar-brand {
  margin-left: 1vw;
  margin-top: 2vh;
}

.row {
  margin-right: 0px;
}

svg {
  width: 40px;
  height: 40px;
}

svg {
  color: white;
  z-index: 1;
}

.logo-center { justify-content: center; margin-left: -15px !important; }

a.navbar-brand {
  padding: 30px 0 0 50px !important;
}

#sidebarCollapse {
  padding: 0;
  margin-top: 40px;
}

#sidebarCollapse li.nav-item a {
  position: relative;
}

#sidebarCollapse li.nav-item a::before {
  position: absolute;
  content: "";
  width: 46px;
  height: 46px;
  left: -36px;
  top: -6px;
  background: #fdd640;
  box-shadow: 0px 0px 17px 5px rgba(253, 214, 64, 0.12);
  border-radius: 15px;
  transform: rotate(45deg);
  opacity: 0;
  transition: 0.3s ease all;
}

#sidebarCollapse li.nav-item a {
  margin: 0;
  padding-left: 30px;
  margin-top: 10px;
}

#sidebarCollapse li.nav-item a.active::before {
  opacity: 1;
}

#sidebarCollapse li.nav-item a:hover::before {
  opacity: 1;
}

#sidebarCollapse li.nav-item a:hover {
  background: transparent;
}


@media (max-width: 991.98px) {

  /* responsive sidebar */
  .navbar-collapse.collapse {
    display: none !important;
    background: #27293b;
    width: 100%;
    height: auto;
    padding: 10px 20px !important;
    margin: 0;
  }

  .sideBar {
    height: auto !important;
  }

  a.navbar-brand {
    padding: 0 !important;
  }

  .profile-wrap {
    display: none !important;
  }

  #sidebarCollapse {
    margin-top: 0;
  }

  .btn-logo-wrap button.navbar-toggler {
    position: absolute;
    right: 30px;
    background: #fff !important;
    z-index: 9999999;
  }

  .container-fluid.leftPanel {
    position: relative;
  }

  .btn-logo-wrap {
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 0 30px;
  }

  .navbar-collapse.collapse.show {
    display: block !important;
  }

  .navOutDiv {
    position: unset;
  }

  #sidebarCollapse ul.navbar-nav {
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  .navbar-collapse.collapse {
    padding: 10px 0px !important;
  }

  main .container {
    max-width: 100%;
  }
  ul.navbar-nav {
    margin: 0 !important;
  }

  #sidebarCollapse li.nav-item a::before {
    left: -26px;
    width: 36px;
    height: 36px;
    top: 0;
  }
}
</style>
