<template>
  <transition name="modal">
    <div class="card border-0 mb-7 table-align">
      <div class="card border-0 mb-7" style="margin: 30px; border-radius: 15px">
        <div class="modal-footer">
          <button @click="close()" type="button" class="business-btn" data-dismiss="modal">
            <p class="loginButtonText">Close</p>
          </button>
          <button type="button" class="business-btn" @click="applyFilter()">
            <p class="loginButtonText">Apply changes</p>
          </button>
        </div>

        <div class="table-responsive" style="border-radius: 36px">
          <table class="table table-hover table-nowrap formtable">
            <thead class="thead-light">
              <tr class="header">
                <th class="heading" scope="col" style="padding-left: 15px">
                  <div class="
                      custom-control custom-checkbox
                      checkbox-lg
                      form-check-inline
                    ">
                    <input type="checkbox" class="custom-control-input" @change="pushAllSelectedUsers($event)"
                      id="all" />
                    <label class="custom-control-label checkLabel" for="all"></label>
                  </div>
                </th>

                <th class="heading" scope="col">USER NAME</th>
                <th class="heading" scope="col">EMAIL</th>
              </tr>
            </thead>
            <tbody v-if="userList.length > 0">
              <tr v-for="(users, index) in userList" v-bind:key="index">
                <td>
                  <div class="
                      custom-control custom-checkbox
                      checkbox-lg
                      form-check-inline
                    ">
                    <input :id="users.id" type="checkbox" class="custom-control-input"
                      @change="pushSelectedUsers($event, users.id)" />
                    <label class="custom-control-label checkLabel" :for="users.id"></label>
                  </div>
                </td>
                <td>
                  <a class="text-heading" style="text-decoration: none" href="#">
                    {{ ` ${users.first_name} ${users.last_name}` }}
                  </a>
                </td>
                <td>
                  <a class="text-heading" style="text-decoration: none" href="#">
                    {{ users.email }}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import moment from "moment";
export default {
  name: "UsersModal",
  components: {},
  computed: {
    checkedNames() {
      return this.names.filter((item) => item.checked).map((name) => name.name);
    },
  },
  props: {
    actionType: {
      type: String,
      default: null,
    },
    selected: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      moment: moment,
      userList: [],
      selectedUsers: [],
    };
  },
  mounted() {
    this.getUserList();
    this.selectedUsers = this.selected;
  },
  methods: {
    getUserList() {
      this.$store.dispatch("allUser").then((response) => {
        if (response.data) {
          this.userList = response.data;
        } else {
          this.userList = [];
        }
      });
    },
    pushSelectedUsers(e, id) {
      if (e.target.checked) {
        this.selectedUsers.push(id);
      } else {
        const index = this.selectedUsers.indexOf(id);
        if (index > -1) {
          this.selectedUsers.splice(index, 1);
        }
      }
    },
    pushAllSelectedUsers(e) {
      if (e.target.checked) {
        this.selectedUsers = this.userList.map((x) => x.id);
        this.selectedUsers.forEach((element) => {
          document.getElementById(element).checked = true;
        });
      } else {
        this.selectedUsers.forEach((element) => {
          document.getElementById(element).checked = false;
        });
        this.selectedUsers = [];
      }
    },
    applyFilter() {
      const intersection = this.userList.filter(element => this.selectedUsers.includes(element.id));
      let data = {
        data: this.selectedUsers,
        userName: intersection,
      }
      if (data) {
        this.$emit("changeTitle", {
          data,
          actionType: this.actionType,
          modal: "usersFilter",
        });
      }
    },
    close() {
      this.$emit("closeModal", "true");
    },
  },
};
</script>
<style scoped>
.table>tbody>tr>td {
  padding: 30px 15px;
}

.table>tbody>tr>td,
.table>thead>tr>th {
  /* border: 0 !important; */
  border-top: none;
}

.formtable {
  margin: 20px;
  width: 95% !important;
  text-align: center;
  border-collapse: inherit;
}

.header th {
  height: 10px;
  /* line-height: 2px; */
}

.heading {
  font-family: "Inter", sans-serif;
  font-style: normal;
  /* font-weight: 700 !important; */
  font-size: 12px;
  line-height: 24px;
  align-items: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #00053c !important;
  font-weight: 600;
  align-items: center;
}

.checkbox-xl .custom-control-label::before,
.checkbox-xl .custom-control-label::after {
  top: 1.2rem;
  width: 1.85rem;
  height: 1.85rem;
}

.checkbox-lg .custom-control-label {
  padding-top: 8px;
  padding-left: 6px;
}

.checkbox-xl .custom-control-label {
  padding-top: 23px;
  padding-left: 10px;
}

.checkbox-lg .custom-control-label::before,
.checkbox-lg .custom-control-label::after {
  top: 0.8rem;
  width: 1.55rem;
  height: 1.55rem;
}

.checkbox-lg .custom-control-label {
  padding-top: 8px;
  padding-left: 6px;
}

.text-heading {
  font-weight: 500 !important;
  text-transform: capitalize;
  color: #00053c;
  font-family: "Inter";
  font-style: normal;
  font-size: 14px;
  align-items: center;
}

.btn {
  border: none;
  padding: 10px;
  /* padding: 10px 20px; */
  border-radius: 50px;
  background-color: #f4f5fa;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 200ms ease-in;
}

.business-btn {
  margin-top: 4px;
  right: 53.47%;
  top: 703px;
  bottom: 25.68%;
  width: auto;
  /* width: 15%; */
  height: 45px;
  background: #00053c;
  border-radius: 15px;
}

.loginButtonText {
  justify-content: center;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  /* or 181% */
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  height: 35px;
  /* height: 40px; */
}
</style>