<template>
  <div>
    <div v-if="apiLoading" class="loading">
      Loading&#8230;
    </div>
    <div class="d-flex flex-column flex-lg-row h-lg-full navOutDiv">
      <!-- Vertical Navbar -->

      <Menu></Menu>
      <!-- Main content -->
      <div class="h-screen flex-grow-1 overflow-y-lg-auto m-4 dashScroll">
        <!-- Header -->
        <Header></Header>

        <!-- Main -->
        <div>
            <main class="py-6">
                <div class="row align-items-center">
                    <div class="col-sm-3 col-12 mb-4 mb-sm-0">
                        <div class="card-header" style="background: none; border: none;">
                            <h2 class="mb-0">Review</h2>
                        </div>
                    </div>
                    <div class="col-md-9 col-12 mb-4 mb-sm-0 d-flex justify-content-end">
                        <div id="v-step-0" class="mb-0 profileSettingHeading"></div>
                        <div class="mb-0 profileSettingHeading v-step-4"></div>
                    </div>
                </div>
                <transition name="modal" v-if="reviewModal">
                    <div class="modal-mask">
                        <div class="modal-wrapper">
                            <div class="modal-container">
                                <div>
                                    <form class="d-block">
                                        <div class="d-flex justify-content-between align-items-center mb-5">
                                            <h1></h1>
                                            <a @click="cancel()"><img src="resources/assets/crossicon.png" /></a>
                                        </div>
                                        <div class="row" style="margin: 0px -23px;">
                                            <div class="col-md-6 mb-4 pb-2" style="background: #f4f5fa; border-radius: 15px;">
                                                <div>
                                                    <div class="form-outline form-white viewReviewInnerBox" style="border-radius: 15px; padding: 25px 0px; margin-top: 20px;">
                                                        <img v-if="currentReview.reviewAuthorImage" alt="..." :src="currentReview.reviewAuthorImage" class="avatar avatar-xl me-2 viewReviewInnerBoxImg" />
                                                        <!-- <img v-else alt="" src="" class="viewReviewInnerBoxImg" style="margin-right: 60px" /> -->
                                                        <a class="reviewModalName">{{ currentReview.reviewAuthor }} </a>
                                                        <span
                                                            class="ratingSpan"
                                                            :class="{
                            googleStarColor: googleColor,
                            fbStarColor: fbColor,
                            yelpStarColor: yelpColor,
                            zillowStarColor: zillowColor,
                            }"
                                                        >
                                                            <img src="resources/assets/rate_star_icon.svg" />&nbsp; {{ currentReview.reviewRating ? currentReview.reviewRating : 0 }} &nbsp;
                                                        </span>
                                                        <div class="text-width" style="width: 90%; margin-left: 20px; background: white;">
                                                            <span class="viewReviewInnerBoxPara"> {{ currentReview.reviewText }}</span>
                                                            <br />
                                                            <br />
                                                            <span class="viewReviewInnerBoxPara">
                                                                {{ moment( String(currentReview.rawDate) ).format("MMM DD, YYYY") }}
                                                                <!-- • 11 Reviews -->
                                                            </span>
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="detail-wrap col-md-5 mb-4 pb-2" style="margin-left: 35px;">
                                                <div class="row align-items-center">
                                                    <div class="col-md-4 col-12 mb-4 mb-sm-0">
                                                        <p class="d-flex align-items-center viewReviewSecondPartTopText">
                                                            {{ moment( String(currentReview.rawDate) ).format("MM/DD/YYYY") }}
                                                        </p>
                                                    </div>
                                                    <!-- Actions -->
                                                    <div class="col-md-8 col-12 mb-4 mb-sm-0 d-flex justify-content-end">
                                                        <p class="d-flex align-items-center viewReviewSecondPartTopText" style="text-transform: capitalize;">
                                                            {{ currentReview.platformName }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row align-items-center">
                                                    <div class="col-md-4 col-12 mb-4 mb-sm-0">
                                                        <p class="d-flex align-items-center viewReviewSecondPartTopName">
                                                            {{ adminDetails.firstName }}
                                                        </p>
                                                    </div>
                                                    <!-- Actions -->
                                                    <div class="col-md-8 col-12 mb-4 mb-sm-0 d-flex justify-content-end">
                                                        <p class="d-flex align-items-center" v-if="currentReview.reviewRating">
                                                            <img
                                                                v-for="index in parseInt(
                                currentReview.reviewRating
                            )"
                                                                :key="index + 'yellow'"
                                                                src="resources/assets/yellowStar.png"
                                                            />
                                                            <img
                                                                v-for="index in 5 -
                            parseInt(currentReview.reviewRating)"
                                                                :key="index + 'blank'"
                                                                src="resources/assets/blankStar.png"
                                                            />
                                                        </p>
                                                        <p v-else>
                                                            <img v-for="index in 5" :key="index + 'blank'" src="resources/assets/blankStar.png" />
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="form-outline form-white"></div>
                                                <button @click="respondNow(currentReview)" type="button" class="loginButton" style="height: 45px;">
                                                    <p style="height: 35px;" class="loginButtonText">
                                                        Respond Now
                                                    </p>
                                                </button>
                                                <div class="viewReviewSecondPartdropdown">
                                                    <select
                                                        class="viewReviewSecondPartdropdownText inputOrange assignFollowUp form-select"
                                                        v-model="userAssignId"
                                                        @change="onAssignUser($event)"
                                                        v-validate="'required'"
                                                        v-bind:name="'userAssign'"
                                                        v-bind:ref="'userAssign'"
                                                        :class="{
                            'is-invalid': errors.has('userAssign'),
                            }"
                                                    >
                                                        <option class="viewReviewSecondPartdropdownText" value="" disabled selected>
                                                            {{ `${reviewUserList.length > 0 ? "Assign for Follow-Up" : "No User Found." }` }}
                                                        </option>
                                                        <option class="viewReviewSecondPartdropdownText" :value="user.id" v-for="user in reviewUserList" :key="user.id">
                                                            {{ `${user.first_name} ${user.last_name ? user.last_name : "" }` }}
                                                        </option>
                                                    </select>
                                                </div>
                                                <textarea v-model="followUpNotes" id="form3Examplea2" placeholder="Follow-Up Notes" class="viewReviewSecondPartTextArea inputOrange" style="width: 100%; padding: 15px;"></textarea>
                                                <button type="button" class="loginButton" style="border: 1px solid #00053c; background: white; height: 45px;" @click="saveReviewFeedback(currentReview)">
                                                    <p class="loginButtonText" style="color: #00053c;">
                                                        Save
                                                    </p>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
                <transition name="modal" v-if="userListData">
                    <div class="modal-mask">
                        <div class="modal-wrapper">
                            <div class="modal-container">
                                <div>
                                    <UsersModal @closeModal="closeM($event)" :actionType="'reviews'" @changeTitle="ChangeT($event)" :selected="filterList.user" />
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
                <transition name="modal" v-if="customerReviewModal">
                    <div class="modal-mask">
                        <div class="modal-wrapper">
                            <div class="modal-container-view">
                                <div>
                                    <form>
                                        <div class="d-flex justify-content-between align-items-center mb-5">
                                            <h1>Customer Details</h1>
                                            <div class="d-flex">
                                                <a v-if="!customerReviewModalEdit" v-b-tooltip.hover @click="editReview()" style="cursor: pointer;" title="Edit"><img src="resources/assets/editIcon.png" /></a>
                                                <a @click="cancel()"><img src="resources/assets/crossicon.png" /></a>
                                            </div>
                                        </div>

                                        <h1></h1>
                                        <form class="d-block">
                                            <div class="row align-items-center">
                                                <div class="col-md-4 col-12 mb-4 mb-sm-0">
                                                    <div class="form-outline viewReviewInnerBox d-flex">
                                                        <div class="text-start">
                                                            <a class="profileFormTitle"> customerName</a>
                                                            <br />
                                                            <a v-if="customerReviewModalEdit && reviewViewData.platformName == 'facebook'" class="profileFormTitleValue">
                                                                <input
                                                                    type="text"
                                                                    class="form-control userForm form-control-lg inputOrange"
                                                                    placeholder="Customer Name"
                                                                    v-model="reviewViewData.reviewAuthor"
                                                                    v-validate="'required'"
                                                                    v-bind:name="'reviewViewData.reviewAuthor'"
                                                                    v-bind:ref="'reviewViewData.reviewAuthor'"
                                                                    :class="{
                                    'is-invalid': errors.has(
                                    'reviewViewData.reviewAuthor'
                                    ),
                                }"
                                                                />
                                                            </a>
                                                            <a v-else class="profileFormTitleValue"> {{ reviewViewData.reviewAuthor }}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4 col-12 mb-4 mb-sm-0">
                                                    <div class="form-outline viewReviewInnerBox d-flex">
                                                        <div class="text-start">
                                                            <a class="profileFormTitle"> Rating</a>
                                                            <br />
                                                            <a class="profileFormTitleValue"> {{ reviewViewData.reviewRating }}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4 col-12 mb-4 mb-sm-0">
                                                    <div class="form-outline viewReviewInnerBox d-flex">
                                                        <div class="text-start">
                                                            <a class="profileFormTitle"> Email</a>
                                                            <br />
                                                            <a v-if="customerReviewModalEdit" class="profileFormTitleValue">
                                                                <input
                                                                    type="email"
                                                                    class="form-control userForm form-control-lg inputOrange"
                                                                    placeholder="Customer Email Address"
                                                                    v-model="reviewViewData.email"
                                                                    v-validate="'required'"
                                                                    v-bind:name="'reviewViewData.email'"
                                                                    v-bind:ref="'reviewViewData.email'"
                                                                    :class="{
                                    'is-invalid': errors.has(
                                    'reviewViewData.email'
                                    ),
                                }"
                                                                />
                                                            </a>
                                                            <a v-else class="profileFormTitleValue">
                                                                {{ reviewViewData.email ? reviewViewData.email : "NA" }}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row align-items-center">
                                                <div class="col-md-4 col-12 mb-4 mb-sm-0">
                                                    <div class="form-outline viewReviewInnerBox d-flex">
                                                        <div class="text-start">
                                                            <a class="profileFormTitle"> Country Code</a>
                                                            <br />
                                                            <a v-if="customerReviewModalEdit" class="profileFormTitleValue">
                                                                <select
                                                                    class="viewReviewSecondPartdropdownText inputOrange assignFollowUp form-select"
                                                                    v-model="reviewCode"
                                                                    v-validate="'required'"
                                                                    v-bind:name="'requestData.code'"
                                                                    v-bind:ref="'requestData.code'"
                                                                    :class="{
                                    'is-invalid':
                                    errors.has('requestData.code'),
                                }"
                                                                >
                                                                    <option class="viewReviewSecondPartdropdownText" value="">
                                                                        Select Country
                                                                    </option>
                                                                    <option class="viewReviewSecondPartdropdownText" :value="location.shortName" v-for="location in countryCodelist" :key="location.id">
                                                                        {{ `${location.name} (+${location.code})` }}
                                                                    </option>
                                                                </select>
                                                            </a>
                                                            <a v-else class="profileFormTitleValue">
                                                                {{ reviewCountryCode ? reviewCountryCode : "NA" }}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4 col-12 mb-4 mb-sm-0">
                                                    <div class="form-outline viewReviewInnerBox d-flex">
                                                        <div class="text-start">
                                                            <a class="profileFormTitle"> Number</a>
                                                            <br />
                                                            <a v-if="customerReviewModalEdit" class="profileFormTitleValue">
                                                                <input
                                                                    type="number"
                                                                    class="form-control userForm form-control-lg inputOrange"
                                                                    placeholder="Customer Phone Number"
                                                                    v-model="reviewViewData.phone"
                                                                    v-validate="'required'"
                                                                    v-bind:name="'reviewViewData.phone'"
                                                                    v-bind:ref="'reviewViewData.phone'"
                                                                    :class="{
                                    'is-invalid': errors.has(
                                    'reviewViewData.phone'
                                    ),
                                }"
                                                                />
                                                            </a>
                                                            <a v-else class="profileFormTitleValue"> {{ reviewViewData.phone ? reviewViewData.phone : "NA" }}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4 col-12 mb-4 mb-sm-0">
                                                    <div class="form-outline viewReviewInnerBox d-flex">
                                                        <div class="text-start">
                                                            <a class="profileFormTitle"> Platform</a>
                                                            <br />
                                                            <a class="profileFormTitleValue"> {{ reviewViewData.platformName }}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <a v-if="customerReviewModalEdit" class="d-flex justify-content-end">
                                                <button @click.prevent="updateCustomerData()" class="platFormCardButton" style="width: 180px; margin-top: 15px;">
                                                    Save
                                                </button>
                                            </a>
                                        </form>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
                <transition name="modal" v-if="tour">
                    <v-tour name="myTour" :steps="steps" :callbacks="myCallbacks">
                        <template slot-scope="tour" style="background: #fff; color: #000">
                            <transition name="fade">
                                <v-step class="v-step" v-if="tour.steps[tour.currentStep]" :key="tour.currentStep"
                                    :step="tour.steps[tour.currentStep]" :previous-step="tour.previousStep"
                                    :next-step="tour.nextStep" :stop="tour.stop" :skip="tour.skip"
                                    :is-first="tour.isFirst" :is-last="tour.isLast" :labels="tour.labels"
                                >
                                    <div slot="content">
                                        <h3>Full Review Page Step 10 of 10 <img @click="tour.stop" src="resources/assets/crossicon.png" /></h3>
                                        <p class="vue-para">
                                            Not every review you get will be because you requested it! Check this page frequently to see new reviews and easily assign them to followed up with by one of your team members! Reviewzy will
                                            go fetch new reviews once per day automatically however, you can always click "refresh" to bring in new reviews whenever you'd like.
                                        </p>
                                    </div>
                                    <div slot="actions">
                                        <button @click="tutorialVideo(10)" class="tourButton">Tutorial Video</button>
                                        <button @click="tour.previousStep; myCustomPreviousStepCallback()" class="tourButton">Previous Step</button>
                                        <button @click="tour.stop; stopWizard()" class="tourButton">Finish Wizard</button>
                                    </div>
                                </v-step>
                            </transition>
                        </template>
                    </v-tour>
                </transition>
                <transition name="modal" v-if="vSteps">
                    <div class="modal-mask">
                        <div class="modal-wrapper">
                            <div class="modal-container modal-video" style="background: none; box-shadow: none;">
                                <div>
                                    <div class="d-flex mb-2" style="justify-content: end;">
                                        <a @click="cancel()" class="f-start"><img src="resources/assets/crossicon.png" /></a>
                                    </div>
                                    <div class="table-responsive">
                                        <div class="plyr__video-embed" id="player">
                                            <iframe class="youtube-video" title="YouTube video" :src="vStepsVideo" allowfullscreen allowtransparency allow="autoplay"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>

                <div class="card border-0 mb-7 table-align" id="userForDiv">
                    <div class="table-outer-wrap">
                        <div class="inner-white-wrap">
                            <ActionBar
                                @changeTitle="ChangeT($event)"
                                @updateList="updateListing($event)"
                                @callSync="syncPlatform($event)"
                                :selected-users="selectedUsers"
                                :filter-options="filterOptions"
                                :actionType="'reviews'"
                                :dateStyle="true"
                                :showRefresh="showRefresh"
                            />

                            <div class="container">
                                <ul class="filterText inputOrange assignFilter">
                                    <li class="filterText" v-for="(data, index) in filterList.platform" v-bind:key="index">
                                        <a class="filter-bg-color">
                                            {{ data }}
                                            <img src="resources/assets/close.svg" alt="" @click="removeFilter(data)" style="padding-left: 15px;" />
                                        </a>
                                    </li>
                                    <li class="filterText" v-for="(data, index) in filterList.ranking" v-bind:key="index">
                                        <a class="filter-bg-color">
                                            {{ data }}
                                            <img src="resources/assets/close.svg" alt="" @click="removeFilter(data)" style="padding-left: 15px;" />
                                        </a>
                                    </li>
                                    <li class="filterText" v-for="(data, index) in filterList.userName" v-bind:key="index">
                                        <a class="filter-bg-color">
                                            {{ `${data.first_name} ${data.last_name ? data.last_name : ""}` }}
                                            <img src="resources/assets/close.svg" alt="" @click="removeFilter(data)" style="padding-left: 15px;" />
                                        </a>
                                    </li>
                                    <li v-if="filterList.startDate" class="filterText">
                                        <a class="filter-bg-color">
                                            {{ `Startdate ${filterList.startDate}` }}
                                            <img src="resources/assets/close.svg" alt="" @click="removeFilter(filterList.startDate)" style="padding-left: 15px;" />
                                        </a>
                                    </li>
                                    <li v-if="filterList.endDate" class="filterText">
                                        <a class="filter-bg-color">
                                            {{ `Enddate ${filterList.endDate}` }}
                                            <img src="resources/assets/close.svg" alt="" @click="removeFilter(filterList.endDate)" style="padding-left: 15px;" />
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div class="table-responsive">
                                <table class="table table-hover table-nowrap formtable">
                                    <thead class="thead-light">
                                        <tr class="header">
                                            <th class="heading" scope="col" style="padding-left: 15px;">
                                                <div class="custom-control custom-checkbox checkbox-lg form-check-inline">
                                                    <input type="checkbox" class="custom-control-input" @change="pushAllSelectedReviews($event)" id="all" />
                                                    <label class="custom-control-label checkLabel" for="all"></label>
                                                </div>
                                            </th>
                                            <th class="heading" scope="col"></th>
                                            <th class="heading" scope="col">DATE IMPORTED</th>
                                            <th class="heading" scope="col">CUSTOMER NAME</th>
                                            <th class="heading" scope="col">PLATFORM</th>
                                            <th class="heading" scope="col">RATINGS & STARS</th>
                                            <th class="heading" scope="col">USER</th>
                                            <th class="heading" scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="reviewsList.length > 0">
                                        <tr v-for="review in reviewsList" :key="review.id">
                                            <td>
                                                <div class="custom-control custom-checkbox checkbox-lg form-check-inline">
                                                    <input :id="review.id" type="checkbox" class="custom-control-input" @change="pushSelectedReviews($event, review.id)" />
                                                    <label class="custom-control-label checkLabel" :for="review.id"></label>
                                                </div>
                                            </td>
                                            <td>
                                                <a class="text-heading new-color" style="cursor: pointer;" @click="viewReview(review.id, review.reviewStatus)">
                                                    {{ review.reviewStatus ? "New!" : "" }}
                                                </a>
                                            </td>
                                            <td>
                                                <a class="text-heading" style="text-decoration: none;" href="#">
                                                    {{ moment(String(review.rawDate)).format( "MM-DD-YYYY" ) }}
                                                </a>
                                            </td>
                                            <td>
                                                <a class="text-heading" style="text-decoration: none;" href="#" @click="customerReviewModalOpen(review.id)">
                                                    {{ review.reviewAuthor }}
                                                </a>
                                            </td>
                                            <td>
                                                <a class="text-heading" style="text-decoration: none;" href="#">
                                                    {{ review.platformName }}
                                                </a>
                                            </td>
                                            <td>
                                                <a class="text-heading" style="text-decoration: none;" href="#" v-if="review.reviewRating">
                                                    <img v-for="index in parseInt(review.reviewRating)" :key="index + 'yello'" src="resources/assets/yellowStar.png" />
                                                    <img
                                                        v-for="index in 5 -
                        parseInt(review.reviewRating)"
                                                        :key="index + 'blank'"
                                                        src="resources/assets/blankStar.png"
                                                    />
                                                </a>
                                                <a class="text-heading" style="text-decoration: none;" href="#" v-else>
                                                    <img v-for="index in 5" :key="index + 'blank'" src="resources/assets/blankStar.png" />
                                                </a>
                                            </td>
                                            <td>
                                                <a class="text-heading" style="text-decoration: none;" href="#">
                                                    {{ review.assignedUserName }}
                                                </a>
                                            </td>
                                            <td>
                                                <a href="#" class="btn btn-sm btn-neutral" style="background: none;" @click="viewReview(review.id, review.reviewStatus)"><img src="resources/assets/view_icon.svg" /></a>
                                                <a href="#" @click="deleteReview(review.id)" class="btn btn-sm btn-neutral" style="background: none;">
                                                    <img src="resources/assets/trash_icon.svg" />
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr>
                                            <td colspan="8" style="text-align: center; font-weight: 600;">
                                                <h1>No Review Found</h1>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div v-if="reviewCount > reviewPerPage" class="admin-new-pagination role-table-pagination">
                                <b-pagination v-model="reviewCurrentPage" :total-rows="reviewCount" :per-page="reviewPerPage" @change="showPage" first-number last-number align="center" pills size="lg"></b-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "./Header";
import Menu from "./Menu";
import ActionBar from "./ActionBar";
import UsersModal from "./UsersModal";
import moment from "moment";
export default {
  name: "Reviews",
  components: {
    ActionBar,
    Header,
    Menu,
    UsersModal
  },
  data() {
    return {
        moment: moment,

        vSteps: false,
        vStepsVideo: "",
        vStepsVideoList: [],
        steps: [
            {
                target: '#v-step-0',
            },
            {
                target: '#v-step-1',
            }
        ],
        myCallbacks: {
            onNextStep: this.myCustomNextStepCallback,
            onPreviousStep: this.myCustomPreviousStepCallback,
            onStop:this.myCustomStopCallback,
        },
        tour: localStorage.getItem("isSetupWizardPlay") === 'true' ? true : false,

        adminDetails: {
            firstName: "",
        },
        show: "reviews",
        reviewModal: false,
        userAssignId: "",
        followUpNotes: "",
        assignUserName: "",
        selectedUsers: [],
        filterOptions: [
            {
            platform: {
                title: "platform",
                data: ["Google", "Facebook", "Yelp", "Zillow"],
            },
            "By User": {
                title: "By User",
            },
            "By Ranking": {
                title: "By Ranking",
                data: ["1", "2", "3", "4", "5"],
            },
            "Sort By": {
                title: "Sort By",
                data: ["Top Ratings", "Low Ratings", "Latest"],
            },
            },
        ],
        filterList: {
            platform: [],
            user: [],
            ranking: [],
            startDate: "",
            endDate: "",
            userName: []
        },
        userList: [],
        reviewsList: [],
        reviewCurrentPage: 1,
        reviewPerPage: 20,
        reviewCount: 0,
        currentReview: [],
        userLoginId: "",
        assignUserName: "",
        module: "",
        userListData: false,
        customerReviewModal: false,
        customerReviewModalEdit: false,
        showRefresh: false,
        googleColor: false,
        fbColor: false,
        yelpColor: false,
        zillowColor: false,
        reviewUserList: [],
        googlePlatform: false,
        facebookPlatform: false,
        yelpPlatform: false,
        zillowPlatform: false,
        apiLoading: false
    };
  },
  mounted() {
    this.reviewCurrentPage = 1;
    this.userLoginId = localStorage.getItem("id");
    this.getUserProfileSettings();
    this.getUserList();
    this.getReviewsList();
    this.reviewUserLists();
    this.checkConnectedPlatform();
    this.countryCode();
    if (this.tour) {
        this.$tours['myTour'].start();
        this.wizardData();
    }
  },
  methods: {
    getUserList() {
      let data = {
        page: this.usersCurrentPage ? this.usersCurrentPage : 1,
      };
      this.$store.dispatch("userList", data).then((response) => {
        this.userList = response.data;
        this.usersCount = response.total;
      });
    },
    onAssignUser(e) {
      let assignName = e.target.options[e.target.options.selectedIndex].text;
      this.assignUserName = assignName;
    },
    getUserProfileSettings() {
      this.$store.dispatch("userProfileSettings").then((response) => {
        let res = response.data[0];

        this.adminDetails.id = res.id;
        this.adminDetails.firstName = res.first_name;
      });
    },
    async saveReviewFeedback(data) {
      let result = await this.$validator.validateAll();
      if (result) {
        this.apiLoading = true;
        let popupData = {
          id: data.id,
          followUpNotes: this.followUpNotes,
          userID: this.userAssignId,
          assignment: this.assignUserName,
        };
        this.$store.dispatch("createFeedback", popupData).then((response) => {
          if (response.success) {
            this.$toastr.s(`${response.message}`);
            this.reviewModal = false;
            let reqParam = {
              page: this.reviewCurrentPage ? this.reviewCurrentPage : 1,
              userId: this.userLoginId,
              platformName: this.filterList.platform ? this.filterList.platform : "",
              reviewRating: this.filterList.ranking ? this.filterList.ranking : "",
              assignedUser: this.filterList.user ? this.filterList.user : "",
              fromDate: this.filterList.startDate ? this.filterList.startDate : "",
              toDate: this.filterList.endDate ? this.filterList.endDate : "",
              module: this.module,
            };
            this.getReviewsList(reqParam);
          } else {
            this.$toastr.e("Error while assigning user");
            this.reviewModal = true;
          }
        }).catch((err) => {
          console.log(err);
        })
          .finally(() => {
            this.apiLoading = false;
          });
      }
    },
    loadMoreReviews() {
      this.reviewsPageNumber = this.reviewsPageNumber + 1;
      let reqParam = {
        page: this.reviewsPageNumber,
        userId: this.userLoginId,
        platformName: this.filterList.platform ? this.filterList.platform : "",
        reviewRating: this.filterList.ranking ? this.filterList.ranking : "",
        fromDate: this.filterList.startDate ? this.filterList.startDate : "",
        toDate: this.filterList.endDate ? this.filterList.endDate : "",
        module: this.module,
      };
      this.getReviewsList(reqParam);
    },

    /**
     * @desc: Show Page
     * @param: page
     * @return: None
     * */
    showPage(page) {
      this.currentPage = page;
      let reqParam = {
        page: this.currentPage ? this.currentPage : 1,
        userId: this.userLoginId,
        platformName: this.filterList.platform ? this.filterList.platform : "",
        reviewRating: this.filterList.ranking ? this.filterList.ranking : "",
        fromDate: this.filterList.startDate ? this.filterList.startDate : "",
        toDate: this.filterList.endDate ? this.filterList.endDate : "",
        module: this.module,
      };
      this.getReviewsList(reqParam);
    },
    getReviewsList(filterConditions) {
      let data;
      if (filterConditions) {
        data = filterConditions;
      } else {
        data = {
          page: this.reviewCurrentPage ? this.reviewCurrentPage : 1,
          userId: this.userLoginId,
        };
      }
      this.$store.dispatch("reviewsList", data).then((response) => {
        this.reviewsList = response.data;
        this.reviewCount = response.total;
      });
    },
    // All Methods Related To Reviews Starts Here
    viewReview(id, status) {
      this.followUpNotes = "";
      this.userAssignId = "";
      this.currentReview = this.reviewsList.find((x) => x.id === id);
      this.yelpColor = this.currentReview.platformName == "yelp" ? true : false;
      this.googleColor =
        this.currentReview.platformName == "google" ? true : false;
      this.zillowColor =
        this.currentReview.platformName == "zillow" ? true : false;
      this.fbColor =
        this.currentReview.platformName == "facebook" ? true : false;

      this.userAssignId = this.currentReview.assignedUser;
      this.assignUserName = this.currentReview.assignedUserName;
      this.followUpNotes = this.currentReview.followUpNotes;
      this.reviewModal = true;

      if (status) {
        this.reviewViewData = [];
        this.$store.dispatch("reviewReadCount", id).then((response) => {
          if (response.success) {
            this.reviewViewData = response.data[0];
            this.reviewViewData = true;
            this.getReviewsList();
            this.newReviews();
          }
        });
      }
    },
    newReviews() {
      let data = {
        userID: this.userLoginId,
      };
      this.$store.dispatch("reviewsNewCount", data).then((response) => {
        this.reviewStatus =
          response.data && response.data.reviewStatus
            ? response.data.reviewStatus
            : "";
      });
    },
    customerReviewModalOpen(id) {
      this.customerReviewModalEdit = false;
      this.reviewViewData = [];

      this.$store.dispatch("viewReview", id).then((response) => {
        this.reviewViewData = response.data[0];

        let countryData = this.countryCodelist.find(
          (element) => element.shortName == this.reviewViewData.countryName
        );
        this.reviewCode =
          countryData && countryData.shortName ? countryData.shortName : "";
        this.reviewCountryCode =
          countryData && countryData.name && countryData.code
            ? `${countryData.name} (+${countryData.code}) `
            : "";
        this.customerReviewModal = true;
      });
    },
    reviewUserLists() {
      this.$store.dispatch("allUser").then((response) => {
        if (response.data) {
          this.reviewUserList = response.data;
        } else {
          this.reviewUserList = [];
        }
      });
    },
    async checkConnectedPlatform() {
      let data = {
        userId: this.userLoginId,
      };
      await this.$store.dispatch("platformCheck", data).then((response) => {
        let res = response.data ? response.data[0] : "";

        res.google
          ? (this.googlePlatform = true)
          : (this.googlePlatform = false);
        res.facebook
          ? (this.facebookPlatform = true)
          : (this.facebookPlatform = false);
        res.yelp ? (this.yelpPlatform = true) : (this.yelpPlatform = false);
        res.zillow
          ? (this.zillowPlatform = true)
          : (this.zillowPlatform = false);
      });
      this.showRefresh =
        this.googlePlatform ||
          this.facebookPlatform ||
          this.yelpPlatform ||
          this.zillowPlatform
          ? true
          : false;
    },
    countryCode() {
      this.$store.dispatch("requestCountryCode").then((response) => {
        if (response.success) {
          this.countryCodelist = response.data;
        }
      });
    },
    editReview() {
      this.customerReviewModalEdit = true;
    },
    async respondNow(data) {
      let result = await this.$validator.validateAll();
      if (result) {
        this.apiLoading = true;
        let popupData = {
          id: data.id,
          followUpNotes: this.followUpNotes,
          userID: this.userAssignId,
          assignment: this.assignUserName,
        };
        this.$store.dispatch("createFeedback", popupData).then((response) => {
          if (response.success) {
            this.reviewModal = false;
            this.getFeedbackId(response.data);
            let reqParam = {
              page: this.reviewCurrentPage ? this.reviewCurrentPage : 1,
              userId: this.userLoginId,
              platformName: this.filterList.platform ? this.filterList.platform : "",
              reviewRating: this.filterList.ranking ? this.filterList.ranking : "",
              assignedUser: this.filterList.user ? this.filterList.user : "",
              fromDate: this.filterList.startDate ? this.filterList.startDate : "",
              toDate: this.filterList.endDate ? this.filterList.endDate : "",
              module: this.module,
            };
            this.getReviewsList(reqParam);
            this.reviewModal = false;
          } else {
            this.$toastr.e(`${response.message}`);
            this.reviewModal = true;
          }
        }).catch((err) => {
          console.log(err);
        })
          .finally(() => {
            this.apiLoading = false;
          });
      }
    },
    getFeedbackId(data) {
      if (data.rating > 0 && data.rating <= 3) {
        this.$router.push(`/individual-less-star/${data.id}`);
      }
      if (data.rating > 3 && data.rating <= 5) {
        this.$router.push(`/individual-more-star/${data.id}`)
      }
      this.feedbackId = data.id;
    },
    pushAllSelectedReviews(e) {
      if (e.target.checked) {
        this.selectedUsers = this.reviewsList.map((x) => x.id);
        this.selectedUsers.forEach((element) => {
          document.getElementById(element).checked = true;
        });
      } else {
        this.selectedUsers.forEach((element) => {
          document.getElementById(element).checked = false;
        });
        this.selectedUsers = [];
      }
    },

    pushSelectedReviews(e, id) {
      if (e.target.checked) {
        this.selectedUsers.push(id);
      } else {
        const index = this.selectedUsers.indexOf(id);
        if (index > -1) {
          this.selectedUsers.splice(index, 1);
        }
      }
    },
    deleteReview(id) {
      this.$store.dispatch("deleteReview", id).then((response) => {
        if (response.success) {
          let reqParam = {
            page: this.reviewCurrentPage ? this.reviewCurrentPage : 1,
            userId: this.userLoginId,
            platformName: this.filterList.platform
              ? this.filterList.platform
              : "",
            reviewRating: this.filterList.ranking
              ? this.filterList.ranking
              : "",
            assignedUser: this.filterList.user ? this.filterList.user : "",
            fromDate: this.filterList.startDate
              ? this.filterList.startDate
              : "",
            toDate: this.filterList.endDate ? this.filterList.endDate : "",
            module: this.module,
          };
          this.getReviewsList(reqParam);
          this.$toastr.s(`${response.message}`);
          this.newReviews();
        } else {
          this.$toastr.e(`${response.message}`);
        }
      });
    },
    updateListing(data) {
      let reqParam = {
        platformName: this.filterList.platform ? this.filterList.platform : "",
        reviewRating: this.filterList.ranking ? this.filterList.ranking : "",
        fromDate: this.filterList.startDate ? this.filterList.startDate : "",
        toDate: this.filterList.endDate ? this.filterList.endDate : "",
      };
      if (data.actionType == "reviews") {
        reqParam.page = this.reviewCurrentPage ? this.reviewCurrentPage : 1;
        reqParam.userId = this.userLoginId;
        reqParam.module = data.actionType;
        this.getReviewsList(reqParam);
      }
      if (data.actionType == "users") {
        (reqParam.module = data.actionType), this.getUserList();
      }
      if (data.actionType == "feedback") {
        reqParam.page = this.feedbackCurrentPage ? this.feedbackCurrentPage : 1;
        (reqParam.userId =
          localStorage.getItem("isAdmin") == 0 ? this.userLoginId : ""),
          (reqParam.parentUserID =
            localStorage.getItem("isAdmin") == 1 ? this.userLoginId : ""),
          (reqParam.module = data.actionType),
          this.getFeedbackList(reqParam);
      }
      if (data.actionType == "request_reviews") {
      }
    },
    removeFilter(data) {
      let platform = this.filterList.platform.indexOf(data);
      if (platform > -1) {
        this.filterList.platform.splice(platform, 1);
      }
      let ranking = this.filterList.ranking.indexOf(data);
      if (ranking > -1) {
        this.filterList.ranking.splice(ranking, 1);
      }

      let dateRangeStart = this.filterList.startDate.split(": ");
      let dateRangeEnd = this.filterList.endDate.split(": ");
      let rangeStart = this.filterList.startDate.indexOf(dateRangeStart[0]);
      let rangeEnd = this.filterList.endDate.indexOf(dateRangeEnd[0]);

      if (rangeStart > -1 || rangeEnd > -1) {
        this.filterList.startDate = "";
        this.filterList.endDate = "";
      }

      let reqParam = {
        page: 1,
        platformName: this.filterList.platform ? this.filterList.platform : "",
        reviewRating: this.filterList.ranking ? this.filterList.ranking : "",
        fromDate: this.filterList.startDate ? this.filterList.startDate : "",
        toDate: this.filterList.endDate ? this.filterList.endDate : "",
        module: this.module,
      };
      this.callFilterApis(reqParam);
    },
    callFilterApis(data) {
      if (data.module == "reviews") {
        (data.userId = this.userLoginId), this.getReviewsList(data);
      }

      if (data.module == "feedback") {
        (data.userId =
          localStorage.getItem("isAdmin") == 0 ? this.userLoginId : ""),
          (data.parentUserID =
            localStorage.getItem("isAdmin") == 1 ? this.userLoginId : ""),
          this.getFeedbackList(data);
      }
    },
    ChangeT(data) {
      let title = data.title;
      let singleData = data.data;
      let modal = data && data.modal ? data.modal : "";
      this.module = data.actionType;

      if (title == "platform") {
        !this.filterList.platform.includes(singleData)
          ? (this.filterList.platform = [
            ...this.filterList.platform,
            singleData,
          ])
          : "";
      }
      if (title == "By Ranking") {
        !this.filterList.ranking.includes(singleData)
          ? (this.filterList.ranking = [...this.filterList.ranking, singleData])
          : "";
      }

      if (title == "By User" && this.module == "reviews") {
        this.userListData = true;
      }

      if (title == "By User" && this.module == "feedback") {
        this.feedbackUserList = true;
      }
      if (singleData != null && modal == "usersFilter") {
        this.filterList.user = singleData.data;
        this.filterList.userName = singleData.userName;
        this.feedbackUserList = false;
        this.userListData = false;
      }

      if (title == "Select Data Range") {
        let startDate = moment(singleData.startDate).format("YYYY-MM-DD");
        let endDate = moment(singleData.endDate).format("YYYY-MM-DD");
        this.filterList.startDate = startDate;
        this.filterList.endDate = endDate;
      }

      if (title != "By User") {
        this.reviewCurrentPage = this.reviewsPageNumber
        let reqParam = {
          page: this.reviewsPageNumber,
          platformName: this.filterList.platform
            ? this.filterList.platform
            : "",
          reviewRating: this.filterList.ranking ? this.filterList.ranking : "",
          assignedUser: this.filterList.user ? this.filterList.user : "",
          fromDate: this.filterList.startDate ? this.filterList.startDate : "",
          toDate: this.filterList.endDate ? this.filterList.endDate : "",
          module: this.module,
        };
        this.callFilterApis(reqParam);
      }
    },
    removeFilter(data) {
      let platform = this.filterList.platform.indexOf(data);
      if (platform > -1) {
        this.filterList.platform.splice(platform, 1);
      }
      let ranking = this.filterList.ranking.indexOf(data);
      if (ranking > -1) {
        this.filterList.ranking.splice(ranking, 1);
      }
      let user;
      data && data.id ? user = this.filterList.user.indexOf(data.id) : "";
      if (user > -1) {
        this.filterList.user.splice(user, 1);
      }

      let userName = this.filterList.userName.findIndex(function (userName, index) {
        if (userName.id == data.id)
          return true;
      });
      if (userName > -1) {
        this.filterList.userName.splice(userName, 1);
      }

      let rangeStart;
      let rangeEnd;
      if ((data == this.filterList.startDate) || (data == this.filterList.endDate)) {
        let dateRangeStart = this.filterList.startDate.split(": ");
        let dateRangeEnd = this.filterList.endDate.split(": ");
        rangeStart = this.filterList.startDate.indexOf(dateRangeStart[0]);
        rangeEnd = this.filterList.endDate.indexOf(dateRangeEnd[0]);
      }

      if (rangeStart > -1 || rangeEnd > -1) {
        this.filterList.startDate = "";
        this.filterList.endDate = "";
      }

      let reqParam = {
        page: 1,
        platformName: this.filterList.platform ? this.filterList.platform : "",
        reviewRating: this.filterList.ranking ? this.filterList.ranking : "",
        assignedUser: this.filterList.user ? this.filterList.user : "",
        fromDate: this.filterList.startDate ? this.filterList.startDate : "",
        toDate: this.filterList.endDate ? this.filterList.endDate : "",
        module: this.module,
      };
      this.callFilterApis(reqParam);
    },
    callFilterApis(data) {
      if (data.module == "reviews") {
        this.reviewCurrentPage = 1;
        (data.userId = this.userLoginId), this.getReviewsList(data);
      }

      if (data.module == "feedback") {
        (data.userId =
          localStorage.getItem("isAdmin") == 0 ? this.userLoginId : ""),
          (data.parentUserID =
            localStorage.getItem("isAdmin") == 1 ? this.userLoginId : ""),
          this.getFeedbackList(data);
      }
    },
    syncPlatform(data) {
      if (this.filterList.platform.length === 0) {
        this.googlePlatform ? this.syncGoogleBusiness() : "";
        this.facebookPlatform ? this.syncFacebookBusiness() : "";
        this.yelpPlatform ? this.syncYelpBusiness() : "";
        this.zillowPlatform ? this.synczillowBusiness() : "";
      } else {
        this.filterList.platform.includes("Google") && this.googlePlatform
          ? this.syncGoogleBusiness()
          : "";

        this.filterList.platform.includes("Facebook") && this.facebookPlatform
          ? this.syncFacebookBusiness()
          : "";

        this.filterList.platform.includes("Yelp") && this.yelpPlatform
          ? this.syncYelpBusiness()
          : "";

        this.filterList.platform.includes("Zillow") && this.zillowPlatform
          ? this.synczillowBusiness()
          : "";
      }
      let reqParam = {
        page: this.reviewCurrentPage ? this.reviewCurrentPage : 1,
        userId: this.userLoginId,
        platformName: this.filterList.platform ? this.filterList.platform : "",
        reviewRating: this.filterList.ranking ? this.filterList.ranking : "",
        assignedUser: this.filterList.user ? this.filterList.user : "",
        fromDate: this.filterList.startDate ? this.filterList.startDate : "",
        toDate: this.filterList.endDate ? this.filterList.endDate : "",
        module: this.module,
      };
      this.getReviewsList(reqParam);
    },
    syncGoogleBusiness() {
      let data = {
        userId: this.userLoginId,
      };
      this.apiLoading = true;

      this.$store
        .dispatch("googleSync", data)
        .then((response) => {
          if (response.success) {
            this.$toastr.s(`${response.message}`);
          } else {
            this.$toastr.e(`${response.message}`);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.apiLoading = false;
        });
    },
    syncFacebookBusiness() {
      let data = {
        userId: this.userLoginId,
      };
      this.apiLoading = true;

      this.$store
        .dispatch("fbSync", data)
        .then((response) => {
          if (response.success) {
            this.$toastr.s(`${response.message}`);
          } else {
            this.$toastr.e(`${response.message}`);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.apiLoading = false;
        });
    },

    syncYelpBusiness() {
      let data = {
        userId: this.userLoginId,
      };
      this.apiLoading = true;

      this.$store
        .dispatch("yelpSync", data)
        .then((response) => {
          if (response.success) {
            this.$toastr.s(`${response.message}`);
          } else {
            this.$toastr.e(`${response.message}`);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.apiLoading = false;
        });
    },
    synczillowBusiness() {
      let data = {
        userId: this.userLoginId,
      };
      this.apiLoading = true;
      this.$store
        .dispatch("zillowSync", data)
        .then((response) => {
          if (response.success) {
            this.$toastr.s(`${response.message}`);
          } else {
            this.$toastr.e(`${response.message}`);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.apiLoading = false;
        });
    },
    async updateCustomerData() {
      let result = await this.$validator.validateAll();
      if (result) {

        let countryCode = this.countryCodelist.find(
          (element) => element.shortName == this.reviewCode
        );

        let data = {
          id: this.reviewViewData.id,
          reviewAuthor: this.reviewViewData.reviewAuthor,
          userID: this.userLoginId,
          email: this.reviewViewData.email,
          phone: this.reviewViewData.phone,
          code: countryCode && countryCode.code ? "+" + countryCode.code : "+1",
          countryName:
            countryCode && countryCode.shortName ? countryCode.shortName : "US",
        };

        this.$store
          .dispatch("reviewUpdate", data)
          .then((response) => {
            if (response.success) {
              this.customerReviewModalEdit = false;
              this.reviewCountryCode =
                countryCode && countryCode.name && countryCode.code
                  ? `${countryCode.name} (+${countryCode.code}) `
                  : "";

              this.$toastr.s(`${response.message}`);
              let reqParam = {
                page: this.reviewCurrentPage ? this.reviewCurrentPage : 1,
                userId: this.userLoginId,
                platformName: this.filterList.platform ? this.filterList.platform : "",
                reviewRating: this.filterList.ranking ? this.filterList.ranking : "",
                assignedUser: this.filterList.user ? this.filterList.user : "",
                fromDate: this.filterList.startDate ? this.filterList.startDate : "",
                toDate: this.filterList.endDate ? this.filterList.endDate : "",
                module: this.module,
              };
              this.getReviewsList(reqParam);
            } else {
              if (response.data.email) {
                this.$toastr.e(response.data.email);
              }
              if (response.data.phone) {
                this.$toastr.e(response.data.phone);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    clearFilterData() {
      this.filterList.platform = [];
      this.filterList.user = [];
      this.filterList.userName = [];
      this.filterList.ranking = [];
      this.filterList.startDate = "";
      this.filterList.endDate = "";
    },
    cancel() {
        this.vSteps = false;
        this.reviewModal = false;
        this.customerReviewModal = "";
    },
    closeM(data) {
        this.userListData = false;
        this.feedbackUserList = false;
    },
    wizardData() {
        this.apiLoading = true;
        let data = {
            page: 1,
        };
        this.$store
        .dispatch("wizardList", data)
        .then((response) => {
            if (response.success) {
                this.vStepsVideoList = response.data.data;
            }
        })
        .catch((err) => {
            console.log(err);
        }).finally(() => (this.apiLoading = false));
    },
    tutorialVideo(data){
        if(this.vStepsVideoList[data-1].tutorialLink){
        this.vSteps = true;
        this.vStepsVideo = `${this.vStepsVideoList[data-1].tutorialLink}?origin=https://plyr.io&amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1&output=embed`
        }
    },
    myCustomPreviousStepCallback(currentStep){
        this.$router.push({ path: "/feedback" });
    },
    myCustomNextStepCallback(currentStep){
        //
    },
    myCustomStopCallback(){
        this.tour = false;
        localStorage.setItem("loginFirstTime", 0);
        localStorage.setItem("isSetupWizardPlay", 'false');
    },
    stopWizard(){
        this.vSteps = false;
        let data = {
          id: this.userLoginId
        };
        this.$store
          .dispatch("wizardFlag", data)
          .then((response) => {
            if (response.success) {
              localStorage.setItem("loginFirstTime",0);
              localStorage.setItem("isSetupWizardPlay", 'false');
              localStorage.setItem("wizard",0);
              this.tour = false;
              }
          })
          .catch((err) => {
            console.log(err);
          });
    },
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  display: block;
  transition: opacity 0.3s ease;
  overflow: auto;
}

.modal-wrapper {
  display: grid;
}

.modal-container {
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  margin-bottom: 100px;
  padding: 30px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container .modal-container-youtube {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}


.navbar>.container .navbar-brand,
.navbar>.container-fluid .navbar-brand {
  margin-left: 1vw;
  margin-top: 2vh;
}

.text-heading {
  font-weight: 500 !important;
  text-transform: capitalize;
  color: #00053c;
  font-family: "Inter";
  font-style: normal;
  font-size: 14px;
  align-items: center;
  white-space: initial;
}

.formtable {
  text-align: center;
  border-collapse: inherit;
}

.heading {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 12px;
  line-height: 24px;
  align-items: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #00053c !important;
  font-weight: 600;
  align-items: center;
}

::placeholder {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 29px;
  /* or 181% */
  display: flex;
  align-items: center;

  color: #9194b3;
}

.userForm {
  height: 50px;
  /* height: 58px; */
  border-radius: 15px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px !important;
}

.loginButtonText {
  justify-content: center;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  /* or 181% */
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  /* height: 50px; */
}

.loginButton {
  margin-top: 20px;
  width: 100%;
  height: 58px;
  background: #00053c;
  border-radius: 15px;
  transition: 0.2s ease;
}

.row {
  margin-right: 0px;
}

.reviewModalName {
  margin: 0px 20px;
  /* margin: 0px 7px; */
  text-decoration: none;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  /* or 181% */
  align-items: center;

  color: #1778f2 !important;
}
.table>tbody>tr>td {
  padding: 30px 15px;
}

/* Review Css  Start*/
.ratingSpan {
  color: white;
  padding: 8px 10px 5px;
  border-radius: 26px;
  font-weight: 500;
  font-size: 16px;
  margin-left: 15px;
}

.yelpStarColor {
  background: #bf2519;
}

.googleStarColor {
  background: #fdd640;
}

.fbStarColor {
  background: #39559a;
}

.zillowStarColor {
  background: #38cb89;
}

.ratingSpan>img {
  padding: 0 5px;
  width: 30px;
  margin-top: -4px;
}

.form-white>div {
  width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  background: #f4f5fa;
  border-radius: 15px;
  font-weight: 500;
  font-size: 20px;
  color: #9194b3;
}

.viewReviewInnerBox {
  background-color: white;
  margin-bottom: 20px;
}

.viewReviewInnerBoxImg {
  margin: 20px 20px;
}

.viewReviewInnerBoxPara {
  word-wrap: break-word;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #9194b3;
}

.viewReviewSecondPartTopText {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #9194b3;
}

.viewReviewSecondPartTopName {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 20px;
  color: #00053c;
}

.viewReviewSecondPartTextArea {
  margin: 15px 0px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  /* or 181% */

  display: flex;
  align-items: center;

  color: #9194b3;

  width: 451px;
  height: 150px;
  left: 889px;
  top: 491px;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  border-radius: 15px;
}

.loginButtonText {
  justify-content: center;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  /* or 181% */
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.viewReviewSecondPartdropdown {
  margin: 15px 0px;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  border-radius: 15px;
}

.viewReviewSecondPartdropdownText {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px !important;
  /* font-size: 16px !important; */
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #9194b3;
}

.filterText {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  display: flex;
  color: #9194b3;
}

/* Review Css End */
.profileFormTitle {
  text-decoration-line: none;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  align-items: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #9194b3 !important;
}

.profileFormTitleValue {
  text-decoration-line: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  /* font-size: 12px; */
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #00053c !important;
}

.platFormCardButton {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  /* or 181% */
  background: #00053c;
  color: #ffffff;
  width: 180px;
  height: 51px;
  border-radius: 15px;
}

.profileSettingHeading {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #00053c;
  margin-top: 24px;
  margin-left: 24px;
}

.btn:hover {
  background-color: #4e5056;
}
.btn {
  border: none;
  padding: 10px;
  /* padding: 10px 20px; */
  border-radius: 50px;
  background-color: #f4f5fa;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 200ms ease-in;
}

.assignFollowUp {
  height: 50px;
  left: 889px;
  top: 413px;
  /* background: #ffffff; */
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  border-radius: 15px;
  width: 100%;
  padding: 10px;
}

textarea {
  resize: none;
}

.inputOrange:focus {
  outline: none !important;
  border: 1.5px solid #ff8c00;
  box-shadow: 0 0 2px #ff8c00;
}

form i {
  margin-left: -30px;
  cursor: pointer;
}

.navOutDiv {
  position: fixed;
  width: 100%;
}

.dashScroll {
  margin-right: 0 !important;
  padding-right: 15px;
}


.filter-bg-color {
  background-color: #f4f5fa;
  border-radius: 15px;
  padding: 5px 10px;
  margin: 0 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.assignFilter {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 15px;
  width: 100%;
  justify-content: flex-end;
  margin: 0 0 10px;
}

.role-table-pagination .pagination {
  justify-content: center;
}

.admin-new-pagination ul.pagination {
  background: none;
}

.admin-new-pagination ul li {
  flex-grow: initial !important;
  box-shadow: 0px 2px 6px #0000000a;
}

.admin-new-pagination {
  padding: 35px 0;
  background: #fff;
  overflow: auto;
}

.admin-new-pagination .pagination {
  /* justify-content: flex-end; */
  height: auto;
  box-shadow: none;
}

.admin-new-pagination .pagination li.active a.page-link {
  background: #623cea;
  border-radius: 0px;
  border-color: #623cea;
  color: #fff;
}

.admin-new-pagination .pagination li.active a.page-link:focus {
  box-shadow: none;
}

.admin-new-pagination .pagination a.page-link {
  color: #a4afb7;
  background: #fff;
}

.admin-new-pagination ul li span,
.admin-new-pagination ul li a,
.admin-new-pagination ul li button {
  padding: 10px 15px !important;
  font-size: 13px !important;
}

.admin-new-pagination .pagination a.page-link:focus {
  box-shadow: none;
}

.is-invalid {
  background-image: none !important;
  border-color: #dc3545 !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545 !important;
}

.table-align {
  margin-top: 15px;
  border-radius: 36px;
  margin-left: 10px;
}

table th:first-child {
  border-radius: 15px 0 0 15px;
}

table th:last-child {
  border-radius: 0 15px 15px 0;
}

.table>tbody>tr>td,
.table>thead>tr>th {
  /* border: 0 !important; */
  border-top: none;
}

.header th {
  height: 10px;
  max-width: 600px;
  margin: 100px auto;
}


table th {
  background-color: #f4f5fa !important;
  height: 51px !important;
  padding: 15px !important;
  text-align: center !important;
}


/* style start */
.inner-white-wrap {
  border-radius: 36px;
  background: #fff;
  /* overflow: hidden; */
  height: 100%;
  padding: 15px;
}

.table-outer-wrap {
  padding: 30px;
  background: rgb(244, 245, 250);
  border-radius: 36px;
}

.card-header h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 32px;
  color: #00053c;
}

.loginButton:hover {
  background: #fdd640 !important;
}

.loginButton:hover p.loginButtonText {
  color: #fff !important;
}

.table-outer-wrap .pagination button,
.table-outer-wrap .pagination span {
  margin: 0 3px;
}

table td h1 {
  font-size: 16px;
}

.modal-container-view {
  width: 800px;
  margin: 0 !important;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  padding: 30px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}


.new-color {
  text-decoration: none;
  color: #ff8c00 !important;
}

.modal-container.modal-sm {
  width: 100% !important;
  max-width: 500px;
}

.modal-container.modal-video {
  width: 100% !important;
  max-width: 600px;
}

@media (min-width: 1400px) {
  .modal-container {
    max-width: 1000px;
    width: 100% !important;
    margin: 0 auto !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    max-height: calc(100vh - 0px);
    overflow-y: auto;
  }
}

@media (max-width: 1599.98px) {
  .table>tbody>tr>td {
    padding: 10px;
  }

  table .btn {
    padding: 0 5px !important;
  }
}

@media (max-width: 1299.98px) {
  .modal-container {
    max-width: 85% !important;
  }

  .text-width {
    width: auto !important;
  }

}

@media (max-width: 1199.98px) {
  .profileSettingHeading {
    margin: 0;
  }
}

@media (max-width: 991.98px) {

  .viewReviewInnerBoxImg {
    display: block;
  }

  .text-width {
    margin: 10px !important;
  }
  .navOutDiv {
    position: unset;
  }
  .modal-container-view {
    top: 0;
    transform: translate(-50%, 50%);
    width: 90%;
  }
}

@media (max-width: 767.98px) {
  .table-align>.card {
    margin: 12px !important;
  }

  .card.border-0.mb-7.inner-feedback-action {
    margin: 0 10px;
  }

  .card.border-0.col-9 {
    width: 100% !important;
  }

  .detail-wrap {
    margin-left: 0 !important;
  }

  main .container {
    max-width: 100%;
  }

  table td h1 {
    font-size: 15px;
  }

  .card-header h2 {
    font-size: 26px;
  }

  .table-responsive {
    border: 0;
  }

  .table-responsive .table th {
    border: 0;
  }

  .modal-wrapper a {
    cursor: pointer;
  }

  .table-outer-wrap {
    padding: 12px;
  }

  div#userForDiv {
    margin: 0;
    margin-top: 10px;
  }
}
/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {

  .form-white.inner-white {
    margin-left: 0 !important;
  }

  .form-outline.form-white.inner-white {
    margin-left: 10px !important;
  }
}
.f-start {
  align-self: flex-start;
}


.tourButton {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 29px;
  background: #00053c;
  color: #ffffff;
  width: 125px;
  height: 40px;
  border-radius: 15px;
}

:deep(.v-step) {
  background: #D7E9F7 !important;
  color: #000 !important;
  box-shadow: none !important;
  /* max-width: none !important; */
  width: 450px !important;
  max-width: 500px !important;
  border-radius: 15px !important;
  z-index: 1 !important;
}

:deep(.v-step__button) {
  font-family: "Inter", sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 29px !important;
  background: #00053c !important;
  color: #ffffff !important;
  width: 125px !important;
  height: 40px !important;
  border-radius: 15px !important;
}

:deep(.vue-para) {
  font-size: 16px
}
</style>
