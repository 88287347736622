<template>
    <div>
        <form>
            <div class="
                            d-flex
                            justify-content-between
                            align-items-center
                            mb-5
                          ">
                <h1>Customer Details</h1>
                <div class="d-flex">
                    <a v-if="!customerFeedbackModalEdit" v-b-tooltip.hover @click="editFeedback()"
                        style="cursor: pointer" title="Edit"><img src="resources/assets/editIcon.png" /></a>
                    <a @click="cancel()"><img src="resources/assets/crossicon.png" /></a>
                </div>
            </div>

            <h1></h1>
            <form class="d-block">
                <div class="row align-items-center">
                    <div class="col-md-4 col-12 mb-4 mb-sm-0">
                        <div class="form-outline viewReviewInnerBox d-flex">
                            <div class="text-start">
                                <a class="profileFormTitle"> customerName</a>
                                <br />
                                <a class="profileFormTitleValue">
                                    {{ feedbackViewData.customerName }}</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-12 mb-4 mb-sm-0">
                        <div class="form-outline viewReviewInnerBox d-flex">
                            <div class="text-start">
                                <a class="profileFormTitle"> Rating</a>
                                <br />
                                <a class="profileFormTitleValue">
                                    {{
                                            feedbackViewData.rating
                                                ? feedbackViewData.rating
                                                : "NA"
                                    }}</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-12 mb-4 mb-sm-0">
                        <div class="form-outline viewReviewInnerBox d-flex">
                            <div class="text-start">
                                <a class="profileFormTitle"> Email</a>
                                <br />
                                <a v-if="customerFeedbackModalEdit" class="profileFormTitleValue">
                                    <input type="email" class="
                                        form-control
                                        userForm
                                        form-control-lg
                                        inputOrange
                                      " placeholder="Customer Email Address" v-model="feedbackViewData.email"
                                        v-validate="'required'" v-bind:name="'feedbackViewData.email'"
                                        v-bind:ref="'feedbackViewData.email'" :class="{
                                            'is-invalid': errors.has(
                                                'feedbackViewData.email'
                                            ),
                                        }" /></a>
                                <a v-else class="profileFormTitleValue">
                                    {{
                                            feedbackViewData.email
                                                ? feedbackViewData.email
                                                : "NA"
                                    }}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-md-4 col-12 mb-4 mb-sm-0">
                        <div class="form-outline viewReviewInnerBox d-flex">
                            <div class="text-start">
                                <a class="profileFormTitle"> Country Code</a>
                                <br />
                                <a v-if="customerFeedbackModalEdit" class="profileFormTitleValue">
                                    <select class="
                                        viewReviewSecondPartdropdownText
                                        inputOrange
                                        assignFollowUp
                                        form-select
                                      " v-model="feedbackCode" v-validate="'required'" v-bind:name="'requestData.code'"
                                        v-bind:ref="'requestData.code'" :class="{
                                            'is-invalid':
                                                errors.has('requestData.code'),
                                        }">
                                        <option class="viewReviewSecondPartdropdownText" value="">
                                            Select Country
                                        </option>
                                        <option class="viewReviewSecondPartdropdownText" :value="location.shortName"
                                            v-for="location in countryCodelist" :key="location.id">
                                            {{
                                                    `${location.name} (+${location.code})`
                                            }}
                                        </option>
                                    </select></a>
                                <a v-else class="profileFormTitleValue">
                                    {{
                                            feedbackCountryCode
                                                ? feedbackCountryCode
                                                : "NA"
                                    }}</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-12 mb-4 mb-sm-0">
                        <div class="form-outline viewReviewInnerBox d-flex">
                            <div class="text-start">
                                <a class="profileFormTitle"> Number</a>
                                <br />
                                <a v-if="customerFeedbackModalEdit" class="profileFormTitleValue">
                                    <input type="number" class="
                                        form-control
                                        userForm
                                        inputOrange
                                      " placeholder="Customer Phone Number" v-model="feedbackViewData.phoneNumber"
                                        v-validate="'required'" v-bind:name="'feedbackViewData.phoneNumber'"
                                        v-bind:ref="'feedbackViewData.phoneNumber'" :class="{
                                            'is-invalid': errors.has(
                                                'feedbackViewData.phoneNumber'
                                            ),
                                        }" /></a>
                                <a v-else class="profileFormTitleValue">
                                    {{
                                            feedbackViewData.phoneNumber
                                                ? feedbackViewData.phoneNumber
                                                : "NA"
                                    }}</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-12 mb-4 mb-sm-0">
                        <div class="form-outline viewReviewInnerBox d-flex">
                            <div class="text-start">
                                <a class="profileFormTitle">
                                    Follow Up Notes</a>
                                <br />
                                <a class="profileFormTitleValue">
                                    {{
                                            feedbackViewData.followUpNotes
                                                ? feedbackViewData.followUpNotes
                                                : "NA"
                                    }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="form-outline viewReviewInnerBox d-flex">
                        <div class="text-start">
                            <a class="profileFormTitle"> Feedback</a>
                            <br />
                            <a class="profileFormTitleValue">
                                {{
                                        feedbackViewData.feedback
                                            ? feedbackViewData.feedback
                                            : "NA"
                                }}
                            </a>
                        </div>
                    </div>
                </div>
                <a v-if="customerFeedbackModalEdit" @click.prevent="updateFeedback()"
                    class="d-flex justify-content-end">
                    <button class="platFormCardButton" style="width: 180px; margin-top: 15px">
                        Save
                    </button>
                </a>
            </form>
        </form>
    </div>
</template>

<script>
export default {
    name: "CustomerDetails",
    props: {
        customerId: {
            type: Number,
        },
    },
    data() {
        return {
            customerFeedbackModalEdit: false,
            feedbackViewData: [],
            countryCodelist: [],
            feedbackCountryCode: "",
            userLoginId: "",

        };
    },

    mounted() {
        this.userLoginId = localStorage.getItem("id");
        this.customerFeedbackModalOpen(this.customerId);
        this.countryCode();
    },
    created() {
    },
    computed: {},
    methods: {
        customerFeedbackModalOpen(customerId) {
            this.customerFeedbackModalEdit = false;
            this.feedbackViewData = [];
            this.$store.dispatch("viewFeedback", customerId).then((response) => {
                this.feedbackViewData = response.data[0];
                let countryData = this.countryCodelist.find(
                    (element) => element.shortName == this.feedbackViewData.shortName
                );
                this.feedbackCode =
                    countryData && countryData.shortName ? countryData.shortName : "";
                this.feedbackCountryCode =
                    countryData && countryData.name && countryData.code
                        ? `${countryData.name} (+${countryData.code}) `
                        : "";
                this.customerFeedbackModal = true;
            });

        },
        editFeedback() {
            this.customerFeedbackModalEdit = true;
        },
        countryCode() {
            this.$store.dispatch("requestCountryCode").then((response) => {
                if (response.success) {
                    this.countryCodelist = response.data;
                }
            });
        },
        async updateFeedback() {
            let result = await this.$validator.validateAll();
            if (result) {
                let countryCode = this.countryCodelist.find(
                    (element) => element.shortName == this.feedbackCode
                );

                let data = {
                    id: this.feedbackViewData.id,
                    userID: this.userLoginId,
                    email: this.feedbackViewData.email,
                    phone: this.feedbackViewData.phoneNumber,
                    code: countryCode && countryCode.code ? "+" + countryCode.code : "+1",
                    shortName:
                        countryCode && countryCode.shortName ? countryCode.shortName : "US",
                };
                this.$store
                    .dispatch("feedbackUpdate", data)
                    .then((response) => {
                        if (response.success) {
                            this.customerFeedbackModalEdit = false;
                            this.feedbackCountryCode =
                                countryCode && countryCode.name && countryCode.code
                                    ? `${countryCode.name} (+${countryCode.code}) `
                                    : "";

                            this.$toastr.s(`${response.message}`);
                        } else {
                            if (response.data.email) {
                                this.$toastr.e(response.data.email);
                            }
                            if (response.data.phone) {
                                this.$toastr.e(response.data.phone);
                            }
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        cancel() {
            this.customerFeedbackModalEdit = false
            this.$emit("closeModal", "true");
        }
    },
};
</script>

<style scoped>
.viewReviewInnerBox {
    background-color: white;
    margin-bottom: 20px;
}

.profileFormTitle {
    text-decoration-line: none;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    align-items: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #9194b3 !important;
}

.profileFormTitleValue {
    text-decoration-line: none;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    /* font-size: 12px; */
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #00053c !important;
}

.platFormCardButton {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    /* or 181% */
    background: #00053c;
    color: #ffffff;
    width: 180px;
    height: 51px;
    border-radius: 15px;
}

.assignFollowUp {
    height: 50px;
    left: 889px;
    top: 413px;
    /* background: #ffffff; */
    border: 1px solid #f3f3f3;
    box-sizing: border-box;
    border-radius: 15px;
    width: 100%;
    padding: 10px;
}

.viewReviewSecondPartdropdownText {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px !important;
    /* font-size: 16px !important; */
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #9194b3;
}

.userForm {
    height: 50px;
    /* height: 58px; */
    border-radius: 15px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px !important;
}
.inputOrange:focus {
  outline: none !important;
  border: 1.5px solid #ff8c00;
  box-shadow: 0 0 2px #ff8c00;
}
@media screen and (max-width: 600px) {
    .form-outline.form-white.inner-white {
        margin-left: 10px !important;
    }
}
</style>
